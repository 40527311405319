import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import deleteIcon from "../../../../assets/img/candidate/Icon feather-delete.svg"
import editIcon from "../../../../assets/img/candidate/Icon feather-edit-3 (1).svg"
import AddmoreIcon from "../../../../assets/img/candidate/Icon feather-plus-circle.svg"
import "./editWorkHistory.css";
import axios from "axios";
import config from "../../../../Shared/Config/config.json";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import LoadingIcon from "../../../../assets/loader/loader.gif";
import { BackEndErrorMsg } from "../../Shared/Error/error";
import Modal from 'react-bootstrap/Modal';
import noDataFound from "../../../../assets/Home-images/nodatafound.png"
import diskette from "../../../../assets/img/candidate/diskette.png"

function EditWorkHistory(props) {
    let [loading, setLoading] = useState(false);
    let [checked, setChecked] = useState(true);
    let [showErrMessage, setShowErrMessage] = useState();
    let [showErrorPopup, setShowErrorPopup] = useState(false);
    let [showSuccessPopup, setShowSuccessPopup] = useState(false);
    let [disableBtn, setDisableBtn] = useState(false);
    let [disableSaveBtn, setDisableSaveBtn] = useState(false);
    let [addMoreExpModal, setAddMoreExpModal] = useState(false);
    let [selectedWorkedUntil, setSelectedWorkedUntil] = useState();
    let [selectedWorkedFrom, setSelectedWorkedFrom] = useState();
    let cId = props.cid;

    let doNotRenderTwiceRfi = false;
    useEffect(() => {
        if (!doNotRenderTwiceRfi) {
            doNotRenderTwiceRfi = true
            props.getProfileCanData(cId);
        }
    }, []);

    let addMoreExpHistory = () => {
        props.setMultiWorkHistory([...props.multiWorkHistory, {
            designation: "",
            companyName: "",
            workedFrom: "",
            workedUntil: "",
            workingNow: "",
        }]);
        setDisableBtn(true);
        setDisableSaveBtn(false);
        setAddMoreExpModal(true);
        setSelectedWorkedUntil("");
        setSelectedWorkedFrom("");
    }

    let closeModal = () => {
        setAddMoreExpModal(false);
        setSelectedWorkedUntil("");
        setSelectedWorkedFrom("");
        setChecked(true);
        props.setMultiWorkHistory(props.multiWorkHistory_2);
        props.getProfileCanData(cId);
    }

    let removeWorkHistoy = (index) => {
        let newFormValues = [...props.multiWorkHistory];
        const removedWork = newFormValues[index].workHistoryId;
        // const removedWork = newFormValues.splice(index, 1)[0];
        newFormValues.splice(index, 1);
        props.setMultiWorkHistory(newFormValues);
        if (removedWork !== undefined) {
            deleteWorkHistory(removedWork);
        }
        setDisableBtn(true);
        // setDisableSaveBtn(true);
    }

    // -----------------> old desing function <-----------------
    // let onHandleDate = (e, i, type) => {
    //     let newFormValues = [...props.multiWorkHistory];
    //     if (type == "workedUntil") {
    //         if (!checked) {
    //             newFormValues[i].workedUntil = ""
    //         } else {
    //             newFormValues[i].workedUntil = e
    //         }
    //     } else {
    //         newFormValues[i].workedFrom = e
    //     }

    //     if (newFormValues[i].designation == "") {
    //         setDisableSaveBtn(false);
    //     } else {
    //         setDisableSaveBtn(true);
    //     }
    //     props.setMultiWorkHistory(newFormValues);
    //     setDisableBtn(true);
    // }

    // -----------------> new desing function <-----------------
    let onHandleDate = (e, ind, inputType, type) => {
        let newFormValues = [...props.multiWorkHistory];
        let newFormValues_2 = [...props.multiWorkHistory_2];
        if (type !== "editWork") {
            if (inputType == "workedUntil") {
                if (!checked) {
                    newFormValues[newFormValues.length - 1].workedUntil = ""
                    setSelectedWorkedUntil("");
                } else {
                    newFormValues[newFormValues.length - 1].workedUntil = moment(e).format("MMM yyyy");
                    setSelectedWorkedUntil(e);
                }
            } else {
                newFormValues[newFormValues.length - 1].workedFrom = moment(e).format("MMM yyyy");
                newFormValues[newFormValues.length - 1].workedUntil = "";
                setSelectedWorkedUntil("");
                setSelectedWorkedFrom(e);
            }
            if (newFormValues[newFormValues.length - 1].companyName == "" || props.multiWorkHistory[newFormValues.length - 1].workedFrom == "" || props.multiWorkHistory[newFormValues.length - 1].workedUntil == "" && props.multiWorkHistory[newFormValues.length - 1].workingNow == false) {
                setDisableSaveBtn(false);
            } else {
                setDisableSaveBtn(true);
            }
        } else {
            newFormValues_2[ind].changeBtn = true;
            if (inputType == "workedUntil") {
                if (!checked) {
                    newFormValues_2[ind].workedUntil = ""
                    setSelectedWorkedUntil("");
                } else {
                    newFormValues_2[ind].workedUntil = moment(e).format("MMM yyyy");
                    setSelectedWorkedUntil(e);
                }
                if (newFormValues_2[ind].designation == "" || newFormValues_2[ind].companyName == "" || props.multiWorkHistory_2[ind].workedFrom == "" || props.multiWorkHistory_2[ind].workedUntil == "" || props.multiWorkHistory_2[ind].workingNow == true) {
                    newFormValues_2[ind].disableChangeBtn = true;
                } else {
                    newFormValues_2[ind].disableChangeBtn = false;
                }
            } else {
                newFormValues_2[ind].workedFrom = moment(e).format("MMM yyyy");
                newFormValues_2[ind].workedUntil = "";
                setSelectedWorkedFrom(e);
                setSelectedWorkedUntil("");
            }
        }
        props.setMultiWorkHistory(newFormValues);
        setDisableBtn(true);
    }

    // -----------------> old desing function <-----------------
    // let handleChange = (e, i) => {
    //     if (e.target.name === "designation") {
    //         if (e.target.name === "designation" && e.target.value === "") {
    //             setDisableSaveBtn(false);
    //         } else {
    //             setDisableSaveBtn(true);
    //         }
    //     }
    //     let newFormValues = [...props.multiWorkHistory];
    //     if (e.target.name == "workingNow") {
    //         if (e.target.checked) {
    //             setChecked(false);
    //             newFormValues[i][e.target.name] = true
    //             newFormValues[i].workedUntil = ""
    //         } else {
    //             setChecked(true);
    //             newFormValues[i][e.target.name] = false
    //             // newFormValues[i].workedUntil = e.target.value
    //         }
    //     } else {
    //         newFormValues[i][e.target.name] = e.target.value;
    //     }

    //     if (newFormValues[i].designation == "" || props.multiWorkHistory[i].workedUntil == "" && props.multiWorkHistory[i].workingNow == false) {
    //         setDisableSaveBtn(false);
    //     } else {
    //         setDisableSaveBtn(true);
    //     }
    //     props.setMultiWorkHistory(newFormValues);
    //     setDisableBtn(true);
    // };

    // -----------------> new desing function <-----------------
    let handleChange = (e, ind, type) => {
        let newFormValues = [...props.multiWorkHistory];
        let newFormValues_2 = [...props.multiWorkHistory_2];

        if (e.target.name === "designation") {
            if (e.target.name === "designation" && e.target.value === "") {
                setDisableSaveBtn(false);
            } else {
                setDisableSaveBtn(true);
            }
        }

        if (type !== "editWork") {
            if (e.target.name == "workingNow") {
                if (e.target.checked) {
                    setChecked(false);
                    newFormValues[newFormValues.length - 1][e.target.name] = true
                    newFormValues[newFormValues.length - 1].workedUntil = ""
                    setSelectedWorkedUntil("");
                } else {
                    setChecked(true);
                    newFormValues[newFormValues.length - 1][e.target.name] = false
                    // newFormValues[newFormValues.length - 1].workedUntil = e.target.value
                    // setSelectedWorkedUntil(e.target.value);
                }
            } else {
                newFormValues[newFormValues.length - 1][e.target.name] = e.target.value;
                // setSelectedWorkedUntil(e.target.value);
                // setSelectedWorkedFrom(e.target.value);
            }

            if (newFormValues[newFormValues.length - 1].companyName == "" || props.multiWorkHistory[newFormValues.length - 1].workedFrom == "" || props.multiWorkHistory[newFormValues.length - 1].workedUntil == "" && props.multiWorkHistory[newFormValues.length - 1].workingNow == false) {
                setDisableSaveBtn(false);
            } else {
                setDisableSaveBtn(true);
            }

        } else {
            newFormValues_2[ind].changeBtn = true;
            if (e.target.name == "workingNow") {
                if (e.target.checked) {
                    setChecked(false);
                    newFormValues_2[ind][e.target.name] = true
                    newFormValues_2[ind].workedUntil = ""
                    setSelectedWorkedUntil("");
                } else {
                    setChecked(true);
                    newFormValues_2[ind][e.target.name] = false
                    // newFormValues[newFormValues.length - 1].workedUntil = e.target.value
                    // setSelectedWorkedUntil(e.target.value);
                }
            } else {
                newFormValues_2[ind][e.target.name] = e.target.value;
                // setSelectedWorkedUntil(e.target.value);
                // setSelectedWorkedFrom(e.target.value);

                if (newFormValues_2[ind].designation == "" || newFormValues_2[ind].companyName == "" || props.multiWorkHistory_2[ind].workedFrom == "" || props.multiWorkHistory_2[ind].workedUntil == "" && props.multiWorkHistory_2[ind].workingNow == false) {
                    newFormValues_2[ind].disableChangeBtn = true;
                } else {
                    newFormValues_2[ind].disableChangeBtn = false;
                }
            }
        }

        props.setMultiWorkHistory(newFormValues);
        props.setMultiWorkHistory_2(newFormValues_2);
        setDisableBtn(true);
    };

    let discardChanges = () => {
        setDisableBtn(false);
        setDisableSaveBtn(false);
        props.getProfileCanData(cId);
    }

    let editAddedSkills = (ind) => {
        let newFormValues_2 = [...props.multiWorkHistory_2];
        newFormValues_2[ind].disableField = false;
        props.setMultiWorkHistory_2(newFormValues_2);
        if (newFormValues_2[ind].workingNow === true) {
            setChecked(false);
        } else {
            setChecked(true);
        }
    }

    const addWorkHistory = async () => {
        setLoading(true);
        let updateMultiwork = props.multiWorkHistory.map(({ changeBtn, disableField, disableChangeBtn, ...rest }) => rest);
        let payLoad = {
            "cId": cId,
            "fresher": false,
            "workHistory": updateMultiwork
        }

        let apiUrl = config.baseUrl_candidate + "/Profile/addWorkHistory"
        await axios.post(apiUrl, payLoad, {
            headers: {
                "X-Session": sessionStorage.getItem("sessionid")
            }
        }).then((result) => {
            setLoading(false);
            let res = result.data;
            props.getProfileCanData(cId);
            setDisableBtn(false);
            setShowSuccessPopup(true);
            setShowErrMessage(res.data.message);
            setAddMoreExpModal(false);
            setSelectedWorkedUntil("");
            setSelectedWorkedFrom("");
            setChecked(true);

        }).catch((err) => {
            if (err) {
                setLoading(false);
                setShowErrorPopup(true);
                setShowErrMessage(err.response.data.error.message);
            }
        })
    }

    const deleteWorkHistory = async (id) => {
        setLoading(true);
        let payLoad = {
            "cId": cId,
            "workHistoryId": id
        }

        let apiUrl = config.baseUrl_candidate + "/Profile/deleteWorkHistory"
        await axios.post(apiUrl, payLoad, {
            headers: {
                "X-Session": sessionStorage.getItem("sessionid")
            }
        }).then((result) => {
            setLoading(false);
            let res = result.data;
            props.getProfileCanData(cId);
            setDisableBtn(false);
            setShowSuccessPopup(true);
            setShowErrMessage(res.data.message);
        }).catch((err) => {
            if (err) {
                setLoading(false);
                setShowErrorPopup(true);
                setShowErrMessage(err.response.data.error.message);
            }
        })
    }


    return (
        <>
            {loading && (
                <div className="loader">
                    <img src={LoadingIcon} alt="loader" />
                </div>
            )}
            {/* -------------> success modal <----------- */}
            {showErrorPopup || showSuccessPopup ?
                <BackEndErrorMsg
                    showErrorPopup={showErrorPopup}
                    showSuccessPopup={showSuccessPopup}
                    setShowErrorPopup={setShowErrorPopup}
                    setShowSuccessPopup={setShowSuccessPopup}
                    message={showErrMessage}
                />
                : null}
            <div className="edit_KeySkills_info">
                <div className="profile_photo_item">
                    <h1>Work History</h1>
                    {props.multiWorkHistory_2?.length > 0 &&
                        <div className="discard_save_btn">
                            {/* {disableBtn &&
                            <label onClick={() => discardChanges()}>Discard</label>
                        }
                        <Button onClick={addWorkHistory} disabled={!disableSaveBtn}>Save</Button> */}
                            <img onClick={addMoreExpHistory} style={{ cursor: "pointer", width: "14px" }} src={AddmoreIcon} alt="add more" />
                            <h3 onClick={addMoreExpHistory}>Add Work History</h3>
                        </div>
                    }
                </div>
                {props.multiWorkHistory_2?.length > 0 ?
                    <div className={props.multiWorkHistory.length > 1 ? "content_1" : "content_2"}>
                        {props.multiWorkHistory_2?.map((element, index) => (
                            <div className="skills_main_block" key={index} style={{ flexDirection: "column" }}>
                                {element.disableChangeBtn &&
                                    <div className="msg">
                                        <label>Mandatory Fields cannot be Empty</label>
                                    </div>}
                                <div className="added_skills" style={{ width: "100%" }}>
                                    <div style={{
                                        display: "flex",
                                        alignItems: "flex-start",
                                        justifyContent: "space-between"
                                    }}>
                                        <div className="skill" style={{ width: "37%" }}>
                                            <label>Designation*</label>
                                            <input type="text" className="form-control" id="usr" placeholder="ex. Software Engineer"
                                                value={element?.designation} name="designation" onChange={(e) => handleChange(e, index, "editWork")} disabled={element.disableField}
                                                autoComplete="off" />
                                        </div>
                                        <div style={{ width: "35%" }}></div>
                                        <div className="edit_delete">
                                            <div className="edit">
                                                {
                                                    !element.changeBtn ?
                                                        <>
                                                            <img onClick={() => editAddedSkills(index)} src={editIcon} alt="edit" style={{ width: "16px" }} />
                                                            <label onClick={() => editAddedSkills(index)} style={{ marginLeft: "7px" }}>Edit</label>
                                                        </>
                                                        :
                                                        <>
                                                            {
                                                                !element.disableChangeBtn &&
                                                                <>
                                                                    <img onClick={() => addWorkHistory("editWork")} src={diskette} alt="edit" style={{ width: "16px" }} />
                                                                    <label style={{ marginLeft: "7px" }} onClick={() => addWorkHistory("editWork")}>Save</label>
                                                                </>
                                                            }
                                                        </>
                                                }
                                            </div>
                                            <div className="delete">
                                                <img src={deleteIcon} alt="delete" />
                                                <label style={{ marginLeft: "3px" }} onClick={() => removeWorkHistoy(index)}>Delete</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="skill_levels">
                                        <div className="skill" style={{ width: "41%" }}>
                                            <label>Company*</label>
                                            <input type="text" className="form-control" id="usr" placeholder="Rao infosoftjoin pvt. lmt."
                                                value={element?.companyName} name="companyName" onChange={(e) => handleChange(e, index, "editWork")} disabled={element.disableField}
                                                autoComplete="off" />
                                        </div>
                                        <div className="workedFrom" style={{ width: "35%", marginLeft: "20px" }}>
                                            <label style={{ marginRight: "10px" }}>Worked from*</label>
                                            <ReactDatePicker
                                                showIcon
                                                selected={element?.workedFrom ? moment(element?.workedFrom).toDate() : null}
                                                name="workedFrom"
                                                onChange={(date) => onHandleDate(date, index, "workedFrom", "editWork")}
                                                // minDate={new Date()}
                                                maxDate={new Date()}
                                                dateFormat="MMM yyyy"
                                                placeholderText="Click to select a date"
                                                showMonthDropdown
                                                peekNextMonth
                                                showYearDropdown
                                                dropdownMode="select"
                                                showMonthYearPicker
                                                disabled={element.disableField}
                                                autoComplete="off"
                                            />
                                        </div>
                                        <div className="workedUntil" style={{ width: "35%", marginLeft: "20px" }}>
                                            <label style={{ marginRight: "10px" }}>Worked until*</label>
                                            <ReactDatePicker
                                                showIcon
                                                selected={element?.workedUntil ? moment(element?.workedUntil).toDate() : null}
                                                name="workedUntil"
                                                onChange={(date) => onHandleDate(date, index, "workedUntil", "editWork")}
                                                minDate={moment(element?.workedFrom).toDate()}
                                                maxDate={new Date()}
                                                dateFormat="MMM yyyy"
                                                placeholderText="Click to select a date"
                                                // disabled={element.workingNow}
                                                disabled={element.disableField || !checked}
                                                autoComplete="off"
                                                showMonthYearPicker
                                            />
                                            {/* {props.multiWorkHistory[index].workedUntil == "" && props.multiWorkHistory[index].workingNow == false ?
                                                <label className="helper_text"> please select last worked date</label> : null
                                            } */}
                                        </div>
                                    </div>
                                    {/* {
                                    checked ?
                                        <div className="currectComp">
                                            <input type="checkbox" name="workingNow"
                                                onChange={(e) => handleChange(e, index)} checked={element?.workingNow} disabled={!element?.workingNow} />
                                            <label style={{ marginLeft: "8px" }} for="currectCompany">I work here now</label>
                                        </div>
                                        : */}
                                    <div className="currectComp">
                                        <input type="checkbox" name="workingNow"  style={{ cursor: "pointer"}}
                                            onChange={(e) => handleChange(e, index, "editWork")} checked={element?.workingNow} disabled={element.disableField} />
                                        <label style={{ marginLeft: "8px" }} for="currectCompany">Present</label>
                                    </div>
                                    {/* } */}
                                </div>
                            </div>
                        ))}
                    </div>
                    :
                    <div className="menu_cat_child2_noRecords_found">
                        <img src={noDataFound} />
                        <div className="text">
                            No work history entries
                            <h6>Click <b onClick={addMoreExpHistory}>'Add Work Experience'</b>to begin shaping your comprehensive career profile</h6>
                        </div>
                    </div>
                }
            </div>

            {/* -------------------> add new skill <--------------------------- */}
            <div className="new_skill_modal">
                <Modal
                    show={addMoreExpModal} onHide={closeModal} className="new_skill_modal">
                    <Modal.Header closeButton>
                        <div className="header">
                            <h4>Add Work History</h4>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="skills_main_block">
                            <div className="added_skills" style={{ width: "100%" }}>
                                <div className="skill">
                                    <label>Designation*</label>
                                    <input type="text" className="form-control" id="usr" placeholder="ex. Software Engineer" name="designation" onChange={(e) => handleChange(e, null, "newWork")}
                                        autoComplete="off" />
                                </div>
                                <div className="skill_levels_new">
                                    <div className="skill">
                                        <label>Company*</label>
                                        <input type="text" className="form-control" id="usr" placeholder="Rao infosoftjoin pvt. lmt." name="companyName" onChange={(e) => handleChange(e, null, "newWork")}
                                            autoComplete="off" />
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        marginTop: "10px"
                                    }}>
                                        <div className="workedFrom">
                                            <label style={{ marginRight: "10px" }}>Worked from*</label>
                                            <ReactDatePicker
                                                showIcon
                                                selected={selectedWorkedFrom ? moment(selectedWorkedFrom).toDate() : null}
                                                name="workedFrom"
                                                onChange={(date) => onHandleDate(date, null, "workedFrom", "newWork")}
                                                // minDate={new Date()}
                                                maxDate={new Date()}
                                                dateFormat="MMM yyyy"
                                                placeholderText="Click to select a date"
                                                showMonthDropdown
                                                peekNextMonth
                                                showYearDropdown
                                                dropdownMode="select"
                                                autoComplete="off"
                                                showMonthYearPicker
                                            />
                                        </div>
                                        <div className="workedUntil">
                                            <label style={{ marginRight: "10px" }}>Worked until*</label>
                                            <ReactDatePicker
                                                showIcon
                                                selected={selectedWorkedUntil ? moment(selectedWorkedUntil).toDate() : null}
                                                name="workedUntil"
                                                onChange={(date) => onHandleDate(date, null, "workedUntil", "newWork")}
                                                minDate={moment(selectedWorkedFrom).toDate()}
                                                maxDate={new Date()}
                                                dateFormat="MMM yyyy"
                                                placeholderText="Click to select a date"
                                                disabled={!checked}
                                                autoComplete="off"
                                                showMonthYearPicker
                                            />
                                            {/* {selectedWorkedUntil == "" && checked?
                                                <label className="helper_text"> please select last worked date</label> : null
                                            } */}
                                        </div>
                                    </div>
                                </div>
                                <div className="currectComp">
                                    <input type="checkbox" name="workingNow" style={{ cursor: "pointer"}}
                                        onChange={(e) => handleChange(e, null, "newWork")} />
                                    <label style={{ marginLeft: "8px", cursor:"pointer" }} for="currectCompany">Present</label>
                                </div>
                                <div className="discard_save_btn">
                                    <Button style={{ marginLeft: "0" }} onClick={() => addWorkHistory("newWork")} disabled={!disableSaveBtn}>SAVE</Button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    );
};

export default EditWorkHistory;