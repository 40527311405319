import React, { useEffect, useState } from "react";
import Select from 'react-select';
import Button from "react-bootstrap/Button";
import deleteIcon from "../../../../assets/img/candidate/Icon feather-delete.svg"
import editIcon from "../../../../assets/img/candidate/Icon feather-edit-3 (1).svg"
import AddmoreIcon from "../../../../assets/img/candidate/Icon feather-plus-circle.svg"
import diskette from "../../../../assets/img/candidate/diskette.png"
import "./editSkills.css";
import axios from "axios";
import config from "../../../../Shared/Config/config.json";
import { Rating } from "@mui/material";
import LoadingIcon from "../../../../assets/loader/loader.gif";
import { BackEndErrorMsg } from "../../Shared/Error/error";
import Modal from 'react-bootstrap/Modal';
// import ReactStars from "react-rating-stars-component";
import noDataFound from "../../../../assets/Home-images/nodatafound.png"

function EditSkills(props) {

    let [loading, setLoading] = useState(false);
    let cId = props.cid;
    let [skillList, setSkillList] = useState([]);
    let [changeBtn, setChangeBtn] = useState(false);
    let [disableSaveBtn, setDisableSaveBtn] = useState(false);
    let [showErrMessage, setShowErrMessage] = useState();
    let [showErrorPopup, setShowErrorPopup] = useState(false);
    let [showSuccessPopup, setShowSuccessPopup] = useState(false);
    let [addMoreSkillModal, setAddMoreSkillModal] = useState(false);
    let matchedIndex;
    let skillLevelList = [
        "Basic",
        "Intermediate",
        "Advanced"
    ];
    let onChangedSkill;
    let previousSkill;

    let doNotRenderTwiceRfi = false;
    useEffect(() => {
        if (!doNotRenderTwiceRfi) {
            doNotRenderTwiceRfi = true
            props.getProfileCanData(cId);
        }
    }, []);

    // -----------------> old desing function <-----------------
    // let onChangeSkill = (val, changed_val, index) => {
    //     let newFormValues = [...props.multiSkills];
    //     let isMatched = newFormValues.some(item => item.skillId === changed_val.skillId);
    //     // if (isMatched) {
    //     //     setMatchedSkillMsg(true);
    //     // } else {
    //     //     setMatchedSkillMsg(false);
    //     // }
    //     if (val.skillNew == "old") {
    //         console.log(val);
    //         if (val.skillId == changed_val.skillId) {
    //             changed_val.disable = false;
    //             // newFormValues.splice(index, 0, changed_val);
    //         } else {
    //             val.disable = true;
    //             newFormValues[index] = val;
    //             changed_val.disable = false;
    //             newFormValues.splice(index, 0, changed_val);
    //         }
    //     } else {
    //         changed_val.disable = false;
    //         newFormValues[index] = changed_val
    //     }
    //     props.setMultiSkills(newFormValues);
    //     console.log(newFormValues);
    //     setDisableBtn(true);
    //     setDisableSaveBtn(true);
    // }

    // -----------------> new desing function <-----------------
    let onChangeSkill = (value) => {
        let newFormValues = [...props.multiSkills];
        newFormValues[newFormValues.length - 1].skillId = value.value
        newFormValues[newFormValues.length - 1].skillName = value.label
    }

    let onChangeSkillLevel = (val, type, ind) => {
        let newFormValues = [...props.multiSkills];
        if (type !== "editSkill") {
            newFormValues[newFormValues.length - 1].skillLevel = val.label;
        } else {
            let newFormValues_2 = [...props.multiSkills_2];
            newFormValues_2[ind].skillLevel = val.label;
            newFormValues_2[ind].changeBtn = true;
            props.setMultiSkills_2(newFormValues_2);
            newFormValues[newFormValues.length - 1].skillLevel = val.label;
        }
    }

    // -----------------> old desing function <-----------------
    // let handleChange = (e, i) => {
    //     let newFormValues = [...props.multiSkills];
    //     if (e.target.value === "") {
    //         setDisableSaveBtn(false);
    //         if (e.target.name === "skillExpMonth" || e.target.name == "skillExperience" && e.target.value === "") {
    //             setDisableSaveBtn(false);
    //         } else {
    //             setDisableSaveBtn(true);
    //         }
    //     } else {
    //         if (e.target.name === "skillExpMonth" || e.target.name == "skillExperience" && e.target.value === "") {
    //             setDisableSaveBtn(false);
    //         } else {
    //             setDisableSaveBtn(true);
    //         }
    //     }
    //     let temp = parseInt(newFormValues[i].skillExpMonth);
    //     if (e.target.name == "skillExperience") {
    //         newFormValues[i].skillExpValue = e.target.value;
    //         let totalSkillExp = (temp * 12) + parseInt(e.target.value);
    //         newFormValues[i][e.target.name] = totalSkillExp;
    //     } else {
    //         newFormValues[i][e.target.name] = e.target.value;
    //     }

    //     if (e.target.name == "skillExpMonth") {
    //         newFormValues[i].skillExperience = e.target.value;
    //         newFormValues[i].skillExpValue = ""
    //     }
    //     props.setMultiSkills(newFormValues);
    //     setDisableBtn(true);
    // };

    // -----------------> new desing function <-----------------
    let handleChange = (e, type, ind) => {
        let newFormValues = [...props.multiSkills];
        let newFormValues_2 = [...props.multiSkills_2];
        if (e.target.value === "") {
            setDisableSaveBtn(false);
            if (e.target.name === "skillExpMonth" || e.target.name == "skillExperience" && e.target.value === "") {
                setDisableSaveBtn(false);
            } else {
                setDisableSaveBtn(true);
            }
        } else {
            if (e.target.name === "skillExpMonth" || e.target.name == "skillExperience" && e.target.value === "") {
                setDisableSaveBtn(false);
            } else {
                setDisableSaveBtn(true);
            }
        }
        if (type !== "editSkill") {
            let lastObject = newFormValues[newFormValues.length - 1];
            let lastObjectExpMonth = lastObject.skillExpMonth;
            let temp = parseInt(lastObjectExpMonth);
            if (e.target.name == "skillExperience") {
                let lastObject_2 = newFormValues[newFormValues.length - 1];
                let lastObjectExpValue = lastObject_2.skillExpValue;
                lastObjectExpValue = e.target.value;
                let totalSkillExp = (temp * 12) + parseInt(e.target.value);
                newFormValues[newFormValues.length - 1][e.target.name] = totalSkillExp;
            } else {
                newFormValues[newFormValues.length - 1][e.target.name] = e.target.value;
            }

            if (e.target.name == "skillExpMonth") {
                newFormValues[newFormValues.length - 1].skillExperience = e.target.value;
                newFormValues[newFormValues.length - 1].skillExpValue = ""
            }
        } else {
            newFormValues_2[ind].changeBtn = true;
            let lastObject_2 = newFormValues_2[ind];
            let temp = parseInt(lastObject_2.skillExpMonth);
            if (e.target.name === "skillExperience") {
                let totalSkillExp = (parseInt(newFormValues_2[ind].skillExpMonth) * 12) + parseInt(e.target.value);
                newFormValues_2[ind][e.target.name] = totalSkillExp;
                newFormValues_2[ind].skillExpValue = e.target.value;
                newFormValues[ind].skillExperience = totalSkillExp;
            } else {
                newFormValues_2[ind][e.target.name] = e.target.value;
                let lastObjectExpValue_2 = lastObject_2.skillExpValue;
                let totalSkillExp = (parseInt(newFormValues_2[ind].skillExpMonth) * 12) + parseInt(lastObjectExpValue_2);
                newFormValues[ind].skillExperience = totalSkillExp;
            }

            // if (e.target.name == "skillExpMonth") {
            //     newFormValues_2[ind].skillExperience = e.target.value;
            //     newFormValues_2[ind].skillExpValue = ""
            // }
        }
        props.setMultiSkills(newFormValues);
        props.setMultiSkills_2(newFormValues_2);
    };

    let addMoreSkills = () => {
        props.setMultiSkills([...props.multiSkills, {
            skillId: "",
            skillLevel: "",
            skillExperience: "",
            skillExpMonth: "",
            skillExpValue: "",
            skillRating: "",
            skillNew: "new",
            disable: false
        }]);
        getSkillList();
        setAddMoreSkillModal(true);
        setDisableSaveBtn(false);
        // props.getProfileCanData(cId);
    }

    let closeModal = () => {
        setAddMoreSkillModal(false);
        props.setMultiSkills(props.multiSkills_2);
        props.getProfileCanData(cId);
    }

    let removeAddedSkills = (index) => {
        let newFormValues = [...props.multiSkills];
        const removedSkill = newFormValues.splice(index, 1)[0];
        // newFormValues.splice(index, 1);
        removedSkill.disable = true;
        newFormValues.push(removedSkill);
        props.setMultiSkills(newFormValues);
        // props.getProfileCanData(cId);
        setDisableSaveBtn(true);
        addSkills(null, "addSkillModal");
    }

    let discardChanges = () => {
        props.getProfileCanData(cId);
        setDisableSaveBtn(false);
    }

    let editAddedSkills = (ind) => {
        let newFormValues_2 = [...props.multiSkills_2];
        newFormValues_2[ind].disableField = false;
        props.setMultiSkills_2(newFormValues_2);
    }

    const getSkillList = () => {
        setLoading(true);
        let searchJson = {
            "json": {
                "request": {
                    servicetype: 6,
                    functiontype: 6001,
                    data: {

                    }
                }
            }
        }

        let searchApiUrl = config.baseUrl + "/JobzTop/SearchJob?request=" + encodeURIComponent(JSON.stringify(searchJson));

        axios.post(searchApiUrl)
            .then((result) => {
                setLoading(false);
                let skillTemp = result.data.json.response.data
                let filteredSkillTemp = skillTemp.filter(skill =>
                    !props.multiSkills.some(multiSkill => multiSkill.skillId === skill.skillId)
                )
                // if (filteredSkillTemp !== -1) {
                //     skillTemp.splice(filteredSkillTemp, 1);
                // }
                // console.log(skillTemp)
                setSkillList(filteredSkillTemp);
            }).catch((err) => {
                if (err) {
                    setLoading(false);
                    setShowErrorPopup(true);
                    setShowErrMessage(err.response.data.error.message);
                }
            })
    }

    // ----------> add or delete or edit skill <------------
    const addSkills = async (ind, type) => {
        setLoading(true);

        let updateMultiSkills = props.multiSkills.map(({ skillName, skillExpMonth, skillExpValue, skillRating, skillNew, changeBtn, disableField, ...rest }) => rest);

        let payLoad = {
            "cId": cId,
            "skillDetails": updateMultiSkills
        }

        let apiUrl = config.baseUrl_candidate + "/Profile/addSkills"
        await axios.post(apiUrl, payLoad, {
            headers: {
                "X-Session": sessionStorage.getItem("sessionid")
            }
        }).then((result) => {
            setLoading(false);
            let res = result.data;
            props.getProfileCanData(cId);
            setShowSuccessPopup(true);
            setShowErrMessage(res.data.message);
            setDisableSaveBtn(false);
            setAddMoreSkillModal(false);
            if (type === "editSkill") {
                let newFormValues_2 = [...props.multiSkills_2];
                newFormValues_2[ind].changeBtn = false;
                props.setMultiSkills_2(newFormValues_2);
            }
        }).catch((err) => {
            if (err) {
                console.log(err);
                setLoading(false);
                props.getProfileCanData(cId);
                setShowErrorPopup(true);
                setShowErrMessage(err.response.data.error.message);
            }
        })
    }

    return (
        <>
            {loading && (
                <div className="loader">
                    <img src={LoadingIcon} alt="loader" />
                </div>
            )}
            {/* -------------> success and error modal <----------- */}
            {showErrorPopup || showSuccessPopup ?
                <BackEndErrorMsg
                    showErrorPopup={showErrorPopup}
                    showSuccessPopup={showSuccessPopup}
                    setShowErrorPopup={setShowErrorPopup}
                    setShowSuccessPopup={setShowSuccessPopup}
                    message={showErrMessage}
                />
                : null}
            <div className="edit_KeySkills_info">
                <div className="profile_photo_item">
                    <h1>Key Skills</h1>
                    {/* <div className="discard_save_btn">
                        {disableBtn &&
                            <label onClick={() => discardChanges()}>Discard</label>
                        }
                        <Button onClick={addSkills} disabled={!disableSaveBtn}>SAVE</Button>
                    </div> */}
                    {props.multiSkills_2?.length > 0 &&
                        <div className="discard_save_btn">
                            <img onClick={addMoreSkills} style={{ cursor: "pointer", width: "14px" }} src={AddmoreIcon} alt="add more" />
                            <h3 onClick={addMoreSkills}>Add Skill</h3>
                        </div>}
                </div>
                {props.multiSkills_2?.length > 0 ?
                    <div className={props.multiSkills.length > 2 ? "content_1" : "content_2"}>
                        {props.multiSkills_2?.map((element, index) => (
                            !element.disable &&
                            <div className="skills_main_block video_resume">
                                <div className="added_skills" style={{ width: "100%" }}>
                                    <div style={{
                                        display: "flex",
                                        alignItems: "flex-start",
                                        justifyContent: "space-between"
                                    }}>
                                        <div className="skill" style={{ width: "35%" }}>
                                            <label style={{ fontSize: "12px" }}>Skill *</label>
                                            <Select
                                                isMulti={false}
                                                value={element.skillId === "" ? { value: '', label: "Select skill" } : { value: element.skillId, label: element.skillName }}
                                                // onChange={(values) => (
                                                //     props.setMultiSkills(prevSkills => prevSkills?.map((skill, i) => {
                                                //         previousSkill = prevSkills[index]
                                                //         onChangedSkill = {
                                                //             skillId: values.value,
                                                //             skillName: values.label,
                                                //             skillLevel: element.skillLevel,
                                                //             skillExpMonth: element.skillExpMonth,
                                                //             skillExpValue: element.skillExpValue,
                                                //             skillExperience: element.skillExperience,
                                                //             skillNew: element.skillNew
                                                //         }
                                                //         if (i === index) {
                                                //             return {
                                                //                 ...skill,
                                                //                 skillId: values.value,
                                                //                 skillName: values.label,
                                                //             };
                                                //         }
                                                //         return skill;
                                                //     })),
                                                //     onChangeSkill(previousSkill, onChangedSkill, index)
                                                //     // matchedIndex = index
                                                // )}
                                                placeholder="Select skill"
                                                // options={skillList.map((item, index) => {
                                                //     return { value: item.skillId, label: item.skillName }
                                                // })}
                                                isDisabled={true}
                                            />
                                            {/* {matchedSkillMsg && <span>This skill already Exist</span>} */}
                                        </div>
                                        <div className="edit_delete">
                                            <div className="edit">
                                                {
                                                    !element.changeBtn ?
                                                        <>
                                                            <img onClick={() => editAddedSkills(index)} src={editIcon} alt="edit" style={{ width: "16px" }} />
                                                            <label onClick={() => editAddedSkills(index)} style={{ marginLeft: "7px", fontSize:"12px" }}>Edit</label>
                                                        </>
                                                        :
                                                        <>
                                                            <img onClick={() => addSkills(index, "editSkill")} src={diskette} alt="edit" style={{ width: "16px" }} />
                                                            <label style={{ marginLeft: "7px", fontSize:"12px" }} onClick={() => addSkills(index, "editSkill")}>Save</label>
                                                        </>
                                                }
                                            </div>
                                            <div className="delete" onClick={() => removeAddedSkills(index)}>
                                                <img src={deleteIcon} alt="delete" />
                                                <label style={{ marginLeft: "3px", fontSize:"12px" }}>Delete</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="skill_levels">
                                        <div className="skill" style={{ width: "35%" }}>
                                            <label style={{ fontSize: "12px" }}>Skill Level *</label>
                                            <Select
                                                isMulti={false}
                                                name="skillLevel"
                                                value={element.skillLevel === "" ? { value: '', label: "Select skillLevel" } : { value: element.skillLevel, label: element.skillLevel }}
                                                onChange={(values) => onChangeSkillLevel(values, "editSkill", index)}
                                                placeholder={"Skills level"}
                                                options={skillLevelList.map((item, index) => {
                                                    return { value: index, label: item }
                                                })}
                                                isDisabled={element.disableField}
                                            />
                                        </div>
                                        <div className="skillexp" style={{ marginLeft: '20px' }}>
                                            <label style={{ fontSize: "12px" }}>Skill Experience *</label>
                                            <div className="skill_experience">
                                                <div className="year">
                                                    <input className="form-control" type="number" style={{
                                                        width: "60px", fontSize: "12px",
                                                        height: "37px"
                                                    }} placeholder="ex. 1"
                                                        min={0}
                                                        name="skillExpMonth" value={element?.skillExpMonth}
                                                        onChange={(e) => handleChange(e, "editSkill", index)} disabled={element.disableField} />
                                                    <label style={{ fontSize: "12px" }}>years</label>
                                                </div>
                                                <div className="month" style={{ marginLeft: "20px" }}>
                                                    <input className="form-control" type="number" style={{
                                                        width: "60px", fontSize: "12px",
                                                        height: "37px"
                                                    }} placeholder="ex. 3"
                                                        min={0}
                                                        name="skillExperience" value={element?.skillExpValue}
                                                        onChange={(e) => handleChange(e, "editSkill", index)} disabled={element.disableField} />
                                                    <label style={{ fontSize: "12px" }}>months</label>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="skill_rating">
                                        <label>Skill Rating</label>
                                        <div>
                                            <Rating
                                                name="skillRating"
                                                value={element?.skillRating}
                                                readOnly
                                            />
                                        </div>
                                    </div> */}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    :
                    <div className="menu_cat_child2_noRecords_found">
                        <img src={noDataFound} />
                        <div className="text">
                            No entries found yet
                            <h6>Click <b onClick={addMoreSkills}>'Add Skills'</b> to add skills and highlight your expertise</h6>
                        </div>
                    </div>
                }
            </div>

            {/* -------------------> add new skill <--------------------------- */}
            <div className="new_skill_modal">
                <Modal
                    show={addMoreSkillModal} onHide={closeModal} className="new_skill_modal">
                    <Modal.Header closeButton>
                        <div className="header">
                            <h4>Add Skill</h4>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="skills_main_block">
                            <div className="added_skills" style={{ width: "100%" }}>
                                <div style={{
                                    display: "flex",
                                    alignItems: "flex-start",
                                    justifyContent: "space-between"
                                }}>
                                    <div className="skill" style={{ width: "100%" }}>
                                        <label>Skill *</label>
                                        <Select
                                            isMulti={false}
                                            placeholder="Select skill"
                                            onChange={(value) => onChangeSkill(value)}
                                            options={skillList.map((item) => {
                                                return { value: item.skillId, label: item.skillName }
                                            })}
                                        />
                                    </div>
                                </div>
                                <div className="skill_levels_new">
                                    <div className="skill" style={{ width: "100%" }}>
                                        <label>Skill Level *</label>
                                        <Select
                                            isMulti={false}
                                            name="skillLevel"
                                            placeholder={"Skills level"}
                                            // onChange={(values) => {
                                            //     const selectedSkillLevel = values.label;
                                            //     props.setMultiSkills(prevSkills => prevSkills?.map((skill, i) => {
                                            //         return {
                                            //             ...skill,
                                            //             skillLevel: selectedSkillLevel,
                                            //         };
                                            //     }))
                                            // }}
                                            onChange={(value) => onChangeSkillLevel(value, "addSkillModal", null)}
                                            options={skillLevelList.map((item, index) => {
                                                return { value: index, label: item }
                                            })}
                                        />
                                    </div>
                                    <div style={{ marginTop: "10px" }}>
                                        <label>Skill Experience *</label>
                                        <div className="skill_experience_new">
                                            <div className="year">
                                                <input className="form-control" type="number" autoComplete="off" style={{
                                                    width: "60px", fontSize: "12px",
                                                    height: "37px"
                                                }} placeholder="ex. 1" name="skillExpMonth" onChange={(e) => handleChange(e, "addSkillModal", null)} min="0" />
                                                <label>years</label>
                                            </div>
                                            <div className="month">
                                                <input className="form-control" type="number" autoComplete="off" style={{
                                                    width: "60px", fontSize: "12px",
                                                    height: "37px"
                                                }} placeholder="ex. 3" name="skillExperience" onChange={(e) => handleChange(e, "addSkillModal", null)} min="0" />
                                                <label>months</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="discard_save_btn">
                                    <Button style={{ marginLeft: "0" }} onClick={() => addSkills(null, "addSkillModal")} disabled={!disableSaveBtn}>SAVE</Button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    );
};

export default EditSkills;