import React, { useEffect, useState } from "react";
import LoadingIcon from "../../../../assets/loader/loader.gif";
import Logo from "../../../../assets/logo/Logo.png"
import arrowBack from "../../../../assets/img/candidate/Icon ionic-ios-arrow-back.svg"
import "./editCandidateProfile.css";
import { Link, useNavigate } from "react-router-dom";
import EditBasicInfoProfile from "../EditBasicInfoProfile/editBasicInfoProfile";
import EditSkills from "../EditSkills/editSkills";
import EditEducation from "../EditEducation/editEducation";
import CandidateNav from "../../Shared/NavbarCandidate/candidateNavbar";
import EditWorkHistory from "../EditWorkHistory/editWorkHistory";
import EditResume from "../EditResume/editResume";
import EditVideo from "../EditVideo/editVideo";
import EditCertification from "../EditCertification/editCertification";
import axios from "axios";
import config from "../../../../Shared/Config/config.json";
import ReactApexChart from "react-apexcharts";
import { getCookie } from "../../../../utiles/Cookies";


function EditCandidateProfile() {
    let navigate = useNavigate();
    let [loading, setLoading] = useState(false);
    let [changeEditProfileContent, setChangeEditProfileContent] = useState("basicInfo");
    let [profileData, setProfileData] = useState();
    let [profileCompletion, setProfileCompletion] = useState();
    let [substractProfileCompletion, setSubstractProfileCompletion] = useState();
    // --------------> BASIC EDIT PROFILE < -----------------
    let [locationSelected, setLocationSelected] = useState("");
    let [preferadLocationSelected, setPreferadLocationSelected] = useState("");
    let [industrySelected, setIndustrySelected] = useState("");
    let [categorySelected, setCategorySelected] = useState("");
    let [firstName, setFirstName] = useState("");
    let [lastName, setLastName] = useState("");
    let [emailId, setEmailId] = useState("");
    let [expYear, setExpYear] = useState("");
    let [expMonth, setExpMonth] = useState("");
    let [aboutMe, setAboutMe] = useState("");
    let [cctc, setCctc] = useState("");
    let [ectc, setEctc] = useState("");
    let [noticePeriod, setNoticePeriod] = useState("");
    let [countryCode, setCountryCode] = useState("in");
    let [mobile, setMobile] = useState("");
    let [phoneNumber, setPhoneNumber] = useState("");
    let [gender, setGender] = useState("");
    let [nickName, setNickName] = useState("");
    let [selectedDate, setSelectedDate] = useState("");
    let [selectedTimezone, setSelectedTimezone] = useState();
    // ----------------> EDIT SKILL <-------------------
    let [multiSkills, setMultiSkills] = useState([{
        skillId: "",
        skillName: "",
        skillLevel: "",
        skillExperience: "",
        skillExpMonth: "",
        skillExpValue: "",
        skillRating: "",
        skillNew: "",
    }]);
    let [multiSkills_2, setMultiSkills_2] = useState([{
        skillId: "",
        skillName: "",
        skillLevel: "",
        skillExperience: "",
        skillExpMonth: "",
        skillExpValue: "",
        skillRating: "",
        skillNew: "",
    }]);
    // ----------------> EDIT WORK HISTORY <-------------------
    let [multiWorkHistory, setMultiWorkHistory] = useState([{
        designation: "",
        companyName: "",
        workedFrom: "",
        workedUntil: "",
        workingNow: false,
        workHistoryId: ""
    }]);
    let [multiWorkHistory_2, setMultiWorkHistory_2] = useState([{
        designation: "",
        companyName: "",
        workedFrom: "",
        workedUntil: "",
        workingNow: false,
        workHistoryId: ""
    }]);
    let fresherVal;
    // ----------------> EDIT EDUCATION <-------------------
    let [multiEducation, setMultiEducation] = useState([{
        collegeId: "",
        qualification: "",
        qualificationId: "",
        specialization: "",
        university: "",
        passedOut: "",
        passedOut: "",
        cgpa_1: "",
        cgpa_2: "",
        marks: "",
        marksType: ""
    }]);
    let [multiEducation_2, setMultiEducation_2] = useState([{
        collegeId: "",
        qualification: "",
        qualificationId: "",
        specialization: "",
        university: "",
        passedOut: "",
        passedOut: "",
        cgpa_1: "",
        cgpa_2: "",
        marks: "",
        marksType: ""
    }]);
    // ----------------> EDIT Certification <-------------------
    let [multiCertification, setMultiCertification] = useState([{
        certificationId: "",
        completionTime: "",
        completionTime_2: "",
        certificationName: "",
        institutionName: "",
        institutionId: "",
        // disable: false
    }]);
    let [multiCertification_2, setMultiCertification_2] = useState([{
        certificationId: "",
        completionTime: "",
        completionTime_2: "",
        certificationName: "",
        institutionName: "",
        institutionId: "",
        // disable: false
    }]);

    let canditionalValue = true;
    let canditionalChildValue = true;

    let cid = getCookie('Cid') || sessionStorage.getItem("Cid");

    let doNotRenderTwiceRfi = false;
    useEffect(() => {
        if (!doNotRenderTwiceRfi) {
            doNotRenderTwiceRfi = true
            getProfileCanData();
        }
    }, [])

    let onChangeEditProfileContent = (type) => {
        switch (type) {
            case "keySkill":
                return setChangeEditProfileContent("keySkill");
            case "experience":
                return setChangeEditProfileContent("experience");
            case "education":
                return setChangeEditProfileContent("education");
            case "certification":
                return setChangeEditProfileContent("certification");
            case "resume":
                return setChangeEditProfileContent("resume");
            case "basicInfo":
                return setChangeEditProfileContent("basicInfo");
            case "video":
                return setChangeEditProfileContent("video");
        }
    }

    // let backToProfile = () => {
    //     // navigate("/candidateprofile")
    //     navigate("/myActivity")
    // }

    let switchCompanies = (id) => {
        if (!id) {
            id = cid;
        } else {
            cid = id
        }
        getProfileCanData();
    }

    // --------- view candidate profile api ---------
    const getProfileCanData = async () => {
        setLoading(true);

        let apiUrl = config.baseUrl_candidate + "/Profile/gp";

        let bodyFormData = new FormData();
        bodyFormData.append('cId', cid);

        await axios({
            method: "post",
            url: apiUrl,
            data: bodyFormData,
            headers: {
                "Content-Type": "multipart/form-data",
                'X-Session': getCookie('sessionid') || sessionStorage.getItem("sessionid")
            }
        }).then((result) => {
            setLoading(false);
            let res = result.data.data;
            setProfileData(res);
            setProfileCompletion(res?.profileCompletion);
            setSubstractProfileCompletion(100 - res?.profileCompletion);
            // ============= ***** Edit basic info ***** ==============
            // -------------> location <------------
            setLocationSelected({
                value: res.currentLocation?.locationId,
                label: res.currentLocation?.city
            });
            let arr = [];
            res.preferredLocation.map((item) => (
                arr.push({
                    "value": item.locationId,
                    "label": item.city
                })
            ))
            setPreferadLocationSelected(arr);
            // ------------> industry & category <-------------
            setIndustrySelected({
                value: res.industry?.industryId,
                label: res.industry?.industryName
            });
            setCategorySelected({
                value: res.category?.categoryId,
                label: res.category?.categoryName
            });
            // -----------> input fields <-------------
            setFirstName(res.firstName);
            setLastName(res.lastName);
            setNickName(res.displayName);
            setEmailId(res.emailId);
            let experience = res.experience;
            let extractYear = Math.floor(experience / 12);
            setExpYear(experience > 1 ? extractYear : res.experience);
            let extractMonth = experience % 12;
            setExpMonth(experience > 1 ? extractMonth : 0);
            setAboutMe(res.aboutMe === null ? "" : res.aboutMe);
            setCctc(res.currentCTC);
            setEctc(res.expectedCTC);
            setNoticePeriod(res?.noticePeriod <= 0 ? 0 : res?.noticePeriod);
            let countrycodeMobile = res.mobile.split(".");
            setCountryCode(JSON.stringify(countrycodeMobile[0]));
            setMobile(JSON.stringify(countrycodeMobile[1]));
            setPhoneNumber(res.mobile);
            setGender(res.gender);
            setSelectedTimezone({
                value: res.timeZone,
                label: res.timeZone
            })
            sessionStorage.setItem("timeZone", res.timeZone);
            setSelectedDate(res.dob == null || res.dob == "" ? "" : new Date(res.dob));
            sessionStorage.setItem("photoIdCandidate", res.photoId);
            sessionStorage.setItem("userName", res.firstName + " " + res.lastName);

            // ============= ***** Edit skills ***** ==============
            let arrSkill = [];
            res?.skills?.map((item) => (
                arrSkill.push({
                    skillId: item.skillId,
                    skillName: item.skillName,
                    skillLevel: item.skillLevel,
                    skillExperience: item.skillExperience,
                    skillExpMonth: JSON.stringify(item.skillExperience >= 1 ? Math.floor(item.skillExperience / 12) : item.skillExperience),
                    skillExpValue: JSON.stringify(item.skillExperience >= 1 ? item.skillExperience % 12 : 0),
                    skillRating: item.skillRating,
                    skillNew: "old",
                    disable: false,
                    disableField: true,
                    changeBtn: false
                })
            ))
            setMultiSkills(arrSkill);
            setMultiSkills_2(arrSkill);

            // ============ ****** Edit Work History ****** =============
            let arrWork = [];
            res?.workHistory?.companyDetails?.map((item) => (
                arrWork.push({
                    designation: item.designation,
                    companyName: item.companyName,
                    workedFrom: item.workedFrom,
                    workedUntil: item.workedUntil,
                    workingNow: item.workingNow,
                    workHistoryId: item.workHistoryId,
                    disableField: true,
                    changeBtn: false,
                    disableChangeBtn: null
                })
            ))
            setMultiWorkHistory(arrWork);
            setMultiWorkHistory_2(arrWork);
            fresherVal = res?.workHistory?.companyDetails.length;

            // ============ ****** Edit Education ****** =============
            let arrEduc = [];
            res?.education?.map((item) => (
                arrEduc.push({
                    collegeId: item.collegeId,
                    educationId: item.educationId,
                    qualification: item.qualification,
                    qualificationId: item.qualificationId,
                    specialization: item.specialization,
                    university: item.university,
                    passedOut: item.passedOut,
                    cgpa_1: item.marks === "" ? "" : item.marks.split("/")[0],
                    cgpa_2: item.marks === "" ? "" : item.marks.split("/")[1],
                    marks: item.marks,
                    marksType: item.marksType,
                    disableField: true,
                })
            ))
            setMultiEducation(arrEduc);
            setMultiEducation_2(arrEduc);

            // ============ ****** Edit Certification ****** =============
            let arrCerti = [];
            res?.certification?.map((item) => (
                arrCerti.push({
                    certificationId: item.certificationId,
                    completionTime: item.completionTime,
                    // completionTime_2: "",
                    certificationName: item.certificationName,
                    institutionName: item.institutionName,
                    institutionId: item.institutionId,
                    // disable: item.disable,
                    disableField: true,
                    changeBtn: false,
                })
            ))
            setMultiCertification(arrCerti);
            setMultiCertification_2(arrCerti);

        }).catch((err) => {
            setLoading(false);
            if (err) {
                // setShowErrorModal(true);
                // setModalMsg(res.message);
            }
        })
    }

    let chartData = {
        series: [profileCompletion, substractProfileCompletion],

        options: {
            chart: { type: "donut" },
            legend: { show: false },
            dataLabels: { enabled: false },
            tooltip: { enabled: false },
            fill: { colors: ["#FF8A00", "#D1D1D1"] },
            states: {
                hover: { filter: { type: "#79CBCA", value: 0.5 } },
                active: { filter: { type: "none", value: 0 } }
            },
            stroke: { width: 0 },
            plotOptions: {
                pie: {
                    expandOnClick: false,
                    donut: {
                        size: "70%",
                        labels: {
                            show: true,
                            name: { show: false },
                            total: {
                                fontSize: "5px !important",
                                show: true,
                                showAlways: true,
                                formatter: function (w) {
                                    return (w.config.series[0] + "%");
                                },
                                fontSize: "10px"
                            }
                        }
                    }
                }
            }
        }
    };

    return (
        <>
            {/* {loading && (
                <div className="loader">
                    <img src={LoadingIcon} alt="loader" />
                </div>
            )} */}
            <div className="main_EditProfile_container">
                < CandidateNav
                    canditionalValue={canditionalValue}
                    switchCompanies={switchCompanies}
                    profileData={profileData}
                    canditionalChildValue={canditionalChildValue} />

                <div className="editCandidateProfile_container">
                    <div className="editCandidateProfile">
                        <div className="Edit_boxes_main">
                            <div className="title">
                                <h2>My Profile</h2>
                                <div className="goBack">
                                    {/* <img style={{ cursor: "pointer" }} src={arrowBack} alt="Back" onClick={() => backToProfile()} /> */}
                                    {/* <h5 onClick={() => backToProfile()}>Back</h5> */}
                                </div>
                            </div>
                            <div className="graph">
                                <div style={{ margin: "5px 0px 0px", marginBottom: "0" }}>
                                    <ReactApexChart
                                        options={chartData?.options}
                                        series={chartData?.series}
                                        type="donut"
                                        width="80px"
                                        height="90px"
                                    />
                                </div>
                                <label>Profile Completion</label>
                            </div>
                        </div>
                        <div className="edit_basic_info_container">
                            <div className="Edit_boxes">
                                <div className={changeEditProfileContent == "basicInfo" ? "acitve_basicInfo" : "Basic_info"}
                                    onClick={() => onChangeEditProfileContent("basicInfo")}>
                                    <label>Personal Info</label>
                                </div>
                                <div className={changeEditProfileContent == "keySkill" ? "acitve_basicInfo" : "Basic_info"}
                                    onClick={() => onChangeEditProfileContent("keySkill")}>
                                    <label>Key Skills</label>
                                </div>
                                <div className={changeEditProfileContent == "experience" ? "acitve_basicInfo" : "Basic_info"}
                                    onClick={() => onChangeEditProfileContent("experience")}>
                                    <label>Work History</label>
                                </div>
                                <div className={changeEditProfileContent == "education" ? "acitve_basicInfo" : "Basic_info"}
                                    onClick={() => onChangeEditProfileContent("education")}>
                                    <label>Education</label>
                                </div>
                                <div className={changeEditProfileContent == "certification" ? "acitve_basicInfo" : "Basic_info"}
                                    onClick={() => onChangeEditProfileContent("certification")}>
                                    <label>Certifications</label>
                                </div>
                                <div className={changeEditProfileContent == "resume" ? "acitve_basicInfo" : "Basic_info"}
                                    onClick={() => onChangeEditProfileContent("resume")}>
                                    <label>Resume</label>
                                </div>
                                <div className={changeEditProfileContent == "video" ? "acitve_basicInfo" : "Basic_info"}
                                    onClick={() => onChangeEditProfileContent("video")}>
                                    <label>Video Resume</label>
                                </div>
                            </div>

                            {/*-------------------> Editable Components <-----------------*/}
                            <div className="editable_components">
                                {
                                    changeEditProfileContent == "basicInfo" ?
                                        // ----------------- BASIC EDIT PROFILE -----------------
                                        <EditBasicInfoProfile profileData={profileData}
                                            getProfileCanData={getProfileCanData}
                                            setLocationSelected={setLocationSelected}
                                            locationSelected={locationSelected}
                                            setPreferadLocationSelected={setPreferadLocationSelected}
                                            preferadLocationSelected={preferadLocationSelected}
                                            setIndustrySelected={setIndustrySelected}
                                            setCategorySelected={setCategorySelected}
                                            categorySelected={categorySelected}
                                            industrySelected={industrySelected}
                                            firstName={firstName}
                                            setFirstName={setFirstName}
                                            lastName={lastName}
                                            setLastName={setLastName}
                                            emailId={emailId}
                                            setEmailId={setEmailId}
                                            setExpYear={setExpYear}
                                            expYear={expYear}
                                            setExpMonth={setExpMonth}
                                            expMonth={expMonth}
                                            setAboutMe={setAboutMe}
                                            aboutMe={aboutMe}
                                            setEctc={setEctc}
                                            setCctc={setCctc}
                                            cctc={cctc}
                                            ectc={ectc}
                                            setNoticePeriod={setNoticePeriod}
                                            noticePeriod={noticePeriod}
                                            setCountryCode={setCountryCode}
                                            countryCode={countryCode}
                                            setMobile={setMobile}
                                            mobile={mobile}
                                            phoneNumber={phoneNumber}
                                            setPhoneNumber={setPhoneNumber}
                                            setGender={setGender}
                                            gender={gender}
                                            nickName={nickName}
                                            setNickName={setNickName}
                                            cid={cid}
                                            setSelectedDate={setSelectedDate}
                                            selectedDate={selectedDate}
                                            setSelectedTimezone={setSelectedTimezone}
                                            selectedTimezone={selectedTimezone}
                                        />
                                        // ----------------- SKILL EDIT ---------------------
                                        : (changeEditProfileContent == "keySkill") ? (
                                            <EditSkills profileData={profileData}
                                                multiSkills={multiSkills}
                                                setMultiSkills={setMultiSkills}
                                                multiSkills_2={multiSkills_2}
                                                setMultiSkills_2={setMultiSkills_2}
                                                cid={cid}
                                                getProfileCanData={getProfileCanData}
                                            />
                                            // ----------------- EXPERIENCE EDIT -----------------
                                        ) : (changeEditProfileContent == "experience") ? (
                                            // ----------------- WORK HISTORY EDIT -----------------
                                            <EditWorkHistory profileData={profileData}
                                                cid={cid}
                                                getProfileCanData={getProfileCanData}
                                                setMultiWorkHistory={setMultiWorkHistory}
                                                multiWorkHistory={multiWorkHistory}
                                                fresherVal={fresherVal}
                                                setMultiWorkHistory_2={setMultiWorkHistory_2}
                                                multiWorkHistory_2={multiWorkHistory_2}
                                            />
                                        ) : (changeEditProfileContent == "education") ? (
                                            // ----------------- EDUCATION EDIT --------------------
                                            <EditEducation profileData={profileData}
                                                cid={cid}
                                                getProfileCanData={getProfileCanData}
                                                setMultiEducation={setMultiEducation}
                                                multiEducation={multiEducation}
                                                setMultiEducation_2={setMultiEducation_2}
                                                multiEducation_2={multiEducation_2}
                                            />
                                        ) : (changeEditProfileContent == "certification") ? (
                                            // ----------------- CERTIFICATION EDIT --------------------
                                            <EditCertification profileData={profileData}
                                                cid={cid}
                                                getProfileCanData={getProfileCanData}
                                                setMultiCertification={setMultiCertification}
                                                multiCertification={multiCertification}
                                                setMultiCertification_2={setMultiCertification_2}
                                                multiCertification_2={multiCertification_2}
                                            />
                                        ) :(changeEditProfileContent == "resume") ? (
                                            // ----------------- RESUME EDIT --------------------
                                            <EditResume profileData={profileData}
                                                cid={cid}
                                                getProfileCanData={getProfileCanData}
                                            />
                                        ) : 
                                        //------------------- VIDEO EDIT -----------------------------
                                        <EditVideo profileData={profileData} 
                                            cid={cid}
                                            getProfileCanData={getProfileCanData}
                                        />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditCandidateProfile;