import React, { useEffect, useState } from "react";
import "./sharedJob.css";
import loaderImage from "../../assets/loader/loader.gif";
import config from "../../Shared/Config/config.json";
import axios from "axios";
import NavBar from "../../Shared/Navbar/NavBar";
import Details from "../FindJob/Details/Details";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

function SharedJob(props) {
    const navigate = useNavigate();
    let [loader, setLoader] = useState(false);
    let [jobDetails, setJobDetails] = useState();
    let [getJobId, setGetJobId] = useState();
    let [showDetailsModal, setShowDetailsModal] = useState(false);
    let jobType = "Not Disclosed"

    let doNotRenderTwice = false;
    useEffect(() => {
        if (!doNotRenderTwice) {
            doNotRenderTwice = true
            getJobDetails();
        }
    }, []);

    let onChangeDetailsPage = (jobId) => {
        setGetJobId(jobId);
        setShowDetailsModal(true);
    }

    let navigateToFindjobPage = () => {
        props.setGetJobId(undefined)
        navigate("/findJob")
    }

    const getJobDetails = () => {
        setLoader(true);

        let json = {
            "json": {
                "request": {
                    "servicetype": "5",
                    "functiontype": "5003",
                    "data": {
                        // "jobId": props.getJobId,
                        "referenceCode": props.getJobId,
                    }
                }
            }
        }

        let apiUrl = config.baseUrl + "/JobzTop/Job?request=" + encodeURIComponent(JSON.stringify(json));

        axios.post(apiUrl).then((result) => {
            setLoader(false);
            let res = result.data.json.response.data;
            setJobDetails(res);
        }).catch((err) => {
            if (err) {
                console.log(err);
            }
        })
    }

    return (
        <>
            {loader && (
                <div className="loader">
                    <img src={loaderImage} alt="loader" />
                </div>
            )}
            {/* ---------------------> job apply modal <----------------- */}
            {showDetailsModal && <Details
                showDetailsModal={showDetailsModal}
                setShowDetailsModal={setShowDetailsModal}
                getJobId={getJobId}
            />}
            <div style={{
                minHeight: "100vh",
                background: "#F6F6F6 0% 0% no-repeat padding-box"
            }}>
                <div>
                    < NavBar getJobId={props.getJobId} />
                </div>
                <div className="container shared_job" data-aos="fade-up" data-aos-delay="300" data-aos-once="true">
                    <div className="row" style={{ display: "flex", justifyContent: "center" }}>
                        <div className="col-lg-12 job_description">
                            <div className="container-fluid">
                                <div className="jobButton">
                                    <h4 style={{ fontSize: "22px", fontWeight: "bold" }}>{jobDetails?.jobTitle}</h4>
                                    <div className="jobBtn" style={{ width: "20%" }}>
                                        <Button onClick={() => onChangeDetailsPage(jobDetails)}>
                                            Apply
                                        </Button>
                                    </div>
                                </div>
                                <p style={{ fontSize: "14px", marginTop: "-10px" }}>{decodeURIComponent(jobDetails?.companyName)}</p>
                            </div>
                            <div className="container-fluid job_details">
                                {/* <div className="row">
                                <div className="col-lg-4">
                                    {jobDetails?.location.map((item, index) => (
                                        <>
                                            <i className="bi bi-geo-alt" style={{ marginRight: "6px" }}></i>
                                            <label>{decodeURIComponent(item.city)}</label>
                                            {jobDetails?.location.length !== index + 1 ?
                                                <>, </> : null
                                            }
                                        </>
                                    ))}
                                </div>
                                <div className="col-lg-4">
                                    <label>
                                        <i className="bi bi-map credit_card" style={{ marginRight: "6px" }}></i>
                                        {decodeURIComponent(jobDetails?.experienceLevel)}
                                    </label>
                                </div>
                                <div className="col-lg-4">
                                    <label>
                                        <i className="bi bi-briefcase" style={{ marginRight: "3px" }}></i>
                                        {decodeURIComponent(jobDetails?.type == undefined ? jobType : jobDetails.type)}
                                    </label>
                                </div>
                            </div> */}
                                <div className="jobList_childBox">
                                    <label>
                                        <i className="bi bi-briefcase" style={{ marginRight: "3px" }}></i>
                                        {jobDetails?.min}-{jobDetails?.max} years
                                    </label>
                                    <span style={{ padding: "0 14px 0 14px" }}>|</span>
                                    <label>
                                        <i className="bi bi-briefcase" style={{ marginRight: "3px" }}></i>
                                        {decodeURIComponent(jobDetails?.type == undefined ? jobType : jobDetails.type)}
                                    </label>
                                    <span style={{ padding: "0 14px 0 14px" }}>|</span>
                                    <label>
                                        <i className="bi bi-geo-alt"></i>
                                        <label>
                                            {jobDetails?.location.map((item, index) => (
                                                <>
                                                    <label>{decodeURIComponent(item.city)}</label>
                                                    {jobDetails?.location.length !== index + 1 ?
                                                        <>, </> : null
                                                    }
                                                </>
                                            ))}
                                        </label>
                                    </label>
                                </div>
                            </div>
                            <hr></hr>
                            {jobDetails?.qualifications?.length !== 0 ?
                                <div className="container-fluid soft_skill" style={{ marginTop: "3%" }}>
                                    <div className="row" style={{
                                        display: "flex",
                                        alignItems: "baseline"
                                    }}>
                                        <div className="col-lg-3 title">
                                            <h6>Qualification</h6>
                                        </div>
                                        <div className="col-lg-9 skill">
                                            {jobDetails?.qualifications?.map((item, index) => (
                                                <button>
                                                    <span key={index}>{item.qualification}</span>
                                                </button>
                                            ))}
                                        </div>
                                    </div>
                                </div> : null
                            }

                            <div className="container-fluid req_skill">
                                <div className="row">
                                    <div className="col-lg-3">
                                        <h6>Required Skills</h6>
                                    </div>
                                    <div className="col-lg-9 skill">
                                        {jobDetails?.keySkill.map((item, index) => (
                                            <>
                                                {
                                                    item?.mandatory &&
                                                    <button>
                                                        <span key={index}>{item?.skillName}</span>
                                                    </button>
                                                }
                                            </>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            <div className="container-fluid good_skill">
                                <div className="row">
                                    <div className="col-lg-3">
                                        <h6>Good to have skills</h6>
                                    </div>
                                    <div className="col-lg-9">
                                        {jobDetails?.keySkill.map((item, index) => (
                                            <>
                                                {
                                                    item?.preferable &&
                                                    <button>
                                                        <span key={index}>{item?.skillName}</span>
                                                    </button>
                                                }
                                            </>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            {/* <div className="container-fluid soft_skill">
                                <div className="row" style={{
                                    display: "flex",
                                    alignItems: "baseline"
                                }}>
                                    <div className="col-lg-3 title">
                                        <h6>Soft Skills</h6>
                                    </div>
                                    <div className="col-lg-9 skill">
                                        {jobDetails?.softSkills?.length == 0 ?
                                            <button>none</button>
                                            :
                                            jobDetails?.softSkills?.map((item, index) => (
                                                <button>
                                                    <span key={index}>{item.skillName}</span>
                                                </button>
                                            ))}
                                    </div>
                                </div>
                            </div> */}
                            {jobDetails?.softSkills?.length !== 0 ?
                                <div className="container-fluid soft_skill">
                                    <div className="row" style={{
                                        display: "flex",
                                        alignItems: "baseline"
                                    }}>
                                        <div className="col-lg-3 title">
                                            <h6>Soft Skills</h6>
                                        </div>
                                        <div className="col-lg-9 skill">
                                            {jobDetails?.softSkills?.map((item, index) => (
                                                <button>
                                                    <span key={index}>{item.skillName}</span>
                                                </button>
                                            ))}
                                        </div>
                                    </div>
                                </div> : null}
                            <div className="container-fluid decription">
                                <div>
                                    <h6>Description</h6>
                                </div>
                                <div>
                                    <p className="font_12">
                                        <span dangerouslySetInnerHTML={{
                                            __html: jobDetails?.jobDescription
                                        }}></span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="navigateTo_findJob">
                            <p onClick={() => navigateToFindjobPage()}>Click here to find more jobs</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SharedJob;