import React, { useEffect, useState } from "react";
import CandidateNav from "../Shared/NavbarCandidate/candidateNavbar";
import feather_bell from '../../../assets/img/candidate/Icon feather-bell.svg';
import awesome_briefcase from '../../../assets/img/candidate/Icon awesome-briefcase (1).svg';
import awesome_briefcase_black from '../../../assets/img/candidate/Icon awesome-briefcase.svg';
import material_location from '../../../assets/img/candidate/Icon material-location-on.svg';
import payment_cash from '../../../assets/img/candidate/Icon payment-cash.svg';
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import config from "../../../Shared/Config/config.json";
import "./myActivity.css";
// import "../CandidateProfile/candidateProfile.css";
import moment from "moment";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from 'react-bootstrap/Modal';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BackEndErrorMsg } from "../Shared/Error/error";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LoadingIcon from "../../../assets/loader/loader.gif";
import noDataFound from "../../../assets/Home-images/nodatafound.png"
import SubmitAssFeedbackModal, { AssessorProfileModal } from "../../../Shared/Modals/Modals";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { getCookie } from "../../../utiles/Cookies";

function MyActivity() {
    const navigate = useNavigate();
    const location = useLocation();
    let currentDate = new Date();
    let canditionalValue = false;
    let [loading, setLoading] = useState(false);
    let [jobListCandidate, setJobListCandidate] = useState();
    let [reschudalededModal, setReSchudaledMoal] = useState(false);
    let [showJobModal, setShowJobModal] = useState(false);
    let [assessorName, setAssessorName] = useState();
    let [selectedDateTime, setSelectedDateTime] = useState();
    let [startDate, setStartDate] = useState("");
    let [showErrMessage, setShowErrMessage] = useState();
    let [showErrorPopup, setShowErrorPopup] = useState(false);
    let [showSuccessPopup, setShowSuccessPopup] = useState(false);
    let [jobView, setJobView] = useState();
    let [timeSlot, setTimeSlot] = useState([]);
    let [startTime, setStartTime] = useState();
    let [reShceduleBtn, setReShceduleBtn] = useState(true);
    let [assessorId, setAssessorId] = useState();
    let [bookingId, setBookingId] = useState();
    let [apiCalled, setApiCalled] = useState(false);
    let cid = getCookie('Cid') || sessionStorage.getItem("Cid");
    let [viewAssessorProfile, setViewAssessorProfile] = useState(false);
    let [showAssFeedBackModal, setShowAssFeedBackModal] = useState(false);
    let [feedbackModalData, setFeedbackModalData] = useState();
    let getMsg = sessionStorage.getItem("msg");

    let doNotRenderTwiceRfi = false;
    useEffect(() => {
        if (!doNotRenderTwiceRfi) {
            doNotRenderTwiceRfi = true
            // candidateJobList();
            myActivityApi();
            if (getMsg == null) {
                setShowSuccessPopup(false);
            }
        }
    }, []);

    // setTimeout(() => {
    //     setShowErrorPopup(false);
    //     setShowSuccessPopup(false);
    // }, 3000);

    let reShcedule = (date) => {
        let temp = date?.scheduleDetails
        let tempDate = moment.utc(temp?.scheduleTime).local().format('DD-MM-YYYY HH:mm');
        let currentTempDate = moment.utc(new Date()).local().format('DD-MM-YYYY HH:mm');;
        if (currentTempDate <= tempDate) {
            getTimeSlot(temp.scheduleTime, temp.assessorId);
            setSelectedDateTime(temp?.scheduleTime);
        }
        setAssessorName(temp.assessorName);
        setReSchudaledMoal(true);
        setAssessorId(temp.assessorId);
        setBookingId(temp.bookingId);
        setStartDate("");
    }

    let reschudalededModalClose = () => {
        setReSchudaledMoal(false);
        setReShceduleBtn(true);
    }

    let closeJobModal = () => {
        setShowJobModal(false);
    }

    let onChangeReshcedule = (date) => {
        setStartDate(date);
        getTimeSlot(date, assessorId);
        if (startDate !== "" && startTime == "") {
            setReShceduleBtn(false);
        }
        // if (startTime.length > 0) {
        //     setShowErrorPopup(true);
        //     setShowErrMessage("Time slot is not available on this date please select different date");
        // }
    }

    let onChangeReshceduleTime = (time) => {
        setStartTime(time.target.value);
        setReShceduleBtn(false);
    }

    let reShchedule = () => {
        reShceduleInterview();
    }

    let takeTest = (jobid) => {
        sessionStorage.setItem("jobId", jobid);
        window.open("/assessment");
    }

    let commonBtnFunction = (item, data) => {
        sessionStorage.setItem("jobTitle", item.jobTitle);
        sessionStorage.setItem("companyName", item.companyName);
        if (data?.buttonLabel == "Take Test") {
            sessionStorage.setItem("jobId", item?.jobId);
            window.open("/assessment");
        } else if (data?.buttonLabel == "Resume Test") {
            sessionStorage.setItem("jobId", item?.jobId);
            sessionStorage.setItem("resumeTest", true);
            sessionStorage.setItem("buttonLabel", data?.buttonLabel);
            window.open("/test");
            // navigate("/test")
        } else if (data?.buttonLabel == "Join Call") {
            passthroughApi(item?.scheduleDetails?.bookingId);
        } else if (data?.buttonLabel == "Apply") {
            getApplyJob(item.jobId);
        } else if (data?.buttonLabel == "Offer") {
            getApplyJob(item.jobId);
        } else if (data?.buttonLabel == "Accept") {
            jobOffer(item.jobId, data?.buttonLabel);
        } else if (data?.buttonLabel == "Reject") {
            jobOffer(item.jobId, data?.buttonLabel);
        } else if (data.buttonLabel == "Submit Feedback" || data.buttonLabel == "View Feedback") {
            setShowAssFeedBackModal(true);
            setFeedbackModalData({
                data: item,
                buttonData: data
            });
        }
    }

    let showAssessorProfile = (data) => {
        let temp = data?.scheduleDetails
        setViewAssessorProfile(true);
        setAssessorId(temp.assessorId);
    }

    let getShortName = (name) => {
        try {
            // Decode the URI component safely
            let decodedName = decodeURIComponent(name);
            // Truncate the decoded name to 200 characters
            let truncatedName = decodedName.slice(0, 200) + "...";
            // Return the truncated name wrapped in a <span> element
            return <span dangerouslySetInnerHTML={{ __html: decodeURIComponent(truncatedName) }}></span>;
        } catch (error) {
            console.error("Error decoding URI component:", error);
            // Return a fallback value or handle the error as needed
            return <span>Error</span>;
        }
    };

    const CustomWidthTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))({
        [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: 5000,
        },
    });

    const switchCompanies = (id) => {
        if (!id) {
            id = cid;
        } else {
            cid = id
        }
        myActivityApi();
    }

    let jobViewModalApi = async (jobId) => {
        setLoading(true);

        let json = {
            "json": {
                "request": {
                    "servicetype": "5",
                    "functiontype": "5003",
                    "data": {
                        "jobId": jobId,
                    }
                }
            }
        }

        let payLoad = config.baseUrl + '/JobzTop/Job?request=' + encodeURIComponent(JSON.stringify(json));

        await axios.post(payLoad).then((result) => {
            setLoading(false);
            let data = result.data.json.response.data
            if (result.data.json.response.statuscode == "0") {
                setShowJobModal(true);
                setJobView(data);
            } else {
                setShowErrorPopup(true);
                setShowErrMessage(result.data.json.response.statusmessage);
            }
            console.log(result.data.json.response.data);
        }).catch((err) => {
            if (err.response.status >= 400 && err.response.status < 500) {
                setShowErrorPopup(true);
                setShowErrMessage(err.response.data.error.message);
            } else {
                navigate("./error");
            }
        })
    }

    // Job list Old api
    const candidateJobList = async () => {
        setLoading(true);

        let json = {
            "json": {
                "request": {
                    "servicetype": "21",
                    "functiontype": "1089",
                    "session": {
                        "sessionid": getCookie('sessionid') || sessionStorage.getItem("sessionid")
                    },
                    "sort": {
                        "sortby": "LastUpdatedTime",
                        "orderby": "DESC"
                    },
                    // "records": {
                    //     "offset": 0,
                    //     "limit": 10
                    // }
                }
            }
        }

        let jobListApiUrl = config.baseUrl + '/JobzTop/Profile?request=' + encodeURIComponent(JSON.stringify(json));

        await axios.post(jobListApiUrl).then((result) => {
            setLoading(false);
            let temp = result.data.json.response.data
            if (result.data.json.response.statuscode == "0") {
                setJobListCandidate(temp.myJobs);
                temp?.myJobs?.map((item) => (
                    setBookingId(item.scheduleDetails.bookingId)
                ))
            } else {
                setShowErrorPopup(true);
                setShowErrMessage(result.data.json.response.statusmessage);
            }
        }).catch((err) => {
            if (err.response.status >= 400 && err.response.status < 500) {
                setShowErrorPopup(true);
                setShowErrMessage(err.response.data.error.message);
            } else {
                navigate("./error");
            }
        })
    }
    // Job list Old api

    //my activity page api
    const myActivityApi = async () => {
        setLoading(true);

        let payLoad = {
            "sortby": "LastUpdatedTime",
            "orderby": "DESC",
            "cId": cid
        }

        let myActivityApiUrl = config.baseUrl_candidate + "/Profile/myActivity"

        await axios.post(myActivityApiUrl, payLoad, {
            headers: {
                "X-Session": getCookie('sessionid') || sessionStorage.getItem("sessionid")
            }
        }).then((res) => {
            setLoading(false);
            let result = res.data.data.myJobs;
            setJobListCandidate(result);
            result?.myJobs?.map((item) => (
                setBookingId(item.scheduleDetails.bookingId)
            ));
            // if (getMsg !== null) {
            //     if (!apiCalled) {
            //         // setShowSuccessPopup(true);
            //         setShowErrMessage(getMsg);
            //         sessionStorage.removeItem("msg")
            //     }
            //     setApiCalled(true);
            // }
        }).catch((err) => {
            setLoading(false);
            if (err.response.status >= 400 && err.response.status < 500) {
                setShowErrorPopup(true);
                setShowErrMessage(err.response.data.error.message);
            } else {
                navigate("./error");
            }
        })
    }
    // /my activity page api

    const passthroughApi = (bookingId) => {
        setLoading(true);
        let json = {
            sessionid: getCookie('sessionid') || sessionStorage.getItem("sessionid"),
            servicetype: 82,
            functiontype: 1,
            data: {
                request: {
                    data: {
                        id: bookingId,
                    },
                    service: {
                        servicetype: "1",
                        functiontype: "1001",
                    },
                },
            },
        };

        let payLoad = encodeURIComponent(JSON.stringify(json));
        let apiUrl = config.baseUrl + "/JobzTop/PassThrough?request=" + payLoad;

        axios.post(apiUrl)
            .then((result) => {
                setLoading(false);
                if (result.data.response.data !== undefined) {
                    let temp = result.data.response.data
                    getConfigVidCall(temp);
                }
                else if (result.data != undefined) {
                    let temp = result.data
                    getConfigVidCall(temp);
                }
            })
            .catch((err) => {
                if (err.response.status >= 400 && err.response.status < 500) {
                    setShowErrorPopup(true);
                    setShowErrMessage(err.response.data.error.message);
                } else {
                    navigate("./error");
                }
            });
    }

    const getConfigVidCall = (temp) => {
        setLoading(true);
        let json = {
            "json": {
                "request": {
                    "servicetype": "21",
                    "functiontype": "1090",
                    "session": {
                        "sessionid": getCookie('sessionid') || sessionStorage.getItem("sessionid")
                    },
                    "data": {
                        "bookingId": temp.id,
                        "vid_sessionid": temp.sessionid,
                        "vid_tokenid": temp.tokenid,
                        "vid_apiKey": temp.apiKey,
                        "cId": cid
                    }
                }
            }
        }

        let payLoad = encodeURIComponent(JSON.stringify(json));
        let apiUrl = config.baseUrl + "/JobzTop/Profile?request=" + payLoad;

        axios.post(apiUrl)
            .then((result) => {
                setLoading(false);
                let temp = result.data.json.response
                if (result.data.json.response.statuscode == "0") {
                    window.open(config.grpzconference + "?token=" + temp.data.token);
                } else {
                    setShowErrorPopup(true);
                    setShowErrMessage(temp.statusmessage);
                }
            })
            .catch((error) => {
                if (error.response.status >= 400 && error.response.status < 500) {
                    setShowErrorPopup(true);
                    setShowErrMessage(error.response.data.error.message);
                } else {
                    navigate("./error");
                }
            });
    }

    const convertToUTC = (localDateTime) => {
        const localDate = moment(localDateTime);
        const utcDate = localDate.utc();
        return utcDate.format('YYYY-MM-DD HH:mm:ss');
    }

    const getTimeSlot = (date, id) => {
        const currentDate = new Date();
        const hours = currentDate.getHours();
        const minutes = currentDate.getMinutes();
        const seconds = currentDate.getSeconds();
        const day_1 = currentDate.getDate() <= 9 ? "0" + currentDate.getDate() : currentDate.getDate();
        const month_1 = currentDate?.getMonth() <= 9 ? "0" + (currentDate?.getMonth() + 1) : (currentDate?.getMonth() + 1);
        const year_1 = currentDate.getFullYear();
        const currentDay = year_1 + "-" + month_1 + "-" + day_1;
        let fromDate;
        let toDate;
        if (moment(date).format("YYYY-MM-DD") == currentDay) {
            fromDate = convertToUTC(moment(date).format("YYYY-MM-DD") + " " + hours + ":" + minutes + ":" + seconds);
            toDate = convertToUTC(moment(date).format("YYYY-MM-DD") + " 23:59:59");
        } else {
            fromDate = convertToUTC(moment(date).format("YYYY-MM-DD") + " 00:00:00");
            toDate = convertToUTC(moment(date).format("YYYY-MM-DD") + " 23:59:59");
        }
        setLoading(true);

        const json = {
            servicetype: 81,
            functiontype: 1,
            sessionid: getCookie('sessionid') || sessionStorage.getItem("sessionid"),
            assessorid: id,
            data: {
                fromDate: fromDate,
                toDate: toDate,
                flag: "available",
            },
        };
        let apiUrl = config.baseUrl + "/JobzTop/PassThrough?request=" + encodeURIComponent(JSON.stringify(json));

        axios.post(apiUrl)
            .then((result) => {
                setLoading(false);
                let temp = result.data.data;
                setTimeSlot(temp?.availability_details);
                // console.log(result.data.data.availability_details.length, "length-2");
                // if (startTime.length > 0) {
                //     setShowErrorPopup(true);
                //     setShowErrMessage("Time slot is not available on this date please select different date");
                // }
            })
            .catch((error) => {
                if (error.response.status >= 400 && error.response.status < 500) {
                    setShowErrorPopup(true);
                    setShowErrMessage(error.response.data.error.message);
                } else {
                    navigate("./error");
                }
            });
    };

    const reShceduleInterview = () => {
        setLoading(true);

        let json = {
            servicetype: 81,
            functiontype: 4,
            sessionid: getCookie('sessionid') || sessionStorage.getItem("sessionid"),
            data: {
                "bookingId": bookingId,
                "startTime": startDate == "" ? moment(selectedDateTime).format("YYYY-MM-DD") + " " +
                    startTime : moment(startDate).format("YYYY-MM-DD") + " " + startTime,
            },
        };
        let apiUrl = config.baseUrl + "/JobzTop/PassThrough?request=" + encodeURIComponent(JSON.stringify(json));

        axios.post(apiUrl)
            .then((result) => {
                setLoading(false);
                let temp = result.data.data
                setTimeSlot(temp?.availability_details);
                setShowSuccessPopup(true);
                setShowErrMessage("Successfully Reshceduled Interview");
                setReSchudaledMoal(false);
                setTimeout(() => {
                    myActivityApi();
                    // candidateJobList();
                }, 2000)
            })
            .catch((error) => {
                if (error.response.status >= 400 && error.response.status < 500) {
                    setShowErrorPopup(true);
                    setShowErrMessage(error.response.data.error.message);
                } else {
                    navigate("./error");
                }
            });
    };

    const getApplyJob = async (jobId) => {
        setLoading(true);

        let apiUrl = config.baseUrl_candidate + "/Profile/jobApply";

        let bodyFormData = new FormData();
        bodyFormData.append('jobId', jobId);
        bodyFormData.append('cId', cid);

        await axios({
            method: "post",
            url: apiUrl,
            data: bodyFormData,
            headers: {
                "Content-Type": "multipart/form-data",
                'X-Session': getCookie('sessionid') || sessionStorage.getItem("sessionid")
            }
        }).then((result) => {
            setLoading(false);
            let res = result.data.data
            setShowSuccessPopup(true);
            setShowErrMessage(res.message);
            myActivityApi();
        }).catch((err) => {
            setLoading(false);
            if (err.response.status >= 400 && err.response.status < 500) {
                setShowErrorPopup(true);
                setShowErrMessage(err.response.data.error.message);
            } else {
                navigate("./error");
            }
        })
    }

    // ------------> Accept or Decline job offer API <---------------

    let jobOffer = async (jobId, state) => {
        setLoading(true);

        let json = {
            "cId": cid,
            "state": state,
            "jobId": jobId
        }

        // let payLoad = encodeURIComponent(JSON.stringify(json));
        let jobOfferApiUrl = config.baseUrl_candidate + "/Profile/aroffer";

        await axios.post(jobOfferApiUrl, json, {
            headers: {
                "X-Session": getCookie('sessionid') || sessionStorage.getItem("sessionid")
            }
        }).then((res) => {
            setLoading(false);
            let result = res.data.data.message;
            setShowSuccessPopup(true);
            setShowErrMessage(result);
            myActivityApi();
        }).catch((err) => {
            setLoading(false);
            if (err.response.status >= 400 && err.response.status < 500) {
                setShowErrorPopup(true);
                setShowErrMessage(err.response.data.error.message);
            } else {
                navigate("./error");
            }
        })
    }

    return (
        <>
            {/* -------------> Error and success modal <----------- */}
            {showErrorPopup || showSuccessPopup ?
                <BackEndErrorMsg
                    showErrorPopup={showErrorPopup}
                    showSuccessPopup={showSuccessPopup}
                    setShowErrorPopup={setShowErrorPopup}
                    setShowSuccessPopup={setShowSuccessPopup}
                    setShowErrMessage={setShowErrMessage}
                    message={showErrMessage}
                />
                : null}
            {/* -------------> Loader <----------- */}
            {loading && (
                <div className="loader">
                    <img src={LoadingIcon} alt="loader" />
                </div>
            )}
            {/* -----------------> Assessor view profile <---------------- */}
            {
                viewAssessorProfile &&
                <AssessorProfileModal
                    setViewAssessorProfile={setViewAssessorProfile}
                    assessorId={assessorId} />
            }
            {/* --------------------> Submit assessor feedback <--------------------- */}
            {showAssFeedBackModal &&
                <SubmitAssFeedbackModal
                    showAssFeedBackModal={showAssFeedBackModal}
                    setShowAssFeedBackModal={setShowAssFeedBackModal}
                    feedbackModalData={feedbackModalData}
                    myActivityApi={myActivityApi}
                />}
            <div className="main_activity_page">
                < CandidateNav
                    switchCompanies={switchCompanies}
                />
                <div className="container-fluid">
                    <div className="row candidate_profile_main_hero">
                        {
                            jobListCandidate?.length !== 0 ?
                                <div className="activity_middle_profile_boxes">
                                    <h3><img src={feather_bell} alt="notification" />Job Application Status</h3>
                                    <div className={jobListCandidate?.length > 2 ? "candJobList" : "candJobList_without_height"}>
                                        {
                                            jobListCandidate?.map((item, index) => (
                                                <div className="activity_middle_profile_box1" key={index}>
                                                    <img src={awesome_briefcase} alt="my activit" />
                                                    <div className="middle_profile" style={{ width: "96%"}}>
                                                        <div className="job_state">
                                                            <div className="job-item-container">
                                                                <div className="state_1">
                                                                    <h6 onClick={() => jobViewModalApi(item?.jobId)}>{item?.jobTitle}</h6>
                                                                    <span>{item?.companyName}</span>
                                                                    {
                                                                        item.scheduled &&
                                                                        <>
                                                                            <span className="scheduled_details">
                                                                                Interview scheduled on
                                                                                <b>{moment.utc(item?.scheduleDetails?.scheduleTime).local().format("DD-MMM-YYYY HH:mm")}</b>
                                                                                {item?.reschedule == true ?
                                                                                    <p style={{
                                                                                        color: "#FE6E27",
                                                                                        fontSize: "12px",
                                                                                        fontWeight: "bold",
                                                                                        marginBottom: "0",
                                                                                        cursor: "pointer"
                                                                                    }} onClick={() => reShcedule(item)}>Reschedule</p>
                                                                                    :
                                                                                    <div className="call_status">
                                                                                        <span>{item?.scheduleDetails?.callStatus}</span>
                                                                                    </div>
                                                                                }
                                                                            </span>
                                                                            {item?.scheduled &&
                                                                                <h4 style={{ display: "flex" }}>Interviewer -
                                                                                    <h4 className="assessor_name" onClick={() => showAssessorProfile(item)}
                                                                                        style={{ color: "#FE6E27" }}>
                                                                                        {item?.scheduleDetails?.assessorName}
                                                                                    </h4>
                                                                                </h4>
                                                                            }
                                                                            {item?.scheduleDetails?.description &&
                                                                                <div className="Inter_description">
                                                                                    <label>Description</label>
                                                                                    {/* <p id={item?.scheduleDetails?.description?.length < 40 ? "desc" : "desc_2"} dangerouslySetInnerHTML={{
                                                                                    __html: decodeURIComponent(item?.scheduleDetails?.description)
                                                                                }}></p> */}

                                                                                    {/* <Tooltip title={<span dangerouslySetInnerHTML={{ __html: decodeURIComponent(item?.scheduleDetails?.description) }}></span>} style={{ fontFamily: "inherit" }}>
                                                                                    <span className="table_data">
                                                                                        {item?.scheduleDetails?.description?.length > 15 ? (<span dangerouslySetInnerHTML={{ __html: getShortName(decodeURIComponent(item?.scheduleDetails?.description)) }}></span>
                                                                                        ) : item?.scheduleDetails}
                                                                                    </span>
                                                                                </Tooltip> */}

                                                                                    <Tooltip title={item?.scheduleDetails?.description?.length > 200 ?
                                                                                        <span dangerouslySetInnerHTML={{ __html: decodeURIComponent(item?.scheduleDetails?.description) }}></span> : null} style={{ fontFamily: "inherit" }} PopoverProps={{
                                                                                            style: { maxWidth: 300 }
                                                                                        }} arrow slotProps={{
                                                                                            popper: {
                                                                                                modifiers: [
                                                                                                    {
                                                                                                        name: 'offset',
                                                                                                        options: {
                                                                                                            offset: [0, -29],
                                                                                                        },
                                                                                                    },
                                                                                                ],
                                                                                            },
                                                                                        }}
                                                                                        sx={{ m: 1 }}
                                                                                    >
                                                                                        <span className="table_data">
                                                                                            {item?.scheduleDetails?.description?.length > 200 ? getShortName(item?.scheduleDetails?.description)
                                                                                                : <span dangerouslySetInnerHTML={{ __html: decodeURIComponent(item?.scheduleDetails?.description) }}></span>}
                                                                                        </span>
                                                                                    </Tooltip>
                                                                                </div>}
                                                                        </>
                                                                        //  : (item.takeTest == true) ?
                                                                        //             <span>This job includes preliminary selection test</span>
                                                                        //             :
                                                                        //             null
                                                                    }
                                                                </div>
                                                                <div className={`state ${item?.state === 'rejected' ? 'done_rej' : ''}`}>
                                                                    <p>{item?.state}</p>
                                                                </div>
                                                            </div>
                                                            <div className="main_progressTracker_1">
                                                                <div className={item.history.length > 9 ? "main_progressTracker" : "main_progressTracker_2"}>
                                                                    <div className={item.history.length > 9 ? "progress_tracker" : "progress_tracker_2"}>
                                                                        {item?.history?.map((track, index) => (
                                                                            <span key={index} className={`done ${track.toState === 'rejected' ? 'done_rej' : ''}`}>
                                                                                {moment.utc(track?.createdTime).local().format("DD-MMM-YYYY")}
                                                                            </span>
                                                                        ))}
                                                                    </div>
                                                                    <div className={item.history.length > 9 ? "comment" : "comment_2"}>
                                                                        {item?.history?.map((track, index) => (
                                                                            <span key={index} className={`toState_track ${track.toState === 'rejected' ? 'done_rej' : ''}`}>{track?.toState}</span>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="cover-bar"></div>
                                                        </div>

                                                        {/* ------------> button <------------- */}
                                                        {/* {
                                                            item?.buttonDetails?.buttonLabel !== undefined && */}
                                                        <div className="myactivity_btn gap-3">
                                                            {/* item?.buttonDetails?.buttonLabel !== "Take Test" || item?.buttonDetails?.buttonLabel !== "Resume Test" ? */}
                                                            {/* <button className="btnJoincall" disabled={!item?.buttonDetails?.buttonEnabled}
                                                            onClick={() => passthroughApi(item?.scheduleDetails?.bookingId)}>
                                                            {item?.buttonDetails?.buttonLabel}
                                                        </button> */}
                                                            {/* :*/}
                                                            {/* <button type="button" onClick={() => commonBtnFunction(item)}
                                                                    className={item?.buttonDetails?.buttonLabel == "Take Test" || item?.buttonDetails?.buttonLabel == "Resume Test"
                                                                        ? "btn btn-dark" : "btnJoincall"} disabled={!item?.buttonDetails?.buttonEnabled}>
                                                                    {item?.buttonDetails?.buttonLabel}
                                                                </button> */}
                                                            {item?.buttonDetails?.map((data, index) => (
                                                                <button type="button" onClick={() => commonBtnFunction(item, data)}
                                                                    className={data?.buttonLabel == "Take Test" || item?.buttonDetails[index]?.buttonLabel == "Resume Test"
                                                                        ? "btn btn-dark" : (data?.buttonLabel == "Reject") ? "rejectBtn" : "btnJoincall"} disabled={!data.buttonEnabled}>
                                                                    {data?.buttonLabel}
                                                                </button>
                                                            ))
                                                            }
                                                        </div>
                                                        {/* } */}
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                                :
                                <div className="noJobFound_container">
                                    <div className="noJobFound">
                                        <img style={{ width: "35%", marginBottom: "14px" }} src={noDataFound} />
                                        <h4 style={{ color: "#dc3545" }}>No Job Found</h4>
                                        <label>Kindly await assignment by the recruiter or proactively apply for positions through the <Link to="/findJob"><b>Find Jobs</b></Link> section</label>
                                    </div>
                                </div>
                        }
                        {/* <div className="col-lg-4 latest_recommend_block" style={{ width: "24%" }}>
                            <div className="latest_interviews" style={{ minHeight: "84vh" }}>
                                <div className="heading">
                                    <h3>Today's Interviews</h3>
                                </div>
                                <div className="main_content">
                                    <div className="d-flex"
                                        style={{
                                            height: "66px",
                                            marginRight: "10px"
                                        }}>
                                        <div className="vr"
                                            style={{
                                                width: "3px",
                                                color: "#3D89D8"
                                            }}
                                        ></div>
                                    </div>
                                    <div className="content">
                                        <label>Senior Developer</label>
                                        <h6>Talentadd</h6>
                                        <div className="date">
                                            <div>
                                                <span>Jun 15 2023 12.30 - 13.30</span>
                                            </div>
                                            <div>
                                                <img src={awesome_briefcase} alt="date picker" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ marginTop: "22px" }}>
                                    <div className="heading">
                                        <h3>Upcoming Interviews</h3>
                                    </div>
                                    <div className="main_content">
                                        <div className="d-flex"
                                            style={{
                                                height: "66px",
                                                marginRight: "10px"
                                            }}>
                                            <div className="vr"
                                                style={{
                                                    width: "3px",
                                                    color: "#3D89D8"
                                                }}
                                            ></div>
                                        </div>
                                        <div className="content">
                                            <label>Senior Developer</label>
                                            <h6>Talentadd</h6>
                                            <div className="date">
                                                <div>
                                                    <span>Jun 15 2023 12.30 - 13.30</span>
                                                </div>
                                                <div>
                                                    <img src={awesome_briefcase} alt="date picker" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>

                {/* -----------------------Re-shcedule modal------------------------- */}
                <div>
                    <Modal
                        className="reshcedule_modal"
                        show={reschudalededModal}
                        onHide={reschudalededModalClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <h4>Change Interview Schedule</h4>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <label style={{ fontSize: "12px" }}>Interviewer</label>
                            <input type="text" className="form-control" disabled value={assessorName} style={{ fontSize: "12px" }} />
                            <div className="date_picker">
                                <div className="date_block">
                                    <label>Choose Date</label>
                                    <DatePicker
                                        showIcon
                                        selected={startDate == "" ? selectedDateTime == undefined ? "" : moment.utc(selectedDateTime).local().toDate() : startDate}
                                        onChange={(date) => onChangeReshcedule(date)}
                                        minDate={new Date()}
                                        maxDate={currentDate.setDate(currentDate.getDate() + 6)}
                                        dateFormat="dd-MMM-yyyy"
                                        placeholderText="Click to select a date"
                                    />
                                </div>
                                <div className="time_slot_blok">
                                    <label>Choose Time Slot</label>
                                    <div className="time_slot">
                                        <select className="form-select" aria-label="Default select example"
                                            onChange={onChangeReshceduleTime}>
                                            {
                                                startDate == "" ?
                                                    selectedDateTime == undefined ?
                                                        <option selected disabled>select time</option>
                                                        :
                                                        <option selected>{moment.utc(selectedDateTime).local().format("HH:mm")}</option>
                                                    :
                                                    <option selected disabled>select time</option>
                                            }
                                            {
                                                timeSlot?.map((item, index) => (
                                                    <option key={index} value={item?.startTime}>{moment.utc(item?.date + " " + item?.startTime).local().format("HH:mm")}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="shcedule_btn">
                                <button disabled={reShceduleBtn} onClick={() => reShchedule()}>Change Schedule</button>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>

                {/* -----------------------view job modal------------------------- */}
                <div className="viewJob_modal">
                    <Modal show={showJobModal}
                        onHide={closeJobModal}
                        className="viewJob_modal">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <h4 style={{ fontSize: "22px", fontWeight: "bold" }}>{jobView?.jobTitle}</h4>
                                <p style={{ fontSize: "18px" }}>{jobView?.companyName}</p>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="container-fluid job_description">
                                <div className="row">
                                    <div className="col-lg-3 location">
                                        <img src={material_location} alt="location" />
                                        {jobView?.location.map((item, index) => (
                                            <>
                                                <label>{decodeURIComponent(item.city)}</label>
                                                {jobView?.location?.length !== index + 1 ?
                                                    <>, </> : null
                                                }
                                            </>
                                        ))}
                                    </div>
                                    <div className="col-lg-3 experience">
                                        <img src={awesome_briefcase_black} alt="breiefcase" />
                                        <label>{jobView?.experienceLevel}</label>
                                    </div>
                                    <div className="col-lg-3 ctc">
                                        <img src={payment_cash} alt="cash" />
                                        {/* {jobView?.minCtc === undefined ? */}
                                        <label>{jobView?.currency} {jobView?.minCtc} - {jobView?.maxCtc}</label>
                                        {/* :
                                            <label>Not Disclosed</label>
                                        } */}
                                    </div>
                                    <div className="col-lg-3 type">
                                        <img src={awesome_briefcase_black} alt="breiefcase" />
                                        <label>{jobView?.type}</label>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid req_skill">
                                <div className="row" style={{
                                    display: "flex",
                                    alignItems: "baseline"
                                }}>
                                    <div className="col-lg-4 title">
                                        <h6>Required Skills</h6>
                                    </div>
                                    <div className="col-lg-8 skill">
                                        {jobView?.keySkill.map((item, index) => (
                                            <>
                                                {
                                                    item?.mandatory == true ?
                                                        <button>
                                                            <span key={index}>{item?.skillName}</span>
                                                        </button>
                                                        : null
                                                }
                                            </>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid good_skill">
                                <div className="row" style={{
                                    display: "flex",
                                    alignItems: "baseline"
                                }}>
                                    <div className="col-lg-4 title">
                                        <h6>Good to have skills</h6>
                                    </div>
                                    <div className="col-lg-8 skill">
                                        {jobView?.keySkill.map((item, index) => (
                                            <>
                                                {jobView?.keySkill?.length == 0 ?
                                                    <button>
                                                        <>none</>
                                                    </button> :
                                                    (
                                                        item?.preferable == true ?
                                                            <button>
                                                                <span key={index}>{item?.skillName}</span>
                                                            </button>
                                                            : null
                                                    )
                                                }
                                            </>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid soft_skill">
                                <div className="row" style={{
                                    display: "flex",
                                    alignItems: "baseline"
                                }}>
                                    <div className="col-lg-4 title">
                                        <h6>Soft Skills</h6>
                                    </div>
                                    <div className="col-lg-8 skill">
                                        {jobView?.softSkills?.length == 0 ?
                                            <button>none</button> : jobView?.softSkills?.map((item, index) => (
                                                <button>
                                                    <span key={index}>{item?.skillName}</span>
                                                </button>
                                            ))}
                                    </div>
                                </div>
                            </div>
                            <div className="decription_main">
                                <div className="title">
                                    <h6>Description</h6>
                                </div>
                                <div className={jobView?.jobDescription?.length > 50 ? "decription" : "decription_2"}>
                                    <p>
                                        <span dangerouslySetInnerHTML={{
                                            __html: jobView?.jobDescription
                                        }}></span>
                                    </p>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            </div>
        </>
    );
}

export default MyActivity;
