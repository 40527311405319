import React, { useEffect, useState } from "react";
import "./assessmentDashboard.css";
import CandidateNav from "../../Shared/NavbarCandidate/candidateNavbar";
import feather_edit from '../../../../assets/img/candidate/assessments/Icon feather-edit-3.svg';
import "bootstrap-icons/font/bootstrap-icons.css";
import Modal from 'react-bootstrap/Modal';
import material_location from '../../../../assets/img/candidate/Icon material-location-on.svg';
import payment_cash from '../../../../assets/img/candidate/Icon payment-cash.svg';
import material_list from '../../../../assets/img/candidate/assessments/Icon material-list.svg';
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import LoadingIcon from "../../../../assets/loader/loader.gif";
import config from "../../../../Shared/Config/config.json";
import awesome_briefcase_black from '../../../../assets/img/candidate/Icon awesome-briefcase.svg';
import onlineTest from '../../../../assets/img/candidate/assessments/online-test.png';
import axios from "axios";
import { BackEndErrorMsg } from "../../Shared/Error/error";


const Assessment_dashboard = () => {
    const navigate = useNavigate();

    let [showJobModal, setShowJobModal] = useState(false);
    let [dashNavTypes, setDashNavTypes] = useState(true);
    let [loading, setLoading] = useState(false);
    let [jobView, setJobView] = useState();
    let [showErrMessage, setShowErrMessage] = useState();
    let [showErrorPopup, setShowErrorPopup] = useState(false);
    let [showSuccessPopup, setShowSuccessPopup] = useState(false);

    let doNotRenderTwiceRfi = false;
    useEffect(() => {
        if (!doNotRenderTwiceRfi) {
            doNotRenderTwiceRfi = true
            jobViewModalApi();
        }
    }, [])

    const closeJobModal = () => {
        setShowJobModal(false);
    }

    let redirectToTest = () => {
        navigate('/test');
    }

    let jobViewModalApi = async () => {
        setLoading(true);

        let json = {
            "json": {
                "request": {
                    "servicetype": "5",
                    "functiontype": "5003",
                    "data": {
                        "jobId": sessionStorage.getItem("jobId"),
                    }
                }
            }
        }

        let payLoad = config.baseUrl + '/JobzTop/Job?request=' + encodeURIComponent(JSON.stringify(json));

        await axios.post(payLoad).then((result) => {
            setLoading(false);
            let data = result.data.json.response.data
            if (result.data.json.response.statuscode == "0") {
                setJobView(data);
            } else {
                setShowErrorPopup(true);
                setShowErrMessage(result.data.json.response.statusmessage);
            }
        }).catch((err) => {
            if (err) {
                setLoading(false);
                setShowErrorPopup(false);
                // navigate("./error");
            }
        })
    }

    return (
        <>
            {/* -------------> Error and success modal <----------- */}
            {showErrorPopup || showSuccessPopup ?
                <BackEndErrorMsg
                    showErrorPopup={showErrorPopup}
                    showSuccessPopup={showSuccessPopup}
                    setShowErrorPopup={setShowErrorPopup}
                    setShowSuccessPopup={setShowSuccessPopup}
                    message={showErrMessage}
                />
                : null}
            {/* -----------------> Loader <---------------- */}
            {loading && (
                <div className="loader">
                    <img src={LoadingIcon} alt="loader" />
                </div>
            )}
            <div className="main_dashboard" style={{
                background: "#F4F5F6",
                minHeight: "100vh"
            }}>
                <div className="main_dashboard_navbar">
                    < CandidateNav dashNavTypes={dashNavTypes} />
                </div>
                <div className="block_content">
                    <div className="content">
                        <div className="headings">
                            <h3>Assessment for {jobView?.jobTitle} position with {jobView?.companyName}</h3>
                            <button onClick={() => setShowJobModal(true)}>VIEW JOB DETAILS</button>
                        </div>

                        <div className="instrunction">
                            <div style={{
                                marginTop: "-1.5%",
                                marginRight: "7%"
                            }}>
                                <img src={onlineTest} alt="screen" />
                            </div>
                            <div>
                                <h6>Please follow the below instructions:</h6>
                                {/* <h6 className="test_time"><i class="bi bi-clock"></i>Test Duration</h6>
                                <p>There is a time limit set for each question</p> */}
                                {/* <h6 className="questions"><img src={material_list} alt="material-list" style={{ marginRight: "4%" }} />Number of Question    15</h6> */}
                                <ul>
                                    <li><img src={feather_edit} alt="test" />Please read each question carefully before providing your response.</li>
                                    <li><img src={feather_edit} alt="test" />Review your responses before clicking the "Submit" button, as you will NOT be able to make changes after submission.</li>
                                    <li><img src={feather_edit} alt="test" />
                                        <div>
                                            <p>In the event of technical issues causing interruptions, you can resume the test from the My Activity page.</p>
                                            <span style={{
                                                color: "#727272",
                                                fontStyle: "italic"
                                            }}>Log in to your account and navigate to "My Activity" page.</span>
                                        </div>
                                    </li>
                                </ul>
                                <div className="wishes">
                                    <h4>WISH YOU GOOD LUCK</h4>
                                    <button onClick={redirectToTest}>START THE TEST</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="viewJob_modal">
                <Modal show={showJobModal}
                    onHide={closeJobModal}
                    className="viewJob_modal">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h4 style={{ fontSize: "22px", fontWeight: "bold" }}>{jobView?.jobTitle}</h4>
                            <p style={{ fontSize: "18px", margin: "0" }}>{jobView?.companyName}</p>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container-fluid job_description">
                            <div className="row">
                                <div className="col-lg-3 location">
                                    <img src={material_location} alt="location" />
                                    <label>{jobView?.location.map((item, index) => (
                                        <span key={index}>{item?.city}</span>
                                    ))}</label>
                                </div>
                                <div className="col-lg-3 experience" style={{ display: "flex" }}>
                                    <img src={awesome_briefcase_black} alt="breiefcase" style={{ width: "19px" }} />
                                    <label>{jobView?.experienceLevel}</label>
                                </div>
                                <div className="col-lg-3 ctc" style={{ display: "flex" }}>
                                    <img src={payment_cash} alt="cash" />
                                    {/* {jobView?.minCtc === undefined ? */}
                                    <label>{jobView?.currency} {jobView?.minCtc} - {jobView?.maxCtc}</label>
                                    {/* :
                                            <label>Not Disclosed</label>
                                        } */}
                                </div>
                                <div className="col-lg-3 type">
                                    <img src={awesome_briefcase_black} alt="breiefcase" />
                                    <label>{jobView?.type}</label>
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid req_skill">
                            <div className="row" style={{
                                display: "flex",
                                alignItems: "baseline"
                            }}>
                                <div className="col-lg-4 title">
                                    <h6>Required Skills</h6>
                                </div>
                                <div className="col-lg-8 skill">
                                    {jobView?.keySkill.map((item, index) => (
                                        <>
                                            {
                                                item?.mandatory.length !== 0 ?
                                                    item?.mandatory &&
                                                    <button>
                                                        <span key={index}>{item?.skillName}</span>
                                                    </button>
                                                    :
                                                    <button>
                                                        <span>none</span>
                                                    </button>
                                            }
                                        </>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid good_skill">
                            <div className="row" style={{
                                display: "flex",
                                alignItems: "baseline"
                            }}>
                                <div className="col-lg-4 title">
                                    <h6>Good to have skills</h6>
                                </div>
                                <div className="col-lg-8 skill">
                                    {jobView?.keySkill.map((item, index) => (
                                        <>
                                            {
                                                item?.preferable.length !== 0 ?
                                                    item?.preferable &&
                                                    <button>
                                                        <span key={index}>{item?.skillName}</span>
                                                    </button>
                                                    :
                                                    <button>
                                                        <span>none</span>
                                                    </button>
                                            }
                                        </>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid soft_skill">
                            <div className="row" style={{
                                display: "flex",
                                alignItems: "baseline"
                            }}>
                                <div className="col-lg-4 title">
                                    <h6>Soft Skills</h6>
                                </div>
                                <div className="col-lg-8 skill">
                                    {jobView?.softSkills?.length == 0 ?
                                        <button>none</button>
                                        :
                                        jobView?.softSkills?.map((item, index) => (
                                            <button>
                                                <span key={index}>{item.skillName}</span>
                                            </button>
                                        ))}
                                </div>
                            </div>
                        </div>
                        <div className="decription_main">
                            <div className="title">
                                <h6>Description</h6>
                            </div>
                            <div className={jobView?.jobDescription?.length > 50 ? "decription" : "decription_2"}>
                                <p>
                                    <span dangerouslySetInnerHTML={{
                                        __html: jobView?.jobDescription
                                    }}></span>
                                </p>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    );
}

export default Assessment_dashboard;