import React from "react";
import "./privacyAndPolicy.css"
import Logo from "../../../../assets/logo/Logo.png"
import config from "../../../../Shared/Config/config.json";

function PrivacyAndPolicy() {

    let getEmail = config.contactUsEmail;

    return (
        <>
            <div className="terms_condition">
                <div className="logo">
                    <img data-aos="zoom-in" data-aos-delay="100" data-aos-once="true" src={Logo} alt="the.shortlistd" style={{ width: "35%" }}></img>
                </div>
                <div className="paraList">
                    <div className="para">
                        <h3>Shortlistd Privacy Policy</h3>
                        <p style={{ color: "#727272" }}>Last Updated: 20 November 2023</p>
                    </div>
                    <div className="list">
                        <div>
                            <span style={{ fontSize: "18px", fontWeight: "bold" }}>Introduction</span>
                            <p>At Shortlistd, we highly appreciate the trust bestowed upon us by our users and customers when granting access to their
                                Personal Data. This Privacy Policy elucidates our commitment to upholding that trust and safeguarding the information
                                shared with us. It outlines the procedures through which we collect, utilize, and disclose both Personal and Non-Personal
                                Data acquired from and about you during your interaction with our online website, application, services and software.</p>
                        </div>
                        <div style={{ marginBottom: "18px" }}>
                            <span style={{ fontSize: "18px", fontWeight: "bold" }}>(App’s) use and transfer of information received from Google APIs to any
                                other app will adhere to <a href="https://developers.google.com/terms/api-services-user-data-policy" target="_blank">Google API Services User Data Policy</a>, including the Limited Use requirements.</span>
                        </div>
                        <div>
                            <span style={{ fontSize: "18px", fontWeight: "bold" }}>Scope of Shortlistd’s Privacy Policy</span>
                            <p>This Privacy Policy covers data that we collect through the Shortlistd application directly from data subjects. Here we
                                outline our practices regarding the collection, use, and disclosure of personal information when you use our platform.
                                By accessing or using Shortlistd, you agree to the terms outlined in this policy.</p>
                        </div>
                        <div>
                            <span style={{ fontSize: "18px", fontWeight: "bold" }}>Data collection and use</span>
                            <p>We collect various types of information to provide and improve our services. This includes:</p>
                        </div>
                        <div >
                            <p><b>Employers/Recruiters</b>: Information about employers and recruiters, including contact details and job postings.</p>
                            <p><b>Assessors</b>: Information about interviewers, including schedules, assessments, and synced Google Calendar data.</p>
                            <p><b>Jobseekers</b>: Information about job seekers, including application status and skills.</p>
                        </div>
                        <div >
                            <span>1. Personal Data we collect from all users:</span>
                            <p><b>Account management data</b> – including login credentials, permissions, and associated account actions (such as when your account
                                is created, when you log in, add information, request a service, and any changes to your account). </p>
                            <p><span style={{ color: "#727272", fontStyle: "italic" }}>How we use it</span>: We collect, analyse, process, and store your account management data.  </p>
                            <p><span>Why we process it</span>: To create and maintain an account at your direction. </p>
                        </div>
                        <div >
                            <p><b>Activity data</b> – areas of the Sites you visit, where you click, scroll, hover over or otherwise interact with the Sites, and
                                when and for how long the activity occurred</p>
                            <p><span style={{ color: "#727272", fontStyle: "italic" }}>How we use it</span>: We collect, analyse, process, and store your account management data.  </p>
                            <p><span style={{ color: "#727272", fontStyle: "italic" }}>Why we process it</span>: To create and maintain an account at your direction. </p>
                        </div>
                        <div >
                            <p><b>Contact information</b> – name, phone number, email and address</p>
                            <p><span style={{ color: "#727272", fontStyle: "italic" }}>How we use it</span>: We collect, process and store your contact information.</p>
                            <span style={{ color: "#727272", fontStyle: "italic" }}>Why we process it</span>:
                            <div>
                                <ul>
                                    <li>To send you marketing and transactional emails, and to send you reminders.</li>
                                    <li>To create an account at your direction.</li>
                                    <li>To conduct or coordinate interviews, where you are using Shortlistd.</li>
                                </ul>
                            </div>
                        </div>
                        <div >
                            <p><b>Scheduling information</b> – your availability and responses to calendar invitations for interviews, including from third-party calendar
                                providers if you choose to link them to your account.</p>
                            <p><span style={{ color: "#727272", fontStyle: "italic" }}>How we use it</span>: We collect, process, and store scheduling information.</p>
                            <p><span style={{ color: "#727272", fontStyle: "italic" }}>Why we process it</span>: To facilitate interviews scheduled through and held on Shortlistd’s interview platform.</p>
                        </div>
                        <div >
                            <span>2. Information we collect about Job Seekers</span>
                            <p><b>Application data</b> – your resume and other materials shared when you apply to a job through Shortlistd, your activity in relation to
                                that application, as well as scheduling of / participation in an interview.</p>
                            <p><span style={{ color: "#727272", fontStyle: "italic" }}>How we use it</span>: We process your application data and, in some cases, store it on behalf of the Employer.</p>
                            <p><span style={{ color: "#727272", fontStyle: "italic" }}>Why we process it</span>: To facilitate your application to a Job Listing. To help Employers find relevant candidates and improve our services.</p>
                        </div>
                        <div >
                            <p><b>Application screening data</b> – your answers to screening questions.</p>
                            <p><span style={{ color: "#727272", fontStyle: "italic" }}>How we use it</span>: We pass your screening data to the relevant Employer, and may listen to and analyze the recording.</p>
                            <p><span style={{ color: "#727272", fontStyle: "italic" }}>Why we process it</span>: To improve our services. As part of your application process to a Job Listing.</p>
                        </div>
                        <div >
                            <p><b>Assessment data</b> – results of Shortlistd Assessments that you complete as part of an application process</p>
                            <p><span style={{ color: "#727272", fontStyle: "italic" }}>How we use it</span>: We collect, analyse, process, profile, and, in applicable markets, store your assessment data.</p>
                            <p><span style={{ color: "#727272", fontStyle: "italic" }}>Why we process it</span>: To help you find more relevant Job Listings. As part of your application process to a Job Listing.</p>
                        </div>
                        <div >
                            <p><b>Disposition data</b> – information about the status of your job application and the decisions taken by the Employer or by you, such as whether
                                you were contacted, interviewed, hired, or if you withdrew your application or rejected an offer</p>
                            <p><span style={{ color: "#727272", fontStyle: "italic" }}>How we use it</span>: We collect, store, process, and analyse this information.</p>
                            <p><span style={{ color: "#727272", fontStyle: "italic" }}>Why we process it</span>: To further understand the effectiveness of our site and its features.</p>
                        </div>
                        <div >
                            <p><b>Profile data</b> – including your resume, photo (where applicable), preferences, when you are online or recently active on Shortlistd,
                                qualifications and assessments information, and any other data included in your Profile.</p>
                            <p><span style={{ color: "#727272", fontStyle: "italic" }}>How we use it</span>: We collect, store, process, and analyse your profile data.</p>
                            <p><span style={{ color: "#727272", fontStyle: "italic" }}>Why we process it</span>: To help you find more relevant Job Listings. To send you invitations to apply to certain jobs.</p>
                        </div>
                        <div >
                            <span>3. Personal Data we collect from Employers/ Recruiters:</span>
                            <p><b>Business information</b> – Employee contact details and physical location of business.</p>
                            <p><span style={{ color: "#727272", fontStyle: "italic" }}>How we use it</span>: We collect, process, store and analyse such data to verify that Employers on Shortlistd are legitimate.</p>
                            <p><span style={{ color: "#727272", fontStyle: "italic" }}>Why we process it</span>: To prevent fraud and spam, and to protect Job Seekers. To enhance our service.</p>
                        </div>
                        <div >
                            <span>4. Personal Data we collect from Assessors:</span>
                            <p><b>Google Calendar Data</b> – Event date, Event Time, Event Description.</p>
                            <p><span style={{ color: "#727272", fontStyle: "italic" }}>How we use it</span>: We incorporate the following information from your Shortlistd account into your Google Calendar: Event date,
                                Event Time, Event Description. This collected data is employed to generate bookings from the Shortlistd calendar to your Google Calendar,
                                ensuring awareness of upcoming sessions.</p>
                        </div>
                        <div >
                            <span style={{ fontSize: "18px", fontWeight: "bold" }}>Security</span>
                            <p>Shortlistd uses reasonable security measures to help protect your personal data. We prioritize the security of your personal information.
                                We implement industry-standard measures to protect against unauthorized access, alteration, disclosure, or destruction of data. </p>
                            <p>Shortlistd intends to make your experience as secure as possible by protecting personal data covered by this policy against loss, misuse,
                                alteration, or unauthorized access. We perform regular vulnerability assessments of our systems and network to help protect your personal
                                data. </p>
                        </div>
                        <div >
                            <span style={{ fontSize: "18px", fontWeight: "bold" }}>Data Sharing with Third Parties</span>
                            <p>We DO NOT share any user data with any third-party tools or AI models.</p>
                            <span style={{ fontSize: "16px", fontWeight: "500" }}>Google Calendar Integration:</span>
                            <div>
                                <ul>
                                    <li>For users who choose to integrate our app with Google Calendar, we may access and process certain data from your Google Calendar account, such as calendars, events, and attendees.</li>
                                    <li>This information is used solely to provide the functionality of the integration and is not shared with any other third parties, including third-party tools or AI models.</li>
                                    <li>We do not share any user data received through Google Integration with any third-party tools or AI models.</li>
                                </ul>
                            </div>
                            <p>We are committed to protecting your privacy and will only share your data as described in this Privacy Policy.</p>
                        </div>
                        <div >
                            <span style={{ fontSize: "18px", fontWeight: "bold" }}>Google User Data</span>
                            <p>Shortlistd integrates with Google Calendar to provide users with seamless interview scheduling. Create interview schedules from Shortlistd
                                to your Google calendar account so you are aware of upcoming interviews.</p>
                            <p>To enable this functionality, we request the following permissions, known as "scopes," regarding Google user data:</p>
                            <p>Calendar Access Scopes: Allow us to create, edit, and delete events on your calendars, as well as view availability and settings.</p>
                            <p>User Info Scopes: Grant access to your Google Account email address, personal information including any personal info you've made publicly
                                available and to download your contacts.</p>
                            <p>These scopes are carefully selected to ensure the smooth functioning of our platform. Users are encouraged to review and understand Google's
                                privacy practices.</p>
                            <p>We will only use this information for the specific reason for which it was provided to us.</p>
                        </div>
                        <div >
                            <span style={{ fontSize: "18px", fontWeight: "bold" }}>Changes to This Privacy Policy</span>
                            <p>We may update this Privacy Policy from time to time. We will notify users of any changes by posting the new policy on this page.</p>
                        </div>
                        <div >
                            <span style={{ fontSize: "18px", fontWeight: "bold" }}>Contact Us</span>
                            <p>If you have any questions or concerns about this Privacy Policy, please contact us at <b className="mail" onClick={() => window.location = 'mailto:' + getEmail}>{getEmail}</b>.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PrivacyAndPolicy;