import React, { useState } from "react";
import "./CandidateInfo.css";
import Logo from "../../../assets/logo/Logo.png"
// import uploadFile from "../../../../assets/img/candidate/upload@2x.png"
import uploadFile from "../../../assets/img/candidate/upload (1).png"
import Select from 'react-select';
import { useNavigate } from "react-router-dom";
import LoadingIcon from "../../../assets/loader/loader.gif";

function CandidateInformatation() {
    const navigate = useNavigate();
    let [candidateInfoType, setCandidateInfoType] = useState("education");
    let [loading, setLoading] = useState(false);
    let displayName = sessionStorage.getItem("userName");

    let onChangeCandidateInfoType = (type) => {
        if (type === "education") {
            navigate("/myActivity")
            setCandidateInfoType("experience");
        } if (type === "experience") {
            setCandidateInfoType("resume");
        } else {
            setCandidateInfoType("experience");
        }
    }

    let previewImage = (event) => {
        var reader = new FileReader();
        reader.onload = function () {
            var preview = document.getElementById('preview');
            preview.src = reader.result;
            preview.style.display = 'block';
        };
        reader.readAsDataURL(event.target.files[0]);
    }

    return (
        <>
            {loading && (
                <div className="loader">
                    <img src={LoadingIcon} alt="loader" />
                </div>
            )}
            <div className="main_information_container">
                <div className="empty_nav_menu">
                    <div className="logo" style={{ display: "block", padding: "0" }}>
                        <img src={Logo} alt="the.shortlistd"></img>
                    </div>
                </div>
                <div className="education_info_container">
                    <div className="main_education_info">
                        <div className="education_info_block">

                            {/* -----------------------------EDUCATION----------------------------- */}
                            {
                                candidateInfoType === "education" ?
                                    <>
                                        <div>
                                            <h2 style={{ fontSize: "18px", margin: "0" }}>Kudos {displayName}...  You have made the right choice!</h2>
                                            <p style={{
                                                fontSize: "16px",
                                                color: "#101010",
                                                margin: "0",
                                                marginBottom: "22px"
                                            }}>Discover your dream job with us</p>
                                            <h1 style={{ fontSize: "16px" }}>Tell us more and we'll help you with the best choices</h1>
                                        </div>
                                        <div className="heading">
                                            <h4 style={{ fontSize: "14px" }}>ADD EDUCATION</h4>
                                        </div>
                                        <div className="qualification">
                                            <label style={{ fontSize: "14px" }}>Highest Qualification</label>
                                            <div className="select_option">
                                                <Select
                                                    isMulti={false}
                                                    // value={selectedSkill}
                                                    // onChange={(value) => onChangeSkill(value)}
                                                    placeholder={"Select your qualification"}
                                                // options={skill.map((item, index) => {
                                                //     return { value: item.skillId, label: item.skillName }
                                                // })}
                                                />
                                            </div>
                                        </div>
                                        <div className="specialization">
                                            <label style={{ fontSize: "14px" }}>Specialization / Major</label>
                                            <div className="select_option">
                                                <Select
                                                    isMulti={false}
                                                    // value={selectedSkill}
                                                    // onChange={(value) => onChangeSkill(value)}
                                                    placeholder={"Select your qualification"}
                                                // options={skill.map((item, index) => {
                                                //     return { value: item.skillId, label: item.skillName }
                                                // })}
                                                />
                                            </div>
                                        </div>
                                        <div className="university">
                                            <label style={{ fontSize: "14px" }}>University / Institute</label>
                                            <div className="select_option">
                                                <Select
                                                    isMulti={false}
                                                    // value={selectedSkill}
                                                    // onChange={(value) => onChangeSkill(value)}
                                                    placeholder={"Select your qualification"}
                                                // options={skill.map((item, index) => {
                                                //     return { value: item.skillId, label: item.skillName }
                                                // })}
                                                />
                                            </div>
                                        </div>
                                        <div className="graduation">
                                            <label style={{ fontSize: "14px" }}>Year of Graduation</label>
                                            <div className="select_option">
                                                <Select
                                                    isMulti={false}
                                                    // value={selectedSkill}
                                                    // onChange={(value) => onChangeSkill(value)}
                                                    placeholder={"Select your qualification"}
                                                // options={skill.map((item, index) => {
                                                //     return { value: item.skillId, label: item.skillName }
                                                // })}
                                                />
                                            </div>
                                        </div>
                                        <div className="education_type">
                                            <label style={{ fontSize: "14px" }}>Education Type</label>
                                            <div className="select_type">
                                                <div className="fullTime">
                                                    <input type="checkbox" />
                                                    <label style={{ marginLeft: "4px" }}>Full Time</label>
                                                </div>
                                                <div className="partTime" style={{ marginLeft: "20px" }}>
                                                    <input type="checkbox" />
                                                    <label style={{ marginLeft: "4px" }}>Part Time</label>
                                                </div>
                                                <div className="distanceLearning" style={{ marginLeft: "20px" }}>
                                                    <input type="checkbox" />
                                                    <label style={{ marginLeft: "4px" }}>Distant Learning</label>
                                                </div>
                                            </div>
                                            <div className="next_btn">
                                                <button type="button" className="btn btn-dark" onClick={() => onChangeCandidateInfoType("experience")}>NEXT - WORK EXPERIENCE</button>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    (candidateInfoType !== "experience") ? (
                                        /* -----------------------------EXPERIENCE----------------------------- */
                                        <>
                                            <div>
                                                <h2 style={{ fontSize: "18px", margin: "0" }}>Kudos {displayName}...  You have made the right choice!</h2>
                                                <p style={{
                                                    fontSize: "16px",
                                                    color: "#101010",
                                                    margin: "0",
                                                    marginBottom: "22px"
                                                }}>Discover your dream job with us</p>
                                                <h1 style={{ fontSize: "16px" }}>You're almost there</h1>
                                            </div>
                                            < div className="heading">
                                                <h4 style={{ fontSize: "14px" }}>ADD WORK EXPERIENCE</h4>
                                            </div>
                                            <div className="qualification">
                                                <label style={{ fontSize: "14px" }}>Designation</label>
                                                <div className="select_option">
                                                    <Select
                                                        isMulti={false}
                                                        // value={selectedSkill}
                                                        // onChange={(value) => onChangeSkill(value)}
                                                        placeholder={"Enter your designation"}
                                                    // options={skill.map((item, index) => {
                                                    //     return { value: item.skillId, label: item.skillName }
                                                    // })}
                                                    />
                                                </div>
                                            </div>
                                            <div className="specialization">
                                                <label style={{ fontSize: "14px" }}>Company</label>
                                                <div className="select_option">
                                                    <Select
                                                        isMulti={false}
                                                        // value={selectedSkill}
                                                        // onChange={(value) => onChangeSkill(value)}
                                                        placeholder={"Enter the name of your company"}
                                                    // options={skill.map((item, index) => {
                                                    //     return { value: item.skillId, label: item.skillName }
                                                    // })}
                                                    />
                                                </div>
                                                <div className="fullTime" style={{ marginTop: "10px" }}>
                                                    <input type="checkbox" />
                                                    <label style={{ marginLeft: "4px" }}>I am currently working here</label>
                                                </div>
                                            </div>
                                            <div className="experienced">
                                                <div className="from_date">
                                                    <label style={{ fontSize: "14px" }}>From</label>
                                                    <div className="select_option">
                                                        <Select
                                                            isMulti={false}
                                                            // value={selectedSkill}
                                                            // onChange={(value) => onChangeSkill(value)}
                                                            placeholder={"From"}
                                                        // options={skill.map((item, index) => {
                                                        //     return { value: item.skillId, label: item.skillName }
                                                        // })}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="till_date">
                                                    <label style={{ fontSize: "14px" }}>Till</label>
                                                    <div className="select_option">
                                                        <Select
                                                            isMulti={false}
                                                            // value={selectedSkill}
                                                            // onChange={(value) => onChangeSkill(value)}
                                                            placeholder={"Present"}
                                                        // options={skill.map((item, index) => {
                                                        //     return { value: item.skillId, label: item.skillName }
                                                        // })}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="graduation">
                                                <label style={{ fontSize: "14px" }}>Notice Period</label>
                                                <div className="select_option">
                                                    <Select
                                                        isMulti={false}
                                                        // value={selectedSkill}
                                                        // onChange={(value) => onChangeSkill(value)}
                                                        placeholder={"Enter Notice Period"}
                                                    // options={skill.map((item, index) => {
                                                    //     return { value: item.skillId, label: item.skillName }
                                                    // })}
                                                    />
                                                </div>
                                            </div>
                                            <div className="education_type">
                                                <div className="next_btn">
                                                    <button type="button" className="btn btn-dark" onClick={() => onChangeCandidateInfoType("resume")}>NEXT - UPLOAD RESUME</button>
                                                </div>
                                            </div>
                                        </>
                                    ) :
                                        (
                                            <>
                                                <div>
                                                    <h2 style={{ fontSize: "18px", margin: "0" }}>Kudos {displayName}...  You have made the right choice!</h2>
                                                    <p style={{
                                                        fontSize: "16px",
                                                        color: "#101010",
                                                        margin: "0",
                                                        marginBottom: "22px"
                                                    }}>Discover your dream job with us</p>
                                                    <h1 style={{ fontSize: "16px" }}>You made it!</h1>
                                                </div>
                                                < div className="heading">
                                                    <h4 style={{ fontSize: "14px" }}>UPLOAD YOUR RESUME</h4>
                                                </div>
                                                <div className="browseYourResume">
                                                    <div className="uploadResume">
                                                        <h6>Browse and upload resume</h6>
                                                        <input type="file" accept="image/*" id="imageInput" onchange={(event) => previewImage(event)} style={{ display: "none" }} />
                                                        <label for="imageInput">
                                                            <img id="preview" src={uploadFile} alt="Preview" style={{ width: "30%", height: "auto", cursor: "pointer" }} />
                                                        </label>
                                                        <p>Supported file formats DOC, DOCX, PDF Maximum file size 6MB</p>
                                                    </div>
                                                </div>
                                                <div className="education_type">
                                                    <div className="next_btn">
                                                        <button type="button" className="btn btn-dark" onClick={() => onChangeCandidateInfoType("education")}>I AM DONE !</button>
                                                    </div>
                                                </div>
                                            </>
                                        )
                            }

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CandidateInformatation;