import React, { useState } from "react";
import "./Footer.css"
import Logo from "../../assets/logo/Logo.png"
import { Button, TextField } from "@mui/material";
import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";
import 'react-phone-input-2/lib/material.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import config from "../Config/config.json";

const Footer = () => {
    let [phone, setPhone] = useState("");

    let handleOnChange = (data) => {
        setPhone(data);
    }


    return (
        <div style={{ background: "#FFFFFF" }}>
            <div className="container footer">
                <div className="row">
                    <div className="col-lg-6 footer_1">
                        <div className="footer_logo" data-aos="fade-right" data-aos-delay="300" data-aos-once="true">
                            <img src={Logo} alt="the.shortlistd"></img>
                        </div>
                        <label style={{ fontSize: "14px" }} data-aos="fade-right" data-aos-delay="400" data-aos-once="true"><b>THE SHORTLISTD</b> is a flagship product of <a href="https://www.infosoftjoin.in/" target="_blank" style={{ textDecoration: "none" }}>
                            <span style={{ color: "#FF6816", cursor: "pointer" }}>Rao's Infosoft Join Pvt. Ltd.</span>
                        </a></label>
                        <div className="adress">
                            <p data-aos="fade-right" data-aos-delay="500" data-aos-once="true" style={{ fontSize: "14px" }}>#49, Murudeshwara Arcade,<br></br>
                                Jayanagar Blk 9,<br></br>
                                Bangalore 560069<br></br></p>
                        </div>
                        <div className="links" style={{ display: "none" }}>
                            <h6>Useful Links</h6>
                            <span>Search Job</span>
                            <span>Search Talent</span>
                            <span>Privacy Policy</span>
                        </div>
                    </div>
                    <div className="col-lg-6 footer_2">
                        <h3>Request a demo</h3>
                        <div style={{ marginTop: "5%", display: "none" }}>
                            {/* <div className="container-fluid input_label">
                                <div className="row main_input">
                                    <div className="col-lg-4">
                                        <h6>Name</h6>
                                    </div>
                                    <div className="col-lg-8 input_group">
                                        <input type="text" />
                                        <label>Enter your full name</label>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid input_label">
                                <div className="row main_input">
                                    <div className="col-lg-4">
                                        <h6>Company</h6>
                                    </div>
                                    <div className="col-lg-8 input_group">
                                        <input type="text" />
                                        <label>Enter your company name</label>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid input_label">
                                <div className="row main_input">
                                    <div className="col-lg-4">
                                        <h6>Email</h6>
                                    </div>
                                    <div className="col-lg-8 input_group">
                                        <input type="text" />
                                        <label>Enter your email id</label>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid input_label">
                                <div className="row main_input">
                                    <div className="col-lg-4">
                                        <h6>Phone</h6>
                                    </div>
                                    <div className="col-lg-8 input_group">
                                        <input type="text" />
                                        <label>Enter your mobile no.</label>
                                        <div className="req_btn">
                                            <button type="submit">REQUEST DEMO</button>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className="container-fluid input_label">
                                <div className="row main_input">
                                    <div className="col-lg-4">
                                        <h6>Name</h6>
                                    </div>
                                    <div className="col-lg-8">
                                        <TextField id="outlined-basic" label="Enter your full name" variant="outlined"
                                            inputProps={{ autoComplete: "off" }} />
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid input_label">
                                <div className="row main_input">
                                    <div className="col-lg-4">
                                        <h6>Company</h6>
                                    </div>
                                    <div className="col-lg-8">
                                        <TextField id="outlined-basic" label="Enter your company name" variant="outlined"
                                            inputProps={{ autoComplete: "off" }} />
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid input_label">
                                <div className="row main_input">
                                    <div className="col-lg-4">
                                        <h6>Email</h6>
                                    </div>
                                    <div className="col-lg-8">
                                        <TextField id="outlined-basic" label="Enter your email id" variant="outlined"
                                            inputProps={{ autoComplete: "off" }} />
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid input_label">
                                <div className="row main_input">
                                    <div className="col-lg-4">
                                        <h6>Phone</h6>
                                    </div>
                                    <div className="col-lg-8 phoneNumberMain">
                                        <div className="phoneCode">
                                            <PhoneInput
                                                country={"in"}
                                                value={phone}
                                                onChange={handleOnChange}
                                                disabled
                                            />
                                        </div>
                                        <TextField id="outlined-basic" label="Enter your mobile no." variant="outlined"
                                            inputProps={{ autoComplete: "off", maxLength: 10 }} />
                                    </div>
                                </div>
                                <div className="req_btn">
                                    <Button type="submit">REQUEST DEMO</Button>
                                </div>
                            </div>
                        </div>
                        <p style={{ fontSize: "14px" }} data-aos="fade-down" data-aos-delay="400" data-aos-once="true">Email us at <b className="mail" onClick={() => window.location = 'mailto:' + config.contactUsEmail}>{config.contactUsEmail}</b></p>
                        <div className="heading_option_3">
                            <a href="/privacy-policy" target="_blank">Privacy Policy</a>
                            <span style={{ margin: "1px 10px 1px 10px" }}>|</span>
                            <a href="/terms-and-conditions" target="_blank">Terms and Conditions</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copy_right" data-aos="fade-top" data-aos-delay="500" data-aos-once="true">
                <h5>Copyright reserved by Rao's Infosoft Join Pvt. Ltd.</h5>
            </div>
        </div >
    )
}

export default Footer;