import React, { useEffect, useState, useRef } from "react";
import "./testQuestions.css"
import { useNavigate } from "react-router-dom";
import CandidateNav from "../../Shared/NavbarCandidate/candidateNavbar";
import { Tab, Tabs } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import LoadingIcon from "../../../../assets/loader/loader.gif";
import axios from "axios";
import config from "../../../../Shared/Config/config.json";
import moment from "moment";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

const TestQuestion = () => {
    const navigate = useNavigate();
    let [testNavTypes, setTestNavTypes] = useState(true);
    let [loading, setLoading] = useState(false);
    let [submitAnswers, setSubmitAnswers] = useState("");
    let [selectedAnswers, setSelectedAnswers] = useState({});
    let [disableSubBtn, setDisableSubBtn] = useState(true);
    let [servingQuentions, setServingQuestions] = useState([]);
    let [selectedTab, setSelectedTab] = useState(0);
    let [answers, setAnswers] = useState([]);
    let [optionId, setOptionId] = useState([]);
    let [checked, setChecked] = useState(false);
    let [question, setQuestion] = useState();
    let [tempQuestion, setTempQuestion] = useState();
    let getResumeTestValue = sessionStorage.getItem("resumeTest");
    let subRun = true;
    let [wordsCount, setWordsCount] = useState(0);
    let [newQuestion, setNewQuestion] = useState([]);
    let [alert, setAlert] = useState(false);
    let [counter, setCounter] = useState({
        minutes: null,
        remainingSeconds: null
    });
    const timerRef = useRef(null);
    const getButtonLabel = sessionStorage.getItem("buttonLabel");
    let [pageReloaded, setPageReloaded] = useState(false);
    let [conditionSelected, setConditionSelected] = useState(true);
    let currentDateTime = moment().format("YYYY-MM-DD HH:mm:ss");

    let doNotRenderTwiceRfi = false;
    useEffect(() => {
        if (!doNotRenderTwiceRfi) {
            doNotRenderTwiceRfi = true
            getServingQuention();

            if (window.performance) {
                if (performance.navigation.type == 1) {
                    setPageReloaded(true);
                } else {
                    setPageReloaded(false);
                }
            }
        }
    }, [])

    let doNotRenderTwice = false;
    useEffect(() => {
        if (!doNotRenderTwice) {
            doNotRenderTwice = true

            // const handleBeforeUnload = (event) => {
            //     // Perform actions before the component unloads
            //     event.preventDefault();
            //     event.returnValue = '';
            //     clearInterval(timerRef.current);
            // };
            // window.addEventListener('beforeunload', handleBeforeUnload);

            // const timer_1 = (counter.minutes > 0 || counter.remainingSeconds > 0) && setInterval(() => {
            //     if (counter.remainingSeconds === 0) {
            //         if (counter.minutes > 0) {
            //             setCounter(prevCounter => ({
            //                 minutes: prevCounter.minutes - 1,
            //                 remainingSeconds: 59
            //             }));
            //         }
            //     } else {
            //         setCounter(prevCounter => ({
            //             ...prevCounter,
            //             remainingSeconds: prevCounter.remainingSeconds - 1
            //         }));
            //     }
            // }, 1000);

            // return () => {
            //     clearInterval(timer_1)
            //     window.removeEventListener('beforeunload', handleBeforeUnload);
            // };

            timerRef.current = setInterval(() => {
                setCounter(prevCounter => {
                    if (prevCounter.remainingSeconds === 0) {
                        if (prevCounter.minutes > 0) {
                            return {
                                minutes: prevCounter.minutes - 1,
                                remainingSeconds: 59
                            };
                        } else {
                            console.log("executeFunTimeLapsed called");
                            executeFunTimeLapsed();
                            clearInterval(timerRef.current);
                            return prevCounter;
                        }
                    } else {
                        console.log(prevCounter);
                        if (prevCounter.minutes === 0 && prevCounter.remainingSeconds <= 30) {
                            checkAlertCondition();
                        }
                        return {
                            ...prevCounter,
                            remainingSeconds: prevCounter.remainingSeconds - 1
                        };
                    }
                });
            }, 1000);

            return () => {
                clearInterval(timerRef.current);
                // window.removeEventListener('beforeunload', handleBeforeUnload);
            };
        }
    }, [counter]);

    const checkAlertCondition = () => {
        setAlert(true);
    };

    let executeFunTimeLapsed = () => {
        if (counter.minutes === 0 && counter.remainingSeconds === 0) {
            let arr = [];
            if (conditionSelected) {
                if (newQuestion?.question_type_id === 1 || newQuestion?.question_type_id === 3) {
                    newQuestion?.answer?.map((item) => {
                        arr.push({
                            question: item.question,
                            option_id: []
                        })
                    });
                }
                postAnswersToQuestions(newQuestion, false, arr);
            } else {
                arr = answers;
                postAnswersToQuestions(newQuestion, false, arr);
            }
        }
    }

    console.log(answers);

    let selectAnswers = (e, ans, opt, rowIndex) => {
        let updatedOptionId;
        console.log(ans);
        setConditionSelected(false);
        if (e.target.checked || e.target.value) {
            setDisableSubBtn(false);
        } else {
            setDisableSubBtn(true);
        }
        // ---------- SINGLE QUESTION TYPE ----------
        if (newQuestion?.question_type_id === 1) {
            setSubmitAnswers({
                questionItm: ans,
                option: opt
            });
            setAnswers([{
                "question": ans.question,
                // "answer": opt.option_name,
                "option_id": [opt.option_id]
            }])
            setOptionId([opt.option_id]);
            // ---------- MULTIPLE QUESTION TYPE ----------
        } else if (newQuestion?.question_type_id === 2) {
            let updatedSubmitAnswers = [...submitAnswers];
            let option_id = opt?.option_id;
            if (e.target.checked) {
                // setChecked(e);
                setDisableSubBtn(false);
                updatedSubmitAnswers[rowIndex] = {
                    ...updatedSubmitAnswers[rowIndex],
                    questionItm: ans,
                    option: opt
                };
                // let question_ans = {
                //     "question": ans.question,
                //     // "answer": opt?.option_name
                //     "option_id": [option_id]
                // };
                setOptionId(prevAnswers => [...prevAnswers, option_id]);
                updatedOptionId = [...optionId, option_id];
                // setAnswers(prevAnswers => [...prevAnswers, question_ans]);
                setTempQuestion(ans.question);
                // // if (opt.option_id) {
                // //     for (let i = 0; i < opt.option_id; i++) {
                // tempOptionId = [...tempOptionId, option_id];
                // console.log(tempOptionId);
                // }
                // }
                // setAnswers([{
                //     "question": ans.question,
                //     // "answer": opt.option_name,
                //     "option_id": (prevAnswers => [...prevAnswers, option_id])
                // }])
            } else {
                //     let updateAnswers = answers.filter(answer => answer.answer !== opt?.option_name);
                let updateOptionId = optionId.filter(optionId => optionId !== opt?.option_id);
                updatedOptionId = updateOptionId;
                //     setAnswers(updateAnswers);
                setOptionId(updateOptionId);
                updatedSubmitAnswers.splice(rowIndex, 1);
                setDisableSubBtn(true);
            }
            setSubmitAnswers(updatedSubmitAnswers);
            if (updatedOptionId.length > 0) {
                setDisableSubBtn(false);
            } else {
                setDisableSubBtn(true);
            }
            // ---------- MATRIX QUESTION TYPE ----------
        } else if (newQuestion?.question_type_id === 3) {
            const updatedSubmitAnswers = [...submitAnswers];
            updatedSubmitAnswers[rowIndex] = {
                questionItm: ans,
                option: opt
            };
            setSubmitAnswers(updatedSubmitAnswers);
            let option_id = opt?.option_id;
            let question_ans = {
                "question": ans,
                // "answer": opt?.option_name
                "option_id": [option_id]
            };
            setOptionId(prevAnswers => [...prevAnswers, option_id]);
            setAnswers(prevAnswers => [...prevAnswers, question_ans]);
            // ---------- DESCRIPTIVE QUESTION TYPE ----------
        } else if (newQuestion?.question_type_id === 4) {
            setAnswers(e.target.value);
            setQuestion(ans.question);
            let word = e.target.value.trim().split(/\s+/);
            let countWords = 0;
            for (let i = 0; i <= word.length - 1; i++) {
                // if (word[i] != '' && !/[^a-zA-Z0-9]/.test(word[i])) {
                countWords++;
                // }
            }
            setWordsCount(countWords);
            if (countWords >= newQuestion?.wordscount) {
                setDisableSubBtn(false);
            } else {
                setDisableSubBtn(true);
            }
        }
    }

    let onChangeTab = (tabIndex) => {
        setSelectedTab(tabIndex);
        document.documentElement.scrollTo({
            top: 0,
            left: document.documentElement.scrollWidth,
            behavior: 'smooth'
        });
        setSubmitAnswers("");
        setDisableSubBtn(true);
        setAnswers("");
        setOptionId([]);
        setWordsCount(0);
        // subRun = true;
    }

    let convertSecondsToMinutesAndSeconds = (totalSeconds) => {
        const minutes = Math.floor(totalSeconds / 60);
        const remainingSeconds = totalSeconds % 60;
        setCounter({
            minutes: minutes,
            remainingSeconds: remainingSeconds
        });
    };

    let postAnsWithEmpty = () => {
        let arr = [];
        newQuestion?.answer?.map((item) => {
            arr.push({
                question: item.question,
                option_id: []
            })
        });
        setAnswers(arr);
    }

    const calculateRemainingTime = (data, currentTime, val) => {
        const getLocalTime = new Date(moment.utc(data.question_start_time).local())
        const startTime = getLocalTime.getTime() + data.maxtime * 1000;
        const currentDate = new Date(currentTime);

        if (val) {
            if (currentDate >= new Date(startTime)) {
                let arr = [];
                if (newQuestion?.question_type_id === 1 || newQuestion?.question_type_id === 3) {
                    newQuestion?.answer?.map((item) => {
                        arr.push({
                            question: item.question,
                            option_id: []
                        })
                    });
                }
                postAnswersToQuestions(data, false, arr);
            } else {
                const elapsedTime = (startTime - currentDate.getTime());
                const seconds = Math.floor(elapsedTime / 1000);

                const remainingSeconds = seconds % 60
                const minutes = Math.floor(seconds / 60);
                setCounter({
                    minutes: minutes,
                    remainingSeconds: remainingSeconds
                });
            }
        } else {
            const minutes = Math.floor(data.maxtime / 60);
            const remainingSeconds = data.maxtime % 60;
            setCounter({
                minutes: minutes,
                remainingSeconds: remainingSeconds
            });
        }
    };

    const getServingQuention = () => {
        setLoading(true);

        let json = {
            "json": {
                "request": {
                    "servicetype": "21",
                    "functiontype": "1091",
                    "session": {
                        "sessionid": sessionStorage.getItem("sessionid")
                    },
                    "data": {
                        "backward": true,
                        "jobId": sessionStorage.getItem("jobId"),
                        "takeOrResumeTest": getResumeTestValue !== null ? JSON.parse(getResumeTestValue) : undefined,
                        "apiversion": 1
                    }
                }
            }
        }

        let payLoad = encodeURIComponent(JSON.stringify(json));
        let apiUrl = config.baseUrl + "/JobzTop/Profile?request=" + payLoad;

        axios.post(apiUrl)
            .then((result) => {
                setLoading(false);
                let temp = result.data.data.data;
                setServingQuestions(temp);
                console.log(temp);
                sessionStorage.removeItem("resumeTest");
                // if (subRun) {
                //     subRun = true;
                let checkAllSubmitted = temp.filter((item) => item.submitted == false);
                if (checkAllSubmitted.length == 0) {
                    navigate("/submitted");
                } else {
                    for (let i = 0; i < temp.length; i++) {
                        if (!temp[i].submitted) {
                            setSelectedTab(i);
                            console.log(temp[i].maxtime);
                            startTime(temp[i].question_id, true);
                            break;
                        }
                    }
                }
                // }
            })
            .catch((error) => {
                if (error) {
                    setLoading(false);
                    navigate("./error");
                }
            });
    }

    const startTime = (questionID, val) => {
        console.log(val);
        setLoading(true);

        let json = {
            "servicetype": 80,
            "functiontype": 17,
            "sessionid": sessionStorage.getItem("sessionid"),
            "data": {
                "context": sessionStorage.getItem("jobId"),
                "start": true,
                "question_id": questionID
            }
        }

        let payLoad = encodeURIComponent(JSON.stringify(json));
        let apiUrl = config.baseUrl + "/JobzTop/PassThrough?request=" + payLoad;

        axios.post(apiUrl)
            .then((result) => {
                setLoading(false);
                let temp = result.data.data;
                setNewQuestion(temp);
                if (getButtonLabel == "Resume Test" || !pageReloaded) {
                    // const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
                    // fetch("https://worldtimeapi.org/api/timezone/" + tz)
                    //     .then(response => response.json())
                    //     .then(data => (
                    //         calculateRemainingTime(temp, data.datetime, true)
                    //     ))
                    calculateRemainingTime(temp, currentDateTime, true)
                } else {
                    calculateRemainingTime(temp, null, false);
                }
            }).catch((err) => {
                if (err) {
                    setLoading(false);
                }
            })
    }

    const postAnswersToQuestions = (questionDetail, val, arr) => {
        if (timerRef.current) {
            clearInterval(timerRef.current);
            timerRef.current = null;
        }

        let remainingTime = counter.minutes * 60 + counter.remainingSeconds;

        setDisableSubBtn(false);
        setLoading(true);

        let json = {}
        if (newQuestion?.question_type_id === 4) {
            json = {
                "sessionid": sessionStorage.getItem("sessionid"),
                "servicetype": 80,
                "functiontype": 8,
                "data": {
                    "context": sessionStorage.getItem("jobId"),
                    "answers": [
                        {
                            "question_bank_id": questionDetail.question_bank_id,
                            "question_id": questionDetail.question_id,
                            // "answer": {
                            // "answers": [{
                            //     "question": val ? question : newQuestion.question,
                            //     "answer": val ? encodeURIComponent(answers) : ""
                            // }] condition for empty array answer

                            "answers": [{
                                "question": question,
                                "answer": wordsCount >= newQuestion?.wordscount ? encodeURIComponent(answers) : ""
                            }]

                            // }
                        }],
                    "apiversion": 1,
                    "time_taken": val === false ? newQuestion.maxtime : remainingTime
                }
            }
        } else if (newQuestion?.question_type_id === 2) {
            json = {
                "sessionid": sessionStorage.getItem("sessionid"),
                "servicetype": 80,
                "functiontype": 8,
                "data": {
                    "context": sessionStorage.getItem("jobId"),
                    "answers": [
                        {
                            "question_bank_id": questionDetail.question_bank_id,
                            "question_id": questionDetail.question_id,
                            // "answer": {
                            //     "option_id": optionId,
                            // "answers": [{
                            //     "question": val ? tempQuestion : newQuestion.question,
                            //     "option_id": val ? optionId : []
                            // }]condition for empty array answer

                            "answers": [{
                                "question": newQuestion.question,
                                "option_id": optionId
                            }]

                            // }
                            // "answers": [
                            //     {
                            //         "question": "How does the Chocolate Ice Cream taste?",
                            //         "option_id": optionId
                            //     }
                            // ]
                        }
                    ],
                    "apiversion": 1,
                    "time_taken": val === false ? newQuestion.maxtime : remainingTime
                }
            }
        } else {
            json = {
                "sessionid": sessionStorage.getItem("sessionid"),
                "servicetype": 80,
                "functiontype": 8,
                "data": {
                    "context": sessionStorage.getItem("jobId"),
                    "answers": [
                        {
                            "question_bank_id": questionDetail.question_bank_id,
                            "question_id": questionDetail.question_id,
                            // "answer": {
                            //     "option_id": optionId,
                            "answers": val ? answers : arr //condition for empty array answer

                            // "answers": answers

                            // }
                            // "answers": [
                            //     {
                            //         "question": "How does the Chocolate Ice Cream taste?",
                            //         "option_id": optionId
                            //     }
                            // ]
                        }
                    ],
                    "apiversion": 1,
                    "time_taken": val === false ? newQuestion.maxtime : remainingTime
                }
            }
        }

        let payLoad = encodeURIComponent(JSON.stringify(json));
        let apiUrl = config.baseUrl + "/JobzTop/PassThrough?request=" + payLoad;

        axios.post(apiUrl)
            .then((result) => {
                let temp = result.data.data
                setLoading(false);
                getServingQuention();
                setSubmitAnswers("");
                setDisableSubBtn(true);
                setAnswers("");
                setOptionId([]);
                setChecked(false);
                setWordsCount(0);
                setAlert(false);
                setConditionSelected(true);
                window.scrollTo({ left: 0, behavior: 'smooth' });
                sessionStorage.removeItem("buttonLabel");
                // isTimeLapsedExecutedRef.current = false;
                // startTime(temp[0]?.question_id, false);

                if (servingQuentions.length - 1 == selectedTab) {
                    subRun = true;
                } else {
                    let selectedTabIndex = selectedTab + 1;
                    // for (let i = selectedTab + 1; i < servingQuentions.length; i++) {
                    if (servingQuentions[selectedTabIndex].submitted) {
                        subRun = true;
                        // break;
                    } else {
                        setSelectedTab(selectedTab + 1);
                        subRun = false;
                        // break;
                    }
                    // }
                }
            })
            .catch((error) => {
                if (error) {
                    setLoading(false);
                    // navigate("./error");
                }
            });
    }

    return (
        <>
            {loading && (
                <div className="loader">
                    <img src={LoadingIcon} alt="loader" />
                </div>
            )}

            <div className="main_activity_page">
                <CandidateNav testNavTypes={testNavTypes} />
                <div className="test_container_main">
                    <div className="test_container">
                        {
                            alert && (
                                <Stack>
                                    <Alert variant="filled" severity="info" style={{ opacity: 0.7 }}>
                                        Automatically Submit in : {counter.remainingSeconds}
                                    </Alert>
                                </Stack>
                            )
                        }
                        <div className="button_tabs">
                            <div className={window.outerWidth >= "992" ? "tabs" : "tabs_2"}>
                                <div className={servingQuentions?.length > 19 ? "tabs_container" : "tabs_containerSmall"} >
                                    {servingQuentions?.map((item, index) => (
                                        <div>
                                            {
                                                item.submitted == true ?
                                                    <div>
                                                        <button className="disabledBtn" onClick={() => onChangeTab(index)}
                                                            disabled key={index}>{index + 1}</button>
                                                    </div>
                                                    :
                                                    <div style={{ width: "60px" }}>
                                                        <button className={index === selectedTab ? 'active_tab' : 'inactive_tab disabledBtn'} onClick={() => onChangeTab(index)}
                                                            disabled key={index}>{index + 1}</button>
                                                    </div>
                                            }
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className={counter.remainingSeconds <= 30 && counter.minutes == 0 ? "counter_2" : "counter"}>
                                <span style={{ paddingRight: "5px" }}>
                                    {counter.minutes < 10 ? `0${counter.minutes === null ? 0 : counter.minutes}` : counter.minutes === null ? 0 : counter.minutes}
                                    :
                                    {counter.remainingSeconds < 10 ?
                                        `0${counter.remainingSeconds === null ? 0 : counter.remainingSeconds}` : counter.remainingSeconds === null ? 0 : counter.remainingSeconds}</span>
                            </div>
                        </div>
                        <div className="container-fluid tabs_content">
                            {
                                <div className="row">
                                    {/* ---------> Question <---------- */}
                                    <div className="col-lg-6 question_box">
                                        <h6
                                            onCopy={(e) => e.preventDefault()}
                                            onCut={(e) => e.preventDefault()}
                                            onPaste={(e) => e.preventDefault()}
                                        >Question {selectedTab + 1}</h6>
                                        <p dangerouslySetInnerHTML={{
                                            __html: decodeURIComponent(newQuestion?.question)
                                        }} style={{ overflow: "hidden" }}
                                            onCopy={(e) => e.preventDefault()}
                                            onCut={(e) => e.preventDefault()}
                                            onPaste={(e) => e.preventDefault()}
                                        ></p>
                                        <button className="submit_btn"
                                            onClick={() => postAnswersToQuestions(newQuestion, true)} disabled={disableSubBtn}>
                                            SUBMIT
                                        </button>
                                    </div>

                                    <div className="col-lg-6">
                                        {/* ---------> Options <---------- */}
                                        {newQuestion?.question_type_id === 1 ? (
                                            <div className="option_box_head">
                                                <h6>SELECT YOUR OPTIONS</h6>
                                                <div className="option_box">
                                                    {newQuestion?.options?.map((itm, index) => (
                                                        <div className="options">
                                                            <input type="radio" className="form-check-input" name="single-choice"
                                                                onChange={(e) => selectAnswers(e, newQuestion, itm, index)} value={itm}
                                                                checked={submitAnswers?.option?.option_id === itm.option_id} />
                                                            <label>{itm.option_name}</label>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        ) : (newQuestion?.question_type_id === 2) ?
                                            <div className="option_box_head">
                                                <h6>SELECT YOUR OPTIONS</h6>
                                                <div className="option_box">
                                                    {newQuestion?.options?.map((itm, index) => (
                                                        <div className="options" key={index}>
                                                            <input type="checkbox" name="multiple-choice"
                                                                onChange={(e) => selectAnswers(e, newQuestion, itm, index)} value={itm}
                                                                // checked={submitAnswers[index]?.option?.option_id === itm.option_id}
                                                                checked={optionId.includes(itm.option_id)}
                                                            />
                                                            <label>{itm?.option_name}</label>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            : (newQuestion?.question_type_id === 3) ?
                                                <div className="option_box_head">
                                                    <h6>SELECT YOUR OPTIONS</h6>
                                                    <div className="option_box">
                                                        <div className="matrix_options_main">
                                                            <table>
                                                                <div style={{ padding: "3%" }}>
                                                                    <tr>
                                                                        <td style={{ width: "150px" }}></td>
                                                                        {newQuestion?.options.map((itm, index) => (
                                                                            <td className="optionName">{itm?.option_name}</td>
                                                                        ))}
                                                                    </tr>
                                                                </div>

                                                                {newQuestion?.answer?.map((matrix, rowIndex) => (
                                                                    <div style={{ marginBottom: "24px" }}>
                                                                        <tr className="matrix_options" key={rowIndex}>
                                                                            <td style={{ width: "150px" }}>{matrix.question}</td>
                                                                            {newQuestion?.options.map((itm, colIndex) => (
                                                                                <td className="optionName" key={colIndex}>
                                                                                    <input type="radio" name={`matrix-1-${rowIndex}`}
                                                                                        onChange={(e) => selectAnswers(e, matrix.question, itm, rowIndex)} value={itm}
                                                                                        checked={submitAnswers?.[rowIndex]?.option?.option_id === itm.option_id} />
                                                                                </td>))}
                                                                        </tr>
                                                                    </div>
                                                                ))}
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                                : (newQuestion?.question_type_id === 4) ?
                                                    <div className="option_box_head">
                                                        <h6 style={{ margin: "0" }}>TYPE YOUR ANSWER</h6>
                                                        <span className={disableSubBtn ? "min_words_label" : "min_words_label_2"}>Minimum {newQuestion?.wordscount} words required</span>
                                                        <div className="option_box">
                                                            <textarea placeholder="Type here..." className="form-control"
                                                                onChange={(e) => selectAnswers(e, newQuestion)} value={answers} style={{ marginTop: "12px" }}
                                                            // onCopy={(e) => e.preventDefault()}
                                                            // onCut={(e) => e.preventDefault()}
                                                            // onPaste={(e) => e.preventDefault()}
                                                            ></textarea>
                                                            <div className="min_words_count">Words count - {wordsCount}</div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="option_box_head">
                                                        <h6>SELECT YOUR OPTIONS</h6>
                                                        <div className="option_box">
                                                            {newQuestion?.options?.map((itm, index) => (
                                                                <div className="options">
                                                                    <input type="checkbox"
                                                                        onChange={(e) => selectAnswers(e, newQuestion, itm, index)} value={itm} />
                                                                    <label>{itm?.option_name}</label>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                        }
                                    </div>
                                </div>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TestQuestion;