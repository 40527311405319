import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CandidateNav from "../../../Shared/NavbarCandidate/candidateNavbar";
import feather_edit from '../../../../../assets/img/candidate/assessments/Icon feather-edit-3.svg';
import './testSubmitted.css'
import onlineTest from '../../../../../assets/img/candidate/assessments/online-test.png';


function TestSubmittedSuccessfully() {
    const navigate = useNavigate();
    let [dashNavTypes, setDashNavTypes] = useState(true);
    let getCompanyName = sessionStorage.getItem("companyName");
    let getJobTitle = sessionStorage.getItem("jobTitle");

    let backToActivity = () => {
        navigate("/myActivity");
    }

    return (
        <>
            <div className="main_dashboard" style={{
                minHeight: "100vh",
                background: "#F4F5F6"
            }}>
                <div className="main_dashboard_navbar">
                    <CandidateNav dashNavTypes={dashNavTypes} />
                </div>
                <div className="block_content">
                    <div className="content">
                        <div className="headings">
                            <h3>Assessment for {getJobTitle} position with {getCompanyName}</h3>
                        </div>

                        <div className="instrunction">
                            <div style={{
                                marginTop: "-1.5%",
                                marginRight: "4%"
                            }}>
                                <img src={onlineTest} alt="screen" />
                            </div>
                            <div className="wishes">
                                <h4>THANK YOU</h4>
                                <div style={{ margin: "6px 0px 8px 0px" }}>
                                    <p>Your test response have been successfully submitted.</p>
                                    <p>You can track the status and progress of your test by visiting the My Activity page.</p>
                                </div>
                                <button onClick={() => backToActivity()}>Back to My Activity</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TestSubmittedSuccessfully;