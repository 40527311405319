import React, { useEffect, useRef, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import "./Modals.css"
import awesomeCheck from "../../assets/Home-images/Icon awesome-check-circle.svg"
import filled_crossImage from "../../assets/Home-images/filled_crossImage.jpg"
import linkedInLogo from "../../assets/img/candidate/Icon ionic-logo-linkedin.svg"
import userAvatar from "../../assets/icons/user-icon.png"
import config from "../../Shared/Config/config.json";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import awesome_briefcase_black from '../../assets/img/candidate/Icon awesome-briefcase.svg';
import payment_cash from '../../assets/img/candidate/Icon payment-cash.svg';
import material_location from '../../assets/img/candidate/Icon material-location-on.svg';
import LoadingIcon from "../../assets/loader/loader.gif";
import ReactStars from "react-rating-stars-component";
import { BackEndErrorMsg } from "../../Layout/Candidate/Shared/Error/error";

// --------------=>>>>>>>>>>>> Error modal <<<<<<<<<<<<<<=-----------------
export function ErrorModal(props) {

    const closeModal = (type) => {
        if (type == "error") {
            props.setShowErrorModal(false);
        }
    }

    return (
        <>
            <div className="errorModal_container">
                <Modal show={props.showErrorModal}
                    onHide={() => closeModal("error")}
                    className="errorModal">
                    <Modal.Header closeButton style={{
                        border: "none",
                        borderLeft: "5px solid red",
                        borderRadius: "4px"
                    }}>
                        <div className="erro_modal">
                            <div className="sideBorder"></div>
                            <div className="content">
                                {/* <img src={awesomeCheck} slt="Right Mark" /> */}
                                <img src={filled_crossImage} slt="cross Mark" />
                                <p>{props.modalMsg}</p>
                            </div>
                        </div>
                    </Modal.Header>
                </Modal>
            </div>
        </>
    )
};

// --------------=>>>>>>>>> Success modal <<<<<<<<<<<=-----------------
export function SuccessModal(props) {
    return (
        <>

        </>
    )
}

// -------------------=>>>>>>>>>> Assessor profile modal <<<<<<<<<<=-----------------------
export function AssessorProfileModal(props) {
    const navigate = useNavigate();
    let [viewAssProfileModal, setViewAssProfileModal] = useState(false);
    let [assessorProfile, setAssessorProfile] = useState();
    let getSessionId = sessionStorage.getItem("sessionid");

    let doNotRenderTwiceRfi = false;
    useEffect(() => {
        if (!doNotRenderTwiceRfi) {
            doNotRenderTwiceRfi = true
            showAssessorModal();
        }
    }, [])

    let AssessorProfileModalClose = () => {
        setViewAssProfileModal(false);
        props.setViewAssessorProfile(false);
    }

    let getLinkedinProfile = (data) => {
        window.open(data)
    }

    // ----------------> view assessor profile from candidate api <-------------------
    let showAssessorModal = async () => {
        setViewAssProfileModal(true);

        let payLoad = {
            "assessorId": props.assessorId
        }

        let apiUrl = config.baseUrl_candidate + "/Profile/getAssessorData";

        await axios.post(apiUrl, payLoad, {
            headers: {
                'X-Session': getSessionId,
                "Content-Type": "application/json"
            }
        })
            .then((result) => {
                let temp = result.data.data;
                setAssessorProfile(temp);
            })
            .catch((error) => {
                if (error) {
                    // navigate("./error");
                }
            });
    }

    return (
        <>
            <div className="assessorProfile_modal">
                <Modal
                    className="assessorProfile_modal"
                    show={viewAssProfileModal}
                    onHide={AssessorProfileModalClose}
                    dialogClassName="assessorProfileModal my-0"
                >
                    <Modal.Header closeButton>
                        Interviewer Profile
                    </Modal.Header>
                    <Modal.Body>
                        <div className="content">
                            <div className="header">
                                {
                                    assessorProfile?.photoId <= 0 ?
                                        <img src={userAvatar} alt="user" style={{ width: "24%" }} />
                                        : <img className="photo" src={config.showPhoto + assessorProfile?.photoId + "&type="} alt="profile"
                                            onError={(e) => e.target.src = userAvatar} />
                                }
                                <h2>{assessorProfile?.name}</h2>
                                <h4>{assessorProfile?.companyName}</h4>
                                <h4>{assessorProfile?.designation}</h4>
                                {/* <h4>{assessorProfile?.skills}</h4> */}
                                {
                                    assessorProfile?.linkedinUrl ?
                                        <div onClick={() => getLinkedinProfile(assessorProfile.linkedinUrl)}>
                                            {assessorProfile.linkedinUrl.includes("linkedin.com") ?
                                                <img src={linkedInLogo} alt="linkedin" style={{ marginRight: "4px" }} />
                                                :
                                                <i className="bi bi-link" style={{ marginRight: "4px", fontSize: "20px" }}></i>
                                            }
                                            View Profiles
                                        </div>
                                        : null
                                }
                            </div>
                            {
                                assessorProfile?.aboutMe ?
                                    <div className="aboutAssessor">
                                        <div disabled dangerouslySetInnerHTML={{
                                            __html: decodeURIComponent(assessorProfile?.aboutMe)
                                        }} ></div>
                                    </div> : null
                            }
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    )
}

// --------------------=>>>>>>>>>>>> Show and copy Link of job <<<<<<<<<<<<<<<=----------------------

export function JobLinkModal(props) {
    let [showMsg, setShowMsg] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setShowMsg(false);
        }, 4000)
    }, [showMsg])

    const closeLinkModal = () => {
        props.setShowLinkModal(false);
    }

    const url = config.candidateUrl + "?id=" + props.getLinkId
    const textInputRef = useRef(url);
    const textareaRef = useRef(null);

    const copyToClipboard = () => {
        // textInputRef.current.select();
        textareaRef.current.value = url;
        textareaRef.current.select();
        document.execCommand('copy');
        setShowMsg(true);
    };

    return (
        <>
            <div className="jobLink_modal">
                <Modal
                    show={props.showLinkModal} onHide={closeLinkModal} className="jobLink_modal">
                    <Modal.Header closeButton>
                        <div className="header">
                            <h4>Share Job Link</h4>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        {showMsg &&
                            <div className="copiedMsg">
                                <label>Link Copied!</label>
                            </div>
                        }
                        <div className="content">
                            <input type="text" ref={textInputRef} value={url} disabled />
                            <button onClick={() => copyToClipboard()}>COPY LINK</button>
                            {/* Hidden textarea for copying text */}
                            <textarea ref={textareaRef} style={{ position: 'absolute', left: '-9999px' }} readOnly />
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    );
};


// --------------------=>>>>>>>>>>>> Show job-details modal <<<<<<<<<<<<<<<=----------------------
export function JobDetails(props) {
    let [showJobModal, setShowJobModal] = useState(false);
    let [jobView, setJobView] = useState();
    let [loading, setLoading] = useState(false);
    let [showErrorPopup, setShowErrorPopup] = useState(false);
    let [showErrMessage, setShowErrMessage] = useState();

    let doNotRenderTwiceRfi = false;
    useEffect(() => {
        if (!doNotRenderTwiceRfi) {
            doNotRenderTwiceRfi = true
            jobViewModal();
        }
    }, []);

    let jobViewModal = async () => {
        setLoading(true);

        let json = {
            "json": {
                "request": {
                    "servicetype": "5",
                    "functiontype": "5003",
                    "data": {
                        "jobId": props.jobId?.jobId,
                    }
                }
            }
        }

        let payLoad = config.baseUrl + '/JobzTop/Job?request=' + encodeURIComponent(JSON.stringify(json));

        await axios.post(payLoad).then((result) => {
            setLoading(false);
            let data = result.data.json.response.data
            if (result.data.json.response.statuscode == "0") {
                setShowJobModal(true);
                setJobView(data);
            } else {
                setShowErrorPopup(true);
                setShowErrMessage(result.data.json.response.statusmessage);
            }
        }).catch((err) => {
            if (err) {
                setLoading(false);
                setShowErrorPopup(false);
                // navigate("./error");
            }
        })
    }
    return (
        <>
            {loading && (
                <div className="loader">
                    <img src={LoadingIcon} alt="loader" />
                </div>
            )}
            <div className="viewJob_modal">
                <Modal show={props.showJobModal}
                    onHide={props.jobViewModal}
                    className="viewJob_modal">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h4 style={{ fontSize: "22px", fontWeight: "bold" }}>{jobView?.jobTitle}</h4>
                            <p style={{ fontSize: "18px" }}>{jobView?.companyName}</p>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container-fluid job_description">
                            <div className="row">
                                <div className="col-lg-3 location">
                                    <img src={material_location} alt="location" />
                                    {jobView?.location.map((item, index) => (
                                        <>
                                            <label>{decodeURIComponent(item.city)}</label>
                                            {jobView?.location?.length !== index + 1 ?
                                                <>, </> : null
                                            }
                                        </>
                                    ))}
                                </div>
                                <div className="col-lg-3 experience">
                                    <img src={awesome_briefcase_black} alt="breiefcase" />
                                    <label>{jobView?.experienceLevel}</label>
                                </div>
                                <div className="col-lg-3 ctc">
                                    <img src={payment_cash} alt="cash" />
                                    {/* {jobView?.minCtc === undefined ? */}
                                    <label>{jobView?.currency} {jobView?.minCtc} - {jobView?.maxCtc}</label>
                                    {/* :
                                <label>Not Disclosed</label>
                            } */}
                                </div>
                                <div className="col-lg-3 type">
                                    <img src={awesome_briefcase_black} alt="breiefcase" />
                                    <label>{jobView?.type}</label>
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid req_skill">
                            <div className="row" style={{
                                display: "flex",
                                alignItems: "baseline"
                            }}>
                                <div className="col-lg-4 title">
                                    <h6>Required Skills</h6>
                                </div>
                                <div className="col-lg-8 skill">
                                    {jobView?.keySkill.map((item, index) => (
                                        <>
                                            {
                                                item?.mandatory == true ?
                                                    <button>
                                                        <span key={index}>{item?.skillName}</span>
                                                    </button>
                                                    : null
                                            }
                                        </>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid good_skill">
                            <div className="row" style={{
                                display: "flex",
                                alignItems: "baseline"
                            }}>
                                <div className="col-lg-4 title">
                                    <h6>Good to have skills</h6>
                                </div>
                                <div className="col-lg-8 skill">
                                    {jobView?.keySkill.map((item, index) => (
                                        <>
                                            {jobView?.keySkill?.length == 0 ?
                                                <button>
                                                    <>none</>
                                                </button> :
                                                (
                                                    item?.preferable == true ?
                                                        <button>
                                                            <span key={index}>{item?.skillName}</span>
                                                        </button>
                                                        : null
                                                )
                                            }
                                        </>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid soft_skill">
                            <div className="row" style={{
                                display: "flex",
                                alignItems: "baseline"
                            }}>
                                <div className="col-lg-4 title">
                                    <h6>Soft Skills</h6>
                                </div>
                                <div className="col-lg-8 skill">
                                    {jobView?.softSkills?.length == 0 ?
                                        <button>none</button> : jobView?.softSkills?.map((item, index) => (
                                            <button>
                                                <span key={index}>{item?.skillName}</span>
                                            </button>
                                        ))}
                                </div>
                            </div>
                        </div>
                        <div className="decription_main">
                            <div className="title">
                                <h6>Description</h6>
                            </div>
                            <div className={jobView?.jobDescription?.length > 50 ? "decription" : "decription_2"}>
                                <p>
                                    <span dangerouslySetInnerHTML={{
                                        __html: jobView?.jobDescription
                                    }}></span>
                                </p>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    )
}

// --------------------=>>>>>>>>>>>> Show submit assessor feedback modal <<<<<<<<<<<<<<<=----------------------
function SubmitAssFeedbackModal(props) {
    let navigate = useNavigate();
    let cid = sessionStorage.getItem("Cid");
    let [rating, setRating] = useState({
        assessorRating: null,
        callRating: null
    })

    let [comment, setComment] = useState('');
    const [isVisible, setIsVisible] = useState(false);
    let [loading, setLoading] = useState(false);
    let [showErrMessage, setShowErrMessage] = useState();
    let [showErrorPopup, setShowErrorPopup] = useState(false);
    let [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [showStars, setShowStars] = useState(false);
    const [isSubmitEnabled, setSubmitEnabled] = useState(false);
    let getSessionId = sessionStorage.getItem("sessionid");

    let doNotRenderTwiceRfi = false;
    useEffect(() => {
        if (!doNotRenderTwiceRfi) {
            doNotRenderTwiceRfi = true
            if (props.feedbackModalData?.buttonData?.buttonLabel === "View Feedback") {
                viewFeedbackApi();
                setTimeout(() => {
                    setLoading(false);
                    setShowStars(true);
                    setIsVisible(true);
                }, 1000); // 3 seconds delay
            } else if (props.feedbackModalData?.buttonData?.buttonLabel === "Submit Feedback") {
                setShowStars(true);
                setIsVisible(true);
                setLoading(false);
            }
        }
    }, []);

    const closeFeedbackModal = () => {
        props.setShowAssFeedBackModal(false);
        setComment("");
        setRating({
            assessorRating: 0,
            callRating: 0
        })
        setSubmitEnabled(false);
    }

    const handleRating = (ratings, type) => {
        setRating(prevRating => ({
            ...prevRating,
            assessorRating: type === "assRating" ? ratings : prevRating.assessorRating,
            callRating: type === "callRating" ? ratings : prevRating.callRating
        }))
        if (ratings) {
            setSubmitEnabled(true);
        } else {
            setSubmitEnabled(false);
        }
    }

    const handleComment = (e) => {
        setComment(e.target.value)
    }


    let submitFeedbackApi = async () => {
        setLoading(true);
        let payLoad = {
            "cId": cid,
            "bookingId": props.feedbackModalData?.data?.scheduleDetails?.bookingId,
            "rating": rating.assessorRating,
            "comment": comment,
            "callRating": rating.callRating
        }

        let apiUrl = config.baseUrl_candidate + "/Profile/rassessor";

        await axios.post(apiUrl, payLoad, {
            headers: {
                'X-Session': getSessionId,
                "Content-Type": "application/json"
            }
        }).then((res) => {
            setLoading(false);
            let temp = res.data;
            console.log(temp);
            setShowErrMessage(temp.data.message);
            setShowSuccessPopup(true);
            props.myActivityApi();
            props.setShowAssFeedBackModal(false);
        })
            .catch((err) => {
                if (err) {
                    setLoading(false);
                    if (err.response.status >= 400 && err.response.status < 500) {
                        setShowErrorPopup(true);
                        setShowErrMessage(err.response.data.error.message);
                    } else {
                        navigate("./error");
                    }
                }
            })
    }

    let viewFeedbackApi = async () => {
        setLoading(true);
        let payLoad = {
            "cId": cid,
            "bookingId": props.feedbackModalData?.data?.scheduleDetails?.bookingId,
        }

        let apiUrl = config.baseUrl_candidate + "/Profile/varating";

        await axios.post(apiUrl, payLoad, {
            headers: {
                'X-Session': getSessionId,
                "Content-Type": "application/json"
            }
        }).then((res) => {
            let result = res.data.data;
            // setLoading(false);
            setComment(decodeURIComponent(result.comment || ''));
            setRating({
                assessorRating: result?.rating,
                callRating: result?.callRating
            })
        })
            .catch((err) => {
                if (err) {
                    setLoading(false);
                    if (err && err.response && err.response.status) {
                        if (err.response.status >= 400 && err.response.status < 500) {
                            setShowSuccessPopup(false);
                            setShowErrorPopup(true);
                            setShowErrMessage(err.response.data.error.message);
                        } else {
                            navigate("./error");
                        }
                    } else {
                        // Handle other types of errors, or log them for debugging
                        console.error("An unexpected error occurred:", err);
                    }
                }
            })
    }

    return (
        <>
            {/* -------------> Loader <----------- */}
            {loading && (
                <div className="loader">
                    <img src={LoadingIcon} alt="loader" />
                </div>
            )}
            {/* -------------> Error and success modal <----------- */}
            {showErrorPopup || showSuccessPopup ?
                <BackEndErrorMsg
                    showErrorPopup={showErrorPopup}
                    showSuccessPopup={showSuccessPopup}
                    setShowErrorPopup={setShowErrorPopup}
                    setShowSuccessPopup={setShowSuccessPopup}
                    setShowErrMessage={setShowErrMessage}
                    message={showErrMessage}
                />
                : null}
            {isVisible && (
                <>
                    <div className="feedback_container">
                        <Modal show={props.showAssFeedBackModal}
                            onHide={() => closeFeedbackModal()}
                            className="feedback_container">
                            <Modal.Header closeButton>
                                {props.feedbackModalData?.buttonData?.buttonLabel === "View Feedback" ? (
                                    <>Feedback provided to the Interviewer</>
                                ) : (
                                    <>Submit your feedback to Interviewer</>
                                )}
                            </Modal.Header>
                            <Modal.Body>
                                <div className="feedback_body">
                                    <div className="rating_container">
                                        <div className="labels">
                                            {showStars && (
                                                <div className="d-flex col-12">
                                                    <div className="col-6 font12 pt-1">
                                                        <label>ASSESSOR RATING</label>
                                                    </div>
                                                    <div className="col-6">
                                                        <ReactStars
                                                            count={5}
                                                            onChange={(e) => handleRating(e, "assRating")}
                                                            size={24}
                                                            isHalf={true}
                                                            value={rating?.assessorRating}
                                                            // value={4.5}
                                                            emptyIcon={<i className="far fa-star"></i>}
                                                            halfIcon={<i className="fa fa-star-half-alt"></i>}
                                                            fullIcon={<i className="fa fa-star"></i>}
                                                            activeColor="#ffd700"
                                                            edit={props.feedbackModalData?.buttonData?.buttonLabel === "View Feedback" ? false : true}
                                                        />
                                                    </div>
                                                </div>)}
                                            {showStars && (
                                                <div className="d-flex col-12">
                                                    <div className="col-6 font12 pt-1">
                                                        <label>CALL RATING</label>
                                                    </div>
                                                    <div className="col-6">
                                                        <ReactStars
                                                            count={5}
                                                            onChange={(e) => handleRating(e, "callRating")}
                                                            size={24}
                                                            isHalf={true}
                                                            value={rating?.callRating}
                                                            emptyIcon={<i className="far fa-star"></i>}
                                                            halfIcon={<i className="fa fa-star-half-alt"></i>}
                                                            fullIcon={<i className="fa fa-star"></i>}
                                                            activeColor="#ffd700"
                                                            edit={props.feedbackModalData?.buttonData?.buttonLabel === "View Feedback" ? false : true}
                                                        />
                                                    </div>
                                                </div>)}
                                        </div>
                                    </div>
                                    <div className="comment">
                                        <label>Comment</label>
                                        <textarea value={comment} onChange={(e) => handleComment(e)}
                                            disabled={props.feedbackModalData?.buttonData?.buttonLabel === "View Feedback" ? true : false}>
                                        </textarea>
                                    </div>
                                </div>
                            </Modal.Body>
                            {props.feedbackModalData?.buttonData?.buttonLabel !== "View Feedback" && <Modal.Footer>
                                <div className="feetback_footer">
                                    <button onClick={() => closeFeedbackModal()}>CANCEL</button>
                                    <button onClick={() => submitFeedbackApi()} disabled={!isSubmitEnabled}>SUBMIT</button>
                                </div>
                            </Modal.Footer>}
                        </Modal>
                    </div>
                </>
            )


            }

        </>
    );
}

export default SubmitAssFeedbackModal;