import React, { useState, useEffect } from "react";
import uploadFile from "../../../../assets/img/candidate/upload (1).png"
import axios from "axios";
import config from '../../../../Shared/Config/config.json'
import { BackEndErrorMsg } from "../../Shared/Error/error";
import { CircularProgress } from "@mui/material";

function EditVideo(props) {

    let cId = props.cid;
    let videoId = props?.profileData?.videoId;
    let [showErrMessage, setShowErrMessage] = useState();
    let [showErrorPopup, setShowErrorPopup] = useState(false);
    let [showSuccessPopup, setShowSuccessPopup] = useState(false);
    let [resumeLoader, setResumeLoader] = useState(false);

    const videoUrl = `${config.uploadVideo}${videoId}`;

    let previewVideo = (event) => {
        var reader = new FileReader();
        reader.onload = function () {
            var preview = document.getElementById('preview');
            preview.src = reader.result;
            preview.style.display = 'block';
        };
        reader.readAsDataURL(event.target.files[0]);
        updateVideo(event);
    }

    const updateVideo = async (e) => {
        setResumeLoader(true);

        let apiUrl = config.baseUrl_candidate + '/Profile/srl'

        let bodyFormData = new FormData();
        bodyFormData.append('file', e.target.files[0]);
        bodyFormData.append('type', 'video');
        bodyFormData.append('cId', cId);

            await axios({
                method: "post",
                url: apiUrl,
                data: bodyFormData,
                headers: {
                    "Content-Type": "multipart/form-data",
                    'X-Session': sessionStorage.getItem("sessionid")
                }
            }).then((result) => {
                setResumeLoader(false);
                let res = result.data.data
                setShowSuccessPopup(true);
                setShowErrMessage(res.message);
                props.getProfileCanData(cId);
            }).catch((err) => {
                if (err) {
                    setResumeLoader(false);
                    setShowErrorPopup(true);
                    setShowErrMessage(err.response.data.error.message)
                }
            })
    }

    return (
        <>
            {showErrorPopup || showSuccessPopup ?
                <BackEndErrorMsg
                    showErrorPopup={showErrorPopup}
                    showSuccessPopup={showSuccessPopup}
                    setShowErrorPopup={setShowErrorPopup}
                    setShowSuccessPopup={setShowSuccessPopup}
                    message={showErrMessage}
                />
                : null}
            <div className="edit_KeySkills_info video_resume">
                <div className="profile_photo_item">
                    <h1>Video Resume</h1>
                </div>
                <div className="skills_main_block">
                    <div className="added_skills" style={{ width: "100%" }}>
                        <div>
                            <div className="skill">
                                <input type="file" id="imageInput" onChange={(event) => previewVideo(event)} style={{ display: "none" }} accept="video/mp4,video/x-matroska"/>
                                <label style={{
                                    display: "flex",
                                    justifyContent: "center"
                                }}>
                                    <span><img src={uploadFile} alt="Preview" style={{ width: "80px", height: "auto" }} /></span>
                                </label>
                                <p style={{ textAlign: "center", fontWeight: "400", fontSize: "12px", marginBottom: "2px" }}>Supported file formats .MP4 and .MKV </p>
                                <p style={{ textAlign: "center", fontWeight: "400", fontSize: "12px", marginBottom: "6px" }}>Maximum file size 100MB</p>
                            </div>
                            <div className="education_type">

                                {videoId > 0 ?
                                    (<label className="next_btn" for="imageInput" style={{ width: "124px", marginLeft: "0px" }}>
                                        <span id="preview">UPDATE VIDEO</span>
                                    </label>) :
                                    (<label className="next_btn" for="imageInput" style={{ width: "124px", marginLeft: "0px" }}>
                                        <span id="preview">UPLOAD VIDEO</span>
                                    </label>)}

                                {
                                    resumeLoader &&
                                    <div style={{ marginLeft: "10px" }}>
                                        <CircularProgress />
                                    </div>
                                }
                            </div>
                            <div style={{
                                marginTop: "20px", display: "flex",
                                justifyContent: "center"
                            }}>
                                {
                                videoId > 0 && <video controls width="400" height="210" key={videoId}>
                                    <source src={videoUrl} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                                }
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default EditVideo;
