import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import config from "../../../Shared/Config/config.json";
import CandidateNav from "../Shared/NavbarCandidate/candidateNavbar";
import "./candidateProfile.css";
import userAvatar from '../../../assets/img/candidate/user avatar.svg';
import feather_bell from '../../../assets/img/candidate/Icon feather-bell.svg';
import awesome_briefcase from '../../../assets/img/candidate/Icon awesome-briefcase.svg';
import awesome_briefcase_orange from '../../../assets/img/candidate/Icon awesome-briefcase (1).svg';
import settings_Icon from '../../../assets/Home-images/Icon feather-settings.svg'
import location_icon_black from '../../../assets/img/candidate/Icon material-location-on.svg'
import awesome_phone from '../../../assets/img/candidate/Icon awesome-phone-alt.svg';
import feather_mail from '../../../assets/img/candidate/Icon feather-mail.svg';
import nodata_found from '../../../assets/img/candidate/nodatafound.png';
import { JobDetails } from "../../../Shared/Modals/Modals";
import axios from "axios";
import { Link, useNavigate } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import LoadingIcon from "../../../assets/loader/loader.gif";
import ReactApexChart from "react-apexcharts";
import { Tooltip, colors } from "@mui/material";
import { BackEndErrorMsg } from "../Shared/Error/error";
import moment from "moment";
import { getCookie } from "../../../utiles/Cookies";
import jobInterview from "../../../../src/assets/img/candidate/job-interview_8416991.png"
import { Button, TextField } from "@mui/material";

function CandidateProfile(props) {
    const navigate = useNavigate();
    let canditionalValue = true;
    let [recommendedJobsList, setRecommendedJobsList] = useState([]);
    let [scheduleList, setScheduleList] = useState([]);
    let [upComingScheduleList, setUpComingScheduleList] = useState([]);
    let [profileData, setProfileData] = useState();
    let [loading, setLoading] = useState(false);
    let [apiCalled, setApiCalled] = useState(false);
    let [currentPage, setCurrentPage] = useState({
        recommended: 0,
        schedulList: 0
    });
    let [pageCount, setPageCount] = useState({
        recommended: 0,
        schedulList: 0
    });
    let [profileCompletion, setProfileCompletion] = useState();
    let [substractProfileCompletion, setSubstractProfileCompletion] = useState();
    let cid = sessionStorage.getItem("Cid");
    let [showErrMessage, setShowErrMessage] = useState();
    let [showErrorPopup, setShowErrorPopup] = useState(false);
    let [showSuccessPopup, setShowSuccessPopup] = useState(false);
    let getMsg = sessionStorage.getItem("msg");
    const [showJobModal, setShowJobModal] = useState(false);
    const [getJobId, setJobId] = useState(null);

    let doNotRenderTwiceRfi = false;
    useEffect(() => {
        if (!doNotRenderTwiceRfi) {
            doNotRenderTwiceRfi = true
            getProfileData();
            RecommendedJobList(0);
            triggerApiBasedOnDate();
        }
    }, []);

    let navigateEditProfile = () => {
        navigate("/editProfile");
    }

    let handlePageClick = ({ selected: selectedPage }, type) => {
        if (type === "recommended") {
            setCurrentPage({ recommended: selectedPage, schedulList: selectedPage });
            RecommendedJobList(selectedPage);
        } else {
            setCurrentPage({ recommended: selectedPage, schedulList: selectedPage });
            getTodaysInterviewApi(selectedPage);
        }
    }

    let switchCompanies = (id) => {
        if (!id) {
            id = getCookie('Cid') || cid;
        } else {
            cid = id
        }
        getProfileData();
        RecommendedJobList(0);
        getTodaysInterviewApi(0);
    }

    const convertToUTC = (localDateTime) => {
        const localDate = moment(localDateTime);
        const utcDate = localDate.utc();
        return utcDate.format('YYYY-MM-DD HH:mm:ss');
    }

    const triggerApiBasedOnDate = () => {
        const currentDate = new Date();
        const futureDate = new Date();
        const hours = currentDate.getHours();
        const minutes = currentDate.getMinutes();
        const seconds = currentDate.getSeconds();
        let fromDate = convertToUTC(moment(currentDate).format("YYYY-MM-DD") + " " + hours + ":" + minutes + ":" + seconds);
        let toDate = convertToUTC(moment(currentDate).format("YYYY-MM-DD") + " 23:59:59");

        const tomorrow = currentDate.setUTCDate(currentDate.getUTCDate() + 1);
        let fromDate_1 = convertToUTC(moment(tomorrow).format("YYYY-MM-DD") + " " + " 00:00:00");

        getTodaysInterviewApi(0, fromDate, toDate);
        getUpcomingInterviewApi(0, fromDate_1);
    }

    const jobViewModal = (id) => {
        setShowJobModal(!showJobModal);
        setJobId(id)
    }

    // -------------> Get Profile API <-------------
    let getProfileData = async () => {
        setLoading(true);

        let apiUrl = config.baseUrl_candidate + "/Profile/gp"

        let bodyFormData = new FormData();
        bodyFormData.append('cId', getCookie('Cid') || cid);

        await axios({
            method: "post",
            url: apiUrl,
            data: bodyFormData,
            headers: {
                "Content-Type": "multipart/form-data",
                'X-Session': getCookie('sessionid') || sessionStorage.getItem("sessionid")
            }
        }).then((result) => {
            setLoading(false);
            let res = result.data.data;
            sessionStorage.setItem("photoIdCandidate", res.photoId);
            setProfileCompletion(res?.profileCompletion);
            setSubstractProfileCompletion(100 - res?.profileCompletion);
            setProfileData(res);
            if (getMsg !== null) {
                if (!apiCalled) {
                    setShowSuccessPopup(true);
                    setShowErrMessage(getMsg);
                    sessionStorage.removeItem("msg")
                }
                setApiCalled(true);
            }
        }).catch((err) => {
            if (err) {
                setLoading(false);
            }
        })
    }

    // ------------> Recommended job API <-----------------
    let RecommendedJobList = async (currentPageCount) => {
        let per_page = 9;
        let offset;
        setLoading(true);
        setCurrentPage({ recommended: currentPageCount, schedulList: currentPage.schedulList });
        offset = currentPageCount * per_page

        let payLoad = {
            "cId": getCookie('Cid') || cid,
            "sortBy": "createdTime",
            "orderBy": "desc",
            "limit": per_page,
            "offset": offset,
        }

        let jobListApiUrl = config.baseUrl_candidate + '/Profile/rjobs';

        await axios.post(jobListApiUrl, payLoad, {
            headers: {
                "X-session": getCookie('sessionid') || sessionStorage.getItem("sessionid")
            }
        })
            .then((result) => {
                setLoading(false);
                let res = result.data.data;
                setRecommendedJobsList(res.jobDetails)
                setPageCount({
                    recommended: Math.ceil(res.count / per_page),
                    schedulList: pageCount.schedulList
                });
            }).catch((err) => {
                setLoading(false);
                setRecommendedJobsList("");
                if (err.response.status >= 400 && err.response.status < 500) {
                    if (err.response.status !== 400) {
                        setShowErrorPopup(true);
                        setShowErrMessage(err.response.data.error.message);
                    }
                } else {
                    navigate("./error");
                }
            })
    }

    const getTodaysInterviewApi = async (currentPageCount, f_date, t_date) => {
        let per_page = 10;
        let offset;
        offset = currentPageCount * per_page
        setCurrentPage({ recommended: currentPage.recommended, schedulList: currentPageCount });
        setLoading(true);

        let payLoad = {
            "cId": getCookie('Cid') || cid,
            "fromDate": f_date,
            "toDate": t_date,
            "limit": per_page,
            "offset": offset,
            "sortBy": "scheduleTime",
            "orderBy": "desc"
        }

        let apiUrl = config.baseUrl_candidate + "/Profile/getScheuleList";

        await axios.post(apiUrl, payLoad, {
            headers: {
                "X-session": getCookie('sessionid') || sessionStorage.getItem("sessionid")
            }
        })
            .then((res) => {
                let result = res.data.data;
                setScheduleList(result.mySchedules);
                setPageCount({
                    recommended: pageCount.recommended,
                    schedulList: Math.ceil(result.count / per_page)
                });
            }).catch((err) => {
                setLoading(false);
                setScheduleList("");
                if (err.response.status >= 400 && err.response.status < 500) {
                    if (err.response.status !== 400) {
                        setShowErrorPopup(true);
                        setShowErrMessage(err.response.data.error.message);
                    }
                } else {
                    navigate("./error");
                }
            })
    }

    const getUpcomingInterviewApi = async (currentPageCount, f_date) => {
        let per_page = 10;
        let offset;
        offset = currentPageCount * per_page
        setCurrentPage({ recommended: currentPage.recommended, schedulList: currentPageCount });
        setLoading(true);

        let payLoad = {
            "cId": getCookie('Cid') || cid,
            "fromDate": f_date,
            "limit": per_page,
            "offset": offset,
            "sortBy": "scheduleTime",
            "orderBy": "desc"
        }

        let apiUrl = config.baseUrl_candidate + "/Profile/getScheuleList";

        await axios.post(apiUrl, payLoad, {
            headers: {
                "X-session": getCookie('sessionid') || sessionStorage.getItem("sessionid")
            }
        })
            .then((res) => {
                let result = res.data.data;
                setUpComingScheduleList(result.mySchedules);
            }).catch((err) => {
                setLoading(false);
                setScheduleList("");
                if (err.response.status >= 400 && err.response.status < 500) {
                    if (err.response.status !== 400) {
                        setShowErrorPopup(true);
                        setShowErrMessage(err.response.data.error.message);
                    }
                } else {
                    navigate("./error");
                }
            })
    }

    const recommendedJobApply = async (jobId) =>{
        setLoading(true);

        let apiUrl = config.baseUrl_candidate + "/Profile/jobApply";

        let bodyFormData = new FormData();
        bodyFormData.append('jobId', jobId);
        bodyFormData.append('cId', cid);

        await axios({
            method: "post",
            url: apiUrl,
            data: bodyFormData,
            headers: {
                "Content-Type": "multipart/form-data",
                "X-Session": sessionStorage.getItem("sessionid")
            }
        }).then((result) => {
            setLoading(false);
            let res = result.data.data
            setShowSuccessPopup(true);
            setShowErrMessage(res.message);
            RecommendedJobList(0);
            
        }).catch((err) => {
            setLoading(false);
            if(err.response.status >= 400 && err.response.status < 500) {
                setShowErrorPopup(true);
                setShowErrMessage(err.response.data.error.message);
            } else {
                navigate("./error");
            }
        })
    }

    let chartData = {
        series: [profileCompletion, substractProfileCompletion],

        options: {
            chart: {
                type: 'donut'
            },
            legend: { show: false },
            dataLabels: { enabled: false, color: "#FFFFFF" },
            tooltip: { enabled: false },
            fill: { colors: ["#FF8A00", "#D1D1D1"] },
            states: {
                hover: { filter: { type: "#79CBCA", value: 0.5 } },
                active: { filter: { type: "none", value: 0 } }
            },
            stroke: { width: 0 },
            plotOptions: {
                pie: {
                    expandOnClick: false,
                    donut: {
                        size: "70%",
                        labels: {
                            show: true,
                            name: { show: false },
                            total: {
                                fontSize: "5px !important",
                                show: true,
                                showAlways: true,
                                formatter: function (w) {
                                    return (w.config.series[0] + "%");
                                },
                                fontSize: "10px"
                            }
                        }
                    }
                }
            }
        }
    };

    return (
        <>
            {loading && (
                <div className="loader">
                    <img src={LoadingIcon} alt="loader" />
                </div>
            )}

            {showErrorPopup || showSuccessPopup &&
                <BackEndErrorMsg
                    showErrorPopup={showErrorPopup}
                    showSuccessPopup={showSuccessPopup}
                    setShowErrorPopup={setShowErrorPopup}
                    setShowSuccessPopup={setShowSuccessPopup}
                    setShowErrMessage={setShowErrMessage}
                    message={showErrMessage}
                />}

            {showJobModal && <JobDetails
                jobId={getJobId}
                showJobModal={showJobModal}
                setShowJobModal={setShowJobModal}
                jobViewModal={jobViewModal}
                loading={setLoading}
            />}

            <div className="candidate_profile_main">
                <div className="candidate_profile_main_navbar">
                    < CandidateNav
                        switchCompanies={switchCompanies} />
                </div>
                <div className="candidate_profile_main_hero_full">
                    <div className="candidate_profile_main_hero">
                        <div className="left_side_profile_boxes">
                            <div className="profile_card">
                                {sessionStorage.getItem("photoIdCandidate") <= 0 ?
                                    <img src={userAvatar} alt="User " />
                                    :
                                    <img src={config.showPhoto + sessionStorage.getItem("photoIdCandidate")} alt="User " onError={(e) => e.target.src = userAvatar} />
                                }
                                <h5>{profileData?.firstName} {profileData?.lastName}<i className="bi bi-pencil-square" onClick={() => navigateEditProfile()}></i></h5>
                                <p className="phone"><img src={awesome_phone} alt="phone " />{profileData?.mobile}</p>
                                <p className="email"><img src={feather_mail} alt="email " />{profileData?.emailId}</p>
                                {/* <button type="button" className="btn btn-dark" onClick={() => navigateEditProfile()}>EDIT PROFILE</button> */}
                                <div className="graph">
                                    <div style={{ margin: "5px 0px 0px", marginBottom: "0" }}>
                                        <ReactApexChart
                                            options={chartData?.options}
                                            series={chartData?.series}
                                            type="donut"
                                            width="80px"
                                            height="90px"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="profile_experience">
                                <p>Experience: {Math.floor(profileData?.experience / 12)} years {profileData?.experience % 12} months</p>
                                {profileData?.currentLocation && <p>Current Location: {profileData?.currentLocation.city}</p>}
                                <p>CTC: {profileData?.currentCTC} L per annum</p>
                                <p>Notice Period:{profileData?.noticePeriod}<span>{"\t"}days</span></p>
                                {/* <p>Job Type: {profileData?.jobType}</p> */}

                                {/* {profileData?.industry ? <p>Industry: {profileData.industry}</p> : null} */}
                                <p>Industry: {profileData?.industry ? profileData.industry.industryName : "Not available"}</p>
                                <p>Category: {profileData?.category ? profileData.category.categoryName : "Not available"}</p>

                                <p>Preferred Location: {"\t"}
                                    {
                                        profileData?.preferredLocation?.map((itm, index) => (
                                            <span key={index}>{itm.city}
                                                {profileData?.preferredLocation?.length !== index + 1 ?
                                                    <>,</> : null
                                                }</span>
                                        ))
                                    }
                                </p>
                            </div>
                        </div>

                        <div className="middle_profile_boxes_container">
                            {
                                recommendedJobsList?.length === 0 ?
                                    <div className="middle_profile_boxes" style={{ textAlign: "center" }} >
                                        <h3>Recommended Jobs<span><Link to="/findJob">View All</Link></span></h3>
                                        <img src={nodata_found} alt="job not found" />
                                    </div> :

                                    <div className="middle_profile_boxes">
                                        <h3>Recommended Jobs<span><Link to="/findJob">View All</Link></span></h3>

                                        {recommendedJobsList?.map((item, index) => (
                                            <div className="middle_profile_boxes_content" key={index}>
                                                <div className="middle">
                                                    <div className="middle_profile_boxes_content1">
                                                        <h3 onClick={() => jobViewModal(item)}>{item.jobTitle}</h3>
                                                        <p>{item.companyName}</p>
                                                    </div>
                                                    <div className="col-lg-6 apply_btn">
                                                        <Button onClick={() => recommendedJobApply(item.jobId)}>
                                                            Apply
                                                        </Button>
                                                    </div>
                                                </div>
                                                <div className="middle_profile_boxes_content_icons">

                                                    <div className="location">
                                                        <div >
                                                            <img src={location_icon_black} alt="location" />
                                                        </div>
                                                        <div style={{ marginLeft: "8px" }}>
                                                            <p>{item.location.map((itm) => (
                                                                itm.city
                                                            ))}</p>
                                                        </div>
                                                    </div>
                                                    <span style={{ padding: "0 16px 0 16px" }}>|</span>
                                                    <div className="experience">
                                                        <div>
                                                            <img src={awesome_briefcase} alt="location" />
                                                        </div>
                                                        <div style={{ marginLeft: "8px" }}>
                                                            <p>{item.experienceLevel}</p>
                                                        </div>
                                                    </div>
                                                    <span style={{ padding: "0 16px 0 16px" }}>|</span>
                                                    <div className="durability">
                                                        <div>
                                                            <img src={awesome_briefcase} alt="location" />
                                                        </div>
                                                        <div style={{ marginLeft: "8px" }}>
                                                            <p>{item.type}</p>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="middle_profile_boxes_content_settings">
                                                    `   <img src={settings_Icon} />
                                                    {item.skillName.map((skill, index) => (
                                                        <p key={index}>
                                                            {skill}
                                                        </p>))}
                                                </div>
                                            </div>
                                        ))}
                                        {/* <div className="middle_profile_box1">
<img src={awesome_briefcase} alt="my activit" />
<div className="middle_profile">
<h6>UI/UX Designer</h6>
<span>XYZ Company Pvt.Ltd.</span>
<p>You are invited to attend the preliminary selection test for this position</p>
<button type="button" className="btn btn-dark"><Link to="/assessment" target="_blank">Click here to take the test</Link></button>
</div>
</div>
<div className="middle_profile_box1">
<img src={awesome_briefcase} alt="my activit" />
<div className="middle_profile">
<h6>UI/UX Designer</h6>
<span>XYZ Company Pvt.Ltd.</span>
<p>You are invited to attend the preliminary selection test for this position</p>
<button><Link to="/assessment" target="_blank">Click here to take the test</Link></button>
</div>
</div>
<div className="middle_profile_box1">
<img src={awesome_briefcase} alt="my activit" />
<div className="middle_profile">
<h6>UI/UX Designer</h6>
<span>XYZ Company Pvt.Ltd.</span>
<p>You are invited to attend the preliminary selection test for this position</p>
<button><Link to="/assessment" target="_blank">Click here to take the test</Link></button>
</div>
</div> */}
                                        {pageCount.recommended > 1 &&
                                            <ReactPaginate
                                                previousLabel={<i className="bi-arrow-left"></i>}
                                                nextLabel={<i className="bi-arrow-right"></i>}
                                                pageCount={pageCount.recommended}
                                                onPageChange={(e) => handlePageClick(e, "recommended")}
                                                forcePage={currentPage.recommended}
                                                containerClassName={"pagination"}
                                                previousLinkClassName={"pagination__link"}
                                                nextLinkClassName={"pagination__link"}
                                                disabledClassName={"pagination__link--disabled"}
                                                activeClassName={"pagination__link--active"}
                                                previousClassName={currentPage.recommended == 0 ? "hidden" : ""}
                                                nextClassName={currentPage.recommended == pageCount.recommended - 1 ? "hidden" : ""}
                                            />}
                                    </div>
                            }
                            <div className="latest_recommend_block">
                                <div className="latest_interviews">
                                    <div className="heading">
                                        <h3>Today's Interviews</h3>
                                    </div>
                                    {scheduleList?.length === 0 && upComingScheduleList?.length === 0 ? (
                                        <div className="no-interviews">
                                            <div>
                                                <img src={jobInterview} style={{ width: "152px", height: "152px", background: "#dbd5d585", borderRadius: "17px" }} />
                                            </div>
                                            <p>There are currently no interviews on your calendar for today.</p>
                                        </div>
                                    ) :
                                        scheduleList?.map((item, index) => (
                                            <div className="main_content" key={index}>
                                                <div className="d-flex"
                                                    style={{
                                                        height: "66px",
                                                        marginRight: "10px"
                                                    }}>
                                                    <div className="vr"
                                                        style={{
                                                            width: "3px",
                                                            color: "#3D89D8"
                                                        }}
                                                    ></div>
                                                </div>
                                                <div className="content">
                                                    <label>{item?.jobTitle}</label>
                                                    <h6>{item?.companyName}</h6>
                                                    <div className="date">
                                                        <div>
                                                            <span>{moment.utc(item?.scheduleTime).local().format("DD-MMM-YYYY hh.mm")}</span>
                                                        </div>
                                                        {/* <div>
                                                    <img src={awesome_briefcase} alt="date picker" />
                                                </div> */}
                                                    </div>
                                                </div>
                                            </div>))}
                                    {upComingScheduleList?.map((item, index) => (
                                        <div style={{ marginTop: "22px" }}>
                                            <div className="heading">
                                                <h3>Upcoming Interviews</h3>
                                            </div>
                                            <div className="main_content">
                                                <div className="d-flex"
                                                    style={{
                                                        height: "66px",
                                                        marginRight: "10px"
                                                    }}>
                                                    <div className="vr"
                                                        style={{
                                                            width: "3px",
                                                            color: "#3D89D8"
                                                        }}
                                                    ></div>
                                                </div>
                                                <div className="content">
                                                    <label>{item?.jobTitle}</label>
                                                    <h6>{item?.companyName}</h6>
                                                    <div className="date">
                                                        <div>
                                                            <span>{moment.utc(item?.scheduleTime).local().format("DD-MMM-YYYY hh.mm")}</span>
                                                        </div>
                                                        {/* <div>
                                                            <img src={awesome_briefcase} alt="date picker" />
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>))}
                                </div>
                                <div className="profile_skill">
                                    <div className="heading" >Skills</div>

                                    <div>
                                        {profileData?.skills.map((item, ind) => (
                                            <div className="skill_block" key={ind}>
                                                <Tooltip title={
                                                    <div>
                                                        <div className="skill_block1" style={{ fontSize: "12px", fontWeight: "400" }}>
                                                            {item.skillLevel}
                                                        </div>
                                                        <div className="skill_block1" style={{ fontSize: "12px", fontWeight: "400" }}>
                                                            {Math.floor(item.skillExperience / 12)} years {item.skillExperience % 12} months
                                                        </div>
                                                    </div>
                                                } placement="right-start" arrow>
                                                    <div className="skill_block1" style={{ fontSize: "12px", fontWeight: "400" }}>
                                                        {item.skillName}
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                {/* <div className="right_profile_boxes">
<h3>Recommended Jobs <span><Link to="/findJob">View All</Link></span></h3>
{
jobList?.slice(0, 4).map((jobname, index) => {
return (
<div className="recommended_jobs" key={index}>
<h6>{jobname.jobTitle}</h6>
<p>{jobname.companyName}</p>
<div className="required">
<span>{jobname?.location?.map((item) => (
item.city
))}</span>
<span>{jobname.experienceLevel}</span>
</div>
<div style={{ marginBottom: "5%" }}>
<span>{jobname.type}</span>
</div>
<button>{jobname.category}</button>
</div>
)
})}
</div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CandidateProfile;
