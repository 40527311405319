import React from "react";
import './termsAndConditions.css'
import Logo from "../../../../assets/logo/Logo.png"
import config from "../../../../Shared/Config/config.json";

function TermsAndConditions() {

    let getEmail = config.contactUsEmail;

    return (
        <>
            <div className="terms_condition">
                <div className="logo">
                    <img data-aos="zoom-in" data-aos-delay="100" data-aos-once="true" src={Logo} alt="the.shortlistd" style={{ width: "35%" }}></img>
                </div>
                <div className="paraList">
                    <div className="para">
                        <h3>Terms and Conditions </h3>
                        <p>These Terms and Conditions ("Terms") govern your access to and use of the Shortlistd website
                            and services provided by Shortlistd ("Shortlistd"). By accessing or using the Shortlistd platform
                            as an employer, you agree to comply with and be bound by these Terms. If you do not agree to these Terms,
                            please do not use our services.</p>
                    </div>
                    <div className="list">
                        <div>
                            <span>1. Eligibility</span>
                            <p>You must be a legally recognized entity or an individual of legal age to form a binding contract in your
                                jurisdiction to use the Shortlistd platform as an employer. By accessing or using the platform, you
                                represent and warrant that you meet these eligibility requirements.</p>
                        </div>
                        <div>
                            <span>2. Registration</span>
                            <p>To use the features of Shortlistd, you may be required to register for an account. You agree to provide
                                accurate, current, and complete information during the registration process and to update such information
                                to keep it accurate, current, and complete.</p>
                        </div>
                        <div>
                            <span>3. Account Security</span>
                            <p>You are responsible for maintaining the security of your account login credentials. You agree not to disclose
                                your login information to any third party and to notify Shortlistd immediately of any unauthorized use of
                                your account.</p>
                        </div>
                        <div>
                            <span>4. Use of Services</span>
                            <p>a. Job Listings: You may use Shortlistd to post job listings. You are solely responsible for the accuracy
                                and completeness of job postings.</p>
                            <p>b. Candidate Data: Shortlistd may collect, store, and process candidate data on your behalf. You agree to
                                comply with all applicable data protection and privacy laws regarding the use and handling of candidate
                                data.</p>
                            <p>c. Communication: You agree that Shortlistd may communicate with you through email, in-app notifications,
                                or other means for service-related updates, customer support, and marketing purposes.</p>
                        </div>
                        <div>
                            <span>5. Fees and Payments</span>
                            <p>Shortlistd may charge fees for certain services. You agree to pay all applicable fees as described on our
                                pricing page or in separate agreements. Fees are non-refundable.</p>
                        </div>
                        <div>
                            <span>6. Termination</span>
                            <p>Shortlistd reserves the right to terminate or suspend your account at any time for violation of these
                                Terms or for any other reason at our sole discretion.</p>
                        </div>
                        <div>
                            <span>7. Intellectual Property</span>
                            <p>All content and materials on the Shortlistd platform are protected by copyright, trademark, and other
                                intellectual property laws. You may not use, reproduce, or distribute any content from the platform
                                without prior written permission from Shortlistd.</p>
                        </div>
                        <div>
                            <span>8. Disclaimer of Warranties</span>
                            <p>The Shortlistd platform is provided "as is" and "as available" without any warranties, express or implied,
                                including but not limited to the implied warranties of merchantability, fitness for a particular purpose,
                                or non-infringement.</p>
                        </div>
                        <div>
                            <span>9. Limitation of Liability</span>
                            <p>Shortlistd shall not be liable for any direct, indirect, incidental, special, consequential, or punitive
                                damages arising out of or relating to your use of the platform.</p>
                        </div>
                        <div>
                            <span>10. Governing Law</span>
                            <p>These Terms and Conditions shall be governed by the laws of Republic of India. The exclusive forum for any
                                disputes arising out of or relating to these Terms of Use shall be a court of law located in Karnataka,
                                India.</p>
                        </div>
                        <div>
                            <span>11. Changes to Terms</span>
                            <p>Shortlistd reserves the right to update or modify these Terms at any time. We will notify you of changes via
                                email or by posting the updated Terms on the platform. Your continued use of Shortlistd after such
                                modifications will constitute your acceptance of the revised Terms.</p>
                        </div>
                        <div>
                            <span>12. Contact Information</span>
                            <p>If you have any questions or concerns about these Terms, please contact us at <b className="mail" onClick={() => window.location = 'mailto:' + getEmail}>{getEmail}</b>.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TermsAndConditions;