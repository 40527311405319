import React, { useEffect, useState } from "react";
import Select from 'react-select';
import Button from "react-bootstrap/Button";
import user from "../../../../assets/icons/user-icon.png"
import "./editBasicInfoProfile.css";
import PhoneInput from "react-phone-input-2";
import axios from "axios";
import config from "../../../../Shared/Config/config.json";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
// import GooglePlacesAutocomplete from "react-google-autocomplete";
import LoadingIcon from "../../../../assets/loader/loader.gif";
import userIcon from "../../../../assets/icons/user-icon.png";
import ImageCrop from "../../Shared/ImageCrop/imageCrop";
import { BackEndErrorMsg } from "../../Shared/Error/error";
import { TextField } from "@mui/material";
import { CKEditor } from "ckeditor4-react";
import { Link, useLocation, useNavigate } from "react-router-dom";

function EditBasicInfoProfile(props) {
    let getSessionId = sessionStorage.getItem("sessionid");
    let [fresher, setFresher] = useState(props.profileData?.workHistory?.fresher);
    let [loading, setLoading] = useState(false);
    let [photoFile, setPhotoFile] = useState();
    let [imgCropModal, setImgCropModal] = useState(false);
    let [showErrMessage, setShowErrMessage] = useState();
    let [showErrorPopup, setShowErrorPopup] = useState(false);
    let [showSuccessPopup, setShowSuccessPopup] = useState(false);
    let [validEmail, setValidEmail] = useState();
    let [showAbout, setShowAbout] = useState(false);
    let [disableBtn, setDisableBtn] = useState(false);
    let [disableSaveBtn, setDisableSaveBtn] = useState(false);
    let [catDropDisable, setCatDropDisable] = useState(true);
    const [showValidationMessage, setShowValidationMessage] = useState(false);
    const navigate = useNavigate();
    let ckeConfig = {
        toolbar: [
            { name: "document", items: ["Source"] },
            { name: "clipboard", items: ["Cut", "Copy", "Paste", "PasteText", "PasteFromWord", "-", "Undo", "Redo"] },
            { name: "basicstyles", items: ["Bold", "Italic", "Underline", "Strike", "Subscript", "Superscript", "-", "RemoveFormat"] },
            { name: "paragraph", items: ["NumberedList", "BulletedList", "-", "Blockquote", "CreateDiv", "-", "JustifyLeft", "JustifyCenter", "JustifyRight", "JustifyBlock"] },
            { name: "links", items: ["Link", "Unlink"] },
            { name: "insert", items: ["Image", "Table"] }, // Remove Horizontal Line and Special Character buttons
            { name: "styles", items: ["Format", "Font", "FontSize"] },
            { name: "colors", items: ["TextColor", "BGColor"] },
            { name: "tools", items: ["Maximize", "ShowBlocks"] },
            { name: "others", items: ["-"] },
        ],
        removeButtons: "Paste,PasteText,PasteFromWord,About,Image,Save,NewPage,Print,Templates,Find,Replace,Scayt,SelectAll,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Flash,IFrame,PageBreak,Anchor,Save,Preview,Cut,Copy,Source,Maximize,Subscript,Superscript,Link,Unlink,Format,Table, Strikethrough",
        versionCheck : false
    };
    // ------ location list and edit state ---------
    let [location, setLocation] = useState([]);
    // --- industry & category ----
    let [industry, setIndustry] = useState([]);
    let [category, setCategory] = useState([]);
    let cId = props.cid;
    // ----- mobile ----
    let [dialCode, setDialCode] = useState("in");
    let timezoneList = ["Asia/Kolkata", "America/New_York"]

    let doNotRenderTwiceRfi = false;
    useEffect(() => {
        if (!doNotRenderTwiceRfi) {
            doNotRenderTwiceRfi = true
            masterDataLocation("");
            getIndustryList();
            setTimeout(() => {
                setShowAbout(true)
            }, 300);
        }
    }, []);

    const onHandleFresherOrExp = (e, type) => {
        if (type == "fresher") {
            setFresher(true);
        } else {
            setFresher(false);
        }
        setDisableBtn(true);
    }

    let onChange_location = (location_data, type) => {
        if (type == "currectLocation") {
            if (location_data.length == 0) {
                setDisableSaveBtn(true);
            } else {
                setDisableSaveBtn(false);
            }
            props.setLocationSelected({
                value: location_data.value,
                label: location_data.label
            });
            // masterDataLocation(location_data.label);
        } else {
            if (location_data.length == 0) {
                setDisableSaveBtn(true);
            } else {
                setDisableSaveBtn(false);
            }
            props.setPreferadLocationSelected(location_data);
            masterDataLocation(location_data.label);
        }
        setDisableBtn(true);
    }

    let onChange_industry = (v) => {
        props.setIndustrySelected({
            value: v.value,
            label: v.label
        });
        props.setCategorySelected("");
        getCategoryList(v.label);
        setDisableBtn(true);
        if (v.length == 0) {
            setCatDropDisable(true);
        } else {
            setCatDropDisable(false);
        }
    }

    let onChange_category = (v) => {
        props.setCategorySelected({
            value: v.value,
            label: v.label
        });
        setShowValidationMessage(false);
        setDisableBtn(true);
    }

    let handleOnChange = (phone, mobileData) => {
        let cd = mobileData.dialCode.length;
        let number = phone.slice(cd);
        props.setMobile(number);
        setDialCode(mobileData.dialCode);
        props.setCountryCode(mobileData.countryCode);
    }

    let onChangeReshcedule = (date) => {
        props.setSelectedDate(date);
        setDisableBtn(true);
    }

    let onChangeInputFields = (e, type) => {
        setDisableBtn(true);
        let inputValue;
        // let inputValue_2;
        if (type === "aboutMe" && e.editor) {
            inputValue = e.editor.getData();
            console.log(inputValue);
            props.setAboutMe(encodeURIComponent(inputValue));
        } else {
            // Handle regular text input changes
            inputValue = e.target.value;

        }

        const alphabeticPattern = /^[A-Za-z\s]+$/;

        if (type === "firstName" || type === "lastName") {
            if (alphabeticPattern.test(inputValue) || inputValue === "") {
                console.log(inputValue);
                if (type === "firstName") {
                    props.setFirstName(inputValue);
                } else {
                    props.setLastName(inputValue);
                }
            }
        } else if (type == "nickName") {
            props.setNickName(inputValue);
        } else if (type == "emailId") {
            setValidEmail('');
            props.setEmailId(inputValue);
        } else if (type == "year" && inputValue !== "e") {
            if (inputValue === "" || (parseInt(inputValue) >= 0 && parseInt(inputValue) <= 99)) {
                props.setExpYear(inputValue);
            }
        } else if (type == "month" && inputValue !== "e") {
            if (inputValue === "" || (parseInt(inputValue) >= 0 && parseInt(inputValue) <= 12)) {
                props.setExpMonth(inputValue);
            }
        }
        //  else if (type == "aboutMe") {
        //     let temp = e.editor.getData();
        //     console.log(temp);
        //     props.setAboutMe(encodeURIComponent(temp));
        // }
        else if (type == "Cctc" && inputValue !== "e") {
            props.setCctc(inputValue);
        } else if (type == "Ectc" && inputValue !== "e") {
            props.setEctc(inputValue);
        } else if (type == "noticePeriod" && inputValue !== "e") {
            if (inputValue === "" || (parseInt(inputValue) >= 0 && parseInt(inputValue) <= 999)) {
                props.setNoticePeriod(inputValue);
            }
        } else if (type == "male") {
            props.setGender("male");
        } else if (type == "female") {
            props.setGender("female");
        } else if (type == "others") {
            props.setGender("others");
        }
    }

    let onSelectPhotoFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setImgCropModal(true);
            setPhotoFile(e);
        }
    };

    // const [showEditor, setShowEditor] = useState(true)
    let discardChanges = () => {
        setDisableBtn(false);
        setShowAbout(false);
        setShowValidationMessage(false);
        props.getProfileCanData(cId);
        setTimeout(() => {
            setShowAbout(true)
        }, 2000);
    }

    let regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    let validate = () => {
        let error = false;
        if (regex.test(props.emailId) == false) {
            setValidEmail("Invalid email Id");
            error = true
            return error
        }
    }

    let onChangeTimezone = (e) => {
        props.setSelectedTimezone({
            value: e.value,
            label: e.label
        });
        setDisableBtn(true);
    }

    const editBasicProfile = async () => {
        setShowValidationMessage(false);

        if (!props.categorySelected || !props.categorySelected.value) {
            setShowValidationMessage(true);
            return; // Prevent form submission
        }

        if (validate()) {
            return
        }
        setLoading(true);

        let tempPref_Location = []
        if (props.preferadLocationSelected.length > 0) {
            for (let i = 0; i < props.preferadLocationSelected.length; i++) {
                tempPref_Location.push(props.preferadLocationSelected[i].value)
            }
        }

        let totalExperience = (props.expYear * 12) + parseInt(props.expMonth)

        let payLoad = {
            "edit": true,
            "cId": cId,
            "firstName": props.firstName,
            "lastName": props.lastName,
            "displayName": props.nickName,
            "dob": props.selectedDate == "" ? null : moment(props.selectedDate).format("YYYY-MM-DD"),
            "emailId": props.emailId,
            "mobileNumber": props.phoneNumber,
            "currentLocation": props.locationSelected.value,
            "gender": props.gender,
            "industryId": props.industrySelected.value,
            "categoryId": props.categorySelected.value,
            "ctc": parseInt(props.cctc),
            "ectc": parseInt(props.ectc),
            "experience": fresher !== true ? totalExperience : undefined,
            "noticePeriod": fresher !== true ? parseInt(props.noticePeriod) : undefined,
            "preferredLocation": tempPref_Location,
            "aboutMe": props.aboutMe,
            "timeZone": props.selectedTimezone.value
        }

        let apiUrl = config.baseUrl_candidate + "/Profile/scp";
        await axios.post(apiUrl, payLoad, {
            headers: {
                'X-Session': getSessionId,
                'content-Type': 'application/json',
            }
        }).then((result) => {
            setLoading(false);
            let res = result.data.data;
            console.log(res, "DVDF");
            props.getProfileCanData(cId);
            setDisableBtn(false);
            setShowSuccessPopup(true);
            setShowErrMessage(res.message);
        }).catch((err) => {
            if (err) {
                props.getProfileCanData(cId);
                setLoading(false);
                setShowErrorPopup(true);
                setShowErrMessage(err.response.data.error.message);
            }
        })
    }

    // let onChangeLocation = (e) => {
    //     masterDataLocation(e);
    // }

    const masterDataLocation = async (e) => {
        setLoading(true);
        let searchJson = {
            "type": "location",
            "orderBy": "asc",
            "sortBy": "city",
            "city": e
        }

        let searchApiUrl = config.baseUrl_candidate + "/Profile/masterData";

        await axios.post(searchApiUrl, searchJson, {
            headers: {
                'X-Session': getSessionId,
                "Content-Type": "application/json"
            }
        })
            .then((result) => {
                setLoading(false);
                let temp = result.data.data.masterData
                setLocation(temp);
            }).catch((err) => {
                if (err) {
                    console.log("This is error message", err);
                    setLoading(false);
                    if (err.response.status !== 404) {
                        setShowErrorPopup(true);
                        setShowErrMessage(err.response.data.error.message);
                    }
                }
            })
    }

    const getIndustryList = async () => {
        setLoading(true);

        let searchIndustryJson = {
            "json": {
                "request": {
                    "servicetype": "21",
                    "functiontype": "1040",
                    "industry": "_ALL_",
                    "sort": {
                        "sortby": "industry",
                        "orderby": "asc"
                    }
                }
            }
        }


        let searchIndustryApiUrl = config.baseUrl + "/JobzTop/QuestionBank?request=" + encodeURIComponent(JSON.stringify(searchIndustryJson))

        axios.post(searchIndustryApiUrl)
            .then((result) => {
                setLoading(false);
                let locationTemp = result.data.json.response.data
                setIndustry(locationTemp);
            }).catch((err) => {
                if (err.response.status >= 400 && err.response.status < 500) {
                    setShowErrorPopup(true);
                    setShowErrMessage(err.response.data.error.message);
                } else {
                    navigate("./error");
                }
            })
    }

    const getCategoryList = (indId) => {
        setLoading(true);
        let CategoryJson = {
            "json": {
                "request": {
                    "servicetype": "21",
                    "functiontype": "1040",
                    "industry": indId,
                    // "sort" : {
                    //     "sortby":"industry",
                    //     "orderby":"asc"
                    // }
                }
            }
        }

        let searchCategoryApiUrl = config.baseUrl + "/JobzTop/QuestionBank?request=" + encodeURIComponent(JSON.stringify(CategoryJson))

        axios.post(searchCategoryApiUrl)
            .then((result) => {
                setLoading(false);
                let categoryTemp = result.data.json.response.data
                setCategory(categoryTemp);
            }).catch((err) => {
                if (err.response.status >= 400 && err.response.status < 500) {
                    setShowErrorPopup(true);
                    setShowErrMessage(err.response.data.error.message);
                } else {
                    navigate("./error");
                }
            })
    }

    const uploadPhoto = (e) => {
        console.log(e);
        setLoading(true);

        const formData = new FormData();
        formData.append('file', e.target.files[0]);
        formData.append('type', "photo");
        formData.append('cId', cId);
        let apiUrl = config.baseUrl_candidate + "/Profile/srl"
        axios({
            method: "post",
            url: apiUrl,
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data",
                'X-Session': sessionStorage.getItem("sessionid")
            }
        }).then((result) => {
            setLoading(false);
            let temp = result.data;
            setImgCropModal(false);
            props.getProfileCanData(cId);
            setShowSuccessPopup(true);
            setShowErrMessage(temp.data.message);
        }).catch((error) => {
            setLoading(false);
            if (error.response.status >= 400 && error.response.status < 500) {
                setShowErrorPopup(true);
                setShowErrMessage(error.response.data.error.message);
            } else {
                navigate("./error");
            }
        });
    }

    return (
        <>
            {loading && (
                <div className="loader">
                    <img src={LoadingIcon} alt="loader" />
                </div>
            )}
            {/* <ErrorModal
                modalMsg={modalMsg}
                showErrorModal={showErrorModal}
                setShowErrorModal={setShowErrorModal} /> */}
            {/* -------------> success modal <----------- */}
            {showErrorPopup || showSuccessPopup ?
                <BackEndErrorMsg
                    showErrorPopup={showErrorPopup}
                    showSuccessPopup={showSuccessPopup}
                    setShowErrorPopup={setShowErrorPopup}
                    setShowSuccessPopup={setShowSuccessPopup}
                    message={showErrMessage}
                />
                : null}
            {/* ------------> image crop component <------------- */}
            {
                imgCropModal &&
                <ImageCrop
                    photoFile={photoFile}
                    // uploadPhoto={uploadPhoto}
                    imgCropModal={imgCropModal}
                    setImgCropModal={setImgCropModal}
                    cId={cId}
                    getProfileCanData={props.getProfileCanData}
                />
            }
            <div className="edit_basic_info">
                <div className="profile_photo_item">
                    {/* <img src={user} className="rounded-circle" alt="Cinque Terre" width="240" height="240" />
                    <div className="profile_list">
                        <h6 style={{ fontWeight: "500" }}>Rohit sharma</h6>
                        <h6> resume headline (resume title) is a short sentence which shows a candidate’s experience and skills. The purpose of a resume title is to make a first impression, catch the hiring manager’s attention, and make them read on. Good resume headlines are snappy one-liners that summarize the job seeker’s industry career.</h6>
                        <h6>Pune</h6>
                        <h6 style={{ color: "blue" }}>cvsonar@gmail.com</h6>
                    </div> */}
                    <h1>Personal Information</h1>
                    {/* <Button>Edit</Button> */}
                    <div className="discard_save_btn">
                        {disableBtn &&
                            <label onClick={() => discardChanges()}>Discard</label>
                        }
                        <Button disabled={!props.selectedDate || !props.firstName || !props.lastName || !props.emailId || props.preferadLocationSelected.length === 0 || !props.locationSelected || disableSaveBtn} onClick={editBasicProfile}>SAVE</Button>
                    </div>
                </div>
                <div className="basic_edit_inputs">
                    <div className="photo">
                        {props.profileData?.photoId <= 0 ?
                            <img src={userIcon} alt="profile photo" />
                            :
                            <img className="cmp-img" src={config.showPhoto + props.profileData?.photoId} alt="profile photo" onError={(e) => e.target.src = userIcon} />
                        }
                        <div style={{ marginLeft: "4px" }}>
                            <label for="file-upload" className="custom-file-upload" onClick={() => setImgCropModal(true)}>
                                UPLOAD PHOTO
                            </label>
                            {/* <input id="file-upload" type="file" accept="image/*"  /> */}
                            {/* <label for="file-upload" className="custom-file-upload">
                                UPLOAD PHOTO
                            </label> */}
                            <p style={{ fontSize: "12px", color: "#707070", marginTop: "-4px" }}>Supported formats .jpg and .png</p>
                        </div>
                    </div>
                    <div className="names_parent">
                        <div className="firstName" style={{ width: "30%" }}>
                            <label>First name*</label>
                            <input type="text" className="form-control" id="usr" placeholder="First name"
                                value={props.firstName} onChange={(e) => onChangeInputFields(e, "firstName")} autoComplete="off" />
                        </div>
                        <div className="lastName" style={{ width: "30%" }}>
                            <label>Last name*</label>
                            <input type="text" className="form-control" id="usr" placeholder="last name"
                                value={props.lastName} onChange={(e) => onChangeInputFields(e, "lastName")} autoComplete="off" />
                        </div>
                        <div className="nickName" style={{ width: "30%" }}>
                            <label>Display name</label>
                            <input type="text" className="form-control" id="usr" placeholder="Nick name"
                                value={props.nickName === null ? "" : props.nickName} onChange={(e) => onChangeInputFields(e, "nickName")} autoComplete="off" />
                        </div>
                    </div>
                    {/* <div className="headLine" style={{ marginTop: "10px" }}>
                        <label>Head line</label>
                        <input type="text" className="form-control" id="usr" placeholder="ex. junior software developer @rao's infosoft join pvt lmt" />
                    </div> */}
                    <div className="socialInfo_parent">
                        <div className="email">
                            <label>Email id*</label>
                            {/* <input type="text" className="form-control" id="usr" placeholder="ex. xyz@gmail.com" value={props.emailId}
                                onChange={(e) => onChangeInputFields(e, "emailId")} /> */}
                            <TextField id="outlined-basic" variant="outlined" placeholder="ex. xyz@gmail.com" value={props.emailId}
                                inputProps={{ autoComplete: "off" }} size="small" onChange={(e) => onChangeInputFields(e, "emailId")}
                                helperText={validEmail} />
                        </div>
                        <div className="phone" style={{ width: "48%" }}>
                            <label>Phone no</label>
                            <PhoneInput
                                // className="form-control"
                                // country={"in"}
                                value={props.phoneNumber}
                                // onChange={handleOnChange}
                                onChange={(value, mobileData) =>
                                    handleOnChange(value, mobileData)
                                }
                                placeholder="+91 88878-95245"
                                countryCodeEditable={false}
                                disabled
                            />
                        </div>
                    </div>
                    <div className="personal_info">
                        <div className="DOB">
                            <label>Date of Birth*</label>
                            {/* <input type="text" className="form-control" id="usr" placeholder="ex. 19 - Jun - 2005" value={props.profileData?.dob} /> */}
                            <div className="date_block">
                                <ReactDatePicker
                                    showIcon
                                    selected={props.selectedDate}
                                    onChange={(date) => onChangeReshcedule(date)}
                                    // minDate={new Date()}
                                    // maxDate={currentDate.setDate(currentDate.getDate() + 8)}
                                    dateFormat="dd-MMM-yyyy"
                                    placeholderText="dd-mm-yyyy"
                                    showMonthDropdown
                                    peekNextMonth
                                    showYearDropdown
                                    maxDate={new Date()}
                                    dropdownMode="select"
                                    autoComplete="off"
                                />
                            </div>
                        </div>
                        <div className="gender">
                            <label>Gender</label>
                            <div className="mainGenderBlock">
                                <div className="G_male">
                                    <input type="radio" className="form-check-input" id="radio1" name="optradio" value="option1"
                                        checked={props.gender == "male" ? true : false} onChange={(e) => onChangeInputFields(e, "male")} autoComplete="off" />
                                    <label className="form-check-label" for="radio1">Male</label>
                                </div>
                                <div className="G_female">
                                    <input type="radio" className="form-check-input" id="radio2" name="optradio" value="option2"
                                        checked={props.gender == "female" ? true : false} onChange={(e) => onChangeInputFields(e, "female")} autoComplete="off" />
                                    <label className="form-check-label" for="radio2">Female</label>
                                </div>
                                <div className="G_others">
                                    <input type="radio" className="form-check-input" id="radio3" name="optradio" value="option3"
                                        checked={props.gender == "others" ? true : false} onChange={(e) => onChangeInputFields(e, "others")} autoComplete="off" />
                                    <label className="form-check-label" for="radio3">Others</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="location">
                        <div className="location_content">
                            <label>Current Location*</label>
                            {/* <GooglePlacesAutocomplete
                                isMulti={true}
                                placeholder={" ex. Bengaluru, Karnataka, India"}
                                key={"AIzaSyBtlqYd7JOWbDcTf6v09qNv73VwAs81C5Y"}
                                className="search-collection-name form-control input-field"
                            /> */}
                            <Select
                                isMulti={false}
                                value={props.locationSelected}
                                onChange={(value) => onChange_location(value, "currectLocation")}
                                placeholder={"Location"}
                                options={location?.map((item, index) => {
                                    return { value: item?.Id, label: item?.City }
                                })}
                            // onInputChange={(e) => onChangeLocation(e)}
                            />
                        </div>
                        <div className="location_content">
                            <label>Preferred Location*</label>
                            {/* <GooglePlacesAutocomplete
                                isMulti={true}
                                placeholder={" ex. Bengaluru, Karnataka, India"}
                                key={"AIzaSyBtlqYd7JOWbDcTf6v09qNv73VwAs81C5Y"}
                                className="search-collection-name form-control input-field"
                            /> */}
                            <Select
                                isMulti
                                value={props.preferadLocationSelected}
                                onChange={(value) => onChange_location(value, "preferredLocation")}
                                placeholder={"Select Location"}
                                options={location?.map((item, index) => {
                                    return { value: item?.Id, label: item?.City }
                                })}
                            // onInputChange={(e) => onChangeLocation(e)}
                            />
                        </div>
                    </div>
                    <div className="freshOrExp_timezone">
                        <div className="freshOrExp">
                            <div className="fresher">
                                <input type="radio" className="form-check-input" id="radio4" name="optradio1" value="option4"
                                    onChange={(e) => onHandleFresherOrExp(e, "fresher")} checked={fresher} autoComplete="off" />
                                <label className="form-check-label" for="radio4">I am a fresher</label>
                            </div>
                            <div className="experience">
                                <input type="radio" className="form-check-input" id="radio5" name="optradio1" value="option5"
                                    onChange={(e) => onHandleFresherOrExp(e, "experience")} checked={!fresher} autoComplete="off" />
                                <label className="form-check-label" for="radio5">I am experienced</label>
                            </div>
                        </div>
                        <div className="timeZone">
                            <label>Time Zone*</label>
                            <Select
                                isMulti={false}
                                value={props.selectedTimezone}
                                onChange={(value) => onChangeTimezone(value)}
                                placeholder={"Select time zone"}
                                options={timezoneList?.map((item, index) => {
                                    return { value: item, label: item }
                                })}
                            />
                        </div>
                    </div>
                    {!fresher && <div className="exp_notic">
                        <div className="main_Experience_block">
                            <h4>Experience as on Date</h4>
                            <div className="Experience">
                                <div className="year" style={{ marginRight: "18px" }}>
                                    <input type="number" className="form-control" id="usr" value={props.expYear}
                                        onChange={(e) => onChangeInputFields(e, "year")} autoComplete="off" min="0" max="99" />
                                    <label>years</label>
                                </div>
                                <div className="month">
                                    <input type="number" className="form-control" id="usr" value={props.expMonth}
                                        onChange={(e) => onChangeInputFields(e, "month")} autoComplete="off" min="0" max="12" />
                                    <label>months</label>
                                </div>
                                {/* <div className="day">
                                    <input type="text" className="form-control" id="usr" />
                                    <label>days</label>
                                </div> */}
                            </div>
                        </div>
                        <div className="noticePeriod">
                            <label>Notice Period (In days)</label>
                            <input type="number" className="form-control" id="usr" placeholder="ex. 60" value={props.noticePeriod}
                                onChange={(e) => onChangeInputFields(e, "noticePeriod")} autoComplete="off" min="0" max="999" />
                        </div>
                    </div>}
                    <div className="industry_sector">
                        <div className="industry">
                            <label>Industry Sector</label>
                            <Select
                                isMulti={false}
                                value={props.industrySelected.value == undefined ? "" : props.industrySelected}
                                onChange={(o) => onChange_industry(o)}
                                placeholder="Select Industry"
                                options={industry.map((itm, ind) => {
                                    return { value: itm.industryId, label: itm.industry }
                                })}
                            // onKeyDown={handleEnterKey}
                            />
                        </div>
                        <div className="functional_content">
                            <label>Functional Area</label>
                            {showValidationMessage && <span style={{ color: 'red' }}>* Please fill out this field</span>}
                            <Select
                                isMulti={false}
                                value={props.categorySelected.value == undefined ? "" : props.categorySelected}
                                onChange={(o) => onChange_category(o)}
                                placeholder="Select category"
                                options={category.map((itm, ind) => {
                                    return { value: itm.categoryid, label: itm.category }
                                })}
                                isDisabled={catDropDisable}
                                required
                            />
                            {/* {catDropDisable && <label>Please select Industry</label>} */}
                        </div>
                    </div>
                    <div className="sallary">
                        <div className="current_sallary">
                            <label>Current Annual CTC</label>
                            <input type="number" className="form-control" id="usr" value={props.cctc}
                                onChange={(e) => onChangeInputFields(e, "Cctc")} autoComplete="off" min="0" />
                        </div>
                        <div className="expected_sallary">
                            <label>Expected Annual CTC</label>
                            <input type="number" className="form-control" id="usr" value={props.ectc}
                                onChange={(e) => onChangeInputFields(e, "Ectc")} autoComplete="off" min="0" />
                        </div>
                    </div>
                    <div className="about_me">
                        <label for="exampleFormControlTextarea1" className="form-label">About Me</label>
                        {/* <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="...Ankit"
                            value={props.aboutMe} onChange={(e) => onChangeInputFields(e, "aboutMe")}></textarea> */}
                        {showAbout && <CKEditor
                            config={ckeConfig}
                            name="aboutMe"
                            initData={decodeURIComponent(props.aboutMe)}
                            value={decodeURIComponent(props.aboutMe)}
                            onChange={(e) => onChangeInputFields(e, "aboutMe")}
                        />}
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditBasicInfoProfile;