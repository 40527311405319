import { Button, CircularProgress } from "@mui/material";
import React, { useState } from "react";
import uploadFile from "../../../../assets/img/candidate/upload (1).png"
import CV from "../../../../assets/img/candidate/cv (1).png"
import axios from "axios";
import config from "../../../../Shared/Config/config.json";
import { BackEndErrorMsg } from "../../Shared/Error/error";


function EditResume(props) {

    let cId = props.cid;
    let [showErrMessage, setShowErrMessage] = useState();
    let [showErrorPopup, setShowErrorPopup] = useState(false);
    let [showSuccessPopup, setShowSuccessPopup] = useState(false);
    let [resumeLoader, setResumeLoader] = useState(false);

    let previewImage = (event) => {
        var reader = new FileReader();
        reader.onload = function () {
            var preview = document.getElementById('preview');
            preview.src = reader.result;
            preview.style.display = 'block';
        };
        reader.readAsDataURL(event.target.files[0]);
        updateResume(event);
    }

    const downloadResume = () => {
        // console.log(config.downloadresume + props.profileData.resumeId)
        const url = config.downloadresume + props.profileData.resumeId;
           window.location.href = url;
        //    window.open(url, "_blank")
    };

    const updateResume = async (e) => {
        setResumeLoader(true);

        let apiUrl = config.baseUrl_candidate + "/Profile/srl"

        let bodyFormData = new FormData();
        bodyFormData.append('file', e.target.files[0]);
        bodyFormData.append("type", "resume");
        bodyFormData.append('cId', cId);

        await axios({
            method: "post",
            url: apiUrl,
            data: bodyFormData,
            headers: {
                "Content-Type": "multipart/form-data",
                'X-Session': sessionStorage.getItem("sessionid")
            }
        }).then((result) => {
            setResumeLoader(false);
            let res = result.data.data
            setShowSuccessPopup(true);
            setShowErrMessage(res.message);
            props.getProfileCanData(cId);
        }).catch((err) => {
            if (err) {
                setResumeLoader(false);
                setShowErrorPopup(true);
                setShowErrMessage(err.response.data.error.message);
            }
        })
    }

    return (
        <>
            {/* -------------> success modal <----------- */}
            {showErrorPopup || showSuccessPopup ?
                <BackEndErrorMsg
                    showErrorPopup={showErrorPopup}
                    showSuccessPopup={showSuccessPopup}
                    setShowErrorPopup={setShowErrorPopup}
                    setShowSuccessPopup={setShowSuccessPopup}
                    message={showErrMessage}
                />
                : null}
            {/* < div className="heading" style={{
                display: "flex",
                justifyContent: "center"
            }}>
                <h4 style={{ fontSize: "14px" }}>UPLOAD YOUR RESUME</h4>
            </div>
            <div className="browseYourResume">
                <div className="uploadResume" style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                }}>
                    <h6>Browse and upload resume</h6>
                    <input type="file" accept="image/*" id="imageInput" onchange={(event) => previewImage(event)} style={{ display: "none" }} />
                    <label for="imageInput" style={{
                        display: "flex",
                        justifyContent: "center"
                    }}>
                        <img id="preview" src={uploadFile} alt="Preview" style={{ width: "30%", height: "auto", cursor: "pointer" }} />
                    </label>
                    <p>Supported file formats DOC, DOCX, PDF Maximum file size 6MB</p>
                </div>
            </div>
            <div className="education_type">
                <div className="next_btn">
                    <button type="button" className="btn btn-dark">Preview</button>
                </div>
            </div> */}
            <div className="edit_KeySkills_info">
                <div className="profile_photo_item">
                    <h1>Resume</h1>
                    {/* <Button>Save</Button> */}
                </div>
                <div className="skills_main_block">
                    <div className="added_skills" style={{ width: "100%" }}>
                        <div>
                            <div className="skill">
                                <input type="file" id="imageInput" onChange={(event) => previewImage(event)} style={{ display: "none" }} />
                                <label style={{
                                    display: "flex",
                                    justifyContent: "center"
                                }}>
                                    <span><img src={uploadFile} alt="Preview" style={{ width: "80px", height: "auto" }} /></span>
                                </label>
                                <p style={{ textAlign: "center", fontWeight: "400", fontSize: "12px", marginBottom: "2px" }}>Supported file formats DOC, DOCX, PDF </p>
                                <p style={{ textAlign: "center", fontWeight: "400", fontSize: "12px", marginBottom: "6px" }}>Maximum file size 6MB</p>
                                <div className="education_type">
                                    <label className="next_btn" for="imageInput">
                                        <span id="preview">UPLOAD FILE</span>
                                    </label>
                                    {props?.profileData?.resumeName && <label className="next_btn">
                                        <span onClick={() => downloadResume()}>DOWNLOAD</span>
                                    </label>}
                                    {
                                        resumeLoader &&
                                        <div style={{ marginLeft: "10px" }}>
                                            <CircularProgress />
                                        </div>
                                    }
                                </div>
                                <div style={{
                                    marginTop: "11px", display: "flex",
                                    justifyContent: "center"
                                }}>
                                    {props?.profileData?.resumeName && <p style={{
                                        textAlign: "center", border: "1px solid #cbc9c9",
                                        borderRadius: "4px",
                                        padding: "1% 2% 1% 2%"
                                    }}><img src={CV} alt="CV" />{props?.profileData?.resumeName}</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
};

export default EditResume;