import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./editCertification.css"
import LoadingIcon from "../../../../assets/loader/loader.gif";
import deleteIcon from "../../../../assets/img/candidate/Icon feather-delete.svg";
import AddmoreIcon from "../../../../assets/img/candidate/Icon feather-plus-circle.svg";
import axios from "axios";
import config from "../../../../Shared/Config/config.json";
import { BackEndErrorMsg } from "../../Shared/Error/error";
import DatePicker from "react-datepicker";
import moment from "moment";
import Modal from 'react-bootstrap/Modal';
import noDataFound from "../../../../assets/Home-images/nodatafound.png";
import diskette from "../../../../assets/img/candidate/diskette.png";
import editIcon from "../../../../assets/img/candidate/Icon feather-edit-3 (1).svg";

function EditCertification(props) {

    let [loading, setLoading] = useState(false);
    let [showErrMessage, setShowErrMessage] = useState();
    let [showErrorPopup, setShowErrorPopup] = useState(false);
    let [showSuccessPopup, setShowSuccessPopup] = useState(false);
    let [disableBtn, setDisableBtn] = useState(false);
    let [disableSaveBtn, setDisableSaveBtn] = useState(false);
    let [addMoreCertiModal, setAddMoreCertiModal] = useState(false);
    let [selectedCompletionTime, setSelectedCompletionTime] = useState()
    let cId = props.cid;

    let doNotRenderTwiceRfi = false;
    useEffect(() => {
        if (!doNotRenderTwiceRfi) {
            doNotRenderTwiceRfi = true
            props.getProfileCanData(cId);
        }
    }, []);

    // -----------------> old desing function <-----------------
    // let handleChange = (e, i, typeName) => {
    //     setDisableSaveBtn(false);
    //     if (typeName == "certificationName") {
    //         if (e.target.value !== "") {
    //             setDisableSaveBtn(false);
    //         } else {
    //             setDisableSaveBtn(true);
    //         }
    //     }
    //     let newFormValues = [...props.multiCertification];
    //     if (typeName == "completionTime") {
    //         newFormValues[i].completionTime_2 = moment(e).format("MM/yyyy");
    //         newFormValues[i].completionTime = e;
    //     } else {
    //         newFormValues[i][e.target.name] = e.target.value;
    //     }
    //     props.setMultiCertification(newFormValues);
    // }


    // -----------------> new desing function <-----------------
    let handleChange = (e, typeName, type, ind) => {
        // setDisableSaveBtn(false);
        let newFormValues = [...props.multiCertification];
        let newFormValues_2 = [...props.multiCertification_2];
        if (type === "newCerti") {
            if (typeName == "certificationName") {
                if (e.target.value == "") {
                    setDisableSaveBtn(false);
                } else {
                    setDisableSaveBtn(true);
                }
            }
            if (typeName == "completionTime") {
                newFormValues[newFormValues.length - 1].completionTime_2 = moment(e).format("MM/yyyy");
                newFormValues[newFormValues.length - 1].completionTime = e;
                setSelectedCompletionTime(e);
            } else {
                newFormValues[newFormValues.length - 1][e.target.name] = e.target.value;
            }
        }else{
            newFormValues_2[ind].changeBtn = true;
            if (typeName == "certificationName") {
                if (e.target.value == "") {
                    setDisableSaveBtn(false);
                } else {
                    setDisableSaveBtn(true);
                }
            }
            let newFormValues = [...props.multiCertification];
            if (typeName == "completionTime") {
                newFormValues[ind].completionTime_2 = moment(e).format("MM/yyyy");
                newFormValues[ind].completionTime = moment(e).format("MM/yyyy");
                newFormValues_2[ind].completionTime_2 = moment(e).format("MM/yyyy");
                newFormValues_2[ind].completionTime = e;
            } else {
                newFormValues[ind][e.target.name] = e.target.value;
                newFormValues[ind].changeBtn = true;
                newFormValues_2[ind][e.target.name] = e.target.value;
            }
        }
        props.setMultiCertification(newFormValues);
        props.setMultiCertification_2(newFormValues_2);
    }

    let addMoreCertification = () => {
        props.setMultiCertification([...props.multiCertification, {
            certificationId: "",
            completionTime: "",
            completionTime_2: "",
            certificationName: "",
            institutionName: "",
            institutionId: "",
            disable: false
        }]);
        setDisableBtn(true);
        setDisableSaveBtn(false);
        setAddMoreCertiModal(true);
    }

    let closeModal = () => {
        setAddMoreCertiModal(false);
        props.setMultiCertification(props.multiCertification_2);
        setSelectedCompletionTime("");
    }

    let removeAddedCerti = (index) => {
        let newFormValues = [...props.multiCertification];
        // const removedCerti = newFormValues.splice(index, 1)[0];
        // let updatedCerti = { ...newFormValues[index] };
        // updatedCerti.disable = true;
        // newFormValues[index] = updatedCerti;
        // newFormValues.splice(index, 1);

        const removedSkill = newFormValues.splice(index, 1)[0];
        removedSkill.disable = true;
        newFormValues.push(removedSkill);
        props.setMultiCertification(newFormValues);
        setDisableBtn(true);
        addCertification();
    }

    let discardChanges = () => {
        setDisableBtn(false);
        props.getProfileCanData(cId);
    }

    let editAddedSkills = (index) => {
        let newFormValues_2 = [...props.multiCertification_2];
        newFormValues_2[index].disableField = false;
        props.setMultiCertification_2(newFormValues_2);
    }

    let addCertification = async () => {
        setLoading(true);
        console.log(props.multiCertification)
        let updateMultiCerti = props.multiCertification.map(({ skillName, skillExpMonth, skillExpValue, skillRating, completionTime_2, changeBtn, disableField, ...rest }) => rest);

        let payLoad = {
            "cId": cId,
            "certificationDetails": updateMultiCerti
        }

        let apiUrl = config.baseUrl_candidate + "/Profile/addCertification"
        await axios.post(apiUrl, payLoad, {
            headers: {
                "X-Session": sessionStorage.getItem("sessionid")
            }
        }).then((result) => {
            setLoading(false);
            let res = result.data;
            props.getProfileCanData(cId);
            setDisableBtn(false);
            setShowSuccessPopup(true);
            setShowErrMessage(res.data.message);
            setAddMoreCertiModal(false);
            setSelectedCompletionTime("");

        }).catch((err) => {
            if (err) {
                setLoading(false);
                setShowErrorPopup(true);
                setShowErrMessage(err.response.data.error.message);
            }
        })
    }

    return (
        <>
            {loading && (
                <div className="loader">
                    <img src={LoadingIcon} alt="loader" />
                </div>
            )}
            {/* -------------> success modal <----------- */}
            {showErrorPopup || showSuccessPopup ?
                <BackEndErrorMsg
                    showErrorPopup={showErrorPopup}
                    showSuccessPopup={showSuccessPopup}
                    setShowErrorPopup={setShowErrorPopup}
                    setShowSuccessPopup={setShowSuccessPopup}
                    message={showErrMessage}
                />
                : null}
            <div className="edit_KeySkills_info">
                <div className="profile_photo_item">
                    <h1>Certifications</h1>
                    {props.multiCertification_2.length > 0 &&
                        <div className="discard_save_btn">
                            <img onClick={addMoreCertification} style={{ cursor: "pointer", width: "14px" }} src={AddmoreIcon} alt="add more" />
                            <h3 onClick={addMoreCertification}>Add Certification</h3>
                        </div>
                    }
                </div>
                {props.multiCertification_2.length > 0 ?
                    <div className={props.multiCertification.length > 1 ? "content_1" : "content_2"}>
                        {props.multiCertification_2?.map((element, index) => (
                            !element.disable &&
                            <div className="skills_main_block" key={index}>
                                < div className="added_skills" style={{ width: "100%" }}>
                                    <div style={{
                                        display: "flex",
                                        alignItems: "flex-start",
                                        justifyContent: "space-between"
                                    }}>
                                        <div className="skill" style={{ width: "35%" }}>
                                            <label>Name of the Certification*</label>
                                            <input type="text" className="form-control" id="usr" placeholder="ex. Front-End Developer Course"
                                                value={element?.certificationName} name="certificationName" onChange={(e) => handleChange(e, "certificationName", "editCerti", index)}
                                                disabled={element.disableField} />
                                        </div>
                                        <div className="edit_delete">
                                            <div className="edit">
                                                {
                                                    !element.changeBtn ?
                                                        <>
                                                            <img onClick={() => editAddedSkills(index)} src={editIcon} alt="edit" style={{ width: "16px" }} />
                                                            <label onClick={() => editAddedSkills(index)} style={{ marginLeft: "7px" }}>Edit</label>
                                                        </>
                                                        :
                                                        <>
                                                            <img onClick={() => addCertification(index)} src={diskette} alt="save" style={{ width: "16px" }} />
                                                            <label onClick={() => addCertification(index)} style={{ marginLeft: "7px" }}>Save</label>
                                                        </>
                                                }
                                            </div>
                                            <div className="delete" onClick={() => removeAddedCerti(index)}>
                                                <img src={deleteIcon} alt="delete" />
                                                <label style={{ marginLeft: "3px" }}>Delete</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="skill_levels" style={{ justifyContent: "flex-start" }}>
                                        <div className="skill" style={{ width: "35%" }}>
                                            <label>Name of Institution</label>
                                            <input type="text" className="form-control" id="usr" placeholder="ex. Rao infosoftjoin"
                                                value={element?.institutionName} name="institutionName" onChange={(e) => handleChange(e, "Institution", "editCerti", index)}
                                                disabled={element.disableField} />
                                        </div>
                                        <div style={{ marginLeft: '20px', width: "35%" }}>
                                            <label>Passing Month/Year</label>
                                            {/* <input type="text" className="form-control" id="usr" placeholder="ex. 2023" style={{ fontSize: "12px" }}
                                            value={element?.completionTime} name="completionTime" onChange={(e) => handleChange(e, index)} /> */}
                                            <div className="date_block">
                                                <DatePicker
                                                    showIcon
                                                    // selected={element?.completionTime === "" ? "MM/yyyy" : new Date(element?.completionTime)}
                                                    selected={element?.completionTime === "" ? "" : new Date(element?.completionTime)}
                                                    onChange={(date) => handleChange(date, "completionTime", "editCerti", index)}
                                                    // minDate={new Date()}
                                                    maxDate={new Date()}
                                                    showMonthYearPicker
                                                    dateFormat="MMM/yyyy"
                                                    placeholderText="mm/yyyy"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    icon={<i className="bi bi-calendar-check"></i>}
                                                    disabled={element.disableField}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {/* <div className="discard_save_btn">
                        <img onClick={addMoreCertification} style={{ cursor: "pointer" }} src={AddmoreIcon} alt="add more" />
                        <h3 onClick={addMoreCertification}>{props.multiCertification.length == 0 ? <>Add</> : <>Add More</>}</h3>
                    </div> */}
                    </div>
                    :
                    <div className="menu_cat_child2_noRecords_found">
                        <img src={noDataFound} />
                        <div className="text">
                            No entries found yet
                            <h6>Click <b onClick={addMoreCertification}>'Add Certifications'</b>to showcase your qualifications and boost your profile</h6>
                        </div>
                    </div>
                }
            </div>

            {/* -------------------> add new certification <--------------------------- */}
            <div className="new_skill_modal">
                <Modal
                    show={addMoreCertiModal} onHide={closeModal} className="new_skill_modal">
                    <Modal.Header closeButton>
                        <div className="header">
                            <h4>Add Certification</h4>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="skills_main_block">
                            <div className="added_skills" style={{ width: "100%" }}>
                                <div style={{
                                    display: "flex",
                                    alignItems: "flex-start",
                                    justifyContent: "space-between"
                                }}>
                                    <div className="skill" style={{ width: "100%" }}>
                                        <label>Name of the Certification*</label>
                                        <input type="text" className="form-control" id="usr" placeholder="ex. Front-End Developer Course"
                                            name="certificationName" onChange={(e) => handleChange(e, "certificationName", "newCerti", null)} autoComplete="off" />
                                    </div>
                                </div>
                                <div className="skill_levels_new" style={{ justifyContent: "flex-start" }}>
                                    <div className="skill">
                                        <label>Name of Institution</label>
                                        <input type="text" className="form-control" id="usr" placeholder="ex. Rao infosoftjoin" name="institutionName" onChange={(e) => handleChange(e, "Institution", "newCerti", null)}
                                            autoComplete="off" />
                                    </div>
                                    <div style={{ marginTop: '10px' }}>
                                        <label>Passing Month/Year</label>
                                        <div className="date_block">
                                            <DatePicker
                                                showIcon
                                                // selected={element?.completionTime === "" ? "MM/yyyy" : new Date(element?.completionTime)}
                                                selected={selectedCompletionTime}
                                                onChange={(date) => handleChange(date, "completionTime", "newCerti", null)}
                                                // minDate={new Date()}
                                                maxDate={new Date()}
                                                showMonthYearPicker
                                                dateFormat="MMM/yyyy"
                                                placeholderText="mm/yyyy"
                                                showMonthDropdown
                                                showYearDropdown
                                                icon={<i className="bi bi-calendar-check"></i>}
                                                autoComplete="off"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="discard_save_btn">
                                    <Button style={{ marginLeft: "0" }} onClick={addCertification} disabled={!disableSaveBtn}>SAVE</Button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    )
};

export default EditCertification;