// import Adminheader from "../adminheader/adminheader";
import "../Home/Home.css"
import React, { useEffect, useState } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import Footer from "../../Shared/Footer/Footer"
import NavBar from "../../Shared/Navbar/NavBar";
import hero_img from "../../assets/Home-images/Component 22_1.png"
import jobs from "../../assets/Home-images/Jobs  - My Jobs@2x.png"
import filter from "../../assets/Home-images/filter (2).png"
import video_call from "../../assets/Home-images/video-call (2).png"
import workflow from "../../assets/Home-images/workflow.png"
import dashboard_icon from "../../assets/Home-images/dashboard (1).png"
import artificial_intelligence from "../../assets/Home-images/artificial-intelligence.png"
import posting_icon from "../../assets/Home-images/posting.png"
import { Button, TextField } from "@mui/material";
import config from "../../Shared/Config/config.json";
import axios from "axios";
import loaderImage from "../../assets/loader/loader.gif"
import Select from 'react-select';
import { useNavigate } from "react-router-dom";
import { BackEndErrorMsg } from "../Candidate/Shared/Error/error";



function Home() {
    let navigate = useNavigate();
    let [location, setLocation] = useState([]);
    let [skill, setSkill] = useState([]);
    let [loader, setLoader] = useState(false);
    let [selectedSkill, setSelectedSkill] = useState([]);
    let [locationSelected, setLocationSelected] = useState([]);
    let [showErrMessage, setShowErrMessage] = useState();
    let [showErrorPopup, setShowErrorPopup] = useState(false);
    let [inputValue, setInputValue] = useState("");

    let doNotRenderTwiceRfi = false;
    useEffect(() => {
        if (!doNotRenderTwiceRfi) {
            doNotRenderTwiceRfi = true
            SearchSkillList("");
            SearchLocation("");
        }
    }, []);

    const handleEnterKey = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            document.getElementById('submitButton').click();
        }
    }

    let onChange_Skill = (val) => {
        setSelectedSkill(val);
        
        const stringifiedSkill = JSON.stringify(val);

        sessionStorage.setItem("Selected", stringifiedSkill);
    }

    let onChange_location = (location_data) => {
        setLocationSelected(location_data);
        
        let stringifiedLocation = JSON.stringify(location_data);
        sessionStorage.setItem("LocationSelected", stringifiedLocation);
    }

    let navigateToFindjob = () => {
        navigate("/findJob")
    }

    // let onchangeLocation = (e) => {
    //     console.log(e);
    //     // SearchLocation(e);
    // }

    // search by location api
    let SearchLocation = (e) => {
        setLoader(true);
        let searchLocationJson = {
            "json": {
                "request": {
                    servicetype: 6,
                    functiontype: 6004,
                    data: {
                        "city": e
                    },
                    "sort": {
                        "sortby": "city",
                        "orderby": "asc"
                    }
                }
            }
        }

        let searchLocationApiUrl = config.baseUrl + "/JobzTop/SearchJob?request=" + encodeURIComponent(JSON.stringify(searchLocationJson));

        axios.post(searchLocationApiUrl)
            .then((result) => {
                setLoader(false);
                let locationTemp = result.data.json.response
                if (locationTemp.statuscode === "0") {
                    setLocation(locationTemp.data);
                } else if (locationTemp.statuscode !== "20021") {
                    setShowErrorPopup(true);
                    setShowErrMessage(locationTemp.statusmessage);
                }
            }).catch((err) => {
                if (err.response.status >= 400 && err.response.status < 500) {
                    setShowErrorPopup(true);
                    setShowErrMessage(err.response.data.error.message);
                } else {
                    navigate("./error");
                }
            })
    }
    // /search by location api

    // let onchangeSkill = (e) => {
    //     SearchSkillList(e)
    // } 

    // search by skill api
    const SearchSkillList = (e) => {
        setLoader(true);
        let searchJson = {
            "json": {
                "request": {
                    servicetype: 6,
                    functiontype: 6001,
                    data: {
                        skill: e
                    }
                }
            }
        }

        let searchApiUrl = config.baseUrl + "/JobzTop/SearchJob?request=" + encodeURIComponent(JSON.stringify(searchJson));

        axios.post(searchApiUrl)
            .then((result) => {
                setLoader(false);
                let temp = result.data.json.response;
                if (temp.statuscode === "0") {
                    setSkill(temp.data);
                } else {
                    setShowErrorPopup(true);
                    setShowErrMessage(temp.statusmessage);
                }
            }).catch((err) => {
                if (err.response.status >= 400 && err.response.status < 500) {
                    setShowErrorPopup(true);
                    setShowErrMessage(err.response.data.error.message);
                } else {
                    navigate("./error");
                }
            })
    }
    // /search by skill api

    return (
        <>
            {loader && (
                <div className="loader">
                    <img src={loaderImage} alt="loader" />
                </div>
            )}
            {/* -------------> Error and success modal <----------- */}
            {showErrorPopup &&
                <BackEndErrorMsg
                    showErrorPopup={showErrorPopup}
                    setShowErrorPopup={setShowErrorPopup}
                    message={showErrMessage}
                />}
            <div className="home_nav_bar">
                < NavBar />
            </div>

            {/* hero section */}
            <div id="section-1" className="container hero">
                <div className="row">
                    <div className="col-lg-5 heroImg">
                        <img src={hero_img} alt="advertisment"></img>
                    </div>
                    <div className="col-lg-7">
                        <h2 style={{ textAlign: "left", fontSize: "26px" }} data-aos="fade-left" data-aos-delay="300" data-aos-once="true">Revolutionizing the way job seekers find their next opportunity</h2>
                        <p style={{ textAlign: "left", fontSize: "14px" }} data-aos="fade-left" data-aos-delay="400" data-aos-once="true">We connect job seekers with top employers, streamlining the job search process and making it easier for job seekers to find their next opportunity</p>
                        <div className="cad_opt_btn">
                            <button>online interviews</button>
                            <button>skill assessments</button>
                            <button>video resume</button>
                            <button>job alerts</button>
                            <button>skill rating</button>
                        </div>
                        <div className="container-fluid input_label" style={{ marginTop: "4%" }}>
                            <div className="row main_input">
                                <div className="col-lg-4">
                                    <h6 className="font_14" data-aos="fade-down" data-aos-delay="400" data-aos-once="true">LOOKING FOR SKILL</h6>
                                </div>
                                <div className="col-lg-8 looking_Job font_14">
                                    {/* <TextField label="Job title, skill" variant="outlined" /> */}
                                    <Select
                                        isMulti
                                        value={selectedSkill}
                                        onChange={(value) => onChange_Skill(value)}
                                        placeholder={"Skills"}
                                        options={skill.map((item, index) => {
                                            return { value: item.skillId, label: item.skillName }
                                        })}
                                    //  onInputChange={(e) => onchangeSkill(e)}

                                    // onKeyDown={handleEnterKey}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid input_label">
                            <div className="row main_input">
                                <div className="col-lg-4">
                                    <h6 className="font_14" data-aos="fade-down" data-aos-delay="500" data-aos-once="true">WHERE</h6>
                                </div>
                                <div className="col-lg-8 looking_Job font_14">
                                    {/* <TextField label="Location" variant="outlined" /> */}
                                    <Select
                                        isMulti
                                        value={locationSelected}
                                        onChange={(value) => onChange_location(value)}
                                        placeholder={"Location"}
                                        options={location.map((item, index) => {
                                            return { value: item.locationId, label: item.location }
                                        })}
                                        // onInputChange={(e) => {
                                        //     if (e !== inputValue) {
                                        //         onchangeLocation(e);
                                        //     }
                                        //     setInputValue(e);
                                        // }}
                                    // onKeyDown={handleEnterKey}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="find_job_btn">
                            <Button type="submit" id="submitButton" onClick={() => navigateToFindjob()}>FIND JOB</Button>
                        </div>
                    </div>
                </div>
            </div>

            {/* about section */}
            <div id="section-2" className="container about">
                <h1 data-aos="fade-right" data-aos-delay="300" data-aos-once="true">Empowering recruiters to make smarter hiring decisions</h1>
                <div className="row job_info">
                    <div className="col-lg-6 instruction">
                        <span data-aos="fade-right" data-aos-delay="400" data-aos-once="true">
                            <p>
                                Our <b>cutting-edge AI-based ATS</b> provides recruiters with valuable insights and
                                recommendations to help them make better hiring decisions.
                            </p>
                            <br></br>
                            <p>
                                With our AI-based ATS, you can rest assured that you will find the best candidates
                                for your open positions, in the most efficient and effective way possible. If you are
                                looking to streamline your recruitment process and find the best candidates, our
                                AI-based ATS is the solution you've been looking for.
                            </p>
                            <br></br>
                            <p>
                                The system can also automate repetitive tasks, such as scheduling interviews,
                                sending follow-up emails, and sending rejections, which can save a lot of time
                                and effort for the recruiting team.
                            </p>
                        </span>
                    </div>
                    <div className="col-lg-6 my_jobs" data-aos="fade-left" data-aos-delay="300" data-aos-once="true">
                        <img src={jobs} alt="jobs"></img>
                    </div>
                </div>
            </div>

            {/* features section */}
            <div id="section-3" className="container features">
                <h1 data-aos="fade-down" data-aos-delay="400" data-aos-once="true">Our Key Features</h1>
                <div className="row features_info">
                    <div className="col-lg-6 features_info_1">
                        <div className="key_faetures_1">
                            <span data-aos="fade-right" data-aos-delay="400" data-aos-once="true">
                                <img src={posting_icon} alt="jobs"></img>
                            </span>
                            <div style={{ marginLeft: "30px" }}>
                                <h5 data-aos="fade-right" data-aos-delay="400" data-aos-once="true">Job Posting and Application Management</h5>
                                <p className="font_14" style={{ margin: "0", textAlign: "justify" }} data-aos="fade-right" data-aos-delay="500" data-aos-once="true">
                                    ATS allows companies to post job openings, and candidates can apply through the system. Recruiters can then view and manage the applications in one central location.</p>
                            </div>
                        </div>
                        <div className="key_faetures_middle">
                            <span data-aos="fade-right" data-aos-delay="400" data-aos-once="true">
                                <img src={filter} alt="jobs"></img>
                            </span>
                            <div style={{ marginLeft: "30px" }}>
                                <h5 data-aos="fade-right" data-aos-delay="400" data-aos-once="true">Search and Filtering</h5>
                                <p className="font_14" style={{ margin: "0", textAlign: "justify" }} data-aos="fade-right" data-aos-delay="500" data-aos-once="true">
                                    ATS allows recruiters to search and filter through candidates based on different criteria such as keywords, qualifications, and experience.</p>
                            </div>
                        </div>
                        <div className="key_faetures_1">
                            <span data-aos="fade-right" data-aos-delay="400" data-aos-once="true">
                                <img src={video_call} alt="jobs"></img>
                            </span>
                            <div style={{ marginLeft: "30px" }}>
                                <h5 data-aos="fade-right" data-aos-delay="400" data-aos-once="true">Interview scheduling</h5>
                                <p className="font_14" style={{ margin: "0", textAlign: "justify" }} data-aos="fade-right" data-aos-delay="500" data-aos-once="true">
                                    ATS allows recruiters to schedule interviews with candidates, and send out calendar invites.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 features_info_2">
                        <div className="key_faetures_1">
                            <span data-aos="fade-left" data-aos-delay="400" data-aos-once="true">
                                <img src={workflow} alt="jobs"></img>
                            </span>
                            <div style={{ marginLeft: "30px" }}>
                                <h5 data-aos="fade-left" data-aos-delay="400" data-aos-once="true">Workflow Management</h5>
                                <p className="font_14" style={{ margin: "0", textAlign: "justify" }} data-aos="fade-left" data-aos-delay="500" data-aos-once="true">
                                    ATS allows recruiters to move candidates through the hiring process, such as from application to interview, to offer, and to acceptance.</p>
                            </div>
                        </div>
                        <div className="key_faetures_middle">
                            <span data-aos="fade-left" data-aos-delay="400" data-aos-once="true">
                                <img src={dashboard_icon} alt="jobs"></img>
                            </span>
                            <div style={{ marginLeft: "30px" }}>
                                <h5 data-aos="fade-left" data-aos-delay="400" data-aos-once="true">Reporting and Analytics</h5>
                                <p className="font_14" style={{ margin: "0", textAlign: "justify" }} data-aos="fade-left" data-aos-delay="500" data-aos-once="true">
                                    ATS reporting and analytics provide valuable insights into recruitment and hiring processes, enabling organizations to make data-driven decisions, optimize their strategies, and ultimately build a more efficient and diverse workforce.</p>
                            </div>
                        </div>
                        <div className="key_faetures_1">
                            <span data-aos="fade-left" data-aos-delay="400" data-aos-once="true">
                                <img src={artificial_intelligence} alt="jobs"></img>
                            </span>
                            <div style={{ marginLeft: "30px" }}>
                                <h5 data-aos="fade-left" data-aos-delay="400" data-aos-once="true">AI Based Features</h5>
                                <p className="font_14" style={{ margin: "0", textAlign: "justify" }} data-aos="fade-left" data-aos-delay="500" data-aos-once="true">
                                    Some advanced ATS use AI based features to automate tasks such as resume screening, matching and predicting candidate success, and even conduct initial screening interviews.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* details section */}
            <div className="details_main">
                <div id="section-4" className="container details" style={{ padding: "3%" }}>
                    <div className="count">
                        <div className="total_count">
                            <span>20M+</span>
                            <h6>Total Applicants</h6>
                        </div>
                        <div className="total_count">
                            <span>1000+</span>
                            <h6>Companies</h6>
                        </div>
                        <div className="total_count">
                            <span>150K+</span>
                            <h6>Jobs</h6>
                        </div>
                        <div className="total_count">
                            <span>10M+</span>
                            <h6>Interviews</h6>
                        </div>
                    </div>
                    <div className="cust_stories">

                    </div>
                </div>
            </div>

            <div className="home_footer">
                <Footer />
            </div>
        </>
    )
}

export default Home;