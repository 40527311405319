import React, { useState, useEffect, useRef } from "react";
import config from "../../../../Shared/Config/config.json";
import { useNavigate, NavLink } from 'react-router-dom';
import "./candidateNavbar.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import Logo from "../../../../assets/logo/Logo.png"
import userAvatar from '../../../../assets/img/candidate/user avatar.svg';
import awesome_briefcase from '../../../../assets/img/candidate/Icon awesome-briefcase.svg';
import material_dashboard from '../../../../assets/img/candidate/Icon material-dashboard.svg';
import feather_search from '../../../../assets/img/candidate/Icon feather-search.svg';
import "bootstrap/dist/js/bootstrap.js"
import axios from "axios";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import LoadingIcon from "../../../../assets/loader/loader.gif";
import { Button, Menu, MenuItem } from "@mui/material";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { eraseCookie, getCookie, setCookie } from "../../../../utiles/Cookies";


function CandidateNav(props) {
    const navigate = useNavigate();
    let [loading, setLoading] = useState(false);
    let companyName = sessionStorage.getItem("companyName");
    let getCompanyList = JSON.parse(sessionStorage.getItem("companyList"));
    let [companyList, setCompanyList] = useState([]);
    // -------button dropdown --------
    let [anchorEl, setAnchorEl] = useState(null);
    let [anchorElCamp, setAnchorElCamp] = useState(null);
    let [arrowIcon, setArrowIcon] = useState(false);
    let [arrowIconCamp, setArrowIconCamp] = useState(false);
    let open = Boolean(anchorEl);
    let openCamp = Boolean(anchorElCamp);

    let handleClick = (event, type) => {
        if (type == "company") {
            setAnchorElCamp(event.currentTarget);
            setAnchorEl(null);
            setArrowIconCamp(true);
            setArrowIcon(false);
        } else {
            setAnchorEl(event.currentTarget);
            setAnchorElCamp(null);
            setArrowIcon(true);
            setArrowIconCamp(false);
        }
    };

    let handleClose = (type) => {
        if (type == "logOut") {
            setAnchorEl(null);
            setArrowIcon(false);
        } else {
            setAnchorElCamp(null);
            setArrowIconCamp(false);
        }
    };

    let commonFunctionBtn = (type) => {
        if (type == "logOut") {
            return CandidateLogout();
        } else {
            navigate("/editProfile")
        }
    }

    let CandidateLogout = () => {
        setLoading(true);

        const json = {
            "json": {
                "request": {
                    "servicetype": "21",
                    "functiontype": "1005",
                    "session": {
                        "sessionid": getCookie('sessionid') || sessionStorage.getItem("sessionid")
                    }
                }
            }
        }
        let CandidateApiUrl = config.baseUrl + '/JobzTop/Profile?request=' + encodeURIComponent(JSON.stringify(json))

        axios.post(CandidateApiUrl).then((result) => {
            setLoading(false);
            let temp = result.data.json.response;
            if (temp.statuscode = "0") {
                sessionStorage.clear();
                localStorage.clear();
                eraseCookie('sessionid');
                eraseCookie('companyName');
                eraseCookie('userName');
                eraseCookie('Cid');
                eraseCookie('companyList');
                navigate('/');
            }
        }).catch((err) => {
            setLoading(false);
            console.log(err);
        })
    }

    let getDataByCompany = (list) => {
        sessionStorage.setItem("companyName", list.companyName);
        sessionStorage.setItem("userName", list.candidateName);
        sessionStorage.setItem("Cid", list.cId);
        sessionStorage.setItem("timeZone", list.timeZone);
        setCookie('companyName', list.companyName, 730);
        setCookie('userName', list.candidateName, 730);
        setCookie('Cid', list.cId, 730);
        setAnchorElCamp(null);
        setArrowIconCamp(false);
        props.switchCompanies(list.cId);
    }

    return (
        <>
            {loading && (
                <div className="loader">
                    <img src={LoadingIcon} alt="loader" />
                </div>
            )}
            <div className="candidate_profile">
                <div className="candidate_profile_navbar">
                    <Navbar expand="lg" className="candidate_profile_content">
                        <Navbar.Brand style={{ width: "24%" }}>
                            <div className="candidate_profile_logo" >
                                <img src={Logo} alt="the.shortlistd"></img>
                            </div>
                        </Navbar.Brand>
                        {
                            !props.dashNavTypes && !props.testNavTypes ?
                                <>
                                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                    <Navbar.Collapse id="basic-navbar-nav">
                                        <Nav className="me-auto">
                                            {/* {!props.canditionalValue && */}
                                            <div className="navbarToggle">
                                                <ul style={{ display: "flex" }}>
                                                    <li activeclassname='active'>
                                                        <NavLink to={"/candidateprofile"}>
                                                            <img src={material_dashboard} alt="search"
                                                                style={{ marginRight: "10px" }} />
                                                            OVERVIEW
                                                        </NavLink >
                                                    </li>
                                                    <li activeclassname='active'>
                                                        <NavLink to={"/myActivity"}>
                                                            <img src={awesome_briefcase} alt="my activit"
                                                                style={{ marginRight: "10px" }} />
                                                            MY ACTIVITY
                                                        </NavLink >
                                                    </li>
                                                    {/* <li activeclassname='active'>
                                                        <NavLink to={"/findJob"}>
                                                            <img src={feather_search} alt="search"
                                                                style={{ marginRight: "10px" }} />
                                                            FIND JOB
                                                        </NavLink >
                                                    </li> */}
                                                </ul>
                                            </div>
                                            <div className="candidate_profile_profile"
                                            // id={props.canditionalValue == true ? "candidate_profile" : null}
                                            >
                                                {/* <img src={notification} alt="User " /> */}
                                                {/* ---------------- Company drop down ----------------- */}
                                                <div className="company_name" style={{ marginRight: "14px" }}>
                                                    <span
                                                        id="basic-button"
                                                        aria-controls={openCamp ? 'basic-menu' : undefined}
                                                        aria-haspopup="true"
                                                        aria-expanded={openCamp ? 'true' : undefined}
                                                        onClick={(e) => handleClick(e, "company")}
                                                        variant="danger"
                                                        style={{
                                                            cursor: "pointer", border: "1px solid #cfcfcf",
                                                            borderRadius: "4px",
                                                            padding: "1% 2% 1% 2%"
                                                        }}
                                                    >
                                                        {getCookie('companyName') || companyName}{arrowIconCamp == true ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                    </span>
                                                    <Menu
                                                        id="basic-menu"
                                                        anchorEl={anchorElCamp}
                                                        open={openCamp}
                                                        onClose={() => handleClose("company")}
                                                        MenuListProps={{
                                                            'aria-labelledby': 'basic-button',
                                                        }}
                                                        style={{ marginTop: "26px" }}
                                                    >
                                                        {
                                                            getCompanyList?.map((list, index) => (
                                                                <MenuItem key={index} onClick={() => getDataByCompany(list)}>{list.companyName}</MenuItem>
                                                            ))
                                                        }
                                                    </Menu>
                                                </div>

                                                {/* ---------------- user drop down ----------------- */}
                                                {sessionStorage.getItem("photoIdCandidate") <= 0 ?
                                                    <img src={userAvatar} alt="User " />
                                                    :
                                                    <img src={config.showPhoto + sessionStorage.getItem("photoIdCandidate")} alt="User " onError={(e) => e.target.src = userAvatar} />
                                                }
                                                <div className="buttonMenu">
                                                    <span
                                                        id="basic-button"
                                                        aria-controls={open ? 'basic-menu' : undefined}
                                                        aria-haspopup="true"
                                                        aria-expanded={open ? 'true' : undefined}
                                                        onClick={(e) => handleClick(e, "logOut")}
                                                        variant="danger"
                                                        style={{
                                                            cursor: "pointer",
                                                            margin: "0px 0px 0px 10px",
                                                            fontWeight: "500",
                                                            fontSize: "12px"
                                                        }}
                                                    >
                                                        {sessionStorage.getItem("userName")}{arrowIcon == true ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                    </span>
                                                    <Menu
                                                        id="basic-menu"
                                                        anchorEl={anchorEl}
                                                        open={open}
                                                        onClose={() => handleClose("logOut")}
                                                        MenuListProps={{
                                                            'aria-labelledby': 'basic-button',
                                                        }}
                                                        style={{ marginTop: "26px" }}
                                                    >
                                                        <MenuItem onClick={() => commonFunctionBtn("profile")}>Profile</MenuItem>
                                                        <MenuItem onClick={() => commonFunctionBtn("logOut")}>Logout</MenuItem>
                                                    </Menu>
                                                </div>

                                            </div>
                                        </Nav>
                                    </Navbar.Collapse>
                                </>
                                :
                                <div className="company_name">
                                    <label>{companyName}</label>
                                </div>
                        }
                    </Navbar>



                </div >
            </div>
        </>
    );
}

export default CandidateNav;

