import { Outlet, Navigate } from "react-router-dom";
import config from "../Shared/Config/config.json";

export const PrivateRoutes = () => {
  return sessionStorage.getItem("sessionid") ? <Outlet /> : <Navigate to="/" />;
};

export const SubRoute = () => {
  const getSearchUrl = window.location;
  const dynamicDomain = getSearchUrl.host.split(".");
  const domain = config.domain;
  console.log(dynamicDomain[0]);

  if (dynamicDomain[0] === domain) {
    return <Outlet />;
  } else {
    return <Navigate to="/" />;
  }
};
