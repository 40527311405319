import React from "react";
import error_image from "../../../../assets/img/candidate/candidate_404.jpg"
import Modal from 'react-bootstrap/Modal';
import filled_crossImage from "../../../../assets/Home-images/filled_crossImage.jpg"
import './error.css'
import { useNavigate } from "react-router-dom";
import Success from "../../../../assets/icons/success.svg";

// ----------------------------------> Not Found <--------------------------------
export function Candidate_Error() {
    const navigate = useNavigate();

    let navigateToLogin = () => {
        // navigate("/")
        navigate(-1);
    }

    return (
        <>
            <div className="main_error_container">
                <div className="image">
                    <img src={error_image} alt="Error" />
                    <div>
                        <button className="btn" onClick={() => navigateToLogin()}><span>Go Back</span></button>
                    </div>
                </div>
            </div>
        </>
    );
};

// ----------------------------------> Error and Success Popup <--------------------------------
export function BackEndErrorMsg(props) {

    let handleCloseErrorPopup = () => {
        props.setShowErrorPopup(false);
        props.setShowSuccessPopup(false);
        props.setShowErrorPopup(false);
        props.setShowErrMessage("");
    }

    return (
        <>
            <Modal
                className="login_error_popup"
                show={props.showErrorPopup || props.showSuccessPopup}
                // backdrop="static"
                // dialogClassName="login-error-modal"
                onHide={handleCloseErrorPopup}
            >
                <Modal.Body className={props.showErrorPopup ? "borderLeft_red" : "borderLeft_green"}>

                    <div className="login_error_popup" style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}>
                        <div className="Icons" style={{
                            display: "flex",
                            alignItems: 'center'
                        }}>
                            {
                                props.showErrorPopup == true ?
                                    <>
                                        <img src={filled_crossImage} alt="Error" style={{ width: "8%" }} />
                                        <div style={{ fontSize: "18px", font: "bold", color: "#FF0000", fontWeight: "600" }}>Oops....</div>
                                    </>
                                    :
                                    <img src={Success} alt="success" />
                            }
                            <p style={{ fontSize: "14px", fontWeight: "500", margin: "0", color: "black", marginLeft: "14px" }}>{props.message}</p>
                        </div>
                        <div className="btn">
                            <button
                                style={{ border: "0px", fontSize: "16px", backgroundColor: "white", color: "#FF0000", fontWeight: "700" }}
                                type="submit"
                                onClick={handleCloseErrorPopup}>
                                X
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}