import { Button, CircularProgress, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import './Details.css';
import Select from 'react-select';
import Modal from 'react-bootstrap/Modal';
import { MuiOtpInput } from "mui-one-time-password-input";
import axios from "axios";
import config from "../../../Shared/Config/config.json";
import { ErrorModal, JobDetails, SuccessModal } from "../../../Shared/Modals/Modals";
import filled_crossImage from "../../../assets/Home-images/filled_crossImage.jpg"
import add_circle from "../../../assets/img/candidate/Icon material-add-circle-outline.svg"
import loaderImage from "../../../assets/loader/loader.gif"
import Success_1 from "../../../assets/icons/success.svg";
import MyActivity from "../../Candidate/MyActivity/myActivity";
import { baseUrlCandidate, shortlistd_APIs } from "../../../Shared/Config/config";
import { setCookie } from "../../../utiles/Cookies";
// "baseUrl_candidate": "http://52.221.14.247:8686/shortlistdcandidate",


function Details(props) {
    let navigate = useNavigate();
    let [changeUiType, setChangeUiType] = useState("mobile");
    let [phoneNumber, setPhoneNumber] = useState("");
    let [dialCode, setDialCode] = useState();
    let [countryCode, setCountryCode] = useState("in");
    let [userOtp, setUserOtp] = useState();
    let [convertToInt, setConvertToInt] = useState();
    let [candidateData, setCandidateData] = useState();
    let getSessionId = sessionStorage.getItem("sessionid");
    let [loader, setLoader] = useState(false);
    let [resumeLoader, setResumeLoader] = useState(false);
    // ---------save candidate profile ----------
    let [firstName, setFirstName] = useState();
    let [lastName, setLastName] = useState();
    let [emailId, setEmailId] = useState();
    let [validEmail, setValidEmail] = useState();
    let [skills, setSkills] = useState([]);
    let [selectedSkill, setSelectedSkill] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    let [specialization, setSpecialization] = useState([]);
    let [specializationSelected, setSpecializationSelected] = useState();
    let [multiSkills, setMultiSkills] = useState([{
        skillId: "",
        skillName: "",
        skillLevel: "",
        skillExperience: "",
        skillExpMonth: "",
        skillExpValue: ""
    }]);
    // ------- skill levels --------
    let skillLevel = [
        "Basic",
        "Intermediate",
        "Advanced"
    ];
    let timezoneList = ["Asia/Kolkata", "America/New_York"]
    // ---------- skill experience ----------
    // let skillExp = [
    //     "Fresher(0-1yr)",
    //     "Range",
    //     "Intermediate(1-3yrs)",
    //     "Well Experienced(>7yrs)",
    //     "Experience(3-7yrs)"
    // ];
    let [selectedSkillExp, setSelectedSkillExp] = useState();
    let [selectedSkillLevel, setSelectedSkillLevel] = useState();
    let [location, setLocation] = useState([]);
    let [locationSelected, setLocationSelected] = useState("");
    let [preferadLocationSelected, setPreferadLocationSelected] = useState("");
    let [experienceMonth, setExperienceMonth] = useState();
    let [experienceYear, setExperienceYear] = useState();
    let [noticePeriod, setNoticePeriod] = useState(null);
    let [qualification, setQualification] = useState([]);
    let [qualificationSelected, setQualificationSelected] = useState();
    let [skillExp, setSkillExp] = useState();
    let [expYear, setExpYear] = useState();
    // ---------save candidate profile ----------
    // --------- modals ----------
    let [showErrorModal, setShowErrorModal] = useState(false);
    let [showSuccessModal, setShowSuccessModal] = useState(false);
    let [modalMsg, setModalMsg] = useState();
    let [getOtpDisable, setGetOtpDisable] = useState(true);
    // --------- modals ----------
    let [jobView, setJobView] = useState();
    let [loading, setLoading] = useState(false);
    let [showJobModal, setShowJobModal] = useState(false);
    let [selectedTimezone, setSelectedTimezone] = useState();

    let handleOnChange = (phone, mobileData) => {
        let cd = mobileData.dialCode.length;
        let number = phone.slice(cd);
        setPhoneNumber(number);
        setDialCode(mobileData.dialCode);
        setCountryCode(mobileData.countryCode);
        setShowErrorModal(false);

        // if (number >= 1000000000 ) {
        //     setGetOtpDisable(false)
        // } else if (mobileData.dialCode != 91) {
        //     setGetOtpDisable(false)
        // } else {
        //     setGetOtpDisable(true)
        // }
        // console.log(mobileData.dialCode);

    }
    // 1000000000
    // Enforce a maximum length of 10 for the phone number
    // if (value.length <= 10) {
    //   setPhoneNumber(value);
    // }



    // const handleChangeOtp = (newValue) => {
    //     setConvertToInt(Number(newValue));
    //     setUserOtp(newValue);
    //     setShowErrorModal(false);
    // }

    const handleChangeOtp = (otp) => {

        if (numberRegex.test(otp)) {
            // Set the OTP value if it consists only of numbers
            // setUserOtp(otp);
            setConvertToInt(otp);
            setUserOtp(otp);
            setShowErrorModal(false);
        }
    }

    let onChangeUiType = (type) => {
        if (type === "mobile") {
            setChangeUiType("mobile");
        } else if (type === "otp") {
            setChangeUiType("otp");
        } else {
            setChangeUiType("candidateDetails");
        }
    }

    let closeDetailsModal = () => {
        props.setShowDetailsModal(false);
        setChangeUiType("mobile");
        setPhoneNumber("");
        setDialCode("");
        setShowErrorModal(false);
        setGetOtpDisable(true)
        setUserOtp();
        setLocationSelected("");
        setPreferadLocationSelected("");
        setQualificationSelected("");
        setExperienceMonth("");
        setNoticePeriod("");
        setMultiSkills([{
            skillId: "",
            skillLevel: "",
            skillExperience: "",
            skillExpMonth: "",
            skillExpValue: ""
        }])
    }

    let getEmail = (e) => {
        setEmailId(e.target.value);
        setValidEmail('');
    }

    let regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    let validate = () => {
        let error = false;
        if (regex.test(emailId) == false) {
            setValidEmail("Invalid email Id");
            error = true
            return error
        }
    }

    const handleKeyDown = (e) => {
        // Check if the pressed key is Enter (key code 13)
        if (e.key === 'Enter') {
            getOtp("getOtp");
        }
    };

    const handleKeyDown_2 = (e) => {
        // Check if the pressed key is Enter (key code 13)
        if (e.key === 'Enter') {
            verifyOtp("verifyOtp")
        }
    };

    // ----------- skill functions -----------

    const addMoreSkills = () => {
        setMultiSkills([...multiSkills, {
            skillId: "",
            skillLevel: "",
            skillExperience: "",
            skillExpMonth: "",
            skillExpValue: ""
        }]);
        setSkillExp("");
    }

    const removeAddedSkills = (index) => {
        let newFormValues = [...multiSkills];
        newFormValues.splice(index, 1);
        setMultiSkills(newFormValues);
    }

    let handleChange = (i, e) => {
        let newFormValues = [...multiSkills];
        let temp = newFormValues[i].skillExpMonth;
        if (e.target.name == "skillExperience") {
            if (e.target.value === "" || (parseInt(e.target.value) >= 0 && parseInt(e.target.value) <= 12)) {
                newFormValues[i].skillExpValue = e.target.value;
                setSkillExp(e.target.value);
            }
            let totalSkillExp = (temp * 12) + parseInt(e.target.value);
            newFormValues[i][e.target.name] = totalSkillExp;
        } else if (e.target.name == "skillExpMonth") {
            if (e.target.value === '' || (parseInt(e.target.value) >= 0 && parseInt(e.target.value) <= 99)) {
                setExpYear(e.target.value);
                newFormValues[i][e.target.name] = e.target.value;
            }
        } else {
            newFormValues[i][e.target.name] = e.target.value;
        }
        setMultiSkills(newFormValues);
    };
    // ----------- skill functions -----------

    let onChange_location = (location_data) => {
        setLocationSelected({
            value: location_data.value,
            label: location_data.label
        });
    }

    let onChangeLocation = (e) => {
        masterDataLocation(e);
    }

    let onChangePreferadLocation = (val) => {
        setPreferadLocationSelected(val);
    }

    const onChangeExperience_month = (e) => {
        // setExperienceMonth(e.target.value);
        const value = e.target.value;
        if (value === '' || (parseInt(value) >= 0 && parseInt(value) <= 12)) {
            setExperienceMonth(value);
        }
    }

    const onChangeExperience_year = (e) => {
        const value = e.target.value;
        if (value === '' || (parseInt(value) >= 0 && parseInt(value) <= 99)) {
            setExperienceYear(value);
        }
    }

    const onChangeNoticePeriod = (e) => {
        const value = e.target.value;
        if (value === '' || (parseInt(value) >= 0 && parseInt(value) <= 999)) {
            setNoticePeriod(value);
        }
    }

    const onChangeQualification = (e) => {
        setSpecialization(e.value)
        // setQualificationSelected({
        //     value: e.value,
        //     label: e.label
        // });
        console.log(e.value, "123")
    }

    const onChangeSpeci = (e) => {
        setSpecializationSelected({
            value: e.value,
        })
        console.log(e.value,"ID")
    }

    const jobViewModal = () => {
        setShowJobModal(!showJobModal);
    }

    let onChangeTimezone = (e) => {
        setSelectedTimezone({
            value: e.value,
            label: e.label
        });
    }

    const getOtp = async (type) => {
        setLoader(true);
        // window.open(`http://192.168.86.12:4000/?param_1=candidate&param_2=isdnfsf`);

        let payLoad = {
            "mobileNumber": "+" + dialCode + "." + phoneNumber,
            "jobId": props.getJobId?.jobId
        }

        let apiUrl = config.baseUrl_candidate + "/Login/gotp"
        await axios.post(apiUrl, payLoad).then((result) => {
            let res = result.data;
            setChangeUiType("otp");
            setLoader(false);
            setUserOtp();
            if (type == "resendOtp") {
                setShowSuccessModal(true);
                setShowErrorModal(true);
                setModalMsg(res.data.message);
            }
        }).catch((err) => {
            if (err) {
                setLoader(false);
                if (err.response.status >= 400 && err.response.status < 500) {
                    setShowSuccessModal(false);
                    setShowErrorModal(true);
                    setModalMsg(err.response.data.error.message);
                } else {
                    navigate("./error");
                }
            } else {
                console.error("Network or unexpected error:", err);
                setShowErrorModal(true);
                setModalMsg("An unexpected error occurred. Please try again later.");
            }
        })
    };

    const verifyOtp = async () => {
        setShowSuccessModal(false);
        setLoader(true);

        let payLoad = {
            "mobileNumber": "+" + dialCode + "." + phoneNumber,
            "otp": convertToInt,
            "jobId": props.getJobId?.jobId
        }

        let apiUrl = config.baseUrl_candidate + "/Login/votp";
        await axios.post(apiUrl, payLoad).then((result) => {
            setLoader(false);
            let res = result.data.data;
            setCandidateData(res);
            let setSessionId = res.session
            getSessionId = res.session
            sessionStorage.setItem("sessionid", setSessionId);
            sessionStorage.setItem("msg", res.message);
            setCookie('sessionid', setSessionId, 730);
            if (res.showDetailsPage) {
                masterDataSkill();
                masterDataLocation();
                getQualificationList();
                setChangeUiType("candidateDetails");
            } else {
                // if (res.candCompDetails !== null) {
                //     sessionStorage.setItem("companyList", JSON.stringify(res.candCompDetails.companySelected));
                // }
                // navigate("/myActivity");
                companyListApi();
                props.setShowDetailsModal(false);
                setChangeUiType("mobile");
                setUserOtp();
            }
        }).catch((err) => {
            if (err) {
                setLoader(false);
                if (err.response.status >= 400 && err.response.status < 500) {
                    setShowErrorModal(true);
                    setModalMsg(err.response.data.error.message);
                } else {
                    navigate("./error");
                }
            }
        })
    }

    const saveCandidateProfile = async () => {
        setLoader(true);

        const tableContainer = document.getElementsByClassName('fillIn_details')[0]
        if (tableContainer) {
            // Scroll the element to the top
            tableContainer.scrollTop = 0;
        }

        if (validate()) {
            return
        }

        let tempPref_Location = []
        if (preferadLocationSelected.length > 0) {
            for (let i = 0; i < preferadLocationSelected.length; i++) {
                tempPref_Location.push(preferadLocationSelected[i].value)
            }
        }

        let totalExperience = (experienceYear * 12) + parseInt(experienceMonth)

        let updateMultiSkills = multiSkills.map(({ skillName, skillExpMonth, skillExpValue, ...rest }) => rest);
        console.log(updateMultiSkills)

        let payLoad = {
            "candidateId": candidateData.candidateId,
            "firstName": firstName,
            "lastName": lastName,
            "emailId": emailId,
            "mobileNumber": "+" + dialCode + "." + phoneNumber,
            "currentLocation": locationSelected.value,
            "experience": totalExperience,
            "skills": updateMultiSkills,
            "jobId": props.getJobId?.jobId,
            "noticePeriod": noticePeriod == null ? 0 : parseInt(noticePeriod),
            // "qualification": qualificationSelected.value,
            "qualification": specializationSelected.value,
            "preferredLocation": tempPref_Location,
            "timeZone": selectedTimezone.value
        }

        let apiUrl = config.baseUrl_candidate + "/Profile/scp";
        await axios.post(apiUrl, payLoad, {
            headers: {
                'X-Session': getSessionId,
                'content-Type': 'application/json',
                // 'Access-Control-Allow-Origin': '*'
            }
        }).then((result) => {
            setLoader(false);
            let res = result.data.data;
            sessionStorage.setItem("msg", res.message);
            // if (res.candCompDetails !== null) {
            //     sessionStorage.setItem("companyList", JSON.stringify(res.candCompDetails.companySelected));
            // }
            // navigate("/myActivity");
            companyListApi();
            props.setShowDetailsModal(false);
            setChangeUiType("mobile");
            setUserOtp();

            setMultiSkills([{
                skillId: "",
                skillLevel: "",
                skillExperience: "",
                skillExpMonth: "",
                skillExpValue: ""
            }])
        }).catch((err) => {
            if (err) {
                setLoader(false);
                if (err.response.status >= 400 && err.response.status < 500) {
                    setShowErrorModal(true);
                    setModalMsg(err.response.data.error.message);
                } else {
                    navigate("./error");
                }
            }
        })
    }

    const handleFileChange = async (e) => {
        setResumeLoader(true);

        let apiUrl = config.baseUrl_candidate + "/Profile/srl?" + "candidateId=" + candidateData.candidateId

        let bodyFormData = new FormData();
        bodyFormData.append('file', e.target.files[0]);

        const file = e.target.files[0];
        const allowedTypes = ['application/pdf', 'application/document', "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];

        if (file && allowedTypes.includes(file.type)) {
            await axios({
                method: "post",
                url: apiUrl,
                data: bodyFormData,
                headers: {
                    "Content-Type": "multipart/form-data",
                    'X-Session': getSessionId
                }
            }).then((result) => {
                setResumeLoader(false);
                setShowErrorModal(false);
                setModalMsg("");
                let res = result.data.data
            }).catch((err) => {
                if (err) {
                    setResumeLoader(false);
                    if (err.response.status >= 400 && err.response.status < 500) {
                        setShowErrorModal(true);
                        setModalMsg(err.response.data.error.message);
                    } else {
                        navigate("./error");
                    }
                }
            })
        }
        else {
            setResumeLoader(false);
            // setError('Please select a valid PDF or DOC/DOCX file.');
            setShowErrorModal(true);
            setModalMsg('Please select a valid PDF or DOC/DOCX file.');
        }


    }

    const masterDataSkill = async () => {
        setLoader(true);
        let searchJson = {
            "type": "skill"
        }

        let searchApiUrl = config.baseUrl_candidate + "/Profile/masterData";
        //let searchApiUrl = "http://192.168.1.27:8080/shortlistdcandidate/Profile/masterData";

        await axios.post(searchApiUrl, searchJson, {
            headers: {
                'X-Session': getSessionId,
                "Content-Type": "application/json"
            }
        })
            .then((result) => {
                setLoader(false);
                let temp = result.data.data.masterData
                // let tempData = [];
                // for (let i = 0; i < temp.length; i++) {
                //     tempData.push({
                //         value: temp[i].Id,
                //         label: temp[i].Title
                //     })
                // }
                setSkills(temp);
            }).catch((err) => {
                if (err) {
                    setLoader(false);
                    if (err.response.status >= 400 && err.response.status < 500) {
                        setShowErrorModal(true);
                        setModalMsg(err.response.data.error.message);
                    } else {
                        navigate("./error");
                    }
                }
            })
    }

    const masterDataLocation = async (e) => {
        setLoader(true);
        let searchJson = {
            "type": "location",
            "orderBy": "asc",
            "sortBy": "city",
            "city": e
        }

        let searchApiUrl = config.baseUrl_candidate + "/Profile/masterData";

        await axios.post(searchApiUrl, searchJson, {
            headers: {
                'X-Session': getSessionId,
                "Content-Type": "application/json"
            }
        })
            .then((result) => {
                setLoader(false);
                let temp = result.data.data.masterData
                setLocation(temp);
            }).catch((err) => {
                if (err) {
                    console.log("This os the error", err);
                    setLoader(false);
                    if (err.response.status >= 400 && err.response.status < 500) {
                        if (err.response.status !== 404) {
                            setShowErrorModal(true);
                            setModalMsg(err.response.data.error.message);
                        }
                    } else {
                        navigate("./error");
                    }
                }
            })
    }

    const getQualificationList = async () => {
        setLoader(true);
        let searchJson = {
            "type": "qualification",
            "version": 1,
            "qualification": "",
            "sortBy": "Qualification",
            "orderBy": "ASC"
        }
        // let apiUrl = config.baseUrl + "/JobzTop/Qualification?request=" + encodeURIComponent(JSON.stringify(payLoad));
        let apiUrl = config.baseUrl_candidate + "/Profile/masterData";

        await axios.post(apiUrl, searchJson, {
            headers: {
                'X-Session': sessionStorage.getItem("sessionid"),
                "Content-Type": "application/json"
            }
        })
            .then((res) => {
                setLoader(false);
                let result = res.data.data.masterData;
                setQualification(result);
                console.log(result, "kee")
            }).catch((err) => {
                if (err) {
                    setLoader(false);
                    if (err.response.status >= 400 && err.response.status < 500) {
                        setShowErrorModal(true);
                        setModalMsg(err.response.data.error.message);
                    } else {
                        navigate("./error");
                    }
                }
            })
    }

    // ----------------> Company-list API <----------------
    const companyListApi = async () => {
        setLoader(true);

        let apiUrl = config.baseUrl_candidate + "/Profile/getComapanyList";

        await axios.get(apiUrl, {
            headers: {
                'X-Session': sessionStorage.getItem("sessionid"),
            }
        })
            .then((result) => {
                setLoader(false);
                let res = result.data.data.companyList
                sessionStorage.setItem("companyName", res[0].companyName);
                sessionStorage.setItem("userName", res[0].candidateName);
                sessionStorage.setItem("Cid", res[0].cId);
                sessionStorage.setItem("companyList", JSON.stringify(result.data.data.companyList));
                sessionStorage.setItem("timeZone", res[0].timeZone);
                navigate("/candidateprofile");
                setCookie('companyName', res[0].companyName, 730);
                setCookie('userName', res[0].candidateName, 730);
                setCookie('Cid', res[0].cId, 730);
                setCookie('companyList', JSON.stringify(result.data.data.companyList), 730);
            }).catch((err) => {
                if (err) {
                    setLoader(false);
                }
            })
    }

    // let doNotRenderTwiceRfi = false;
    // useEffect(() => {
    //     if (!doNotRenderTwiceRfi) {
    //         doNotRenderTwiceRfi = true
    // masterDataLocation()
    //         saveCandidateProfile();
    //         // handleUploadResume()
    //     }
    // }, [])
    const numberRegex = /^[0-9]*$/;

    return (
        <>
            {loader && (
                <div className="loader">
                    <img src={loaderImage} alt="loader" />
                </div>
            )}
            {/* -----------------> ERROR MODAL <--------------- */}
            {/* <ErrorModal
                setShowErrorModal={setShowErrorModal}
                showErrorModal={showErrorModal}
                modalMsg={modalMsg} /> */}

            {/* -----------------> ERROR MODAL <--------------- */}
            {/* <SuccessModal /> */}

            {/* -----------------> JOB-DETAILS MODAL <--------------- */}
            {showJobModal && <JobDetails
                jobId={props.getJobId}
                showJobModal={showJobModal}
                setShowJobModal={setShowJobModal}
                jobViewModal={jobViewModal}
            />}

            {/* --------------------> MAIN DETAIL MODAL <------------------- */}
            <Modal show={props.showDetailsModal}
                // onHide={closeDetailsModal}
                className="detailJob_modal">
                {/* <button onClick={() => saveCandidateProfile()}>SCP</button>
                <button onClick={() => masterDataSkill()}>master</button> */}
                {
                    changeUiType === "mobile" ?
                        <Modal.Header>
                            <div className="mainErrorMsg">
                                {
                                    showErrorModal &&
                                    <div className="errorMsg">
                                        <>
                                            <img src={filled_crossImage} alt="cross mark" />
                                        </>
                                        <label>{modalMsg}</label>
                                    </div>
                                }
                                <div className="modal_title">
                                    <h5>Verify Mobile Number</h5>
                                    <button
                                        style={{ border: "0px", fontSize: "19px", backgroundColor: "white", color: "black", fontWeight: "700" }}
                                        type="submit"
                                        onClick={closeDetailsModal}>
                                        X
                                    </button>
                                </div>
                            </div>
                        </Modal.Header>
                        : changeUiType === "otp" ? (
                            <Modal.Header>
                                <div className="mainErrorMsg">
                                    {
                                        showErrorModal &&
                                        <div className={showSuccessModal ? "successMsg" : "errorMsg"}>
                                            {showSuccessModal == true ?
                                                <img src={Success_1} alt="right mark" />
                                                :
                                                <img src={filled_crossImage} alt="cross mark" />
                                            }
                                            <label>{modalMsg}</label>
                                        </div>
                                    }
                                    <div className="modal_title">
                                        <h5>Verify Mobile Number</h5>
                                        <button
                                            style={{ border: "0px", fontSize: "19px", backgroundColor: "white", color: "black", fontWeight: "700" }}
                                            type="submit"
                                            onClick={closeDetailsModal}>
                                            X
                                        </button>
                                    </div>
                                </div>
                            </Modal.Header>
                        ) : (changeUiType === "candidateDetails") ?
                            <Modal.Header>
                                <div className="mainErrorMsg">
                                    {
                                        showErrorModal &&
                                        <div className="errorMsg">
                                            <img src={filled_crossImage} alt="cross mark" />
                                            <label>{modalMsg}</label>
                                        </div>
                                    }
                                    <div className="modal_title">
                                        <div>
                                            <h5>Please fill in the details to apply for <span style={{ color: "#FE6E27" }}>{props.getJobId?.jobTitle}</span>
                                            </h5>
                                            <span style={{ color: "grey", fontWeight: "bold" }}>{props.getJobId?.companyName}</span>
                                        </div>
                                        <button
                                            style={{ border: "0px", fontSize: "19px", backgroundColor: "white", color: "black", fontWeight: "700" }}
                                            type="submit"
                                            onClick={closeDetailsModal}>
                                            X
                                        </button>
                                    </div>
                                </div>
                            </Modal.Header>
                            : null
                }
                <Modal.Body>
                    <div className="main_details_container" id={changeUiType === "candidateDetails" ? "main_details_container" : null}>
                        <div className="details_block">
                            {
                                changeUiType === "mobile" ?
                                    // --------------------- MOBILE NUMBER ---------------------
                                    <div className="mobile_block">
                                        <span className="heading">
                                            <label>Enter Mobile Number</label>
                                        </span>
                                        <div className="phone_btn">
                                            <div className="phone">
                                                <PhoneInput
                                                    country={countryCode}
                                                    // value={phoneNumber}
                                                    // onChange={handleOnChange}
                                                    onChange={(value, mobileData) =>
                                                        handleOnChange(value, mobileData)
                                                    }
                                                    placeholder="+91 88878-95245"
                                                    countryCodeEditable={false}
                                                    onKeyDown={handleKeyDown}
                                                />
                                            </div>
                                            <div className="btn_mobile">
                                                <Button onClick={() => getOtp("getOtp")} disabled={!phoneNumber}>GET OTP</Button>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    // --------------------- OTP ---------------------
                                    changeUiType === "otp" ? (
                                        <div className="mobile_block">
                                            <span className="heading">
                                                <label>Enter Mobile Number</label>
                                            </span>
                                            <div className="phone_btn">
                                                <div className="phone">
                                                    <PhoneInput
                                                        country={countryCode}
                                                        // value={phoneNumber}
                                                        // onChange={handleOnChange}
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                            <div className="inner_mobile_block">
                                                <label>Enter the OTP send to the mobile number</label>
                                                <div className="edit_num" style={{ marginTop: "-5px" }}>
                                                    <label>+{dialCode}-{phoneNumber}</label>
                                                    <Button onClick={() => onChangeUiType("mobile")}>Edit Number</Button>
                                                </div>
                                            </div>
                                            <div className="opt_btn">
                                                <div className="otp" style={{ width: "100%" }}>
                                                    {/* <TextField id="outlined-basic" label="Enter otp here" variant="outlined"
                                                        inputProps={{ autoComplete: "off" }} size="small" style={{ width: "100%" }} /> */}
                                                    <MuiOtpInput value={userOtp}
                                                        regex={numberRegex}
                                                        onKeyDown={handleKeyDown_2}
                                                        onChange={handleChangeOtp}
                                                        autoFocus
                                                        display="flex" length={6}
                                                    />
                                                </div>
                                                <div className="btn_mobile" style={{ textAlign: "center" }}>
                                                    <Button onClick={() => verifyOtp()}
                                                        style={{
                                                            width: "200px",
                                                            padding: "4%"
                                                        }} disabled={!userOtp}>VERIFY OTP</Button>
                                                    <p onClick={() => getOtp("resendOtp")}>Resend OTP</p>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (changeUiType == "candidateDetails") ? (
                                        // --------------------- FILL DETAILS ---------------------
                                        <div className="mobile_block fillIn_details">
                                            {/* <div className="inner_mobile_block">
                                                <label>Please fill in the details to apply for the job</label>
                                            </div> */}
                                            <div className="candidate_details">
                                                <div className="names">
                                                    <div className="firstName">
                                                        <label id="label">First Name *</label>
                                                        <TextField id="outlined-basic" label="Enter First Name" variant="outlined"
                                                            inputProps={{ autoComplete: "off" }} size="small" onChange={(e) => setFirstName(e.target.value)} />
                                                    </div>
                                                    <div className="lastName">
                                                        <label id="label">Last Name *</label>
                                                        <TextField id="outlined-basic" label="Enter Last Name" variant="outlined"
                                                            inputProps={{ autoComplete: "off" }} size="small" onChange={(e) => setLastName(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="socialInfo">
                                                    <div className="email">
                                                        <label id="label">Email Id *</label>
                                                        <TextField id="outlined-basic" label="Enter email id" variant="outlined"
                                                            inputProps={{ autoComplete: "off" }} size="small" onChange={(e) => getEmail(e)}
                                                            helperText={validEmail} />
                                                    </div>
                                                    <div className="mobileNum">
                                                        <label id="label">Mobile Number *</label>
                                                        <div className="phone_btn">
                                                            <div className="phone" style={{ width: "100%" }}>
                                                                <PhoneInput
                                                                    country={countryCode}
                                                                    value={dialCode + phoneNumber}
                                                                    // onChange={handleOnChange}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="location_experience">
                                                    <div className="location">
                                                        <label id="label">Current Location *</label>
                                                        <Select
                                                            isMulti={false}
                                                            value={locationSelected}
                                                            onChange={(value) => onChange_location(value)}
                                                            placeholder={"Location"}
                                                            options={location?.map((item, index) => {
                                                                return { value: item?.Id, label: item?.City }
                                                            })}
                                                            onInputChange={(e) => onChangeLocation(e)}
                                                        />
                                                    </div>
                                                    <div className="prefered_location">
                                                        <label id="label">Preferred Location *</label>
                                                        <Select
                                                            isMulti
                                                            value={preferadLocationSelected}
                                                            onChange={(value) => onChangePreferadLocation(value)}
                                                            placeholder={"Select Location"}
                                                            options={location?.map((item, index) => {
                                                                return { value: item?.Id, label: item?.City }
                                                            })}
                                                            onInputChange={(e) => onChangeLocation(e)}
                                                        />
                                                    </div>

                                                </div>
                                                <div className={multiSkills.length < 4 ? "addMore_skill" : "addMore_skill_2"}>
                                                    {
                                                        multiSkills?.map((element, index) => (
                                                            <div className="skill" key={index}>
                                                                <div className="skillName">
                                                                    <label id="label">Skill *</label>
                                                                    <Select
                                                                        isMulti={false}
                                                                        value={element.skillId === "" ? { value: '', label: "Select skill" } : { value: element.skillId, label: element.skillName }}
                                                                        onChange={(values) => {
                                                                            setMultiSkills(prevSkills => prevSkills?.map((skill, i) => {
                                                                                if (i === index) {
                                                                                    return {
                                                                                        ...skill,
                                                                                        skillId: values.value,
                                                                                        skillName: values.label,
                                                                                    };
                                                                                }
                                                                                return skill;
                                                                            }));
                                                                        }
                                                                            // onChangeSkill(values, index)
                                                                        }
                                                                        placeholder={"Select Skills"}
                                                                        options={skills.map((item) => {
                                                                            return { value: item.Id, label: item.Title, }
                                                                        })}
                                                                    />
                                                                </div>
                                                                <div className="skillLevel">
                                                                    <label id="label">Skill Level *</label>
                                                                    {/* <Select
                                                                    name="skillLevel"
                                                                    isMulti={false}
                                                                    value={element.skillLevel}
                                                                    onChange={(e) => handleChange(index, e)}
                                                                    placeholder={"Select Skill level"}
                                                                    options={skillLevel?.map((item, index) => {
                                                                        return { value: item, label: item, name: "skillLevel" }
                                                                    })}
                                                                /> */}
                                                                    <select
                                                                        name="skillLevel"
                                                                        value={element.skillLevel}
                                                                        onChange={(e) => handleChange(index, e)}
                                                                    >
                                                                        <option value="">Select Skill Level</option>
                                                                        <option>Basic</option>
                                                                        <option>Intermediate</option>
                                                                        <option>Advanced</option>
                                                                    </select>
                                                                </div>
                                                                <div className="skillExperience">
                                                                    <label id="label">Skill Experience *</label>
                                                                    {/* <Select
                                                                    // isMulti
                                                                    value={element.skillLevelExp || 0}
                                                                    onChange={(e) => handleChange(index, e)}
                                                                    placeholder={"Select Skill experience"}
                                                                    options={skillExp.map((item) => {
                                                                        return { value: item, label: item }
                                                                    })}
                                                                /> */}
                                                                    {/* <input
                                                                        type="number"
                                                                        name="skillExperience"
                                                                        value={element.skillExperience}
                                                                        onChange={(e) => handleChange(index, e)}
                                                                        placeholder="Exp in months"
                                                                        min={0}
                                                                    /> */}
                                                                    <div className="skillExp">
                                                                        <TextField id="outlined-basic" size="small" name="skillExpMonth" type="number" value={element?.skillExpMonth} style={{ width: "80%" }}
                                                                            inputProps={{ autoComplete: "off", min: 0, max: 999 }} onChange={(e) => handleChange(index, e)}
                                                                            placeholder="Years" />
                                                                        <TextField id="outlined-basic" size="small" name="skillExperience" type="number" value={element.skillExpValue} style={{ width: "80%", marginLeft: "20px" }}
                                                                            inputProps={{ autoComplete: "off", min: 0, max: 999 }} onChange={(e) => handleChange(index, e)}
                                                                            placeholder="months" />
                                                                    </div>
                                                                </div>
                                                                {multiSkills.length !== 1 ? (
                                                                    <div className="remove_skill" onClick={() => removeAddedSkills(index)}>
                                                                        X
                                                                    </div>
                                                                ) : null
                                                                }
                                                            </div>
                                                        ))
                                                    }
                                                    <div className="addMore_skills" onClick={() => addMoreSkills()}>
                                                        <img src={add_circle} alt="addMore" />
                                                        Add More
                                                    </div>
                                                </div>
                                                <div className="qualification_noticePeriod">
                                                    <div className="qualification">
                                                        <label id="label">Highest Qualification *</label>
                                                        <Select
                                                            isMulti={false}
                                                            // value={qualificationSelected}
                                                            onChange={(value) => onChangeQualification(value)}
                                                            placeholder={"Select Qualification"}
                                                            options={qualification?.map((item, index) => {
                                                                return { value: item.specializations, label: item.qualification }
                                                            })}
                                                        />
                                                    </div>
                                                    <div className="qualification">
                                                        <label id="label">Specialization*</label>
                                                        <Select
                                                            isMulti={false}
                                                            // value={specializationSelected}
                                                            placeholder={"Select Specialization"}
                                                            onChange={(value) => onChangeSpeci(value)}
                                                            options={specialization.map((item) => {
                                                                return {
                                                                    value: item.id,
                                                                    label: item.specialization === "null" ? "NOT APPLICABLE" : item.specialization
                                                                };
                                                            })}
                                                        />
                                                    </div>
                                                </div>
                                                {/* <div className="CTC">
                                                    <div className="currentCTC">
                                                        <label id="label">Current CTC</label>
                                                        <TextField id="outlined-basic" size="small" type="number" value={noticePeriod} style={{ width: "100%" }}
                                                            inputProps={{ autoComplete: "off", min: 0, max: 999 }}
                                                            label="Enter Current CTC" />
                                                    </div>
                                                    <div className="expectedCTC">
                                                        <label id="label">Expected CTC</label>
                                                        <TextField id="outlined-basic" size="small" type="number" value={noticePeriod} style={{ width: "100%" }}
                                                            inputProps={{ autoComplete: "off", min: 0, max: 999 }}
                                                            label="Enter Expected CTC" />
                                                    </div>
                                                </div> */}
                                                <div style={{
                                                    display: "flex",
                                                    justifyContent: "space-between"
                                                }}>
                                                    <div className="noticePeriod">
                                                        <label id="label">Notice Period(in days)</label>
                                                        <div className="noticePeriod_inner">
                                                            <TextField id="outlined-basic" size="small" type="number" value={noticePeriod} style={{ width: "100%" }}
                                                                inputProps={{ autoComplete: "off", min: 0, max: 999 }} onChange={(e) => onChangeNoticePeriod(e)}
                                                                placeholder="enter notice period" />
                                                        </div>
                                                    </div>
                                                    <div className="experience" style={{ width: "46%" }}>
                                                        <label id="label" style={{ marginTop: "16px" }}>Experience *</label>
                                                        <div className="Experience_inner">
                                                            <div className="year">
                                                                <TextField id="outlined-basic" size="small" type="number" value={experienceYear}
                                                                    inputProps={{ autoComplete: "off", min: 0, max: 999 }} onChange={(e) => onChangeExperience_year(e)} />
                                                                <div className="exp_label">years</div>
                                                            </div>
                                                            <div className="month">
                                                                <TextField id="outlined-basic" size="small" type="number" value={experienceMonth}
                                                                    inputProps={{ autoComplete: "off", min: 0, max: 999 }} onChange={(e) => onChangeExperience_month(e)} />
                                                                <div className="exp_label">months</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="noticePeriod">
                                                        <label id="label">Time zone*</label>
                                                        <div className="noticePeriod_inner">
                                                            <Select
                                                                isMulti={false}
                                                                // value={selectedTimezone.value}
                                                                onChange={(e) => onChangeTimezone(e)}
                                                                placeholder={"Select Time zone"}
                                                                options={timezoneList?.map((item, index) => {
                                                                    return { value: item, label: item }
                                                                })}
                                                            />
                                                        </div>
                                                    </div> */}
                                                </div>
                                                <div style={{
                                                    display: "flex",
                                                    justifyContent: "space-between"
                                                }}>
                                                    <div className="noticePeriod">
                                                        <label id="label">Time zone*</label>
                                                        <div className="noticePeriod_inner">
                                                            <Select
                                                                isMulti={false}
                                                                // value={selectedTimezone.value}
                                                                onChange={(e) => onChangeTimezone(e)}
                                                                placeholder={"Select Time zone"}
                                                                options={timezoneList?.map((item, index) => {
                                                                    return { value: item, label: item }
                                                                })}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="upload_resume">
                                                        <label id="label">Upload Resume</label>
                                                        <div className="uploadResume_block">
                                                            {/* <TextField id="outlined-basic" size="small"
                                                            inputProps={{ autoComplete: "off" }}
                                                            type="file" onChange={(e) => handleFileChange(e)} className="hidden-file-input"
                                                            helperText={"Supported file formats are .doc, .docx and .pdf"} style={{ fontSize: "12px" }} 
                                                            /> */}
                                                            <div id="outlined-basic" size="small" style={{ border: "1px solid #cecdcd", padding: "4px", borderRadius: "4px", width: "310px" }}>
                                                                <input type="file" onChange={handleFileChange} accept=".pdf,.doc,.docx" />
                                                            </div>

                                                            {/* <Button onClick={() => handleUploadResume()}>
                                                            Upload
                                                        </Button> */}
                                                            {
                                                                resumeLoader &&
                                                                <div style={{ marginLeft: "10px" }}>
                                                                    <CircularProgress />
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="btn_mobile" style={{ textAlign: "center", marginTop: "18px" }}>
                                                <Button
                                                    style={{
                                                        marginTop: "20px",
                                                        marginBottom: "10px",
                                                        width: "174px",
                                                        padding: "2%"
                                                    }} onClick={() => saveCandidateProfile()}
                                                    disabled={!firstName || !lastName || !emailId || !locationSelected || !experienceMonth || !experienceYear ||
                                                        !selectedSkill || !skillExp || !expYear || !selectedTimezone}
                                                >APPLY</Button>
                                            </div>
                                        </div>
                                    ) : null
                            }
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Details;