import axios from "axios";
import React, { useEffect, useState, version } from "react";
import deleteIcon from "../../../../assets/img/candidate/Icon feather-delete.svg"
import editIcon from "../../../../assets/img/candidate/Icon feather-edit-3 (1).svg"
import AddmoreIcon from "../../../../assets/img/candidate/Icon feather-plus-circle.svg"
import diskette from "../../../../assets/img/candidate/diskette.png"
import "./editEducation.css";
import config from "../../../../Shared/Config/config.json";
import Select from 'react-select';
import MatSelect from '@mui/material/Select';
import { Button, FormControl, InputLabel } from "@mui/material";
import LoadingIcon from "../../../../assets/loader/loader.gif";
import { BackEndErrorMsg } from "../../Shared/Error/error";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import Modal from 'react-bootstrap/Modal';
import noDataFound from "../../../../assets/Home-images/nodatafound.png";
import MenuItem from '@mui/material/MenuItem';

function EditEducation(props) {

    let cId = props.cid;
    let [loading, setLoading] = useState(false);
    let [university, setUniversity] = useState([]);
    let [qualification, setQualification] = useState([]);
    let [specialization, setSpecialization] = useState([]);
    const [showSpecialization, setShowSpecialization] = useState(true);
    let [showErrMessage, setShowErrMessage] = useState();
    let [showErrorPopup, setShowErrorPopup] = useState(false);
    let [showSuccessPopup, setShowSuccessPopup] = useState(false);
    let [disableBtn, setDisableBtn] = useState(false);
    let [disableSaveBtn, setDisableSaveBtn] = useState(false);
    let [addMoreEducModal, setAddMoreEducModal] = useState(false);
    let [selectedPassout, setSelectedPassout] = useState();
    let [gradeSystem, setGradeSystem] = useState("");
    let [cgpaValue_1, setCgpaValue_1] = useState("");
    let [cgpaValue_2, setCgpaValue_2] = useState("");
    let [percentageVal, setPercentageVal] = useState("");
    let [editDisablebtn, setEditDisablebtn] = useState(false);
    let maxLength = 3;
    let maxLengthPer = 5;

    let onChangedEdu;
    let onChangedUni;
    let previousEduc;
    let previousEduc_3;

    let doNotRenderTwiceRfi = false;
    useEffect(() => {
        if (!doNotRenderTwiceRfi) {
            doNotRenderTwiceRfi = true
            // let tempSpec = props.multiEducation.map((itm) => (
            //     masterDataSpecialization(itm.qualification)
            // ));
            props.getProfileCanData(cId);
        }
    }, []);

    // -----------------> old desing function <-----------------
    // let onChangeEduc = (val, changed_val, index) => {
    //     // let newFormValues = [...props.multiEducation];
    //     // let changedEduc = val;
    //     // let previousEduc = newFormValues[index];
    //     // previousEduc.disable = true;
    //     // newFormValues[index] = previousEduc;
    //     // newFormValues.push(changedEduc);
    //     // props.setMultiEducation(newFormValues);
    //     let newFormValues = [...props.multiEducation];
    //     if (val.disable == undefined) {
    //         val.disable = true;
    //         newFormValues[index] = val;
    //         changed_val.disable = false;
    //         newFormValues.splice(index, 0, changed_val);
    //     } else {
    //         newFormValues[index] = changed_val
    //     }
    //     props.setMultiEducation(newFormValues);
    //     // masterDataSpecialization(changed_val.qualification);
    //     setDisableBtn(true);
    //     if (newFormValues[index].qualificationId == "") {
    //         setDisableSaveBtn(false);
    //     } else {
    //         setDisableSaveBtn(true);
    //     }
    // }

    // -----------------> new desing function <-----------------
    let onChangeEduc = (previousEduc, type, ind, value) => {
        let newFormValues = [...props.multiEducation];
        console.log(newFormValues, "fsdf")
        let newFormValues_2 = [...props.multiEducation_2];

        if (type === "newEdu") {
            newFormValues[newFormValues.length - 1].qualification = value.label;
            newFormValues[newFormValues.length - 1].qualificationId = value.value;
            // for (let i = 0; i < qualification.length; i++) {
            //     if (qualification[i].qualification === value.label) {
            //         setSpecialization(qualification[i].specializations);
            //         console.log(qualification[i].specializations, "specId");
            //     }
            // }
            setDisableSaveBtn(true);
        } else if (ind !== undefined && newFormValues[ind]) {
            // Ensure the index exists in the array
            newFormValues[ind].disable = true;  // Only update if the item exists at the given index
            newFormValues.push({ ...value, disable: false, changeBtn: true });
            newFormValues_2.push({ ...value, disable: false, changeBtn: true });
            setEditDisablebtn(true);
        } else {
            console.error("Index is out of bounds or invalid.");
        }

        props.setMultiEducation(newFormValues);
    };

    // let onChangeEduc_2 = (changed_val, index) => {
    //     let newFormValues = [...props.multiEducation];
    //     newFormValues[index] = changed_val
    //     props.setMultiEducation(newFormValues);
    //     setDisableBtn(true);
    // }

    // -----------------> old desing function <-----------------
    // let onChangeEduc_3 = (val, changed_val, index) => {
    //     let newFormValues = [...props.multiEducation];
    //     newFormValues[index] = changed_val
    //     props.setMultiEducation(newFormValues);
    //     setDisableBtn(true);
    //     setDisableSaveBtn(true);
    // }

    // -----------------> new desing function <-----------------
    let onChangeEduc_3 = (val, type, ind) => {
        let newFormValues = [...props.multiEducation];
        let newFormValues_2 = [...props.multiEducation_2];
        if (type === "newEdu") {
            newFormValues[newFormValues.length - 1].collegeId = val.value;
        } else {
            newFormValues_2[ind].disableField = false;
            newFormValues_2[ind].collegeId = val.value;
            newFormValues_2[ind].university = val.label;
            newFormValues[ind].collegeId = val.value;
            newFormValues[ind].changeBtn = true;
            setEditDisablebtn(true);

        }
        props.setMultiEducation_2(newFormValues_2);
        props.setMultiEducation(newFormValues);
    }

    // -----------------> old desing function <-----------------
    // let handleChange = (e, i, typeName) => {
    //     let newFormValues = [...props.multiEducation];
    //     if (typeName == "passedOut") {
    //         newFormValues[i].passedOut_2 = moment(e).format("yyyy");
    //         newFormValues[i].passedOut = e;
    //     } else {
    //         newFormValues[i][e.target.name] = e.target.value;
    //     }
    //     props.setMultiEducation(newFormValues);
    //     setDisableBtn(true);
    // };

    // -----------------> new desing function <-----------------
    let handleChange = (e, typeName, type, ind) => {
        let newFormValues = [...props.multiEducation];
        let newFormValues_2 = [...props.multiEducation_2];
        if (type === "newEdu") {
            // if ((cgpaValue_1 != "" && cgpaValue_2 == "") || (cgpaValue_1 == "" && cgpaValue_2 != "")) {
            //     setDisableSaveBtn (true)
            // }
            // handleDisable()
            if (typeName === "passedOut") {
                newFormValues[newFormValues.length - 1].passedOut = moment(e).format("yyyy");
                setSelectedPassout(e);
            } else {
                if (e.target.name === "cgpa_1") {
                    if (e.target.value.length <= maxLength && e.target.value != "") {
                        newFormValues[newFormValues.length - 1].cgpa_1 = e.target.value;
                        setCgpaValue_1(e.target.value);
                        handleDisable("newEdu", null);
                    } else {
                        newFormValues[newFormValues.length - 1].cgpa_1 = "";
                        setCgpaValue_1("");
                        if (e.target.value === "" && newFormValues[newFormValues.length - 1].cgpa_2 === "") {
                            newFormValues[newFormValues.length - 1].marks = ""
                        }
                        handleDisable("newEdu", null)
                    }
                } else if (e.target.name === "cgpa_2") {
                    const numberRegex = /^[0-9]*$/;
                    if (numberRegex.test(e.target.value) || e.target.value === "") {
                        if (e.target.value >= 0 && e.target.value <= 10) {
                            newFormValues[newFormValues.length - 1].cgpa_2 = e.target.value;
                            let cgpa1 = (newFormValues[newFormValues.length - 1].cgpa_1);
                            let cgpa2 = (newFormValues[newFormValues.length - 1].cgpa_2);
                            let result = cgpa1 + "/" + cgpa2;
                            newFormValues[newFormValues.length - 1].marks = result
                            setCgpaValue_2(e.target.value);
                            handleDisable("newEdu", null);
                        }
                    }
                    // if ((cgpaValue_1 != "" && cgpaValue_2 == "") || (cgpaValue_1 == "" && cgpaValue_2 != "")) {
                    //     setDisableSaveBtn (true)
                    // }
                } else {
                    if (e.target.value.length <= maxLengthPer && e.target.value <= 100) {
                        newFormValues[newFormValues.length - 1][e.target.name] = e.target.value;
                        setPercentageVal(e.target.value);
                    }

                }
            }
            // if (newFormValues[newFormValues.length - 1].qualification == "" ) {
            //     setDisableSaveBtn(false);
            // } else {
            //     setDisableSaveBtn(true);
            // }

        } else {
            newFormValues_2[ind].changeBtn = true;
            if (typeName === "passedOut") {
                newFormValues[ind].passedOut = moment(e).format("yyyy");
                setSelectedPassout(e);
                setEditDisablebtn(true);

            } else {
                if (e.target.name === "cgpa_1") {
                    if (e.target.value.length <= maxLength) {
                        newFormValues[ind].cgpa_1 = e.target.value;
                        let result;
                        if (e.target.value === "") {
                            result = "";
                        } else {
                            let cgpa1 = parseFloat(newFormValues[ind].cgpa_1);
                            let cgpa2 = parseFloat(newFormValues[ind].cgpa_2);
                            result = cgpa1 + "/" + cgpa2;
                        }
                        newFormValues[ind].marks = result;
                        handleDisable("editEdu", ind);
                    }
                } else if (e.target.name === "cgpa_2") {
                    if (e.target.value >= 0 && e.target.value <= 10) {
                        const numberRegex = /^[0-9]*$/;
                        if (numberRegex.test(e.target.value)) {
                            newFormValues[ind].cgpa_2 = e.target.value;
                            let result;
                            if (e.target.value === "") {
                                result = "";
                            } else {
                                let cgpa1 = parseFloat(newFormValues[ind].cgpa_1);
                                let cgpa2 = parseFloat(newFormValues[ind].cgpa_2);
                                result = cgpa1 + "/" + cgpa2;
                            }
                            newFormValues[ind].marks = result;
                            handleDisable("editEdu", ind);
                        }
                    }
                } else {
                    if (e.target.value.length <= maxLengthPer && e.target.value <= 100) {
                        newFormValues[ind][e.target.name] = e.target.value;
                        newFormValues[ind][e.target.name] = e.target.value;
                    }

                }
            }
        }

        props.setMultiEducation(newFormValues);
        props.setMultiEducation_2(newFormValues_2);
        setDisableBtn(true);
    };

    const handleDisable = (type, index) => {
        let newFormValues = [...props.multiEducation];
        let tempCgpa_1 = newFormValues[newFormValues.length - 1].cgpa_1;
        let tempCgpa_2 = newFormValues[newFormValues.length - 1].cgpa_2;
        let tempQualification = newFormValues[newFormValues.length - 1].qualification;

        if (type === "newEdu") {
            if (tempQualification && tempCgpa_1 === "" && tempCgpa_2 === "") {
                setDisableSaveBtn(true);
                newFormValues[newFormValues.length - 1].marksType = "";
            } else if (tempCgpa_1 !== "" && tempCgpa_2 === "") {
                setDisableSaveBtn(false);
            } else if (tempCgpa_1 === "" && tempCgpa_2 !== "") {
                setDisableSaveBtn(false);
            } else if (tempQualification && tempCgpa_1 !== "" && tempCgpa_2 !== "") {
                setDisableSaveBtn(true);
            }
        } else {
            if (newFormValues[index].cgpa_1 === "" && newFormValues[index].cgpa_2 === "") {
                setEditDisablebtn(true)
                // newFormValues[index].marksType = "";
            } else if (newFormValues[index].cgpa_1 !== "" && newFormValues[index].cgpa_2 === "") {
                setEditDisablebtn(false)
            } else if (newFormValues[index].cgpa_1 === "" && newFormValues[index].cgpa_2 !== "") {
                setEditDisablebtn(false)
            } else if (newFormValues[index].cgpa_1 !== "" && newFormValues[index].cgpa_2 !== "") {
                setEditDisablebtn(true)
            }
        }
        props.setMultiEducation(newFormValues);
    }

    let addMoreEduc = () => {
        props.setMultiEducation([...props.multiEducation, {
            collegeId: "",
            educationId: "",
            qualification: "",
            qualificationId: "",
            // specialization: "",
            university: "",
            passedOut: "",
            passedOut_2: "",
            marks: "",
            marksType: "",
            disable: false,
            cgpa_1: "",
            cgpa_2: ""
        }]);
        setDisableBtn(true);
        setAddMoreEducModal(true);
        setDisableSaveBtn(false);
        masterDataUniversity();
        masterDataQualiication();
        setShowSpecialization(true);
    }

    let closeModal = () => {
        setAddMoreEducModal(false);
        props.setMultiEducation(props.multiEducation_2)
        setSelectedPassout("");
        setGradeSystem("Select Marks Type");
        setCgpaValue_1("");
        setCgpaValue_2("");
        setPercentageVal("");
        props.getProfileCanData(cId);
    }

    let removeAddedEduc = (index) => {
        let newFormValues = [...props.multiEducation];
        // const removedSkill = newFormValues.splice(index, 1)[0];
        // newFormValues.splice(index, 1);
        // removedSkill.disable = true;
        // newFormValues.push(removedSkill);
        // let updatedEduc = { ...newFormValues[index] };
        // updatedEduc.disable = true;
        // newFormValues[index] = updatedEduc;
        // props.setMultiEducation(newFormValues);

        const removedSkill = newFormValues.splice(index, 1)[0];
        removedSkill.disable = true;
        newFormValues.push(removedSkill);
        props.setMultiEducation(newFormValues);
        // props.getProfileCanData(cId);
        setDisableBtn(true);
        addEducation();
    }

    let discardChanges = () => {
        setDisableBtn(false);
        setDisableSaveBtn(false);
        props.getProfileCanData(cId);
    }

    const handleChangeGrade = (event, type, ind) => {
        let newFormValues = [...props.multiEducation];
        let newFormValues_2 = [...props.multiEducation_2];
        if (type === "newEdu") {
            setGradeSystem(event.target.value);
            newFormValues[newFormValues.length - 1].marksType = event.target.value === "Select Marks Type" ? "" : event.target.value;
            setCgpaValue_1("");
            setCgpaValue_2("");
            setPercentageVal("");
        } else {
            newFormValues_2[ind].marksType = event.target.value === "Select Marks Type" ? "" : event.target.value;
            newFormValues[ind].marksType = event.target.value === "Select Marks Type" ? "" : event.target.value;
            newFormValues[ind].cgpa_1 = "";
            newFormValues[ind].cgpa_2 = "";
            newFormValues_2[ind].marks = "";
            newFormValues_2[ind].changeBtn = true;
            setEditDisablebtn(true);
        }
        // console.log(newFormValues_2);
        props.setMultiEducation([...props.multiEducation]);
        props.setMultiEducation_2([...props.multiEducation_2]);
    };

    let editAddedSkills = (index) => {
        let newFormValues_2 = [...props.multiEducation_2];
        newFormValues_2[index].disableField = false;
        props.setMultiEducation_2(newFormValues_2);
        masterDataUniversity();
        masterDataQualiication();
    }

    let handleQualifi = (val) => {
        setDisableSaveBtn(false);
        setSpecialization(val.value)
    }


    const masterDataUniversity = async () => {
        setLoading(true);
        let searchJson = {
            "type": "university",
        }

        let searchApiUrl = config.baseUrl_candidate + "/Profile/masterData";

        await axios.post(searchApiUrl, searchJson, {
            headers: {
                'X-Session': sessionStorage.getItem("sessionid"),
                "Content-Type": "application/json"
            }
        })
            .then((result) => {
                setLoading(false);
                let temp = result.data.data.masterData;
                let filteredEducTemp = temp.filter(skill =>
                    !props.multiEducation.some(multiEduc => multiEduc.collegeId === skill.Id)
                )
                setUniversity(filteredEducTemp);
            }).catch((err) => {
                if (err) {
                    setLoading(false);
                    // setShowErrorModal(true);
                    // setModalMsg(err.response.data.error.message);
                }
            })
    }

    const masterDataQualiication = async () => {
        setLoading(true);
        let searchJson = {
            "type": "qualification",
            "version": 1,
            "qualification": "",
            "sortBy": "Qualification",
            "orderBy": "ASC"
        }

        let searchApiUrl = config.baseUrl_candidate + "/Profile/masterData";

        await axios.post(searchApiUrl, searchJson, {
            headers: {
                'X-Session': sessionStorage.getItem("sessionid"),
                "Content-Type": "application/json"
            }
        })
            .then((result) => {
                setLoading(false);
                let temp = result.data.data.masterData;
                let filteredEducTemp = temp.filter(skill =>
                    !props.multiEducation.some(multiEduc => multiEduc.qualificationId === skill.Id)
                )
                setQualification(filteredEducTemp);
            }).catch((err) => {
                if (err) {
                    setLoading(false);
                    // setShowErrorModal(true);
                    // setModalMsg(err.response.data.error.message);
                }
            })
    }

    const masterDataSpecialization = async (quali) => {
        setLoading(true);
        let searchJson = {
            "type": "qualification",
            "qualification": quali
        }

        let searchApiUrl = config.baseUrl_candidate + "/Profile/masterData";

        await axios.post(searchApiUrl, searchJson, {
            headers: {
                'X-Session': sessionStorage.getItem("sessionid"),
                "Content-Type": "application/json"
            }
        })
            .then((result) => {
                setLoading(false);
                let temp = result.data.data.masterData
                setSpecialization(temp);
            }).catch((err) => {
                if (err) {
                    setLoading(false);
                    // setShowErrorModal(true);
                    // setModalMsg(err.response.data.error.message);
                }
            })
    }

    // -------------> Add or Delete Education <-------------
    const addEducation = async () => {
        setLoading(true);
        let updateMultiEduc = props.multiEducation.map(({ qualification, specialization, university, specializationId, passedOut_2, disableField, changeBtn, cgpa_1, cgpa_2, ...rest }) => rest);

        let payLoad = {
            "cId": cId,
            "educationDetails": updateMultiEduc
        }

        let apiUrl = config.baseUrl_candidate + "/Profile/addEducation";
        let ses = sessionStorage.getItem("sessionid")
        console.log(ses);

        await axios.post(apiUrl, payLoad, {
            headers: {
                'X-Session': sessionStorage.getItem("sessionid"),
                'content-Type': 'application/json'
            }
        })
            .then((result) => {
                console.log(result, "425");
                setLoading(false);
                let temp = result.data.data;
                props.getProfileCanData(cId);
                setDisableBtn(false);
                setDisableSaveBtn(false);
                setShowSuccessPopup(true);
                setShowErrMessage(temp.message);
                setAddMoreEducModal(false);
                setSelectedPassout("");
                setGradeSystem("Select Marks Type");
                setCgpaValue_1("");
                setCgpaValue_2("");
                setPercentageVal("");
            }).catch((err) => {
                if (err) {
                    // props.getProfileCanData(cId);
                    setLoading(false);
                    setShowErrorPopup(true);
                    setShowErrMessage(err.response.data.error.message);
                }
                console.log(err);
            })
    }


    return (
        <>
            {loading && (
                <div className="loader">
                    <img src={LoadingIcon} alt="loader" />
                </div>
            )}
            {/* -------------> success modal <----------- */}
            {showErrorPopup || showSuccessPopup ?
                <BackEndErrorMsg
                    showErrorPopup={showErrorPopup}
                    showSuccessPopup={showSuccessPopup}
                    setShowErrorPopup={setShowErrorPopup}
                    setShowSuccessPopup={setShowSuccessPopup}
                    message={showErrMessage}
                    setShowErrMessage={setShowErrMessage}
                />
                : null}
            <div className="edit_KeySkills_info">
                <div className="profile_photo_item">
                    <h1>Education</h1>
                    {props.multiEducation_2.length > 0 &&
                        <div className="discard_save_btn">
                            {/* {disableBtn &&
                            <label onClick={() => discardChanges()}>Discard</label>
                        }
                        <Button onClick={addEducation} disabled={!disableSaveBtn}>Save</Button> */}
                            <img onClick={addMoreEduc} style={{ cursor: "pointer", width: "14px" }} src={AddmoreIcon} alt="add more" />
                            <h3 onClick={addMoreEduc}>Add Education</h3>
                        </div>
                    }
                </div>
                {props.multiEducation_2.length > 0 ?
                    <div className={props.multiEducation.length > 1 ? "content_1" : "content_2"}>
                        {props.multiEducation_2?.map((element, index) => (
                            !element.disable &&
                            <div className="skills_main_block">
                                <div className="added_skills" style={{ width: "100%" }}>
                                    <div style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}>
                                        <div className="skill" style={{ width: "41%" }}>
                                            <label>Qualification*</label>
                                            <Select
                                                // isMulti={false}
                                                value={element.qualification === "" ? { value: '', label: "Select" } : { value: element.Id, label: element.qualification }}
                                                // onChange={(values) => (
                                                //     props.setMultiEducation_2(prevSkills => prevSkills?.map((skill, i) => {
                                                //         previousEduc = prevSkills[index]
                                                //         onChangedEdu = {
                                                //             qualificationId: values.value,
                                                //             qualification: values.label,
                                                //             collegeId: element.collegeId,
                                                //             educationId: element.educationId,
                                                //             passedOut: element.passedOut,
                                                //             specialization: element.specialization,
                                                //             university: element.university,
                                                //             marks: element.marks,
                                                //             marksType: element.marksType,
                                                //             disableField: element.disableField,
                                                //             cgpa_1: element.cgpa_1,
                                                //             cgpa_2: element.cgpa_2,
                                                //             changeBtn: element.changeBtn
                                                //         }
                                                //         if (i === index) {
                                                //             return {
                                                //                 ...skill,
                                                //                 qualificationId: values.value,
                                                //                 qualification: values.label,
                                                //             };
                                                //         }
                                                //         return skill;
                                                //     })),
                                                //     onChangeEduc(previousEduc, "editEdu", index, onChangedEdu)
                                                // )}
                                                placeholder="select"
                                                onChange={(value) => onChangeEduc(value)}
                                                // options={qualification.map((item, index) => {
                                                //     return { value: item.Id, label: item.qualificationSpecialization }
                                                // })}
                                                isDisabled={true}
                                            // isDisabled={element.disableField}

                                            />
                                        </div>
                                        <div className="skill" style={{ width: "41%", marginLeft: "2.5%" }}>
                                            <label>Specialization*</label>
                                            <Select
                                                value={element.specialization === "" ? { value: '', label: "Select" } : { value: element.Id, label: element.specialization }}
                                                placeholder="Select"
                                                isDisabled={true}
                                            />
                                        </div>
                                        {/* <div className="skill" style={{ width: "35%", marginLeft: "90px" }}>
                                        <label>Specialization *</label>
                                        <Select
                                            isMulti={false}
                                            value={element.specialization === "" ? { value: '', label: "Select" } : { value: element.Id, label: element.specialization }}
                                            onChange={(values) => (
                                                props.setMultiEducation(prevSkills => prevSkills?.map((skill, i) => {
                                                    onChangedEdu = {
                                                        qualificationId: values.value,
                                                        qualification: element.qualification,
                                                        specializationId: values.value,
                                                        specialization: values.label,
                                                        collegeId: element.collageId,
                                                        educationId: element.educationId,
                                                        passedOut: "",
                                                        passedOut_2: "",
                                                        percentage: "",
                                                        university: ""
                                                    }
                                                    if (i === index) {
                                                        return {
                                                            ...skill,
                                                            specializationId: values.value,
                                                            specialization: values.label,
                                                        };
                                                    }
                                                    return skill;
                                                })),
                                                onChangeEduc_2(onChangedEdu, index)
                                            )}
                                            placeholder={"select"}
                                            options={specialization.map((item, index) => {
                                                return { value: item.Id, label: item.specialization }
                                            })}
                                        />
                                    </div> */}
                                    </div>
                                    <div className="university">
                                        <div style={{ width: "85%" }}>
                                            <label>University / Institution</label>
                                            <Select
                                                isMulti={false}
                                                value={element.university === "" ? { value: '', label: "Select" } : { value: element.collegeId, label: element.university }}
                                                onChange={(value) => onChangeEduc_3(value, "editEdu", index)}
                                                placeholder="select"
                                                options={university.map((item, index) => {
                                                    return { value: item.Id, label: decodeURIComponent(item.universityInstitute) }
                                                })}
                                                isDisabled={element.disableField}
                                            />
                                        </div>
                                    </div>
                                    <div className="skill_levels" style={{
                                        justifyContent: "unset",
                                        marginTop: "10px",
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        alignItems: "flex-end",
                                        width: "85%"
                                    }} >
                                        <div className="skill" style={{ width: "33.33%" }}>
                                            <label>Passing Year</label>
                                            {/* <input type="text" className="form-control" id="usr" placeholder="ex. 4 - Jun - 2019" name="passedOut"
                                            value={element?.passedOut} onChange={(e) => handleChange(e, index)} /> */}
                                            <div className="date_block">
                                                <ReactDatePicker
                                                    showIcon
                                                    // selected={element?.completionTime === "" ? "MM/yyyy" : new Date(element?.completionTime)}
                                                    selected={element?.passedOut === "" ? "" : new Date(element?.passedOut)}
                                                    onChange={(date) => handleChange(date, "passedOut", "editEdu", index)}
                                                    // minDate={new Date()}
                                                    maxDate={new Date()}
                                                    showYearPicker
                                                    dateFormat="yyyy"
                                                    placeholderText="yyyy"
                                                    icon={<i className="bi bi-calendar-check"></i>}
                                                    disabled={element.disableField}
                                                />
                                            </div>
                                        </div>

                                        <div className="workedFrom selectGrade" style={{ width: "31.33%", marginLeft: "2%", marginRight: "2%" }}>
                                            <label>Grading System</label>
                                            {/* <input type="text" className="form-control" id="usr" placeholder="ex. 8.6" name="percentage" style={{ fontSize: "12px", height: "41px" }}
                                                value={element?.percentage} onChange={(e) => handleChange(e, index)} disabled /> */}
                                            <FormControl size="small" disabled={element.disableField}>
                                                <MatSelect
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                    placeholder="Select Marks Type"
                                                    id="demo-select-small"
                                                    value={element.marksType === "" ? "Select Marks Type" : element.marksType}
                                                    onChange={(e) => handleChangeGrade(e, "editEdu", index)}

                                                    sx={{
                                                        "& fieldset": {
                                                            border: 'none',
                                                            color: "black",
                                                            // zIndex: -10000,
                                                            backgroundColor: element.disableField ? "#ebebeb" : "#FFFFFF",
                                                            border: element.disableField ? "none" : "1px solid #c1c1c1",
                                                            cursor: element.disableField ? "no-drop !important" : "pointer",
                                                        },
                                                    }}
                                                // sx={{ border: 0 }}
                                                >
                                                    <MenuItem value={"Select Marks Type"}>
                                                        <em>
                                                            Select Marks Type
                                                        </em>
                                                    </MenuItem>
                                                    <MenuItem value={"CGPA"}>CGPA</MenuItem>
                                                    <MenuItem value={"Percentage"}>Percentage</MenuItem>
                                                    {/* <MenuItem value={"Grade"}>Grade</MenuItem> */}
                                                </MatSelect>
                                            </FormControl>
                                        </div>
                                        <div className="gradeSystemUI" style={{ width: "33.33%" }}>
                                            {
                                                element.marksType === "CGPA" ?
                                                    <div className="CGPA">
                                                        <input type="number" className="form-control" id="usr" placeholder="ex.8.6" name='cgpa_1'
                                                            style={{ fontSize: "14px", height: "41px" }} onChange={(e) => handleChange(e, "cgpa_1", "editEdu", index)}
                                                            autoComplete="off" min="0" maxLength={maxLength} value={element.cgpa_1} disabled={element.disableField} />
                                                        <span>/</span>
                                                        <input type="text" className="form-control" id="usr" placeholder="ex.10" name='cgpa_2'
                                                            style={{ fontSize: "14px", height: "41px" }} onChange={(e) => handleChange(e, "cgpa_2", "editEdu", index)}
                                                            autoComplete="off" min="0" max="10" value={element.cgpa_2} disabled={element.disableField}
                                                        />
                                                    </div>
                                                    : (element.marksType === "Percentage") ?
                                                        <div className="percentage">
                                                            <input type="text" className="form-control" id="usr" placeholder="ex.80%" name="marks"
                                                                style={{ fontSize: "14px", height: "41px" }} onChange={(e) => handleChange(e, "cgpa", "editEdu", index)}
                                                                autoComplete="off" min="0" maxLength={maxLengthPer} value={element.marks} disabled={element.disableField} />
                                                            <span>%</span>
                                                        </div> : null
                                                // <div className="CGPA">
                                                //     <input type="number" className="form-control" id="usr" placeholder="ex.8.6" name='cgpa_1'
                                                //         style={{ fontSize: "14px", height: "41px" }} onChange={(e) => handleChange(e, "cgpa_1", "editEdu", index)}
                                                //         autoComplete="off" min="0" maxLength={maxLength} value={element.cgpa_1} disabled={element.disableField} />
                                                //     <span>/</span>
                                                //     <input type="text" className="form-control" id="usr" placeholder="ex.8.6" name='cgpa_2'
                                                //         style={{ fontSize: "14px", height: "41px" }} onChange={(e) => handleChange(e, "cgpa_2", "editEdu", index)}
                                                //         autoComplete="off" min="0" maxLength={maxLength} value={element.cgpa_2} disabled={element.disableField}
                                                //     />
                                                // </div>
                                                // : (element.marksType === "Grade") ?
                                                //     <input type="text" className="form-control" id="usr" placeholder="ex.A+" name="marks"
                                                //         style={{ fontSize: "12px", height: "41px" }} onChange={(e) => handleChange(e, "cgpa", "editEdu", index)}
                                                //         autoComplete="off" min="0" pattern="[A-DF-Za-df-z]+" value={element.marks} disabled={element.disableField} /> : null
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="edit_delete">
                                    <div className="edit">
                                        {
                                            !element.changeBtn ?
                                                <>
                                                    <img onClick={() => editAddedSkills(index)} src={editIcon} alt="edit" style={{ width: "16px" }} />
                                                    <label onClick={() => editAddedSkills(index)} style={{ marginLeft: "7px" }}>Edit</label>
                                                </>
                                                :
                                                <>
                                                    {
                                                        editDisablebtn &&
                                                        <>
                                                            <img onClick={() => addEducation(index, "editSkill")} src={diskette} alt="edit" style={{ width: "16px" }} />
                                                            <label style={{ marginLeft: "7px" }} onClick={() => addEducation(index, "editSkill")}>Save</label>
                                                        </>
                                                    }
                                                </>
                                        }
                                    </div>
                                    <div className="delete" onClick={() => removeAddedEduc(index)}>
                                        <img src={deleteIcon} alt="delete" />
                                        <label style={{ marginLeft: "3px" }}>Delete</label>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {/* <div className="discard_save_btn">
                        <img onClick={addMoreEduc} style={{ cursor: "pointer" }} src={AddmoreIcon} alt="add more" />
                        <h3 onClick={addMoreEduc}>{props.multiEducation.length == 0 ? <>Add</> : <>Add More</>}</h3>
                    </div> */}
                    </div>
                    :
                    <div className="menu_cat_child2_noRecords_found">
                        <img src={noDataFound} />
                        <div className="text">
                            No education entries found
                            <h6>Click <b onClick={addMoreEduc}>'Add Education'</b>to share your learning journey</h6>
                        </div>
                    </div>
                }
            </div>

            {/* -------------------> add new education <--------------------------- */}
            <div className="new_skill_modal">
                <Modal
                    show={addMoreEducModal} onHide={closeModal} className="new_skill_modal">
                    <Modal.Header closeButton>
                        <div className="header">
                            <h4>Add Education</h4>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="skills_main_block">
                            <div className="added_skills" style={{ width: "100%" }}>
                                <div style={{
                                    display: "flex",
                                    alignItems: "flex-start",
                                    justifyContent: "space-between"
                                }}>
                                    <div className="skill" style={{ width: "98%", marginRight: "4%" }}>
                                        <label>Qualification*</label>
                                        <Select
                                            isMulti={false}
                                            placeholder="Select"
                                            onChange={(value) => handleQualifi(value)}
                                            options={qualification.map((item) => {
                                                return { value: item.specializations, label: item.qualification }
                                            })}
                                        />
                                    </div>
                                    <div className="skill" style={{ width: "98%" }}>
                                        <label>Specialization*</label>
                                        <Select
                                            isMulti={false}
                                            placeholder="Select"
                                            onChange={(value) => onChangeEduc(null, "newEdu", null, value)}
                                            options={specialization.map((item) => {
                                                return {
                                                    value: item.id,
                                                    label: item.specialization === "null" ? "NOT APPLICABLE" : item.specialization
                                                };
                                            })}
                                        />
                                    </div>
                                </div>
                                <div className="skill_levels_new">
                                    <div className="skill" style={{ width: "100%" }}>
                                        <label>University / Institution</label>
                                        <Select
                                            isMulti={false}
                                            name="collegeId"
                                            placeholder="Select"
                                            onChange={(value) => onChangeEduc_3(value, "newEdu", null)}
                                            options={university.map((item) => {
                                                return { value: item.Id, label: decodeURIComponent(item.universityInstitute) }
                                            })}
                                        />
                                    </div>
                                    <div style={{
                                        marginTop: "10px",
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        alignItems: "flex-end",
                                    }}>
                                        <div className="skill" style={{ width: "40.5%" }}>
                                            <label>Passing Year</label>
                                            <div className="date_block">
                                                <ReactDatePicker
                                                    showIcon
                                                    selected={selectedPassout}
                                                    onChange={(date) => handleChange(date, "passedOut", "newEdu", null)}
                                                    maxDate={new Date()}
                                                    showYearPicker
                                                    dateFormat="yyyy"
                                                    placeholderText="yyyy"
                                                    icon={<i className="bi bi-calendar-check"></i>}
                                                    autoComplete="off"
                                                />
                                            </div>
                                        </div>
                                        <div className="workedFrom" style={{ width: "50%", marginLeft: "2%", marginRight: "2%" }}>
                                            <label>Grading System</label>
                                            <FormControl size="small">
                                                <MatSelect defaultValue="Select Marks Type"
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                    labelId="demo-select-small-label"
                                                    id="demo-select-small"
                                                    value={gradeSystem === "" ? "Select Marks Type" : gradeSystem}
                                                    placeholder="Select Marks Type"
                                                    onChange={(e) => handleChangeGrade(e, "newEdu", null)}
                                                >
                                                    <MenuItem value={"Select Marks Type"}>Select Marks Type</MenuItem>
                                                    <MenuItem value={"CGPA"}>CGPA</MenuItem>
                                                    <MenuItem value={"Percentage"}>Percentage</MenuItem>
                                                    {/* <MenuItem value={"Grade"}>Grade</MenuItem> */}
                                                </MatSelect>
                                            </FormControl>
                                        </div>
                                        <div className="gradeSystemUI">
                                            {
                                                gradeSystem === "CGPA" ?
                                                    <div className="CGPA">
                                                        <input type="number" className="form-control" id="usr" placeholder="ex.8.0" name="cgpa_1"
                                                            style={{ fontSize: "14px", height: "41px", width: "63px" }} onChange={(e) => handleChange(e, "cgpa_1", "newEdu", null)}
                                                            autoComplete="off" min="0" maxLength={maxLength} value={cgpaValue_1} />
                                                        <span>/</span>
                                                        <input type="text" className="form-control" id="usr" placeholder="ex.10" name="cgpa_2"
                                                            style={{ fontSize: "14px", height: "41px", width: "63px" }} onChange={(e) => handleChange(e, "cgpa_2", "newEdu", null)}
                                                            autoComplete="off" min="0" max="10" value={cgpaValue_2} />
                                                    </div>
                                                    : (gradeSystem === "Percentage") ?
                                                        <div className="percentage">
                                                            <input type="text" className="form-control" id="usr" placeholder="ex. 80%" name="marks"
                                                                style={{ fontSize: "14px", height: "41px" }} onChange={(e) => handleChange(e, "cgpa", "newEdu", null)}
                                                                autoComplete="off" min="0" max="100" value={percentageVal} />
                                                            <span>%</span>
                                                        </div> : null
                                                // : (gradeSystem === "Grade") ?
                                                //     <input type="text" className="form-control" id="usr" placeholder="ex. A+" name="marks"
                                                //         style={{ fontSize: "10px", height: "41px" }} onChange={(e) => handleChange(e, "cgpa", "newEdu", null)}
                                                //         autoComplete="off" min="0" /> : null
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="discard_save_btn">
                                    <Button style={{ marginLeft: "0" }} onClick={addEducation} disabled={!disableSaveBtn}>SAVE</Button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>

        </>
    );
};

export default EditEducation;