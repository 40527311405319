import axios from "axios";
import React, { useEffect, useState } from "react";
// import "./linkedinLogin.css";
import { LinkedIn } from 'react-linkedin-login-oauth2';
import { useLinkedIn } from 'react-linkedin-login-oauth2';

function LinkedinLogin(props) {
    // 86laduhx9sbmip
    // 9QIyCV4b9ZT4P9ZD
    // http://market-qa.theshortlistd.com/

    // -----------> li googy <-------------
    // 86mcq7ywsqs4ey
    // cRfWi2pW8bNxjtWb

    // useEffect(() => {
    //     getAuth();
    // }, [])


    function exchangeAuthorizationCodeForAccessToken(code) {
        const accessTokenUrl = "https://www.linkedin.com/oauth/v2/accessToken";
        const data = {
            grant_type: "authorization_code",
            client_id: "86laduhx9sbmip",
            client_secret: "9QIyCV4b9ZT4P9ZD",
            code: code,
            redirect_uri: "http://market-qa.theshortlistd.com/linkedin",
        };

        axios.post(accessTokenUrl, null, {
            params: data,
        })
            .then((response) => {
                const accessToken = response.data.access_token;
                // You now have the access token, and you can use it for LinkedIn API requests.
                console.log("Access token:", accessToken);
            })
            .catch((error) => {
                console.error("Error exchanging code for access token:", error);
            });
    }

    const { linkedInLogin } = useLinkedIn({
        clientId: '86laduhx9sbmip',
        redirectUri: "http://market-qa.theshortlistd.com/linkedin",
        scope: "openid profile email",
        state: "J6OBZTOPS1U2T3h",
        onSuccess: (code) => {
            console.log(code);
            exchangeAuthorizationCodeForAccessToken(code);
        },
        onError: (error) => {
            console.log(error);
        },
    });

    return (
        <>
            {/* <LinkedIn
                clientId="86mcq7ywsqs4ey"
                redirectUri="http://localhost:3001/linkedin"
                onSuccess={(code) => {
                    // Handle the success, e.g., initiate the access token request
                    console.log("LinkedIn login successful. Authorization code: " + code);
                    // Call a function to exchange the authorization code for an access token.
                    exchangeAuthorizationCodeForAccessToken(code);
                }}
                onError={(error) => {
                    console.log("LinkedIn login error:", error);
                }}
            >
                {({ linkedInLogin }) => (
                    <button onClick={linkedInLogin}>Login with LinkedIn</button>
                )}
            </LinkedIn> */}

            <img
                onClick={linkedInLogin}
                src="linkedin"
                alt="Sign in with Linked In"
                style={{ maxWidth: '180px', cursor: 'pointer' }}
            />
        </>
    )

}

export default LinkedinLogin;