import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import Select from "react-select";

function AutoComplete() {
  let [inputValue, setInputValue] = useState("");
  let [locationSelected, setLocationSelected] = useState([]);

  const location = [
    { location: "The Shawshank Redemption", locationId: 1994 },
    { location: "The Godfather", locationId: 1972 },
    { location: "The Godfather: Part II", locationId: 1974 },
    { location: "The Dark Knight", locationId: 2008 },
    { location: "12 Angry Men", locationId: 1957 },
    { location: "Schindler's List", locationId: 1993 },
    { location: "Pulp Fiction", locationId: 1994 },
  ];

  const location_1 = [
    { location_1: "The Shawshank Redemption", locationId: 1994 },
    { location_1: "The Godfather", locationId: 1972 },
    { location_1: "The Godfather: Part II", locationId: 1974 },
    { location_1: "The Dark Knight", locationId: 2008 },
    { location_1: "12 Angry Men", locationId: 1957 },
    { location_1: "Schindler's List", locationId: 1993 },
    { location_1: "Pulp Fiction", locationId: 1994 },
  ];

  const promiseAll = () => {
    const payLoad_1 = {
      json: {
        request: {
          servicetype: "21",
          functiontype: "1025",
          qualification: "",
        },
      },
    };
    let apiUrl_1 = fetch(
      "https://restapi-qa.theshortlistd.com/JobzTop/Qualification?request=" +
        encodeURIComponent(JSON.stringify(payLoad_1))
    );

    const payLoad_2 = {
        "json": {
            "request": {
                servicetype: 6,
                functiontype: 6001,
                data: {

                }
            }
        }
    }
    let apiUrl_2 = fetch(
      "https://restapi-qa.theshortlistd.com/JobzTop/SearchJob?request=" +
        encodeURIComponent(JSON.stringify(payLoad_2))
    );

    Promise.all([apiUrl_1, apiUrl_2])
      .then((response) => Promise.all(response.map((res) => res.json())))
      .then((data) => {
        console.log(data);
      });
  };

  promiseAll();

  const onChnageInput = (e) => {
    console.log(e);
  };

  let onchangeLocation = (e) => {
    console.log(e);
    // SearchLocation(e);
  };

  return (
    <>
      <Select
        isMulti
        value={locationSelected}
        // onChange={(value) => onChange_location(value)}
        placeholder={"Location"}
        options={location.map((item, index) => {
          return { value: item.locationId, label: item.location };
        })}
        onInputChange={(e) => {
          if (e !== inputValue) {
            onchangeLocation(e);
          }
          setInputValue(e);
        }}
        // onKeyDown={handleEnterKey}
      />
    </>
  );
}

export default AutoComplete;
