const baseUrl = "https://restapi-qa.theshortlistd.com";

export const shortlistd_APIs = {
  passThroughAPI: `${baseUrl}/JobzTop/PassThrough?request=`,

  jobAPI: `${baseUrl}/JobzTop/Job?request=`,

  profileApi: `${baseUrl}/JobzTop/Profile?request=`,

  qualificationApi: `${baseUrl}/JobzTop/Qualification?request=`,

  questionBankApi: `${baseUrl}/JobzTop/QuestionBank?request=`,

  downloadImage: `${baseUrl}/JobzTop/PassThroughV2?code=aaa&type=thumbnl&id=`,

  searchJobApi: `${baseUrl}/JobzTop/SearchJob?request=`,
};

export const candidate_APIs = {};

export const baseUrlCandidate =
  "https://restapi-qa.theshortlistd.com/shortlistdcandidate";

export const ES_APIs = {
  searchJobApi: `${baseUrl}/JobEngine/JobSearch/js?request=`,
};

export const candidateUrl = "http://market-qa.theshortlistd.com";
export const LoginLinkEmp = "https://employer-qa.theshortlistd.com/";
export const showPhoto = `${baseUrl}/JobzTop/PassThroughV2?code=aaa&type=thumbnl&id=`;
export const grpzconference = "https://videoconf-qa.theshortlistd.com";
export const contactUsEmail = "hi-there@theshortlistd.com";
export const downloadresume = `${baseUrl}/JobzTop/PassThroughV2?code=yyy&id=`;
export const domain = "market-qa";
