import React, { useState, useCallback, useRef, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import "./imageCrop.css";
import Avatar from "react-avatar-edit";
import Button from "react-bootstrap/Button";
import axios from 'axios';
import config from "../../../../Shared/Config/config.json";
import { BackEndErrorMsg } from "../Error/error";
import LoadingIcon from "../../../../assets/loader/loader.gif";

function ImageCrop(props) {

    let onClose = () => {
        props.setImgCropModal(false);
    };

    const [croppedImage, setCroppedImage] = useState();
    let [showErrMessage, setShowErrMessage] = useState();
    let [showErrorPopup, setShowErrorPopup] = useState(false);
    let [showSuccessPopup, setShowSuccessPopup] = useState(false);
    let [loading, setLoading] = useState(false);
    let [tempImageCrop, setTempImageCrop] = useState();

    const onCrop = async (view) => {
        // console.log(view)
        const response = await fetch(view);
        // console.log(response, "Response 63")
        const blob = await response.blob();
        // console.log(blob, "Blob 63")
        const imageFile = new File([blob], tempImageCrop?.name, { type: tempImageCrop?.type });
        setCroppedImage(imageFile);
    }

    const defaultSrc = '';
    const [src, setSrc] = useState(defaultSrc);

    // const onClose = () => {
    //     setPreview(null);
    // };

    // const onCrop = (preview) => {
    //     console.log(preview);
    //     setPreview(preview);
    // };

    const onBeforeFileLoad = (elem) => {
        setTempImageCrop(elem.target.files[0]);
        // if (elem.target.files[0].size > 71680) {
        //     alert("File is too big!");
        //     elem.target.value = "";
        // }
    };

    const saveImage = async () => {
        setLoading(true);
        const formData = new FormData();
        formData.append('file', croppedImage);
        formData.append('type', "photo");
        formData.append('cId', props.cId);
        let apiUrl = config.baseUrl_candidate + "/Profile/srl"
        await axios({
            method: "post",
            url: apiUrl,
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data",
                'X-Session': sessionStorage.getItem("sessionid")
            }
        }).then((result) => {
            setLoading(false);
            let temp = result.data;
            props.setImgCropModal(false);
            props.getProfileCanData(props.cId);
            if (result.status == 201) {
                setShowSuccessPopup(true);
                setShowErrMessage(temp.data.message);
            }
            // onClose();
        }).catch((error) => {
            setLoading(false);
            if (error) {
                setShowErrorPopup(true);
                setShowErrMessage(error.response.data.error.message);
                onClose();
            }
        });
    };

    return (
        <>
            {loading && (
                <div className="loader">
                    <img src={LoadingIcon} alt="loader" />
                </div>
            )}
            {showErrorPopup || showSuccessPopup ?
                <BackEndErrorMsg
                    showErrorPopup={showErrorPopup}
                    showSuccessPopup={showSuccessPopup}
                    setShowErrorPopup={setShowErrorPopup}
                    setShowSuccessPopup={setShowSuccessPopup}
                    message={showErrMessage}
                />
                : null}
            <div className="imageCrop_modal">
                <Modal
                    className="post_job_success_popup"
                    show={props.imgCropModal}
                    onHide={onClose}
                    backdrop="static"
                    dialogClassName="post-job-success-modal"
                    keyboard={false}
                >
                    <Modal.Header closeButton className="modal-title">
                        <Modal.Title>
                            <div className="modal-info">
                                <div style={{fontSize:"16px"}}>Upload Your Image</div>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center">
                            <Avatar
                                width={300}
                                height={250}
                                onCrop={onCrop}
                                onClose={onClose}
                                onBeforeFileLoad={onBeforeFileLoad}
                                src={src}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="cropBtn">
                            {/* <Button className="save-button">Save</Button> */}
                            <Button className="save-button" onClick={saveImage}>Save</Button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    );
};

export default ImageCrop;