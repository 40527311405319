import logo from "./logo.svg";
import "./App.css";
import Home from "./Layout/Home/Home.js";
// import Categories from './Categories/Categories.js';
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/js/bootstrap.js";
import FindJob from "./Layout/FindJob/FindJob";
import JobList from "./Layout/JobList/JobList";
import ComingsoonPage from "./Shared/Maintenance/comingSoonPage";
import Details from "./Layout/FindJob/Details/Details";
import { useEffect, useState } from "react";
import { Axios } from "axios";
import MyActivity from "./Layout/Candidate/MyActivity/myActivity";
// import FindJobCandidate from './Layout/Candidate/FindJob/findJobs';
import CandidateProfile from "./Layout/Candidate/CandidateProfile/candidateProfile";
import Assessment_dashboard from "./Layout/Candidate/Assessment/AssessmentDash/assessmentDashboard";
import TestQuestion from "./Layout/Candidate/Assessment/Test/testQuestions";
import CandidateInformatation from "./Layout/Candidate/CandIformation/CandidateInfo";
import EditCandidateProfile from "./Layout/Candidate/CandidateProfile/EditCandidateProfile/editCandidateProfile";
import TestSubmittedSuccessfully from "./Layout/Candidate/Assessment/Test/testSubmitted/testSubmitted";
import { Candidate_Error } from "./Layout/Candidate/Shared/Error/error";
import AOS from "aos";
import "aos/dist/aos.css";
import TermsAndConditions from "./Layout/Candidate/Shared/TermsAndConditions/termsAndConditions";
import SharedJob from "./Layout/SharedJob/sharedJob";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import LinkedinLogin from "./Layout/linkedinLogin/linkedinLogin";
import PrivacyAndPolicy from "./Layout/Candidate/Shared/PrivacyAndPolicy/privacyAndPolicy";
import PrivateRoutes, { SubRoute } from "./utiles/PrivateRoutes.js";
import SubDomain from "./Layout/SharedJob/components/subDomainJobs/subDomain.js";
import config from "./Shared/Config/config.json";
import AutoComplete from "./autoComplete.js";
import { getCookie } from "./utiles/Cookies";
import CandidateNav from "./Layout/Candidate/Shared/NavbarCandidate/candidateNavbar";

function App() {
  let getSearchUrl = window.location;
  let queryParams = new URLSearchParams(getSearchUrl.search);
  let domain = config.domain;
  const [dynamicDomain, setDynamicDomain] = useState(
    getSearchUrl.host
      .split(".")
      .slice(0, getSearchUrl.host.includes("localhost") ? -1 : -2)
  );
  let [getJobId, setGetJobId] = useState(queryParams.get("id"));
  let [sessionIdExist, setSessionIdExist] = useState(false);
  let sessionID;

  useEffect(() => {
    AOS.init(); // Initialize AOS
    // getContent();
    sessionStorage.setItem("subDomain", dynamicDomain[0]);

    sessionID = getCookie('sessionid');
    let companyName = getCookie('companyName');
    let userName = getCookie('userName');
    let cid = getCookie('Cid');
    let companyList = getCookie('companyList');
    if (sessionID) {
      sessionStorage.setItem('sessionid', sessionID);
      sessionStorage.setItem('companyName', companyName);
      sessionStorage.setItem('userName', userName);
      sessionStorage.setItem('Cid', cid);
      sessionStorage.setItem('companyList', companyList);
      setSessionIdExist(true);
      //   getSearchUrl.replace("http://market-qa.localhost:3001/myActivity")
    } else {
      setSessionIdExist(false);
    }
  }, []);

  // let getContent = () => {
  //   const host = window.location.host;
  //   const arr = host.split(".").slice(0, host.includes("localhost") ? -1 : -2);
  //   if (arr.length > 0) {
  //     setDynamicDomain(arr[0]);
  //   }
  // }

  // useEffect(() => {
  //   sessionStorage.setItem("loader", "loader");
  // }, [])

  // let token = sessionStorage.getItem("sessionid");

  // Axios.interceptors?.request?.use(function (config) {
  //   document.body.classList.add('loading-indicator');
  //   if (token) {
  //     config.headers = `Bearer ${token}`
  //   }
  //   return config
  // }, function (error) {
  //   return Promise.reject(error);
  // });

  // Axios.interceptors?.response?.use(function (response) {
  //   document.body.classList.remove('loading-indicator');

  //   return response;
  // }, function (error) {
  //   document.body.classList.remove('loading-indicator');
  //   return Promise.reject(error);
  // });

  // TO CHECK DEPLOYMENT
  console.log(window.location);
  return (
    <div className="App">
      <Router>
        <Routes>
          {!sessionID ? <Route
            path="/"
            element={
              dynamicDomain?.length !== 0 && dynamicDomain[0] !== domain ? (
                <SubDomain dynamicDomain={dynamicDomain} />
              ) : getJobId ? (
                <SharedJob getJobId={getJobId} setGetJobId={setGetJobId} />
              ) : (
                <Home />
              )
            }
          /> :
            <Route path="/" element={<CandidateProfile sessionID={sessionID} />} />}
          <Route path="/home" element={<Home />} />

          <Route element={<SubRoute />}>
            <Route path="/findJob" element={<FindJob />} />
            <Route path="/details" element={<Details />} />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />
            <Route path="/privacy-policy" element={<PrivacyAndPolicy />} />
          </Route>

          {/* <Route element={<PrivateRoutes />}> */}
          <Route path="/linkedin" element={<LinkedInCallback />} />
          <Route path="/linkedinLogin" element={<LinkedinLogin />} />

          {/* <Route path="/jobList" element={<JobList />} /> */}
          {/* <Route path="/login" element={<Login />} /> */}
          {/* ------------ Coming Soon Page -------------- */}
          {/* <Route path="/" element={<ComingsoonPage />} /> */}

          {/* ---------- candidate ------- */}
          <Route path="/candidateprofile" element={<CandidateProfile />} />
          <Route path="/assessment" element={<Assessment_dashboard />} />
          <Route path="/test" element={<TestQuestion />} />
          <Route path="/myActivity" element={<MyActivity />} />
          {/* <Route path="/candidateNavbar" element={<CandidateNav setSessionIdExist={setSessionIdExist}/>} /> */}

          {/* <Route path="/findjob" element={<FindJobCandidate />} /> */}

          <Route path="/Information" element={<CandidateInformatation />} />
          <Route path="/editProfile" element={<EditCandidateProfile />} />
          <Route path="/submitted" element={<TestSubmittedSuccessfully />} />
          <Route path="/autocomplete" element={<AutoComplete />} />
          {/* <Route path="/subDomain" element={<SubDomain />} /> */}
          <Route path="*" element={<Candidate_Error />} />
          {/* </Route> */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
