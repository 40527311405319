import axios from "axios";
import { useEffect, useState } from "react";
import config from "../../../../Shared/Config/config.json";
import NavBar from "../../../../Shared/Navbar/NavBar";
import Select from "react-select";
import "./subDomain.css";
import Logo from "../../../../assets/logo/Logo.png";
import { Button, TextField } from "@mui/material";
import noDataFound from "../../../../assets/Home-images/nodatafound.png";
import Modal from "react-bootstrap/Modal";
import Details from "../../../FindJob/Details/Details";
import settingIcon from "../../../../assets/Home-images/Icon feather-settings.svg";
import shareIcon from "../../../../assets/Home-images/share (1).png";
import companyIcon from "../../../../assets/img/candidate/Component 37.png";
import ReactPaginate from "react-paginate";
import "../../../FindJob/FindJob.css";
import { useNavigate } from "react-router-dom";
import { BackEndErrorMsg } from "../../../Candidate/Shared/Error/error";
import { JobLinkModal } from "../../../../Shared/Modals/Modals";
import loaderImage from "../../../../assets/loader/loader.gif"
import { shortlistd_APIs } from "../../../../Shared/Config/config";
import searchIcon from "../../../../../src/assets/icons/icons8-search-24.png"

function SubDomain(props) {
  let navigate = useNavigate();
  let [itemlist, setItemList] = useState([]);
  let [activeColor, setActiveColor] = useState("activeBorderColor");
  let [jobDescription, setJobDescription] = useState([]);
  let [currentPage, setCurrentPage] = useState(0);
  let [pageCount, setPageCount] = useState(0);
  let [showJobModal, setShowJobModal] = useState(false);
  let [getJobId, setGetJobId] = useState();
  let [showDetailsModal, setShowDetailsModal] = useState(false);
  let jobType = "Not Disclosed";
  let [showLinkModal, setShowLinkModal] = useState(false);
  let [getLinkId, setGetLinkId] = useState();
  let getSubDomain = sessionStorage.getItem("subDomain");
  let [skill, setSkill] = useState([]);
  let [showErrMessage, setShowErrMessage] = useState();
  let [showErrorPopup, setShowErrorPopup] = useState(false);
  let [showSuccessPopup, setShowSuccessPopup] = useState(false);
  let [loader, setLoader] = useState(false);
  let [location, setLocation] = useState([]);
  let [industry, setIndustry] = useState([]);
  let [selectedSkill, setSelectedSkill] = useState("");
  let [locationSelected, setLocationSelected] = useState("");
  let [industrySelected, setIndustrySelected] = useState("");
  let [experience, setExperience] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [jobTitle, setJobTitle] = useState('');

  let doNotRenderTwice = false;

  let exper = [
    "Fresher(0-1yr)",
    "Intermediate(1-3yrs)",
    "Experience(3-7yrs)",
    "Well Experienced(>7yrs)",
  ];

  useEffect(() => {
    if (!doNotRenderTwice) {
      doNotRenderTwice = true;
      jobList(0, selectedSkill, locationSelected, experience, industrySelected, jobTitle);
      console.log(getSubDomain);
      getSubDomain = props.dynamicDomain[0];
      console.log(props.dynamicDomain[0]);
      SearchSkillList();
      SearchLocation("");
      SearchIndustry();
    }
  }, []);

  let jobDetails = (itemname) => {
    setJobDescription(itemname);
    setActiveColor(itemname.jobId);
    setShowJobModal(true);
  };

  let closeJobModal = () => {
    setShowJobModal(false);
  };

  let onChangeDetailsPage = (jobId) => {
    setGetJobId(jobId);
    setShowDetailsModal(true);
    setShowJobModal(false);
  };

  let handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
    jobList(selectedPage, selectedSkill, locationSelected, experience, industrySelected);
    document.getElementsByClassName("jobList")[0].scrollTop = 0;
  };

  let onChangeSkill = (val) => {
    setSelectedSkill(val);
    jobList(0, val, locationSelected, experience, industrySelected);
  };

  let onChange_location = (location_data) => {
    setLocationSelected(location_data);
    jobList(0, selectedSkill, location_data, experience, industrySelected);
    const stringifiedLocation = JSON.stringify({
      value: location_data.value,
      label: location_data.label,
    });
    sessionStorage.setItem("Selected", stringifiedLocation);
  };

  let onChange_industry = (v) => {
    setIndustrySelected({
      value: v.value,
      label: v.label,
    });
    jobList(0, selectedSkill, locationSelected, experience, v);
  };

  let onChange_experience = (e) => {
    setExperience({
      value: e.value,
      label: e.label,
    });
    jobList(0, selectedSkill, locationSelected, e, industrySelected);
  };

  let resetFilter = () => {
    setExperience("");
    setIndustrySelected("");
    setSelectedSkill("");
    setLocationSelected("");
    setJobTitle("")
    jobList(0, "", "", "", "","");
  };

  const getLinkModal = (id) => {
    setShowLinkModal(true);
    setGetLinkId(id);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setJobTitle(value); // Update the jobTitle state
    jobList(0, selectedSkill, locationSelected, industrySelected, experience, value); // Trigger jobList function with the input value
};

  // API INTEGRATION
  let per_page = 10;
  let offset;
  let jobList = (currentPageCount, selectedSkill, location, experience, industrySelected, jobTitle = '') => {
    setLoader(true);
    setCurrentPage(currentPageCount);
    offset = currentPageCount * per_page;

    if (!getSubDomain) {
      getSubDomain = props.dynamicDomain[0];
    }

    let tempSkill = [];
    if (selectedSkill?.length > 0) {
      for (let i = 0; i < selectedSkill?.length; i++) {
        tempSkill.push(selectedSkill[i].value);
      }
    }

    let tempLocation = [];
    if (location?.length > 0) {
      for (let i = 0; i < location?.length; i++) {
        tempLocation.push(location[i].value);
      }
    }

    let tempIndustry = [];
    if (industrySelected?.value === undefined) {
      tempIndustry = [];
    } else {
      tempIndustry = [industrySelected?.value];
    }

    let json = {
      json: {
        request: {
          servicetype: 6,
          functiontype: 6000,
          data: {
            skills: tempSkill,
            location: tempLocation,
            industry: tempIndustry,
            experienceLevel: experience?.value,
            subdomain: [getSubDomain],
            jobTitle: jobTitle
          },
          records: {
            limit: per_page,
            offset: offset,
          },
          sort: {
            sortby: "createdtime",
            orderby: "DESC",
          },
        },
      },
    };

    let apiUrl =
      config.baseUrl +
      "/JobzTop/SearchJob?request=" +
      encodeURIComponent(JSON.stringify(json));

    axios
      .post(apiUrl)
      .then((result) => {
        setLoader(false);
        if (result.data.json.response.statuscode === "0") {
          setItemList(result.data.json.response.data);
          setJobDescription(result.data.json.response.data[0]);
          setActiveColor(result.data.json.response.data[0].jobId);
          setPageCount(Math.ceil(result.data.json.response.count / per_page));
        } else {
          setItemList([]);
        }
      })
      .catch((error) => {
        setLoader(false);
      });
  };

  let SearchSkillList = () => {
    setLoader(true);
    let searchJson = {
      json: {
        request: {
          servicetype: 6,
          functiontype: 6001,
          data: {},
        },
      },
    };

    let searchApiUrl =
      config.baseUrl +
      "/JobzTop/SearchJob?request=" +
      encodeURIComponent(JSON.stringify(searchJson));

    axios
      .post(searchApiUrl)
      .then((result) => {
        let temp = result.data.json.response;
        if (temp.statuscode === "0") {
          setSkill(temp.data);
        } else {
          setShowErrorPopup(true);
          setShowErrMessage(temp.statusmessage);
        }
      })
      .catch((err) => {
        setLoader(false);
        if (err) {
          navigate("./error");
        }
      });
  };

  let SearchLocation = (e) => {
    setLoader(true);
    let searchLocationJson = {
      json: {
        request: {
          servicetype: 6,
          functiontype: 6004,
          data: {
            city: e,
          },
          sort: {
            sortby: "city",
            orderby: "asc",
          },
        },
      },
    };

    let searchLocationApiUrl =
      config.baseUrl +
      "/JobzTop/SearchJob?request=" +
      encodeURIComponent(JSON.stringify(searchLocationJson));

    axios
      .post(searchLocationApiUrl)
      .then((result) => {
        setLoader(false);
        let locationTemp = result.data.json.response;
        if (locationTemp.statuscode === "0") {
          setLocation(locationTemp.data);
          console.log(locationTemp.data);
        } else if (locationTemp.statuscode !== "20021") {
          setShowErrorPopup(true);
          setShowErrMessage(locationTemp.statusmessage);
        }
      })
      .catch((err) => {
        setLoader(false);
        if (err) {
          navigate("./error");
        }
      });
  };

  let SearchIndustry = () => {
    setLoader(true);

    let searchIndustryJson = {
      json: {
        request: {
          servicetype: 6,
          functiontype: 6002,
          data: {},
          sort: {
            sortby: "industry",
            orderby: "asc",
          },
        },
      },
    };

    let searchIndustryApiUrl =
      config.baseUrl +
      "/JobzTop/SearchJob?request=" +
      encodeURIComponent(JSON.stringify(searchIndustryJson));

    axios
      .post(searchIndustryApiUrl)
      .then((result) => {
        setLoader(false);
        let locationTemp = result.data.json.response;
        if (locationTemp.statuscode === "0") {
          setIndustry(locationTemp.data);
        } else {
          setShowErrorPopup(true);
          setShowErrMessage(locationTemp.statusmessage);
        }
      })
      .catch((err) => {
        setLoader(false);
        if (err.response.status >= 400 && err.response.status < 500) {
          setShowErrorPopup(true);
          setShowErrMessage(err.response.data.error.message);
        } else {
          navigate("./error");
        }
      });
  };

  return (
    <>
      {loader && (
        <div className="loader">
          <img src={loaderImage} alt="loader" />
        </div>
      )}
      {/* -------------> Error and success modal <----------- */}
      {showErrorPopup || showSuccessPopup ? (
        <BackEndErrorMsg
          showErrorPopup={showErrorPopup}
          showSuccessPopup={showSuccessPopup}
          setShowErrorPopup={setShowErrorPopup}
          setShowSuccessPopup={setShowSuccessPopup}
          setShowErrMessage={setShowErrMessage}
          message={showErrMessage}
        />
      ) : null}
      {/* ---------------------> job apply modal <----------------- */}
      {showDetailsModal && (
        <Details
          showDetailsModal={showDetailsModal}
          setShowDetailsModal={setShowDetailsModal}
          getJobId={getJobId}
        />
      )}
      {/* -------------------------> get Job link modal <------------------ */}
      {showLinkModal && (
        <JobLinkModal
          getLinkId={getLinkId}
          showLinkModal={showLinkModal}
          setShowLinkModal={setShowLinkModal}
        />
      )}

      <div className="subDomain">
        <div className="container subDomain_container">
          <div className="mainContainer">
            <div className="">
              <img
                src={Logo}
                alt="the.shortlistd"
                style={{ width: "33%", marginLeft: "5px" }}
              ></img>
            </div>
            <div className="iconLogo">
              {jobDescription?.logoId <= 0 ? (
                <img src={companyIcon} style={{ width: "90px" }}></img>
              ) : (
                <div className="">
                  <img
                    src={config.showPhoto + jobDescription?.logoId}
                    alt="the.shortlistd"
                    onError={(e) => (e.target.src = companyIcon)}
                    style={{ width: "90px", cursor:'pointer' }}
                  ></img>
                </div>
              )}
            </div>
            {/* <div className="login_modes">
                        <label>
                            <a style={{ textDecoration: "none", color: "black", fontWeight: "500" }} onClick={() => onChangeDetailsPage()} target="_blank">Candidates</a>
                        </label>
                        <span style={{ margin: "0 10px" }}>|</span>
                        <label>
                            <a style={{ textDecoration: "none", color: "black", fontWeight: "500" }} href={config.LoginLinkEmp} target="_blank">Employers</a>
                        </label>
                    </div> */}
          </div>
          <div className="main_searchBox" style={{ width: "100%" }}>
            <div className="col-lg-5" style={{ width: "100%" }}>
              <div className="container-fluid">
                <div className="row" style={{ width: "100%" }}>
                  <div className="col-lg-3 loc_label" style={{ width: "12%" }}>
                    <h6 className="font_14">LOOKING FOR</h6>
                  </div>
                  <div className="col-lg-10 font_14 search-input">
                    <img src={searchIcon} alt="Search Icon" className="icon" style={{
                      width: '24px', height: '24px', marginRight: '8px', position: "absolute",
                      left: "93%",
                      top: "20%",
                      opacity: "16%"
                    }} />
                    <input
                      className="search-collection-name form-control input-new-border"
                      type="text"
                      placeholder="Search job title"
                      value={jobTitle}
                      onChange={handleInputChange}
                    ></input>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="filter_fields">
            <div>
              <label style={{ fontSize: "12px", fontWeight: "500" }}>
                FILTER BY
              </label>
            </div>
            <div className="ms-3 font_12">
              <Select
                isMulti={false}
                value={experience}
                onChange={(value) => onChange_experience(value)}
                placeholder={"Experience"}
                options={exper.map((item) => {
                  return { value: item, label: item };
                })}
              />
            </div>
            <div className="ms-3 font_12">
              <Select
                placeholder={"Industry"}
                isMulti={false}
                options={industry.map((itm, index) => {
                  return { value: itm.industryId, label: itm.industryName };
                })}
                onChange={(o) => onChange_industry(o)}
                value={industrySelected}
              />
            </div>
            <div className="ms-3 font_12">
              <Select
                isMulti
                placeholder={"Skills"}
                options={skill.map((item, index) => {
                  return { value: item.skillId, label: item.skillName };
                })}
                value={selectedSkill}
                onChange={(value) => onChangeSkill(value)}
              />
            </div>
            <div className="ms-3 font_12">
              <Select
                placeholder={"Location"}
                isMulti
                options={location.map((item, index) => {
                  return { value: item.locationId, label: item.location };
                })}
                value={locationSelected}
                onChange={(value) => onChange_location(value)}
              />
            </div>
            <div className="subBtn">
              <Button onClick={() => resetFilter()}>Reset All</Button>
            </div>
          </div>
        </div>

        <div className="main_container">
          <div className="container findJob" style={{ paddingTop: "1%" }}>
            <div className="row" style={{ marginLeft: "0", marginRight: "0" }}>
              {/* ------------> listing jobs <----------------- */}
              <>
                {itemlist.length > 0 ? (
                  <div className="conteiner-fluid">
                    <div className="row main_jobList">
                      {/* job notification */}
                      <div className="col-lg-6">
                        {/* <div className={itemlist.length >= 10 ? "jobList" : "jobList_2"}> */}
                        <div className="jobList">
                          {itemlist?.map((itemname, index) => (
                            <div
                              key={index}
                              className={
                                activeColor == itemname.jobId
                                  ? "jobList_box_active"
                                  : "jobList_box"
                              }
                            >
                              <div className="row apply_job">
                                <div className="col-lg-6 applyBtn">
                                  <h6 onClick={() => jobDetails(itemname)}>
                                    {itemname?.jobTitle}
                                  </h6>
                                  <span>
                                    {decodeURIComponent(itemname?.companyName)}
                                  </span>
                                </div>
                                <div className="col-lg-6 apply_btn">
                                  <Button
                                    onClick={() =>
                                      onChangeDetailsPage(itemname)
                                    }
                                  >
                                    Apply
                                  </Button>
                                </div>
                              </div>
                              <div className="container-fluid jobList_childBox">
                                {/* <div className="row">
                                                                            <div className="col-lg-3">
                                                                                <label>
                                                                                    <i className="bi bi-briefcase" style={{ marginRight: "3px" }}></i>
                                                                                    {itemname?.min}-{itemname?.max} years
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-lg-3">
                                                                                <label>
                                                                                    <i className="bi bi-briefcase" style={{ marginRight: "3px" }}></i>
                                                                                    {decodeURIComponent(itemname.type == undefined ? jobType : itemname.type)}
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-lg-3">
                                                                                <i className="bi bi-geo-alt"></i>
                                                                                <label style={{ paddingLeft: "6px" }}>
                                                                                    {itemname?.location.map((item, index) => (
                                                                                        <>
                                                                                            <label>{decodeURIComponent(item.city)}</label>
                                                                                            {itemname?.location.length !== index + 1 ?
                                                                                                <>, </> : null
                                                                                            }
                                                                                        </>
                                                                                    ))}
                                                                                </label>
                                                                            </div>
                                                                        </div> */}
                                <div className="jobList_childBox">
                                  {/* <label>
                                    <i
                                      className="bi bi-briefcase"
                                      style={{ marginRight: "3px" }}
                                    ></i>
                                    {itemname?.min}-{itemname?.max} years
                                  </label> */}
                                  <label style={{ cursor: "text" }}>
                                    <i className="bi bi-briefcase" style={{ marginRight: "3px" }}></i>
                                    {itemname?.max >= 100
                                      ? itemname?.experienceLevel
                                      : `${itemname?.min}-${itemname?.max} years`}
                                  </label>
                                  <span style={{ padding: "0 14px 0 14px" }}>
                                    |
                                  </span>
                                  <label>
                                    <i
                                      className="bi bi-briefcase"
                                      style={{ marginRight: "3px" }}
                                    ></i>
                                    {decodeURIComponent(
                                      itemname.type == undefined
                                        ? jobType
                                        : itemname.type
                                    )}
                                  </label>
                                  <span style={{ padding: "0 14px 0 14px" }}>
                                    |
                                  </span>
                                  <label>
                                    <i className="bi bi-geo-alt"></i>
                                    <label>
                                      {itemname?.location.map((item, index) => (
                                        <>
                                          <label>
                                            {decodeURIComponent(item.city)}
                                          </label>
                                          {itemname?.location.length !==
                                            index + 1 ? (
                                            <>, </>
                                          ) : null}
                                        </>
                                      ))}
                                    </label>
                                  </label>
                                </div>
                              </div>
                              <div className="row req_skill">
                                <div className="col-lg-1">
                                  <img src={settingIcon} alt="skill" />
                                </div>
                                <div
                                  className="col-lg-11"
                                  style={{ padding: "0" }}
                                >
                                  {itemname?.keySkill?.map((listItem, ind) => (
                                    <button>
                                      <span
                                        key={ind}
                                        style={{
                                          color: "#101010",
                                          fontSize: "12px",
                                        }}
                                      >
                                        {listItem.skillName}
                                      </span>
                                    </button>
                                  ))}
                                </div>
                              </div>
                            </div>
                          ))}
                          {pageCount !== 1 ? (
                            <ReactPaginate
                              previousLabel={<i className="bi-arrow-left"></i>}
                              nextLabel={<i className="bi-arrow-right"></i>}
                              pageCount={pageCount} //state
                              onPageChange={handlePageClick}
                              containerClassName={"pagination"}
                              previousLinkClassName={"pagination__link"}
                              nextLinkClassName={"pagination__link"}
                              disabledClassName={"pagination__link--disabled"}
                              activeClassName={"pagination__link--active"}
                              breakClassName="page-item"
                              breakLinkClassName="page-link"
                              forcePage={currentPage}
                            />
                          ) : null}
                        </div>
                      </div>
                      {/* job description */}
                      {window.outerWidth >= "992" ? (
                        <div className="col-lg-6 job_description">
                          <div className="container-fluid">
                            <div className="jobButton">
                              <h4
                                style={{ fontSize: "16px", fontWeight: "bold" }}
                              >
                                {jobDescription?.jobTitle}
                              </h4>
                              <div className="jobBtn">
                                <img
                                  src={shareIcon}
                                  style={{
                                    marginRight: "6px",
                                    width: "15%",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    getLinkModal(jobDescription?.referenceCode)
                                  }
                                />
                                <Button
                                  onClick={() =>
                                    onChangeDetailsPage(jobDescription)
                                  }
                                >
                                  Apply
                                </Button>
                              </div>
                            </div>
                            <p style={{ fontSize: "14px", marginTop: "-16px" }}>
                              {decodeURIComponent(jobDescription?.companyName)}
                            </p>
                          </div>
                          <div className="container-fluid job_details">
                            {/* <div className="row">
                                                                    <div className="col-lg-4">
                                                                        <i className="bi bi-geo-alt" style={{ marginRight: "3px" }}></i>
                                                                        {jobDescription?.location.map((item, index) => (
                                                                            <>
                                                                                <label>{decodeURIComponent(item.city)}</label>
                                                                                {jobDescription?.location.length !== index + 1 ?
                                                                                    <>, </> : null
                                                                                }
                                                                            </>
                                                                        ))}
                                                                    </div>
                                                                    <div className="col-lg-4">
                                                                        <label>
                                                                            <i className="bi bi-briefcase" style={{ marginRight: "3px" }}></i>
                                                                            {jobDescription?.min}-{jobDescription?.max} years
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-lg-4">
                                                                        <label>
                                                                            <i className="bi bi-briefcase" style={{ marginRight: "3px" }}></i>
                                                                            {decodeURIComponent(jobDescription.type == undefined ? jobType : jobDescription.type)}
                                                                        </label>
                                                                    </div>
                                                                </div> */}
                            <div className="jobList_childBox">
                              <label>
                                <i
                                  className="bi bi-briefcase"
                                  style={{ marginRight: "3px" }}
                                ></i>
                                {jobDescription?.min}-{jobDescription?.max}{" "}
                                years
                              </label>
                              <span style={{ padding: "0 14px 0 14px" }}>
                                |
                              </span>
                              <label>
                                <i
                                  className="bi bi-briefcase"
                                  style={{ marginRight: "3px" }}
                                ></i>
                                {decodeURIComponent(
                                  jobDescription.type == undefined
                                    ? jobType
                                    : jobDescription.type
                                )}
                              </label>
                              <span style={{ padding: "0 14px 0 14px" }}>
                                |
                              </span>
                              <label>
                                <i className="bi bi-geo-alt"></i>
                                <label>
                                  {jobDescription?.location.map(
                                    (item, index) => (
                                      <>
                                        <label>
                                          {decodeURIComponent(item.city)}
                                        </label>
                                        {jobDescription?.location.length !==
                                          index + 1 ? (
                                          <>, </>
                                        ) : null}
                                      </>
                                    )
                                  )}
                                </label>
                              </label>
                            </div>
                          </div>
                          <hr></hr>
                          <div className="container-fluid req_skill">
                            <div className="row">
                              <div className="col-lg-3">
                                <h6>Required Skills</h6>
                              </div>
                              <div className="col-lg-9 skill">
                                {jobDescription?.keySkill.map((item, index) => (
                                  <>
                                    {item?.mandatory && (
                                      <button>
                                        <span key={index}>
                                          {item?.skillName}
                                        </span>
                                        {item?.skillLevelExp === 0 ? null : (
                                          <span style={{ color: "#fd7e14" }}>
                                            {" "}
                                            {item?.skillLevelExp / 12}
                                            {item?.skillLevelExp === 12 ? (
                                              <> year</>
                                            ) : (
                                              <> years</>
                                            )}
                                          </span>
                                        )}
                                      </button>
                                    )}
                                  </>
                                ))}
                              </div>
                            </div>
                          </div>

                          <div className="container-fluid good_skill">
                            <div className="row">
                              <div className="col-lg-3">
                                <h6>Good to have skills</h6>
                              </div>
                              <div className="col-lg-9">
                                {jobDescription?.keySkill.map((item, index) => (
                                  <>
                                    {item?.preferable && (
                                      <button>
                                        <span key={index}>
                                          {item?.skillName}
                                        </span>
                                        {/* {item?.skillLevelExp === 0 ? null : <span style={{ color: "#fd7e14" }}> {(item?.skillLevelExp / 12)}
                                                                                            {item?.skillLevelExp === 12 ? <> year</> : <> years</>}</span>} */}
                                      </button>
                                    )}
                                  </>
                                ))}
                              </div>
                            </div>
                          </div>
                          <div className="container-fluid soft_skill">
                            <div
                              className="row"
                              style={{
                                display: "flex",
                                alignItems: "baseline",
                              }}
                            >
                              <div className="col-lg-3 title">
                                <h6>Soft Skills</h6>
                              </div>
                              <div className="col-lg-9 skill">
                                {jobDescription?.softSkills?.length == 0 ? (
                                  <button>none</button>
                                ) : (
                                  jobDescription?.softSkills?.map(
                                    (item, index) => (
                                      <button>
                                        <span key={index}>
                                          {item.skillName}
                                        </span>
                                      </button>
                                    )
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="container-fluid decription">
                            <div>
                              <h6>Description</h6>
                            </div>
                            <div>
                              <p style={{ fontSize: "12px" }}>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: jobDescription.jobDescription,
                                  }}
                                ></span>
                              </p>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="viewJob_modal">
                          <Modal
                            show={showJobModal}
                            onHide={closeJobModal}
                            className="viewJob_modal"
                          >
                            <Modal.Header closeButton>
                              {/* <Modal.Title>
                                                                                <h4 style={{ fontSize: "22px", fontWeight: "bold" }}>UI/UX Designer</h4>
                                                                                <p style={{ fontSize: "18px" }}>XYZ corporation Pvt Ltd.</p>
                                                                            </Modal.Title> */}
                            </Modal.Header>
                            <Modal.Body closeButton>
                              <div className="col-lg-6 job_description ">
                                <div className="container-fluid">
                                  <div className="jobButton">
                                    <h4
                                      style={{
                                        fontSize: "22px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {jobDescription?.jobTitle}
                                    </h4>
                                    <div className="jobBtn">
                                      <i
                                        className="bi-house house"
                                        style={{ marginRight: "6px" }}
                                        onClick={() =>
                                          getLinkModal(jobDescription?.jobId)
                                        }
                                      ></i>
                                      <Button
                                        onClick={() =>
                                          onChangeDetailsPage(
                                            jobDescription.jobId
                                          )
                                        }
                                      >
                                        Apply
                                      </Button>
                                    </div>
                                  </div>
                                  <p style={{ fontSize: "18px" }}>
                                    {decodeURIComponent(
                                      jobDescription?.companyName
                                    )}
                                  </p>
                                </div>
                                <div className="container-fluid job_details">
                                  {/* <div className="row">
                                                                                <div className="col-lg-3">
                                                                                    {jobDescription?.location.map((item, index) => (
                                                                                        <>
                                                                                            <label>{decodeURIComponent(item.city)}</label>
                                                                                            {jobDescription?.location.length !== index + 1 ?
                                                                                                <>, </> : null
                                                                                            }
                                                                                        </>
                                                                                    ))}
                                                                                </div>
                                                                                <div className="col-lg-4">
                                                                                    <label>
                                                                                        <i className="bi bi-briefcase" style={{ marginRight: "3px" }}></i>
                                                                                        {jobDescription?.min}-{jobDescription?.max} years
                                                                                    </label>
                                                                                </div>
                                                                                <div className="col-lg-3">
                                                                                    <label>
                                                                                        <i className="bi bi-briefcase" style={{ marginRight: "3px" }}></i>
                                                                                        {decodeURIComponent(jobDescription.type == undefined ? jobType : jobDescription.type)}
                                                                                    </label>
                                                                                </div>
                                                                            </div> */}
                                  <div className="jobList_childBox">
                                    <label>
                                      <i
                                        className="bi bi-briefcase"
                                        style={{ marginRight: "3px" }}
                                      ></i>
                                      {jobDescription?.min}-
                                      {jobDescription?.max} years
                                    </label>
                                    <span style={{ padding: "0 14px 0 14px" }}>
                                      |
                                    </span>
                                    <label>
                                      <i
                                        className="bi bi-briefcase"
                                        style={{ marginRight: "3px" }}
                                      ></i>
                                      {decodeURIComponent(
                                        jobDescription.type == undefined
                                          ? jobType
                                          : jobDescription.type
                                      )}
                                    </label>
                                    <span style={{ padding: "0 14px 0 14px" }}>
                                      |
                                    </span>
                                    <label>
                                      <i className="bi bi-geo-alt"></i>
                                      <label>
                                        {jobDescription?.location.map(
                                          (item, index) => (
                                            <>
                                              <label>
                                                {decodeURIComponent(item.city)}
                                              </label>
                                              {jobDescription?.location
                                                .length !==
                                                index + 1 ? (
                                                <>, </>
                                              ) : null}
                                            </>
                                          )
                                        )}
                                      </label>
                                    </label>
                                  </div>
                                </div>
                                <hr></hr>
                                <div className="container-fluid req_skill">
                                  <div className="row">
                                    <div className="col-lg-3">
                                      <h6>Required Skills</h6>
                                    </div>
                                    <div className="col-lg-9 skill">
                                      {jobDescription?.keySkill.map(
                                        (item, index) => (
                                          <>
                                            {item?.mandatory.length !== 0 ? (
                                              item?.mandatory && (
                                                <button>
                                                  <span key={index}>
                                                    {item?.skillName}
                                                  </span>
                                                </button>
                                              )
                                            ) : (
                                              <button>
                                                <span>none</span>
                                              </button>
                                            )}
                                          </>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </div>

                                <div className="container-fluid good_skill">
                                  <div className="row">
                                    <div className="col-lg-6">
                                      <label>Good to have skills</label>
                                    </div>
                                    <div className="col-lg-6">
                                      {jobDescription?.keySkill.map(
                                        (item, index) => (
                                          <>
                                            {item?.preferable.length !== 0 ? (
                                              item?.preferable && (
                                                <button>
                                                  <span key={index}>
                                                    {item?.skillName}
                                                  </span>
                                                </button>
                                              )
                                            ) : (
                                              <button>
                                                <span>none</span>
                                              </button>
                                            )}
                                          </>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="container-fluid soft_skill">
                                  <div
                                    className="row"
                                    style={{
                                      display: "flex",
                                      alignItems: "baseline",
                                    }}
                                  >
                                    <div className="col-lg-4 title">
                                      <h6>Soft Skills</h6>
                                    </div>
                                    <div className="col-lg-8 skill">
                                      {jobDescription?.softSkills?.length ==
                                        0 ? (
                                        <button>none</button>
                                      ) : (
                                        jobDescription?.softSkills?.map(
                                          (item, index) => (
                                            <button>
                                              <span key={index}>
                                                {item.skillName}
                                              </span>
                                            </button>
                                          )
                                        )
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="container-fluid decription">
                                  <div>
                                    <h6>Description</h6>
                                  </div>
                                  <div
                                    style={{
                                      height: "22vh",
                                      overflowY: "scroll",
                                    }}
                                  >
                                    <p>
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: jobDescription.jobDescription,
                                        }}
                                      ></span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </Modal.Body>
                          </Modal>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="menu_cat_child2_noRecords_found">
                    <img src={noDataFound} />
                    <div className="text">
                      <h3>
                        Oops! It seems we couldn't find any jobs matching your
                        search criteria.
                      </h3>
                      <h4>But don't let that dampen your spirits!</h4>
                      <p
                        style={{
                          marginBottom: "0",
                          marginTop: "20px",
                        }}
                      >
                        Expand your horizons and explore different job
                        categories, industries, or locations.
                      </p>
                      <p>
                        Sometimes, the perfect fit is waiting where you least
                        expect it!
                      </p>
                    </div>
                  </div>
                )}
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default SubDomain;
