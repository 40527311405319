import React, { useState, useEffect, useRef } from "react";
import config from "../Config/config.json";
import { Link, useNavigate, NavLink } from 'react-router-dom';
import "./NavBar.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/js/bootstrap.js"
import axios from "axios";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Logo from "../../assets/logo/Logo.png"
import Details from "../../Layout/FindJob/Details/Details";


function NavBar(props) {
    const [logOpen, setLogOpen] = useState(false);
    let [showDetailsModal, setShowDetailsModal] = useState(false);
    const [expanded, setExpanded] = useState(false);

    const ref = useRef();
    const navigate = useNavigate();
    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if (logOpen && ref.current && !ref.current.contains(e.target)) {
                setLogOpen(false);
            }
        };

        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [logOpen]);

    let clearSessionOfFilter = () => {
        sessionStorage.removeItem("Selected");
        sessionStorage.removeItem("LocationSelected");
    }

    let onChangeDetailsPage = () => {
        setShowDetailsModal(true);
        setExpanded(false);
    }

    let handleToggle = () => {
        setExpanded(!expanded);
    }

    let CandidateLogout = () => {
        const json = {
            "json": {
                "request": {
                    "servicetype": "21",
                    "functiontype": "1005",
                    "session": {
                        "sessionid": sessionStorage.getItem("sessionid")
                    }
                }
            }
        }
        let CandidateApiUrl = config.baseUrl + '/JobzTop/Profile?request='

        axios.post(CandidateApiUrl, json).then((result) => {
            let temp = result.data.json.response;
            if (temp.statuscode = "0") {
                sessionStorage.clear();
                navigate('/Login')
            }
        })
    }


    return (
        <>
            <div className="nav_bar" data-aos="fade-down" data-aos-delay="300" data-aos-once="true">
                <div className="container marketing_navbar">
                    <Navbar key="lg" expand="lg" className="candidate_profile_content" expanded={expanded}>
                        <Navbar.Brand>
                            <div className="candidate_profile_logo" >
                                <img src={Logo} alt="the.shortlistd"></img>
                            </div>
                        </Navbar.Brand>
                        <>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleToggle}/>
                            <Navbar.Collapse id="basic-navbar-nav">
                                <Nav className="me-auto">
                                    {props.getJobId == undefined ?
                                        <div className="navbarToggle">
                                            <ul>
                                                <li><NavLink to={"/"} activeclassname='active' onClick={() => clearSessionOfFilter()}>HOME</NavLink ></li>
                                                <li><NavLink to={"/findJob"} activeclassname='active'>FIND JOBS</NavLink ></li>
                                                <li><button disabled style={{
                                                    border: "none",
                                                    background: "none",
                                                    width: "135px",
                                                    height: "34px",
                                                    display: 'none'
                                                }}>FIND TALENTS</button ></li>
                                                <li><button disabled style={{
                                                    border: "none",
                                                    background: "none",
                                                    display: 'none'
                                                }}>ASSESSMENTS</button ></li>
                                            </ul>
                                        </div> : null
                                    }
                                    <div className="candidate_profile_profile" id={props.getJobId !== undefined ? "candidate_profile" : null}>
                                        <div>
                                            <label style={{ paddingRight: "20px", cursor: "pointer", fontWeight: "500", fontSize: "14px" }} onClick={() => onChangeDetailsPage()}>
                                                {/* <a style={{ textDecoration: "none", color: "black" }} href={config.registerLink + encodeURIComponent("candidate")}> */}
                                                Candidate Login
                                                {/* </a> */}
                                            </label>
                                            {/* <label style={{ paddingRight: "20px" }}>
                                                    <a style={{ textDecoration: "none", color: "black" }} href={config.LoginLink + encodeURIComponent("candidate")}>Sign in</a>
                                                </label> */}
                                        </div>
                                        {props.getJobId == undefined ?
                                            <>
                                                <span style={{ paddingRight: "20px" }}>|</span>
                                                <div>
                                                    <label>
                                                        <a style={{ textDecoration: "none", color: "black", fontWeight: "500", fontSize: "14px" }} href={config.LoginLinkEmp + "Login"} target="_blank">Employers</a>
                                                    </label>
                                                </div>
                                                <span style={{ paddingRight: "20px", paddingLeft: "20px" }}>|</span>
                                                <div>
                                                    <label>
                                                        <a style={{ textDecoration: "none", color: "black", fontWeight: "500", fontSize: "14px" }} href={config.LoginLinkEmp + "Assessor-Login"} target="_blank">Assessor</a>
                                                    </label>
                                                </div>
                                            </> : null
                                        }
                                    </div>
                                </Nav>
                            </Navbar.Collapse>
                        </>
                    </Navbar>



                </div >
            </div >
            <Details
                setShowDetailsModal={setShowDetailsModal}
                showDetailsModal={showDetailsModal} />
        </>
    );
}

export default NavBar;
