import react, { useEffect, useState } from "react";
import "../FindJob/FindJob.css"
import Footer from "../../Shared/Footer/Footer";
import NavBar from "../../Shared/Navbar/NavBar";
import Select from 'react-select';
import axios from "axios";
import config from "../../Shared/Config/config.json";
import ReactPaginate from "react-paginate";
import { Button, TextField } from "@mui/material";
import loaderImage from "../../assets/loader/loader.gif"
import noDataFound from "../../assets/Home-images/nodatafound.png"
import settingIcon from "../../assets/Home-images/Icon feather-settings.svg"
import shareIcon from "../../assets/Home-images/share (1).png"
import { useNavigate } from "react-router-dom";
import Details from "./Details/Details";
import Modal from 'react-bootstrap/Modal';
import { JobLinkModal } from "../../Shared/Modals/Modals";
import { BackEndErrorMsg } from "../Candidate/Shared/Error/error";
import { ES_APIs, shortlistd_APIs } from "../../Shared/Config/config";
import searchIcon from "../../assets/icons/icons8-search-24.png";

function FindJob() {
    let navigate = useNavigate();
    let [itemlist, setItemList] = useState([]);
    let [currentPage, setCurrentPage] = useState(0);
    let [pageCount, setPageCount] = useState(0);
    let [selectedSkill, setSelectedSkill] = useState("");
    let [locationSelected, setLocationSelected] = useState("");
    let [industrySelected, setIndustrySelected] = useState("")
    let [companySelected, setCompanySelected] = useState("")
    let [experience, setExperience] = useState("");
    let [jobDescription, setJobDescription] = useState([]);
    let [activeColor, setActiveColor] = useState("activeBorderColor");
    let [loader, setLoader] = useState(false);
    let [skill, setSkill] = useState([]);
    let [location, setLocation] = useState([]);
    let [industry, setIndustry] = useState([]);
    let [company, setCompany] = useState([]);
    let [category, setCategory] = useState([]);
    let jobType = "Not Disclosed"
    let [tempDetails, setTempDetails] = useState("findJob");
    let [showJobModal, setShowJobModal] = useState(false);
    let [showDetailsModal, setShowDetailsModal] = useState(false);
    let [getJobId, setGetJobId] = useState();
    let [showLinkModal, setShowLinkModal] = useState(false);
    let [getLinkId, setGetLinkId] = useState();
    let [showErrMessage, setShowErrMessage] = useState();
    let [showErrorPopup, setShowErrorPopup] = useState(false);
    const [jobTitle, setJobTitle] = useState('');

    let doNotRenderTwiceRfi = false;
    useEffect(() => {
        if (!doNotRenderTwiceRfi) {
            doNotRenderTwiceRfi = true
            let parcedSkill = JSON.parse(sessionStorage.getItem("Selected"));
            let parcedLocation = JSON.parse(sessionStorage.getItem("LocationSelected"));
            console.log(parcedSkill);
            setSelectedSkill(parcedSkill);
            setLocationSelected(parcedLocation);
            SearchSkillList();
            SearchLocation("");
            SearchIndustry();
            searchCompany();
            searchCategory();
            jobList(0, parcedSkill, parcedLocation, industrySelected, companySelected, experience, jobTitle);
        }
    }, []);

    let exper = [
        "Fresher(0-1yr)",
        // "Range",
        "Intermediate(1-3yrs)",
        "Experience(3-7yrs)",
        "Well Experienced(>7yrs)",
    ];

    let closeJobModal = () => {
        setShowJobModal(false);
    }

    let onChangeSkill = (val) => {
        console.log("Skills", val);
        setSelectedSkill(val);

        // const stringiSkills = JSON.stringify({
        //     value: val.value,
        //     label: val.label
        // }) 
        // sessionStorage.setItem("selected", stringiSkills)
        // let data = []
        // data.push(val)
        // setSelectedSkill({
        //     value: val.value,
        //     label: val.label
        // });
        // let stringifiedSkill = JSON.stringify(data);
        // sessionStorage.setItem("Selected", stringifiedSkill);
    }

    let onChange_experience = (e) => {
        setExperience({
            value: e.value,
            label: e.label
        })
        jobList(0, selectedSkill, locationSelected, industrySelected, companySelected, e);
    }

    let onChange_industry = (v) => {
        // let data = []
        // data.push(v)
        setIndustrySelected({
            value: v.value,
            label: v.label
        });
        jobList(0, selectedSkill, locationSelected, v, companySelected, experience);
    }

    let onChange_company = (u) => {
        // let data = []
        // data.push(u)
        setCompanySelected({
            value: u.value,
            label: u.label
        });
        jobList(0, selectedSkill, locationSelected, industrySelected, u, experience);
    }

    let onChange_location = (location_data) => {
        setLocationSelected(location_data);

        // const stringifiedLocation = JSON.stringify({
        //     value: location_data.value,
        //     label: location_data.label
        // })

        // sessionStorage.setItem("Selected", stringifiedLocation)
        // let data = []
        // data.push(location_data)
        // setLocationSelected({
        //     value: location_data.value,
        //     label: location_data.label
        // });
        // let stringifiedLocation = JSON.stringify(data);
        // sessionStorage.setItem("LocationSelected", stringifiedLocation);
    }

    let onSearch = () => {
        jobList(0, selectedSkill, locationSelected, industrySelected, companySelected, experience);
    };

    let resetFilter = () => {
        setSelectedSkill("");
        setLocationSelected("");
        setIndustrySelected("");
        setCompanySelected("");
        setExperience("");
        setJobTitle("")
        jobList(0, "", "", "", "", "","");
        sessionStorage.removeItem("Selected");
        sessionStorage.removeItem("LocationSelected");
    }

    let handlePageClick = ({ selected: selectedPage }) => {
        setCurrentPage(selectedPage);
        jobList(selectedPage, selectedSkill, locationSelected, industrySelected, companySelected, experience);
        document.getElementsByClassName('jobList')[0].scrollTop = 0;
    }

    let jobDetails = (itemname) => {
        setJobDescription(itemname)
        setActiveColor(itemname.jobId);
        setShowJobModal(true);
        window.scrollTo({ top: 0, behavior: 'smooth' });
        // document.getElementById("scrollUp").scrollTop = 0;

        const scrollupElement = document.getElementById("scrollUp");
        if (scrollupElement) {
            scrollupElement.scrollTop = 0;
        } else {
            console.log("This is scrolling Up");
        }
    }

    let handleEnterKey = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            document.getElementById('submitButton').click();
        }
    }

    let onChangeDetailsPage = (jobId) => {
        setGetJobId(jobId);
        setShowDetailsModal(true);
        setShowJobModal(false);
    }

    const getLinkModal = (id) => {
        setShowLinkModal(true);
        setGetLinkId(id);
    }

    const handleInputChange = (e) => {
        const value = e.target.value;
        setJobTitle(value); // Update the jobTitle state
        jobList(0, selectedSkill, locationSelected, industrySelected, companySelected, experience, value); // Trigger jobList function with the input value
    };

    let per_page = 10;
    let offset;
    let jobList = (currentPageCount, selectedSkill, locationSelected, industrySelected, companySelected, experience, jobTitle = '') => {
        setLoader(true);
        setCurrentPage(currentPageCount)
        offset = currentPageCount * per_page

        let tempSkill = [];
        // if (selectedSkill?.value === undefined) {
        //     tempSkill = [];
        // } else {
        // tempSkill = [selectedSkill?.value];
        if (selectedSkill?.length > 0) {
            for (let i = 0; i < selectedSkill?.length; i++) {
                tempSkill.push(selectedSkill[i].value);
            }
        }
        // }

        let tempLocation = [];
        // if (locationSelected?.value === undefined) {
        //     tempLocation = [];
        // } else {
        //     tempLocation = [locationSelected?.value];
        // }

        if (locationSelected?.length > 0) {
            for (let i = 0; i < locationSelected?.length; i++) {
                tempLocation.push(locationSelected[i].value)
            }
        }

        let tempIndustry;
        if (industrySelected?.value === undefined) {
            tempIndustry = [];
        } else {
            tempIndustry = [industrySelected?.value];
        }

        let tempCompany;
        if (companySelected?.value === undefined) {
            tempCompany = [];
        } else {
            tempCompany = [companySelected?.value];
        }

        let json = {
            "json": {
                "request": {
                    servicetype: 6,
                    functiontype: 6000,
                    data: {
                        skills: tempSkill,
                        location: tempLocation,
                        industry: tempIndustry,
                        company: tempCompany,
                        experienceLevel: experience?.value,
                        jobTitle: jobTitle
                    },
                    records: {
                        limit: per_page,
                        offset: offset
                    },
                    "sort": {
                        "sortby": "createdtime",
                        "orderby": "DESC"
                    }
                }
            }
        }

        let apiUrl = config.baseUrl + "/JobzTop/SearchJob?request=" + encodeURIComponent(JSON.stringify(json)); // Regular API
        // let apiUrl = config.searchJobApi + encodeURIComponent(JSON.stringify(json)); // ES API

        axios.post(apiUrl)
            .then((result) => {
                setLoader(false);

                // ---------------------- Regular API response set-up ---------------------

                if (result.data.json.response.statuscode === "0") {
                    if (document.getElementsByClassName('jobList')[0] !== undefined) {
                        document.getElementsByClassName('jobList')[0].scrollTop = 0;
                    }
                    setItemList(result.data.json.response.data);
                    console.log(result.data.json.response.data)
                    setJobDescription(result.data.json.response.data[0])
                    setActiveColor(result.data.json.response.data[0].jobId)
                    setPageCount(Math.ceil(result.data.json.response.count / per_page));
                } else {
                    setItemList([]);
                    if (result.data.json.response.statuscode === "20051") {
                        setShowErrorPopup(false);
                        setShowErrMessage("");
                    } else {
                        setShowErrorPopup(true);
                        setShowErrMessage(result.data.json.response.statusmessage);
                    }
                }

                // ---------------------- ES API response set-up ---------------------
                // if (document.getElementsByClassName('jobList')[0] !== undefined) {
                //     document.getElementsByClassName('jobList')[0].scrollTop = 0;
                // }
                // setItemList(result.data.json.response.data);
                // setJobDescription(result.data.json.response.data[0])
                // setActiveColor(result.data.json.response.data[0].jobId)
                // setPageCount(Math.ceil(result.data.json.response.count / per_page));
            })
            .catch((error) => {
                setLoader(false);
                if (error.response.status >= 400 && error.response.status < 500) {
                    setItemList("");
                    if (error.response.status === 404) {
                        setShowErrorPopup(false);
                        setShowErrMessage("");
                    } else {
                        setShowErrorPopup(true);
                        setShowErrMessage(error.response.data.error.message);
                    }
                } else {
                    navigate("./error");
                }
            }
            );
    }

    // search by skill api
    let SearchSkillList = () => {
        // setLoader(true);
        let searchJson = {
            "json": {
                "request": {
                    servicetype: 6,
                    functiontype: 6001,
                    data: {

                    }
                }
            }
        }

        let searchApiUrl = config.baseUrl + "/JobzTop/SearchJob?request=" + encodeURIComponent(JSON.stringify(searchJson));

        axios.post(searchApiUrl)
            .then((result) => {
                // setLoader(false);
                let temp = result.data.json.response
                if (temp.statuscode === "0") {
                    setSkill(temp.data);
                } else {
                    setShowErrorPopup(true);
                    setShowErrMessage(temp.statusmessage)
                }
            }).catch((err) => {
                setLoader(false);
                if (err.response.status >= 400 && err.response.status < 500) {
                    setShowErrorPopup(true);
                    setShowErrMessage(err.response.data.error.message);
                } else {
                    navigate("./error");
                }
            })
    }

    let onChangeLocation = (e) => {
        SearchLocation(e);
    }

    // /search by skill api

    // search by location api
    let SearchLocation = (e) => {
        // setLoader(true);
        let searchLocationJson = {
            "json": {
                "request": {
                    servicetype: 6,
                    functiontype: 6004,
                    data: {
                        "city": e
                    },
                    "sort": {
                        "sortby": "city",
                        "orderby": "asc"
                    }
                }
            }
        }

        let searchLocationApiUrl = config.baseUrl + "/JobzTop/SearchJob?request=" + encodeURIComponent(JSON.stringify(searchLocationJson));

        axios.post(searchLocationApiUrl)
            .then((result) => {
                // setLoader(false);
                let locationTemp = result.data.json.response;
                if (locationTemp.statuscode === "0") {
                    setLocation(locationTemp.data);
                } else if (locationTemp.statuscode !== "20021") {
                    setShowErrorPopup(true);
                    setShowErrMessage(locationTemp.statusmessage)
                }
            }).catch((err) => {
                setLoader(false);
                if (err.response.status >= 400 && err.response.status < 500) {
                    setShowErrorPopup(true);
                    setShowErrMessage(err.response.data.error.message);
                } else {
                    navigate("./error");
                }
            })
    }
    // /search by location api

    // search by industry api
    let SearchIndustry = () => {
        // setLoader(true);

        let searchIndustryJson = {
            "json": {
                "request": {
                    servicetype: 6,
                    functiontype: 6002,
                    data: {

                    },
                    "sort": {
                        "sortby": "industry",
                        "orderby": "asc"
                    }
                }
            }
        }

        let searchIndustryApiUrl = config.baseUrl + "/JobzTop/SearchJob?request=" + encodeURIComponent(JSON.stringify(searchIndustryJson))

        axios.post(searchIndustryApiUrl)
            .then((result) => {
                // setLoader(false);
                let locationTemp = result.data.json.response
                if (locationTemp.statuscode === "0") {
                    setIndustry(locationTemp.data);
                } else {
                    setShowErrorPopup(true);
                    setShowErrMessage(locationTemp.statusmessage)
                }
            }).catch((err) => {
                setLoader(false);
                if (err.response.status >= 400 && err.response.status < 500) {
                    setShowErrorPopup(true);
                    setShowErrMessage(err.response.data.error.message);
                } else {
                    navigate("./error");
                }
            })
    }
    // /search by industry api

    // search by company api
    let searchCompany = () => {
        // setLoader(true);
        let companyJson = {
            "json": {
                "request": {
                    servicetype: 6,
                    functiontype: 6003,
                    data: {

                    },
                    "sort": {
                        "sortby": "Companyname",
                        "orderby": "asc"
                    }
                }
            }
        }

        let searchcompanyApiUrl = config.baseUrl + "/JobzTop/SearchJob?request=" + encodeURIComponent(JSON.stringify(companyJson))

        axios.post(searchcompanyApiUrl)
            .then((result) => {
                // setLoader(false);
                let companyTemp = result.data.json.response
                if (companyTemp.statuscode === "0") {
                    setCompany(companyTemp.data);
                } else {
                    setShowErrorPopup(true);
                    setShowErrMessage(companyTemp.statusmessage)
                }
            }).catch((err) => {
                setLoader(false);
                if (err.response.status >= 400 && err.response.status < 500) {
                    setShowErrorPopup(true);
                    setShowErrMessage(err.response.data.error.message);
                } else {
                    navigate("./error");
                }
            })
    }
    // /search by company api

    // search by category api
    let searchCategory = () => {
        // setLoader(true);
        let CategoryJson = {
            "json": {
                "request": {
                    "servicetype": "21",
                    "functiontype": "1014",
                    "industry": "_ALL_"
                }
            }
        }

        let searchCategoryApiUrl = config.baseUrl + "/JobzTop/QuestionBank?request=" + encodeURIComponent(JSON.stringify(CategoryJson))

        axios.post(searchCategoryApiUrl)
            .then((result) => {
                // setLoader(false);
                let categoryTemp = result.data.json.response
                if (categoryTemp.statuscode === "0") {
                    setCategory(categoryTemp.data);
                } else {
                    setShowErrorPopup(false)
                    setShowErrMessage(categoryTemp.statusmessage)
                }
                // if (result.status === 404) {
                //     throw "new UserNotFound()";
                // } else {
                //     throw "..........new UnexpectedError()";
                // }
            }).catch((err) => {
                setLoader(false)
                if (err.response.status >= 400 && err.response.status < 500) {
                    setShowErrorPopup(true);
                    setShowErrMessage(err.response.data.error.message);
                } else {
                    navigate("./error");
                }
            })
    }
    // /search by category api


    return (
        <>
            {loader && (
                <div className="loader">
                    <img src={loaderImage} alt="loader" />
                </div>
            )}
            {/* ---------------------> job apply modal <----------------- */}
            <Details
                showDetailsModal={showDetailsModal}
                setShowDetailsModal={setShowDetailsModal}
                getJobId={getJobId}
            />
            {/* -------------------------> get Job link modal <------------------ */}
            {showLinkModal &&
                <JobLinkModal
                    getLinkId={getLinkId}
                    showLinkModal={showLinkModal}
                    setShowLinkModal={setShowLinkModal} />
            }
            {/* -------------> Error and success modal <----------- */}
            {showErrorPopup &&
                <BackEndErrorMsg
                    showErrorPopup={showErrorPopup}
                    setShowErrorPopup={setShowErrorPopup}
                    message={showErrMessage}
                />}

            <div style={{
                minHeight: "100vh",
                background: "#F4F5F6 0% 0% no-repeat padding-box",
                opacity: "1"
            }}>
                <div className="findJob_nav_bar">
                    < NavBar />
                </div>

                <div className="main_container">
                    <div className="container findJob">
                        <div className="row" style={{ marginLeft: "0", marginRight: "0" }}>
                            <div className="main_searchBox">
                                <div className="col-lg-5">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-lg-3 loc_label">
                                                <h6 className="font_14">LOOKING FOR</h6>
                                            </div>
                                            {/* <div className="col-lg-8 font_14">
                                                <Select
                                                    isMulti
                                                    value={selectedSkill}
                                                    onChange={(value) => onChangeSkill(value)}
                                                    placeholder={"Skills"}
                                                    options={skill.map((item, index) => {
                                                        return { value: item.skillId, label: item.skillName }
                                                    })}
                                                />
                                            </div> */}
                                            <div className="col-lg-8 font_14 search-input">
                                                <img src={searchIcon} alt="Search Icon" className="icon" style={{
                                                    width: '24px', height: '24px', marginRight: '8px', position: "absolute",
                                                    left: "85%",
                                                    top: "20%",
                                                    opacity: "16%"
                                                }} />
                                                <input
                                                    className="search-collection-name form-control input-new-border"
                                                    type="text"
                                                    placeholder="Search job title"
                                                    value={jobTitle}
                                                    onChange={handleInputChange}
                                                ></input>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-lg-3 loc_label" style={{ justifyContent: "center" }}>
                                                <h6 className="font_14">WHERE</h6>
                                            </div>
                                            <div className="col-lg-8 font_14">
                                                <Select
                                                    isMulti
                                                    value={locationSelected}
                                                    onChange={(value) => onChange_location(value)}
                                                    placeholder={"Location"}
                                                    options={location.map((item, index) => {
                                                        return { value: item.locationId, label: item.location }
                                                    })}
                                                    onInputChange={(e) => onChangeLocation(e)}
                                                // onKeyDown={handleEnterKey}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2 findJob_btn">
                                    <Button type="submit" onClick={() => onSearch()} id="submitButton">FIND JOB</Button>
                                </div>
                            </div>

                            <div className="search_filter">
                                <h6 style={{ marginRight: "25px", fontSize: "12px" }}>FILTER BY</h6>
                                <div className="Experience font_12">
                                    <Select
                                        isMulti
                                        value={selectedSkill}
                                        onChange={(value) => onChangeSkill(value)}
                                        placeholder={"Skills"}
                                        options={skill.map((item, index) => {
                                            return { value: item.skillId, label: item.skillName }
                                        })}
                                    />
                                </div>
                                {/* search by experience */}
                                <div className="Experience font_12">
                                    <Select
                                        isMulti={false}
                                        value={experience}
                                        onChange={(value) => onChange_experience(value)}
                                        placeholder={"Experience"}
                                        options={exper.map((item) => {
                                            return { value: item, label: item }
                                        })}
                                    // onKeyDown={handleEnterKey}
                                    />
                                </div>

                                {/* search by industry */}
                                <div className="main_select font_12">
                                    <Select
                                        isMulti={false}
                                        value={industrySelected}
                                        onChange={(o) => onChange_industry(o)}
                                        placeholder={"Industry"}
                                        options={industry.map((itm, ind) => {
                                            return { value: itm.industryId, label: itm.industryName }
                                        })}
                                    // onKeyDown={handleEnterKey}
                                    />
                                </div>

                                {/* search by company */}
                                <div className="main_select font_12">
                                    <Select
                                        isMulti={false}
                                        value={companySelected}
                                        onChange={(ob) => onChange_company(ob)}
                                        placeholder={"Company"}
                                        options={company.map((itme, ind) => {
                                            return { value: itme.companyId, label: decodeURIComponent(itme.companyName) }
                                        })}
                                    // onKeyDown={handleEnterKey}
                                    />
                                </div>

                                {/* search by Category */}
                                {/* <div className="main_select">
                                    <Select
                                        isMulti={false}
                                        // value={}
                                        // onChange={(e) => onChange_category(e)}
                                        placeholder={"Category"}
                                        options={category.map((item, index) => {
                                            return { label: item }
                                        })}
                                        onKeyDown={handleEnterKey}
                                    />
                                </div> */}

                                <div className="resetBtn">
                                    <Button onClick={() => resetFilter()}>
                                        Reset All
                                    </Button>
                                </div>
                            </div>

                            <>
                                {
                                    itemlist.length > 0 ?
                                        <div className="conteiner-fluid">
                                            <div className="row main_jobList">

                                                {/* job notification */}
                                                <div className="col-lg-6">
                                                    {/* <div className={itemlist.length >= 10 ? "jobList" : "jobList_2"}> */}
                                                    <div className="jobList">
                                                        {
                                                            itemlist?.map((itemname, index) => (
                                                                <div key={index} className={activeColor == itemname.jobId ? "jobList_box_active" : "jobList_box"}>
                                                                    <div className="row apply_job">
                                                                        <div className="col-lg-6 applyBtn">
                                                                            <h6 onClick={() => jobDetails(itemname)}>{itemname?.jobTitle}</h6>
                                                                            <span>{decodeURIComponent(itemname?.companyName)}</span>
                                                                        </div>
                                                                        <div className="col-lg-6 apply_btn">
                                                                            <Button onClick={() => onChangeDetailsPage(itemname)}>
                                                                                Apply
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="container-fluid jobList_childBox">
                                                                        {/* <div className="row">
                                                                            <div className="col-lg-3">
                                                                                <label>
                                                                                    <i className="bi bi-briefcase" style={{ marginRight: "3px" }}></i>
                                                                                    {itemname?.min}-{itemname?.max} years
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-lg-3">
                                                                                <label>
                                                                                    <i className="bi bi-briefcase" style={{ marginRight: "3px" }}></i>
                                                                                    {decodeURIComponent(itemname.type == undefined ? jobType : itemname.type)}
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-lg-3">
                                                                                <i className="bi bi-geo-alt"></i>
                                                                                <label style={{ paddingLeft: "6px" }}>
                                                                                    {itemname?.location.map((item, index) => (
                                                                                        <>
                                                                                            <label>{decodeURIComponent(item.city)}</label>
                                                                                            {itemname?.location.length !== index + 1 ?
                                                                                                <>, </> : null
                                                                                            }
                                                                                        </>
                                                                                    ))}
                                                                                </label>
                                                                            </div>
                                                                        </div> */}
                                                                        <div className="jobList_childBox">
                                                                            <label style={{ cursor: "text" }}>
                                                                                <i className="bi bi-briefcase" style={{ marginRight: "3px" }}></i>
                                                                                {itemname?.max >= 100
                                                                                    ? itemname?.experienceLevel
                                                                                    : `${itemname?.min}-${itemname?.max} years`}
                                                                            </label>
                                                                            <span style={{ padding: "0 14px 0 14px" }}>|</span>
                                                                            <label style={{ cursor: "text" }}>
                                                                                <i className="bi bi-briefcase" style={{ marginRight: "3px" }}></i>
                                                                                {decodeURIComponent(itemname.type == undefined ? jobType : itemname.type)}
                                                                            </label>
                                                                            <span style={{ padding: "0 14px 0 14px" }}>|</span>
                                                                            <label style={{ cursor: "text" }}>
                                                                                <i className="bi bi-geo-alt"></i>
                                                                                <label style={{ cursor: "text" }}>
                                                                                    {itemname?.location.map((item, index) => (
                                                                                        <>
                                                                                            <label style={{ cursor: "text" }}>{decodeURIComponent(item.city)}</label>
                                                                                            {itemname?.location.length !== index + 1 ?
                                                                                                <>, </> : null
                                                                                            }
                                                                                        </>
                                                                                    ))}
                                                                                </label>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row req_skill">
                                                                        <div className="col-lg-1">
                                                                            <img src={settingIcon} alt="skill" style={{ cursor: "text" }} />
                                                                        </div>
                                                                        <div className="col-lg-11" style={{ padding: "0" }}>
                                                                            {itemname?.keySkill?.map((listItem, ind) => (
                                                                                <button style={{ cursor: "text" }}>
                                                                                    <span key={ind} style={{ color: "#101010", fontSize: "12px" }}>
                                                                                        {listItem.skillName}
                                                                                    </span>
                                                                                </button>
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        {
                                                            pageCount !== 1 ? (
                                                                <ReactPaginate
                                                                    previousLabel={<i className="bi-arrow-left"></i>}
                                                                    nextLabel={<i className="bi-arrow-right"></i>}
                                                                    pageCount={pageCount} //state
                                                                    onPageChange={handlePageClick}
                                                                    containerClassName={"pagination"}
                                                                    previousLinkClassName={"pagination__link"}
                                                                    nextLinkClassName={"pagination__link"}
                                                                    disabledClassName={"pagination__link--disabled"}
                                                                    activeClassName={"pagination__link--active"}
                                                                    breakClassName="page-item"
                                                                    breakLinkClassName="page-link"
                                                                    forcePage={currentPage}
                                                                    previousClassName={currentPage === 0 ? 'hidden' : ""}
                                                                    nextClassName={currentPage === pageCount - 1 ? 'hidden' : ""}
                                                                />
                                                            ) : null
                                                        }
                                                    </div>
                                                </div>
                                                {/* job description */}
                                                {
                                                    window.outerWidth >= "992" ?
                                                        <div className="col-lg-6 job_description" id="scrollUp">
                                                            <div className="container-fluid">
                                                                <div className="jobButton">
                                                                    <h4 style={{ fontSize: "16px", fontWeight: "bold" }}>{jobDescription?.jobTitle}</h4>
                                                                    <div className="jobBtn">
                                                                        <img src={shareIcon} style={{ marginRight: "23px", width: "15%", cursor: "pointer" }} onClick={() => getLinkModal(jobDescription?.referenceCode)} />
                                                                        <Button onClick={() => onChangeDetailsPage(jobDescription)}>
                                                                            Apply
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                                <p style={{ fontSize: "14px", marginTop: "-11px" }}>{decodeURIComponent(jobDescription?.companyName)}</p>
                                                            </div>
                                                            <div className="container-fluid job_details">
                                                                {/* <div className="row">
                                                                    <div className="col-lg-4">
                                                                        <i className="bi bi-geo-alt" style={{ marginRight: "3px" }}></i>
                                                                        {jobDescription?.location.map((item, index) => (
                                                                            <>
                                                                                <label>{decodeURIComponent(item.city)}</label>
                                                                                {jobDescription?.location.length !== index + 1 ?
                                                                                    <>, </> : null
                                                                                }
                                                                            </>
                                                                        ))}
                                                                    </div>
                                                                    <div className="col-lg-4">
                                                                        <label>
                                                                            <i className="bi bi-briefcase" style={{ marginRight: "3px" }}></i>
                                                                            {jobDescription?.min}-{jobDescription?.max} years
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-lg-4">
                                                                        <label>
                                                                            <i className="bi bi-briefcase" style={{ marginRight: "3px" }}></i>
                                                                            {decodeURIComponent(jobDescription.type == undefined ? jobType : jobDescription.type)}
                                                                        </label>
                                                                    </div>
                                                                </div> */}
                                                                <div className="jobList_childBox">
                                                                    <label>
                                                                        <i className="bi bi-briefcase" style={{ marginRight: "3px" }}></i>
                                                                        {jobDescription?.min}-{jobDescription?.max} years
                                                                    </label>
                                                                    <span style={{ padding: "0 14px 0 14px" }}>|</span>
                                                                    <label>
                                                                        <i className="bi bi-briefcase" style={{ marginRight: "3px" }}></i>
                                                                        {decodeURIComponent(jobDescription.type == undefined ? jobType : jobDescription.type)}
                                                                    </label>
                                                                    <span style={{ padding: "0 14px 0 14px" }}>|</span>
                                                                    <label>
                                                                        <i className="bi bi-geo-alt"></i>
                                                                        <label>
                                                                            {jobDescription?.location.map((item, index) => (
                                                                                <>
                                                                                    <label>{decodeURIComponent(item.city)}</label>
                                                                                    {jobDescription?.location.length !== index + 1 ?
                                                                                        <>, </> : null
                                                                                    }
                                                                                </>
                                                                            ))}
                                                                        </label>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <hr></hr>
                                                            <div className="container-fluid req_skill">
                                                                <div className="row">
                                                                    <div className="col-lg-3">
                                                                        <h6>Required Skills</h6>
                                                                    </div>
                                                                    <div className="col-lg-9 skill">
                                                                        {jobDescription?.keySkill.map((item, index) => (
                                                                            <>
                                                                                {
                                                                                    item?.mandatory &&
                                                                                    <button>
                                                                                        <span key={index}>{item?.skillName}</span>
                                                                                        {item?.skillLevelExp === 0 ? null : <span style={{ color: "#fd7e14" }}> {(item?.skillLevelExp / 12)}
                                                                                            {item?.skillLevelExp === 12 ? <> year</> : <> years</>}</span>}
                                                                                    </button>
                                                                                }
                                                                            </>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="container-fluid good_skill">
                                                                <div className="row">
                                                                    <div className="col-lg-3">
                                                                        <h6>Good to have skills</h6>
                                                                    </div>
                                                                    <div className="col-lg-9">
                                                                        {jobDescription?.keySkill.map((item, index) => (
                                                                            <>
                                                                                {
                                                                                    item?.preferable &&
                                                                                    <button>
                                                                                        <span key={index}>{item?.skillName}</span>
                                                                                        {/* {item?.skillLevelExp === 0 ? null : <span style={{ color: "#fd7e14" }}> {(item?.skillLevelExp / 12)}
                                                                                            {item?.skillLevelExp === 12 ? <> year</> : <> years</>}</span>} */}
                                                                                    </button>
                                                                                }
                                                                            </>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="container-fluid soft_skill">
                                                                <div className="row" style={{
                                                                    display: "flex",
                                                                    alignItems: "baseline"
                                                                }}>
                                                                    <div className="col-lg-3 title">
                                                                        <h6>Soft Skills</h6>
                                                                    </div>
                                                                    <div className="col-lg-9 skill">
                                                                        {jobDescription?.softSkills?.length == 0 ?
                                                                            <button>none</button>
                                                                            :
                                                                            jobDescription?.softSkills?.map((item, index) => (
                                                                                <button>
                                                                                    <span key={index}>{item.skillName}</span>
                                                                                </button>
                                                                            ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="container-fluid decription">
                                                                <div>
                                                                    <h6>Description</h6>
                                                                </div>
                                                                <div>
                                                                    <p style={{ fontSize: "12px" }}>
                                                                        <span dangerouslySetInnerHTML={{
                                                                            __html: jobDescription.jobDescription
                                                                        }}></span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="container-fluid client">
                                                                {jobDescription.clientName && (
                                                                    <div>
                                                                        <h6>Client Name</h6>
                                                                    </div>
                                                                )}
                                                                <div>
                                                                    <p style={{ fontSize: "12px" }}>
                                                                        <span
                                                                            dangerouslySetInnerHTML={{
                                                                                __html: jobDescription.clientName
                                                                            }}
                                                                        ></span>
                                                                    </p>
                                                                </div>
                                                            </div>

                                                            <div className="container-fluid clientDescription">
                                                                {jobDescription.aboutCompany ? (
                                                                    // If aboutCompany is present, show Company Description
                                                                    <div>
                                                                        <h6>Company Description</h6>
                                                                        <p style={{ fontSize: "12px" }}>
                                                                            <span
                                                                                dangerouslySetInnerHTML={{
                                                                                    __html: jobDescription.aboutCompany
                                                                                }}
                                                                            ></span>
                                                                        </p>
                                                                    </div>
                                                                ) : jobDescription.aboutClient ? (
                                                                    // If aboutCompany is not present, show Client Description
                                                                    <div>
                                                                        <h6>Client Description</h6>
                                                                        <p style={{ fontSize: "12px" }}>
                                                                            <span
                                                                                dangerouslySetInnerHTML={{
                                                                                    __html: jobDescription.aboutClient
                                                                                }}
                                                                            ></span>
                                                                        </p>
                                                                    </div>
                                                                ) : (
                                                                    // Optionally, you can add a fallback if neither are present
                                                                    <p>No client or company description available.</p>
                                                                )}
                                                            </div>

                                                        </div>
                                                        :
                                                        (<div className="viewJob_modal">
                                                            <Modal show={showJobModal}
                                                                onHide={closeJobModal}
                                                                className="viewJob_modal">
                                                                <Modal.Header closeButton>
                                                                    {/* <Modal.Title>
                                                                                <h4 style={{ fontSize: "22px", fontWeight: "bold" }}>UI/UX Designer</h4>
                                                                                <p style={{ fontSize: "18px" }}>XYZ corporation Pvt Ltd.</p>
                                                                            </Modal.Title> */}
                                                                </Modal.Header>
                                                                <Modal.Body closeButton>
                                                                    <div className="col-lg-6 job_description " >
                                                                        <div className="container-fluid">
                                                                            <div className="jobButton">
                                                                                <h4 style={{ fontSize: "22px", fontWeight: "bold" }}>{jobDescription?.jobTitle}</h4>
                                                                                <div className="jobBtn">
                                                                                    <i className="bi-house house" style={{ marginRight: "6px" }} onClick={() => getLinkModal(jobDescription?.jobId)}></i>
                                                                                    <Button onClick={() => onChangeDetailsPage(jobDescription)}>
                                                                                        Apply
                                                                                    </Button>
                                                                                </div>
                                                                            </div>
                                                                            <p style={{ fontSize: "18px" }}>{decodeURIComponent(jobDescription?.companyName)}</p>
                                                                        </div>
                                                                        <div className="container-fluid job_details">
                                                                            {/* <div className="row">
                                                                                <div className="col-lg-3">
                                                                                    {jobDescription?.location.map((item, index) => (
                                                                                        <>
                                                                                            <label>{decodeURIComponent(item.city)}</label>
                                                                                            {jobDescription?.location.length !== index + 1 ?
                                                                                                <>, </> : null
                                                                                            }
                                                                                        </>
                                                                                    ))}
                                                                                </div>
                                                                                <div className="col-lg-4">
                                                                                    <label>
                                                                                        <i className="bi bi-briefcase" style={{ marginRight: "3px" }}></i>
                                                                                        {jobDescription?.min}-{jobDescription?.max} years
                                                                                    </label>
                                                                                </div>
                                                                                <div className="col-lg-3">
                                                                                    <label>
                                                                                        <i className="bi bi-briefcase" style={{ marginRight: "3px" }}></i>
                                                                                        {decodeURIComponent(jobDescription.type == undefined ? jobType : jobDescription.type)}
                                                                                    </label>
                                                                                </div>
                                                                            </div> */}
                                                                            <div className="jobList_childBox">
                                                                                <label>
                                                                                    <i className="bi bi-briefcase" style={{ marginRight: "3px" }}></i>
                                                                                    {jobDescription?.min}-{jobDescription?.max} years
                                                                                </label>
                                                                                <span style={{ padding: "0 14px 0 14px" }}>|</span>
                                                                                <label>
                                                                                    <i className="bi bi-briefcase" style={{ marginRight: "3px" }}></i>
                                                                                    {decodeURIComponent(jobDescription.type == undefined ? jobType : jobDescription.type)}
                                                                                </label>
                                                                                <span style={{ padding: "0 14px 0 14px" }}>|</span>
                                                                                <label>
                                                                                    <i className="bi bi-geo-alt"></i>
                                                                                    <label>
                                                                                        {jobDescription?.location.map((item, index) => (
                                                                                            <>
                                                                                                <label>{decodeURIComponent(item.city)}</label>
                                                                                                {jobDescription?.location.length !== index + 1 ?
                                                                                                    <>, </> : null
                                                                                                }
                                                                                            </>
                                                                                        ))}
                                                                                    </label>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <hr></hr>
                                                                        <div className="container-fluid req_skill">
                                                                            <div className="row">
                                                                                <div className="col-lg-3">
                                                                                    <h6>Required Skills</h6>
                                                                                </div>
                                                                                <div className="col-lg-9 skill">
                                                                                    {jobDescription?.keySkill.map((item, index) => (
                                                                                        <>
                                                                                            {
                                                                                                item?.mandatory.length !== 0 ?
                                                                                                    item?.mandatory &&
                                                                                                    <button>
                                                                                                        <span key={index}>{item?.skillName}</span>
                                                                                                    </button>
                                                                                                    :
                                                                                                    <button>
                                                                                                        <span>none</span>
                                                                                                    </button>
                                                                                            }
                                                                                        </>
                                                                                    ))}
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="container-fluid good_skill">
                                                                            <div className="row">
                                                                                <div className="col-lg-6">
                                                                                    <label>Good to have skills</label>
                                                                                </div>
                                                                                <div className="col-lg-6">
                                                                                    {jobDescription?.keySkill.map((item, index) => (
                                                                                        <>
                                                                                            {
                                                                                                item?.preferable.length !== 0 ?
                                                                                                    item?.preferable &&
                                                                                                    <button>
                                                                                                        <span key={index}>{item?.skillName}</span>
                                                                                                    </button>
                                                                                                    :
                                                                                                    <button>
                                                                                                        <span>none</span>
                                                                                                    </button>
                                                                                            }
                                                                                        </>
                                                                                    ))}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="container-fluid soft_skill">
                                                                            <div className="row" style={{
                                                                                display: "flex",
                                                                                alignItems: "baseline"
                                                                            }}>
                                                                                <div className="col-lg-4 title">
                                                                                    <h6>Soft Skills</h6>
                                                                                </div>
                                                                                <div className="col-lg-8 skill">
                                                                                    {jobDescription?.softSkills?.length == 0 ?
                                                                                        <button>none</button>
                                                                                        :
                                                                                        jobDescription?.softSkills?.map((item, index) => (
                                                                                            <button>
                                                                                                <span key={index}>{item.skillName}</span>
                                                                                            </button>
                                                                                        ))}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="container-fluid decription">
                                                                            <div>
                                                                                <h6>Description</h6>
                                                                            </div>
                                                                            <div style={{
                                                                                height: "22vh",
                                                                                overflowY: "scroll"
                                                                            }}>
                                                                                <p>
                                                                                    <span dangerouslySetInnerHTML={{
                                                                                        __html: jobDescription.jobDescription
                                                                                    }}></span>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="container-fluid client">
                                                                            <div>
                                                                                <h6>Client Name</h6>
                                                                            </div>
                                                                            <div>
                                                                                <p style={{ fontSize: "12px" }}>
                                                                                    <span dangerouslySetInnerHTML={{
                                                                                        __html: jobDescription.clientName
                                                                                    }}
                                                                                    ></span>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="container-fluid clientDescription">
                                                                            {jobDescription.aboutClient && (
                                                                                <div>
                                                                                    <h6>Client Description</h6>
                                                                                </div>
                                                                            )}
                                                                            <div>
                                                                                <p style={{ fontSize: "12px" }}>
                                                                                    <span
                                                                                        dangerouslySetInnerHTML={{
                                                                                            __html: jobDescription.aboutClient
                                                                                        }}
                                                                                    ></span>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Modal.Body>
                                                            </Modal>
                                                        </div>)
                                                }

                                            </div>
                                        </div>
                                        :
                                        (<div className="menu_cat_child2_noRecords_found">
                                            <img src={noDataFound} />
                                            <div className="text">
                                                <h3>Oops! It seems we couldn't find any jobs matching your search criteria.</h3>
                                                <h4>But don't let that dampen your spirits!</h4>
                                                <p style={{
                                                    marginBottom: "0",
                                                    marginTop: "20px"
                                                }}>Expand your horizons and explore different job categories, industries, or locations.</p>
                                                <p>Sometimes, the perfect fit is waiting where you least expect it!</p>
                                            </div>
                                        </div>)
                                }
                            </>

                        </div>
                    </div>
                </div>

                {/* <div className="home_footer">
                    <Footer />
                </div> */}
            </div>
        </>
    )
}

export default FindJob;